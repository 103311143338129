.product-details-page__container {
  display: flex;
  flex: 1 0 auto;
}

.product-details-page__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.product-details-page__menu {
  background-color: var(--pc__background-secondary_v2);
  height: max-content;
}
 
.product-details-page__content .activity:not(.activity__body) {
  margin: 0 var(--wallet-details__body-margin);
  margin-bottom: var(--wallet-details__body-margin);
  width: var(--wallet-details__body-width);
}

.product-details-page__wallet-details,
.product-details-page__transfers {
  margin: var(--wallet-details__body-margin) var(--wallet-details__body-margin);
}
@media (max-width:699px) {
  .product-details-page__wallet-details{
    margin: 0 2rem;
  }
  
}

.product-details-page__transfers-header {
  display: flex;
  padding: 0rem 1.5rem 0rem 3.5rem;
  line-height: 7rem;
  justify-content: space-between;
  background: var(--nav-header__bg-color);
  border-bottom: 2px solid var(--component__border-color--glow);
  font-size: 2rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 768px) {
  .product-details-page__content .activity:not(.activity__body) {
    width: auto;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 699px) {
  .product-details-page__container {
    flex-direction: column;
  }

  .product-details-page__content {
    flex-direction: column;
    flex: 1 0 auto;
  }
}

.product-details-page__transfers.product-details-container{
  
}
.product-details-page__transfers.product-details-container .pagination__previous.activity-pagination__previous a:nth-child(2),
.product-details-page__transfers.product-details-container .pagination__next.activity-pagination__next a:first-child{
  display: none;
}
.product-details-page__transfers.product-details-container .pagination__text.activity-pagination__text,
.product-details-page__transfers.product-details-container .pagination__page, .pagination__page:last-child{
  border: none;
}
.product-details-page__transfers.product-details-container .activity-pagination__text{
  height: max-content;
  width: max-content;
  color: var(--text-gray-v2);
}
.product-details-page__transfers.product-details-container .activity-pagination__page{
  width: max-content;
  margin: 0 1rem;
  
}
body #root .dark .product-details-page__transfers.product-details-container a.pagination__page.pagination__page--active.activity-pagination__page.activity-pagination__page--active{
  background: transparent!important;
  color: var( --pc_font-secundary)!important;
  text-decoration: underline!important;
}
.product-details-page__transfers.product-details-container{}
.product-details-page__transfers.product-details-container .activity-pagination__container{
  gap: 0!important;
  padding: 0!important;
}
.product-details-page__transfers.product-details-container .pagination__pages{
  /* margin: 0!important; */
  display: flex!important;
}
