.orders-content{
  max-width: 1450px;
  margin: auto;
  border-left: 1px solid var(--border-gray-v2);
  border-right: 1px solid var(--border-gray-v2);
}
.orders-table{
  position: relative;
}
.filters-exchange-history,
.filters-exchange-history > div{
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
  margin-left: 2rem;
  margin-bottom: 2rem;
}
.filters-exchange-history * {
  font-size: 1.2rem;
  font-weight: 400;
}
.filters-exchange-history .form-group{
  margin: 0;
}
.filters-exchange-history input{
  background-color: transparent!important;
  padding: 6px!important;
  height: auto;
  border-radius: 5px!important;
  font-size: 1.2rem;
  width: 100px;
}
.clear-filters__btn--clear-filters{
  background: #9b9bb117;
  color: white;
  border-radius: 5px;
  text-transform: capitalize!important;
}
.clear-filters__btn--clear-filters:focus{
  background: #9b9bb117!important;
}
.clear-filters__btn--clear-filters:hover{
  background: #9b9bb117;
}
.pika-single.is-bound{
  border: none;
  border-radius: 5px;
  box-shadow: 0 5px 7px -5px rgba(0,0,0,.5);
}
.pika-single.is-bound, .pika-single.is-bound *{
  background-color: #404060;
  color: #a8a8c7;
}
.pika-button.pika-day:hover{
background-color: #2e2e48;
}
.flex-table__body.order-history-table__body{
  gap: 0.5rem;
  padding: 0.5rem 0;
  font-size: 1.4rem;
}
.flex-table__column.order-history-table__column{
  font-size: 1.2rem;
  font-weight: 400;
}
body #root .dark .order-history-table__body {
  background: var(--background-primary_v2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.orders-table .flex-table__row,
.orders-table .flex-table__header--background{
  background-color: var(--background-primary_v2)!important;
}
body #root .dark .ap-tab__tab.order-history__tab .flex-table__header .flex-table__column{
  border: none!important;
}
body #root .dark .order-history-table__body::-webkit-scrollbar-thumb{
  background-color:var(--bg-dark-v2);
  border-radius: 6px;
}
body #root .dark .order-history-table__body::-webkit-scrollbar-track{
  background-color:var(--background-primary_v2);
  border-radius: 6px;
}
.order-history-table-pagination__content,.order-history-table__body{
  min-height: 220px;
}
.flex-table__row.order-history-table__row{
  padding: 1.5rem 1rem;
}
.flex-table__header--background{
  padding-bottom: .5rem;
  border-top: 1px solid var(--border-gray-v2);
}
.empty-orders{
  font-size: 1.4rem;
  color: var(--text-gray-v2);
}
.search-transactions-filters{
  color: white;
  background: #9b9bb14a;
  padding: 0 1rem;
  height: 32px;
  border-radius: 5px;
}
body #root .dark .exchange-container label.ap--label.ap-datepicker__label.custom-datepicker__dpk-label{
  color: var(--text-gray-v2)!important;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
}
.not-logged{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: #2e2e48d4;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-logged p{
  font-size: 1.4rem;
}
.not-logged a{
  font-size: 1.4rem;
  color: #FF9443;
}
@media (width < 785px) {
  .orders-table{
    overflow: auto
  }
  .not-logged{
    bottom: 30px;
  }
  .orders-table.not-logged-table{
    overflow: hidden;
  }
}