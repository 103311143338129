.banexcard-max-w{
  max-width: 500px;
  padding-bottom: 1rem!important;
  margin: auto;
}
.menu-card .main-options-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.menu-card .main-options{
  width: 100%;
}
.menu-card .main-options .options{
  margin-top: 2rem;
}
.menu-card .main-options .title p{
  font-size: 2.2rem;
  font-weight: 400;
  margin-bottom: 5px;
}
.menu-card .main-options .title strong{
  color: var(--pc_font-secundary);
  font-weight: 400;
}
.menu-card .main-options .title span{
  background-color: var(--success-color-v2);
  border-radius: 10px;
  padding: 4px 8px;
  font-weight: 400;
  margin-left: 10px;
  font-size: 1.3rem;
}
.menu-card .main-options .title small{
  font-size: 1.2rem;
  color: var(--text-gray-v2);
}
.menu-card .option{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-dark-v2);
  border-radius: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  transition: all .3s ease;
  width: 100%;
  cursor: pointer;
}
.menu-card .option:not(.desplegable, .toggle):hover {
  -webkit-box-shadow: 0px 4px 3px 1px var(--pc_font-secundary); 
  box-shadow: 0px 4px 3px 1px var(--pc_font-secundary)

}
.menu-card .option.desplegable{
  display: block;
}
.menu-card .option.desplegable .icon{
  transform: rotate(90deg);
  transition: all .3s ease;
}
.menu-card .option.desplegable .icon.open{
  transform: rotate(-90deg);
}
.menu-card .option > div:first-child,
.menu-card .option.desplegable .desplegable-first,
.menu-card .option.desplegable .desplegable-second{
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
.menu-card .option .image-container{
  background-color: var(--background-primary_v2);
  width: 40px;
  height: 40px;
  border-radius: 15px;
  display: grid;
  place-content: center;
}
.menu-card .option .image-container img{
  width: 20px;
  height: 20px;
}
.menu-card .option .title-description{
  max-width: 86%;
}
.menu-card .option .title-description p{
  font-size: 1.4rem;
}
.menu-card .option .title-description p:nth-child(2){
  color: var(--text-gray-v2);
  font-size: 1.2rem;
}
.menu-card .desplegable {
  /* height: 60px; */
  transition: all .3s ease;
  overflow: hidden;
}
.menu-card .desplegable.open{
  height: auto;
}
.menu-card .details-card-option{
  display: block!important;
  margin-top: 1rem;
}
.menu-card .details-card-option p{
  color: var(--text-gray-v2);
  font-size: 1.4rem;
}
.menu-card .details-card-option strong{
  color: white;
  margin-left: 5px;
  font-weight: 400;
}

.menu-card .option.toggle{
  cursor: default;
}