.banexcard-recharge-page{
  width: 96%;
  margin: 0 auto;
  max-width: 1400px;
  min-height: calc(100vh - 80px);
  display: grid;
  place-content: center;
}
.banexcard-recharge-w{
  width: 95%;
  min-width: 600px;
  max-width: 648px;
  padding-left: 6rem;
  padding-right: 6rem;
}
.banexcard-recharge-page .body{
  display: flex;
  gap: 4rem;
  align-items: center;
  margin-top: 2rem;
}
.banexcard-recharge-page .content{
  text-align: center;
}
.banexcard-recharge-page .balances{
  text-align: left;
  margin-top: 2rem;
}
.banexcard-recharge-page .balances > p:first-child{
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.banexcard-recharge-page .balances .balance-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.banexcard-recharge-page .balance-row *{
  font-size: 1.4rem;
  color: var(--text-gray-v2);
}
.banexcard-recharge-page .balance-row .value{
  font-size: 1.6rem;
}
.banexcard-recharge-page .balance-row img{
  margin-right: 5px;
}
.banexcard-recharge-page .balance-row .value img{
  margin-left: 60px;
}
.banexcard-recharge-page .inputs{
  display: flex;
  gap: 2rem;
  margin-top: 4rem;
}
.banexcard-recharge-page .inputs > div{
  padding: 1rem;
  flex: .333;
}
.banexcard-recharge-page .inputs .item {
  flex: .4;
}
.banexcard-recharge-page .inputs .item.commission{
  flex: .2;
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
}
.banexcard-recharge-page .inputs .item.commission p:nth-child(2){
  font-size: 2rem;
}
.banexcard-recharge-page .inputs .item p{ 
  color: var(--text-gray-v2);
  font-size: 1.4rem;
}
.banexcard-recharge-page .content h2 img,
.banexcard-recharge-page .inputs .item img,
.banexcard-recharge-page .balance-remaining .remaining-aprox img{
  width: 18px;
  height: 18px;
  margin-left: 5px;
} 
.banexcard-recharge-page .balance-remaining .remaining-aprox img{
  margin-top: -5px;
}
.banexcard-recharge-page .inputs .item p.value{
  color: white;
  font-size: 1.6rem;
} 
.banexcard-recharge-page .inputs .item input,
.banexcard-recharge-page .inputs .item .total{
  padding: 1rem;
  font-size: 1.8rem;
  text-align: center;
  font-size: 2rem!important;
  margin-top: -5px;
  background-color: var(--pc-inputs-background);
  border-radius: 15px;
}
.banexcard-recharge-page .inputs .item .total{
  border: 1px solid #a8a8c738;
  height: 56px;
  margin-top: 10px;
}
.banexcard-recharge-page .inputs .input-element.second>div {
  border: 1px solid #a8a8c738;
}
.banexcard-recharge-page .inputs .item .total p{
  color: white;
  font-size: 1.8rem;
  margin-bottom: 0;
  margin-top: 4px;
}
.card-icon, .wallet-icon{
  margin-bottom: 4px;
}
.banexcard-recharge-page .commission-details{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 4rem;
}
.banexcard-recharge-page .commission-details .details > div{
  display: flex;
  gap: 2rem;
  text-align: left;
}
.banexcard-recharge-page .commission-details .details > div > span{
  width: 126px;
}
.banexcard-recharge-page .commission-details .details .headers{
  color: var(--text-gray-v2);
  font-size: 1.2rem;
}
.banexcard-recharge-page .commission-details .details .commission-row *{
  color: var(--text-gray-v2);
  opacity: .2;
  transition: .2s all;
}
.banexcard-recharge-page .commission-details .details .commission-row.active *{
  opacity: 1;
  color: white;
}
.banexcard-recharge-page .change-type-value{
  color: var(--text-gray-v2);
}
.banexcard-recharge-page .balance-remaining{
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}
.banexcard-recharge-page .balance-remaining *,.banexcard-recharge-page .commission-details *{
  font-size: 1.4rem;
  color: var(--text-gray-v2);
}
.banexcard-recharge-page .balance-remaining p:first-child{
  color: var(--text-gray-v2);
  font-size: 1.3rem;
}
.banexcard-recharge-page .balance-remaining p strong{
  font-weight: 400;
  color: white;
}
.banexcard-recharge-page .body .left{
  position: relative;
}
.banexcard-recharge-page .body .left .coins{
  display: flex;
  flex-direction: column;
  gap: .6rem;
  align-items: flex-end;
  position: absolute;
  top: -80px;
  right: -14px;
}
/* DETAILS CONFIRMATION */
.details-confirmation-w{
  min-width: 500px;
}
.banexcard-recharge-page .details-confirmation,
.banexcard-recharge-page .recharge-result{
  width: 100%;
  text-align: center;
}
.banexcard-recharge-page .details-confirmation .details-confirmation-row,
.banexcard-recharge-page .recharge-result .recharge-result-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.banexcard-recharge-page .details-confirmation .details-confirmation-row span,
.banexcard-recharge-page .recharge-result .recharge-result-row span{
  color: var(--text-gray-v2);
  font-size: 1.4rem;
}
.banexcard-recharge-page .details-confirmation .details-confirmation-row span.value,
.banexcard-recharge-page .recharge-result .recharge-result-row span.value{
  color: white;
}
.banexcard-recharge-page .details-confirmation .bnx-bg-black{
  position: absolute;
  opacity: .7;
  z-index: 1;
  right: 45%;
  top: 35%;
}

/* 2FA CONFIRMATION */
.banexcard-recharge-page .recharge-result .line-gradient{
  margin-bottom: 2rem;
}
.banexcard-recharge-page .recharge-result .final-amount-recharge{
  padding: 1rem;
  font-size: 1.6rem;
  font-weight: 400;
  border-radius: 10px;
  background: linear-gradient(180deg, #454763 0%, rgba(69, 71, 99, 0.00) 100%);
  -webkit-box-shadow: 0px 4px 3px 1px #2e2e48; 
  box-shadow: 0px 4px 3px 1px #2e2e48;
  margin-bottom: 2rem;
}
.banexcard-recharge-page .recharge-result .final-amount-recharge p:last-child{
  font-size: 2.4rem;
}
.banexcard-recharge-page .recharge-result-error{
  max-width: 500px;
}
.banexcard-recharge-page .recharge-result-success{
  margin-top: 6rem;
}
.banexcard-recharge-page .range_container{
  margin-top: -1rem;
}
.calculating{
  font-size: 1.4rem;
  color: var(--text-gray-v2);
}