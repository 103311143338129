.confirm-single-report {
  display: flex;
  flex-direction: column;
  width: 32rem;
}

.confirm-single-report__lwt-container {
  height: 2.8rem;
  line-height: 2.8rem;
  border-top: 1px solid var(--border__color);
  justify-content: flex-start;
}

.confirm-single-report__lwt-text {
  color: var(--font__color-primary);
  padding-left: 3.3rem;
  font-size: 1.2rem;
}

.confirm-single-report__lwt-label {
  width: 8.3rem;
  font-size: 1.2rem;
}

.confirm-report-modal__header {
  height: 6.8rem;
  padding: 2.8rem;
  border-bottom: 2px solid var(--tab__border-color--selected);
  font-size: 2rem;
}

.confirm-report-modal__content {
  padding: 2.8rem 2.8rem 12.5rem;
}

.confirm-report-modal__footer {
  padding: 2.8rem;
  margin: 0;
}

.confirm-report-modal__btn {
  width: 100%;
  height: 4.4rem;
  background: var(--success__bg-color);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 1.4rem;
}

.confirm-report-modal {
  border-radius: 10px;
}

.confirm-report-modal__close-icon {
  padding: 1.3rem;
  color: var(--success__bg-color);
}

.confirm-single-report__title {
  font-size: 1.4rem;
  padding-bottom: 2rem;
  margin: 0;
}
