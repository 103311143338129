.public-auth{
  font-size: 1.4rem;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  color: white;
  transition: all 0.3s ease;
  margin-left: 21px;
}
.public-auth.register{
  background: var(--primary-color-v2);
  margin-left: -10px;
}
.public-auth:hover{
  color: white;
  transform: translateY(-5px);
}

@media (width < 480px) {
  .public-auth{
    margin-left: 0;
  }
}