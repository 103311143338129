.schedule-report {
  height: 100%;
}

.schedule-report-list {
  height: auto;
  display: flex;
  max-height: 22.4rem;
  flex-direction: column;
}
.flex-table__column {
  align-content: initial;
  text-align: inherit;
}
.flex-table__header-cell.flex-table__header-cell--absolute.cyclical-report-class__fixed.cyclical-report-class__fixed--type {
  margin-left: 3rem;
}
.flex-table__column.cyclical-report-class__column.cyclical-report-class__column--type {
margin-left: 3rem;
}
.flex-table__column.cyclical-report-class__column.cyclical-report-class__column--frequency {
  margin-left: -3rem;
}
.flex-table__header-cell.flex-table__header-cell--absolute.cyclical-report-class__fixed.cyclical-report-class__fixed--actions{
  margin-left: 8rem;
}
button.ap-inline-btn__btn.ap-inline-btn__btn--subtractive.cyclical-report-class__action-btn__btn.cyclical-report-class__action-btn__btn--subtractive {
  margin-left: 8rem;
}
.flex-table__header.flex-table__header--background.cyclical-report-class__table-header.cyclical-report-class__table-header--background{
  border-bottom: 2px solid var(--table__row-separator-color);
  padding-bottom: 2rem;
}
.schedule-report-list__body {
  min-width: 70rem;
}
.flex-table__row.download-report__row{
  height: 3rem!important;
}
.flex-table.cyclical-report-class.cyclical-report-class__body.cyclical-report-class__body--single-page {
  background: var(--pc__background-secondary_v2);
}
.flex-table__row.cyclical-report-class__row {
  padding-bottom: 3rem;
}
.flex-table__body.cyclical-report-class__body {
  margin-top: 2rem;
  overflow: visible;
}

.schedule-report-list__container {
  margin-bottom: 2rem;
}

.schedule-report-list__body.flex-table {
  background: var(--advanced-settings__bg-color);
}

.schedule-report-list__fixed {
  position: relative;
  margin: 0rem;
}

.schedule-report-list__fixed.flex-table__header-cell {
  text-align: left;
}

.schedule-report-list__header {
  display: flex;
  align-items: center;
  color: var(--settings__card-header-title-font-color);
  background: var(--settings__card-header-bg-color);
  height: var(--settings__body-header-with-border-height);
  padding: var(--settings__card-header-padding);
  justify-content: space-between;
  border-bottom: var(--settings__card-header-accent-border);
  font-size: var(--settings__body-header-title-font-size);
  letter-spacing: var(--settings__card-header-title-letter-spacing);
}

.schedule-report-list__table-title,
.schedule-report-list__header-column--type,
.schedule-report-list__column--type {
  padding-left: var(--settings__body-header-title-padding-left);
}

.schedule-report-list__row {
  border-bottom: 1px solid var(--border__color);
  height: 4rem;
}

.schedule-report-list__row:last-child {
  border-bottom: none;
}

.schedule-report-list__column {
  flex: 1;
  color: var(--font__color-primary);
  text-align: left;
}

.schedule-report-list__action-btn__btn {
  display: block;
  width: calc(100% - 1.5rem);
}

.schedule-report-list__action-btn__btn:hover{
  background: var(--button-inline__bg-color);
}

.schedule-report-list__action-btn__btn:active{
  background: transparent;
}

.schedule-report-list__action-btn__image--cancel > svg {
  width: 1rem;
  height: 1rem;
}

.schedule-report-list__column--actions,
.schedule-report-list__header-column--actions {
  max-width: 16rem;
}

.schedule-report-list__column--created,
.schedule-report-list__header-column--created {
  max-width: 20rem;
  letter-spacing: normal;
}

.schedule-report-list__refresh-reports__btn {
  border: none;
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-report-list__refresh-reports__image {
  margin-top: 4px;
}

.schedule-report-list__refresh-reports__btn:hover {
  background: var(--secondary__bg-color--hover);
}

.schedule-report-list__spinner-container {
  position: relative;
  width: 10rem;
}

.schedule-report-list__table-header {
  border-bottom: 2px solid var(--table__row-separator-color);
}

.schedule-report-list__table-header:not(.flex-table__header--background),
.download-report__table-header:not(.flex-table__header--background) {
  height: 3rem;
  margin-top: -3rem;
}
.schedule-report-list .flex-table__header-cell,
.download-report__body .flex-table__header-cell {
  margin: 0.75rem 0;
}
