.depth-chart {
  position: relative;
  height: 100%;
  width: 100%;
}

.depth-chart__buttons {
  position: absolute;
  top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  z-index: 3;
}

.depth-chart__button--zoom {
  border: 1px solid #888282;
  color: #9a9494;
  padding: 0.1rem 0.4rem;
  line-height: 1;
  border-radius: 3px;
  font-size: 1.2rem;
  margin: 0 0.5rem;
}

.depth-chart__label {
  position: absolute;
  top: 3.8rem;
  width: 100%;
  text-align: center;
  font-size: 1rem;
}

.depth-chart__zoom-label {
  position: absolute;
  top: 2.7rem;
  width: 100%;
  text-align: center;
  font-size: 1rem;
}

.depth-chart__header {
  height: var(--component__header-height);
  line-height: var(--component__header-height);
  background: var(--component__header-bg-color);
  padding: 0 1.75rem;
  border-bottom: 1px solid var(--component__header-separator-color);
}

@media only screen and (max-width: 1280px) {
  .depth-chart__header {
    display: none;
  }

  .depth-chart {
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .depth-chart__button--zoom {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0 1rem;
  }
  .depth-chart__zoom-label {
    top: 3rem;
  }
  .depth-chart__label {
    top: 4.1rem;
  }
}
