.modal-2fa{
  max-width: 90%;
  width: 400px;
  color: white;
}
.modal-2fa-header img{
  position: absolute;
  right: 20px;
  top: 20px;
}
.modal-2fa-header{
  margin-top: 3rem;
}
.modal-2fa-header p{
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}
.modal-2fa input[type="number"]::-webkit-inner-spin-button,
.modal-2fa input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-2fa-body {
  padding: 1rem 2rem 0!important;
}
.modal-2fa-body p.desc{
  margin-top: 2rem;
  font-size: 1.6rem;
}
.modal-footer{
  border: none;
}
.modal-2fa-body .form-group{
  width: 94px;
  margin: auto;
}
.modal-2fa-body .form-group input{
  font-size: 1.8rem!important;
}
.modal-2fa .modal-footer button{
  width: 100%;
  margin: 1rem 2rem;
}
.modal-2fa .text-danger{
  padding: 0!important;
  height: 24px;
  font-weight: bold;
  color: var(--error-color-v2);
} 
.modal-2fa-withdraw-coink{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.modal-2fa-withdraw-coink button{
  font-weight: 400!important;
  font-size: 1.6rem!important;
  border-radius: 18px!important;
  margin: 0!important;
  width: 45%!important;
}
.modal-2fa-withdraw-coink button:disabled{
  opacity: 0.5;
  cursor: not-allowed;
}