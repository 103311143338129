.remove-banexcard-page p{
  margin: 0;
}
.remove-banexcard-page{
  width: 96%;
  margin: 0 auto 4rem;
  max-width: 1400px;
  text-align: center;
  display: grid;
  place-content: center;
  min-height: 75vh;
}
.remove-result-w{
  max-width: 500px;  
}
.remove-banexcard-page h2{
  font-size: 2.2rem;
  text-align: center;
  font-weight: 400;
  margin: 2rem 0 0;
}
.remove-banexcard-w{
  max-width: 500px;
  padding-top: 30px;
  margin: 2rem auto;
}
.remove-banexcard-page > p:nth-child(2){
  background-color: #2c2e44;
  width: 100%;
  padding: 2rem;
  border-radius: 1.5rem;
  font-size: 1.8rem;
  color: var(--text-gray-v2);
  font-weight: 300;
  margin: 1rem 0;
}
.remove-banexcard-page .content{
  width: 100%;
}
.remove-banexcard-page .content p{
  color: var(--text-gray-v2);
  text-align: left;
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.unselected-circle{
  width: 15px;
  height: 15px;
  border: 1px solid var(--text-gray-v2);
  border-radius: 100%;
  opacity: .5;
}
.remove-banexcard-page .input-element  > div{
  padding-top: 10px;
  height: 100px;
}
.remove-banexcard-page .input-element  .input-element .textarea-container > textarea{
  height: 100%;
}
.remove-banexcard-page .input-element  .input-element .textarea-container{
  border-radius: 2rem;
}

/* ATTENTION MODAL */
.remove-banexcard-page .attention-modal{
  max-width: 500px;
  margin-top: 4rem;
}
.remove-banexcard-page .attention-modal h2{
  margin-top: 0;
}
.remove-banexcard-page .attention-content p{
  color: var(--text-gray-v2);
  font-size: 1.4rem;
  text-align: left;
  margin-bottom: 2.5rem;
}
.remove-banexcard-page .attention-content strong{
  color: var(--pc_font-secundary);
}
.remove-banexcard-page .attention-content strong.rest{
  color: white;
}

.remove-banexcard-page .attention-content ul{  
  margin-bottom: 2.5rem;
}
.remove-banexcard-page .attention-content ul > li{
  font-size: 1.4rem;
  text-align: left;
  color: var(--text-gray-v2);
}