.containerSteppr{
    padding: 0 !important;
    height: 100%;
}
.containerSteppr .all{
    display: grid;
    row-gap: 10px;
    height: 100%;
    display: grid;
    grid-template-rows: auto 123px;
    padding-top: 33px;
}
.containerSteppr .all .body{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.containerSteppr .all .body p span b{
    bottom: 0 !important;
}
.containerSteppr .all .footer{
    height: max-content;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-bottom: 33px;
}
.containerSteppr .all .footer button{
    height: 54px;
}
.containerSteppr .all .footer b{
    bottom: 0 !important;
}
.coink-app .containerSteppr .containerMesg{
    height: 100%;
    padding-top: 33px;
    display: grid;
    grid-template-rows: auto;
    align-items: center;
}
.coink-app .containerSteppr .containerMesg.upsError {
    padding-top: 0px;
    margin-bottom: 30px;
}
.coink-app .containerSteppr .containerMesg.upsError >div{
    text-align: center;
}
.coink-app .containerSteppr .containerMesg >div{
    padding-bottom: 33px;
}
.coink-app .containerSteppr .containerMesg >div >div{
    font-size: 1.4rem;
}
.containerSteppr.infStatusPage{
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerSteppr.infStatusPage h2{
    text-align: center;
}
.containerSteppr.infStatusPage p{
    width: 100%;
}