.activity-reporting-buttons__btn {
  width: var(--settings__trade-reports-create-new-actions-btn-width);
  color: var(--settings__trade-reports-create-new-actions-btn-font-color);
  background: var(--settings__trade-reports-create-new-actions-btn-bg-color);
}

.activity-reporting-buttons__btn:hover {
  background: var(--settings__trade-reports-create-new-actions-btn-bg-color);
}

.activity-reporting-buttons__btn:nth-of-type(2) {
  margin-top: 2rem;
}

.activity-reporting-buttons__btn:focus {
  border: none;
  background: var(--settings__trade-reports-create-new-actions-btn-bg-color);
}
