.banexcard-pin-creation{
  text-align: center;
  width: 96%;
  margin: 0 auto;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;
}
.banexcard-pin-creation.success{
  display: grid;
  place-content: center;
}
.banexcard-pin-creation h2{
  font-size: 2.2rem;
  text-align: center;
  font-weight: 400;
  margin: 2rem 0;
}
.banexcard-pin-creation p{
  color: var(--text-gray-v2);
  font-size: 1.4rem;
}
.banexcard-pin-creation button{
  margin-top: 2rem;
  background-color: var(--select-text-v2)!important;
  transition: all .3s!important;
}
.banexcard-pin-creation button:disabled{
  opacity: .5!important;
  cursor: not-allowed;
}
.banexcard-pin-creation button:hover{
  opacity: .5!important;
}
.banexcard-pin-creation h4{
  font-size: 2rem;
  font-weight: 400;
  margin: 2rem 0;
}
.banexcard-pin-creation .description{
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  margin-bottom: 2rem;
}
.banexcard-pin-creation .inputs-container{
  max-width: 300px;
  margin: 0 auto;
}