.claim-page {
    margin-top: 2rem;
}

.claim-page .react-tel-input .country-list {
    background-color: var(--background-primary_v2) !important;
    width: 180px;
    color: white;
    cursor: default;
}

.claim-page *::-webkit-scrollbar-thumb {
    background-color: var(--background-primary_v2);
}

.claim-page *::-webkit-scrollbar-track {
    background-color: var(--pc__background-secondary_v2);
}

.claim-page .react-tel-input .country-list .country.highlight,
.claim-page .react-tel-input .country-list .country:hover {
    cursor: pointer;
    background-color: var(--pc__background-secondary_v2) !important;
}

.claim-page .react-tel-input .flag-dropdown.open,
.claim-page .react-tel-input .flag-dropdown.open .selected-flag,
.claim-page .react-tel-input .flag-dropdown,
.claim-page .react-tel-input input.form-control:focus {
    background: var(--pc-inputs-background) !important;
}

.claim-page .react-tel-input .flag-dropdown {
    border-color: var(--pc-inputs-background);
    height: 50px;
    margin-top: 3px;
    margin-left: 3px;
}

.claim-page .react-tel-input {
    color: var(--text-gray-v2);
    background: var(--pc-inputs-background);
    border-radius: 15px;
    min-width: 150px;
    grid-auto-columns: auto;
    border: 2px solid transparent;
    margin-bottom: 5px;
}
.error-phone{
    border-color: var(--error-color-v2)!important;
}

.claim-page .phone-input-container{
    margin-bottom: 2rem;
}

.claim-page .react-tel-input input.form-control {
    height: 56px !important;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 16px;
    background: var(--pc-inputs-background) !important;
    outline: none;
    box-shadow: none !important;
    border-radius: 15px;
    border: none!important;
}

.claim-page .react-tel-input input.form-control::placeholder {
    color: var(--text-gray-v2);
}

.left-box-info {
    width: 100%;
    max-width: 560px;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 4rem;
    padding: 50px 20px 20px 10%;
}

@media (max-width:1200px) {
    .left-box-info {
        display: none;
    }
}

.title-left-box-info h2 {
    font-size: 26px;
}

.title-left-box-info p {
    color: var(--text-gray-v2);
    margin-top: 10px;
    font-size: 14px;
}

.left-box-info>div:nth-child(2) {
    border: 1px solid #39395e;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}

.left-box-info>div:nth-child(2) h2 {
    font-size: 14px;
    color: var(--text-gray-v2);
}

.left-box-info>div>h2:nth-child(3) {
    margin-top: 3rem;
}

.left-box-info>div:nth-child(2) p {
    font-size: 16px;
}

.claim-page input[type=number]::-webkit-inner-spin-button,
.claim-page input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.claim-page .title-radio-button-group {
    font-size: 14px;
}

.claim-page .radio-button-group {
    display: flex;
    justify-content: flex-start;
    gap: 5rem;
    align-items: center;
    margin-bottom: 2rem;
    margin-left: 2rem;
}

.claim-page .radio-button-group label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.4rem;
    margin-top: 1rem;
}

.claim-page .radio-button-group input[type="radio"] {
    display: none;
}

.claim-page .radio-button-group label {
    position: relative;
}

.claim-page .radio-button-group .customize-radio-btn {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: var(--pc__background-secondary_v2);
    position: absolute;
    border: 1px solid var(--background-primary_v2);
    left: -25px;
}

.claim-page .radio-button-group .customize-radio-btn:before {
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    background: var(--primary-color-v2);
    margin-top: 4px;
    margin-left: 3px;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    transition: all 0.25s ease;
    transform: scale(0);
    opacity: 0;
}

.claim-page .radio-button-group input[type=radio]:checked+i:before {
    transform: scale(1);
    opacity: 1;
}

.claim-page .captcha-container{
    margin-bottom: 2rem;
}

.claim-page .captcha-container > div >div > div{
    width: 340px;
    margin: auto;
}
.claim-page .captcha-container > div >div > div iframe{
    width: 100%;
}

.claim-page .captcha-container .rc-anchor.rc-anchor-normal.rc-anchor-light{
    margin: auto!important;
}