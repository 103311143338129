.exchange-content{
  display: flex;
  margin-top: 1rem;
  border-left: 1px solid var(--border-gray-v2);
  border-right: 1px solid var(--border-gray-v2);
  border-bottom: 1px solid var(--border-gray-v2);
  max-width: 1450px;
  margin: auto;
}
.exchange-content .first-column{
  width: 60%;
  padding: 2rem 0 0;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}
.exchange-content .second-column{
  width: 18%;
  padding: 2rem 0 0;
  border-left: 1px solid var(--border-gray-v2);
}
.exchange-content .third-column{
  width: 22%;
  padding: 2rem 0 0;
  border-left: 1px solid var(--border-gray-v2);
}
.depth-chart{
  background-color: var(--background-primary_v2)
}
.pt-1{
  padding-top: 1rem;
}
.-mt-50{
  margin-top: -50px;
}
.order-entry-buttons{
  display: none;
}
.order-entry-modal-backdrop{
  position: fixed;
  width: 0;
  top: 0;
  height: 0;
  transition: 0ms all;
  background-color: transparent;
}
.order-entry-modal-backdrop.active{
  background-color: #16161b86;
  width: 100vw;
  height: 100vh;
}
.order-entry-modal{
  position: fixed;
  z-index: 99999;
  bottom: 0;
  padding-top: 1rem;
  height: max-content;
  align-items: flex-end;
  width: 100%;
  transition: 300ms all;
  flex-direction: column;
  animation: displayAnimation 0.3s ease-in-out;
  display: none;
  transform: translateY(100%);
  opacity: 0;
  max-height: 100vh;
  overflow: auto;
}
.order-entry-modal > div{
  width: 100%;
  background-color: #2e2e48;
}
.order-entry-modal.show{
  display: flex;
  transform: translateY(0);
  opacity: 1;
}
@keyframes displayAnimation {
  100% {
    opacity: 1;
    transform: translateY(0);
    display: flex;
  }
}
.close-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem;
}
.close-container img{
  filter: invert(1);
  cursor: pointer;
}
@media ( width < 1500px ) {
  .-mt-50{
    margin-top: -15px;
  }
}

@media ( width < 1200px ) {
  .exchange-content .first-column{
    width: 70%;
  }
  .exchange-content .second-column{
    width: 30%;
  }
}
@media ( width < 785px ) {
  .exchange-content .first-column{
    width: 100%;
    display: block;
  }
  .exchange-content .second-column{
    display: none;
  }
  .order-entry-buttons{
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    text-align: center;
    background-color: #404060;
    text-align: center;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  .order-entry-buttons button{
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
    width: 45%;
    padding: 1rem;
    border-radius: 5px;
  }
  .order-entry-buttons button:first-child{
    background-color: var(--success-color-v2);
  }
  .order-entry-buttons button:nth-child(2){
    background-color: var(--error-color-v2);
  }
}
.instrument-table__value--24h-negative{
  color: var(--exchange-colors__sell)!important;
}
.instrument-table__value--24h-positive{
  color: var(--exchange-colors__buy)!important;
}