.affiliate__tag {
    margin-bottom: 10px;
    line-height: 1.27;
    font-size: 2rem;
    letter-spacing: 0.5px;
    color: var(--component__color-primary);
  }
  
  .affiliate__tag-description {
    max-width: 80rem;
    line-height: 1.27;
    letter-spacing: 0.5px;
    color: var(--component__color-primary);
    background: unset;
    cursor: pointer;
  }
  
  .affiliate__container-content {
    padding: 2rem;
  }
  
  .affiliate__btn-container {
    height: 5rem;
    margin: 6.7rem -2rem 0 -2rem;
    border-top: 1px solid;
    border-color: var(--affiliate__btn-border-color--hover);
  }
  
  .affiliate__btn {
    height: 3rem;
    line-height: 2.4rem;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    margin: 2rem 2rem 0 2rem;
    color: var(--affiliate__btn-color);
    border: 1px solid;
    border-radius: 0.2rem;
    border-color: var(--affiliate__btn-border-color);
    background-color: var(--affiliate__btn-bg-color);
  }
  
  .affiliate__btn:hover {
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    color: var(--affiliate__btn-color);
    border: 1px solid;
    border-color: var(--affiliate__btn-border-color--hover);
    background-color: var(--affiliate__btn-bg-color--hover);
  }
  
  .affiliate-modal__form {
    font-size: 1.4rem;
  }
  
  .affiliate-modal__input-wraper{
    min-height: 10rem;
  }
  
  .affiliate-modal__input {
    min-width: 320px;
  }
  
  .affiliate-modal__ap-modal {
    border-radius: 0.4rem;
  }
  
  .affiliate-modal__footer {
    margin: 0 -2.5rem 0 -2.5rem;
    min-height: 6rem;
    border-top: 1px solid;
    border-color: var(--affiliate__btn-border-color--hover);
  }
  
  .affiliate-modal__btn {
    height: 4rem;
    width: 32rem;
    margin: 2rem 2.5rem 0 2.5rem;
    align-self: center;
    justify-self: center
  }
  
  /* MEDIA QUERIES */
  
  /* Extra Small Devices, Phones */
  @media only screen and (max-width: 480px) {
    .affiliate-modal__ap-modal{
      width:100%;
    }
  
    .affiliate-modal__input{
      min-width: 100%;
    }
  
    .affiliate-modal__footer{
      margin: 0;
    }
    
    .affiliate-modal__btn{
      width: 100%;
      margin: 2.5rem 0 0;
    }
  }
  