.wallet-row-list {
  width: 100%;
  border-radius: 1rem;
  overflow-x: auto;
  max-height: 50vh;
}

.wallet-row-list::-webkit-scrollbar {
  width: 0.5rem;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .wallet-row-list {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}
