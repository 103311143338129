
.banexcard-page p{
  margin: 0;
}
.banexcard-page{
  display: grid;
  margin-top: 4rem;
  margin-bottom: 6rem;
}
.banexcard-page.has-banexcard-page{
  place-content: initial;
  margin-top: 0;
  margin-bottom: 2rem;
}
.card-with-request-details{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}
.request-details{
  background-color: #1D1E2B;
  border-radius: 1.6rem;
  padding: 2rem;
  max-width: 450px;
  margin-bottom: 2rem;
}
.banexcard-image{
  width: 200px;
  margin-top: -6rem;
}
.banexcard-page .title-pt1,
.banexcard-page .title-pt2 {
  font-size: 2.2rem;
  text-align: center;
  font-weight: 300;
}
.banexcard-page .title-pt2{
  margin-bottom: 4rem;
}
.request-details-item{
  display: grid;
  grid-template-columns: 70px 1fr;
  margin-bottom: 2rem;
  gap: 1.5rem;
}
.request-details-item-text p{
  font-size: 1.6rem;
  margin: 0;
}
.request-details-item-text span{
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  font-weight: 300;
}
.request-details-item-image{
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: #171723;
  display: grid;
  place-content: center;
  border: 2px solid #262633;
}
.request-details-item-image div{
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: grid;
  place-content: center;
  border: 2px solid #262633;
}
.has-banexcard{
  width: 96%;
  margin: 0 auto;
  max-width: 1400px;
}
.has-banexcard .header{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: var(--bg-dark-v2);
  padding: 1.2rem 1rem;
  height: 60px;
  border-radius: 1.6rem;
}

.has-banexcard .header .line-gradient{
  position: relative;
}
.has-banexcard .header .line-gradient.first,
.has-banexcard .header .line-gradient.second{
  width: 200px;
  height: 1px;
}
.has-banexcard .header .line-gradient::after{
  content: "";
  background-color: var(--text-gray-v2);
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  right: 0;
  top: -1px;
}
.has-banexcard .header .line-gradient.second::after{
  left: 0;
}
.has-banexcard .header .balance{
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0 2rem;
}
.has-banexcard .header img:first-child{
  margin-right: 20px;
}
.has-banexcard .header .balance p{
  margin: 0;
  color: var(--text-gray-v2);
  font-size: 1.4rem;
}
.has-banexcard .header .balance span{
  font-size: 1.4rem;
  color: var(--text-gray-v2);
}
.has-banexcard .header .balance .aprox{
  margin: 0 10px;
}
.has-banexcard .header .balance span.amount{
  color: white;
}
.has-banexcard span.aprox-value{
  margin-right: 20px;
}
.has-banexcard .body{
  display: grid;
  grid-template-columns: 30% 35% 35%;
  place-content: center;
  gap: 2rem;
  margin: 4rem auto;
}
.has-banexcard .body .left{
  padding: 2rem;
  text-align: center;
  width: 280px;
  margin: auto;
}
.has-banexcard .body .left img.card-image{
  width: 150px;
  margin: auto;
}
.has-banexcard .left .details{
  background-color: var(--bg-dark-v2);
  padding: 1rem .5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: left;
  border-radius: 1.6rem;
  height: 52px;
  margin-top: 1rem;
}
.has-banexcard .left .details .label{
  color: var(--text-gray-v2);
  font-size: 1.2rem;
  font-weight: 300;
}
.has-banexcard .left .details.second .label{
  margin-right: 10px;
}
.has-banexcard .left .details .value{
  font-size: 1.4rem;
  color: white;
}
.has-banexcard .left .details.first{
  position: relative;
}
.has-banexcard .show-banexcard-data{
  position: absolute;
  left: -50px;
  bottom: -25px;
  background-color: var(--bg-dark-v2);
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
}
.has-banexcard .show-banexcard-data::after{
  content: "";
  width: 20px;
  height: 20px;
  bottom: -24px;
  display: block;
  position: absolute;
  left: 22px;
  border-left: 2px solid var(--bg-dark-v2);
  border-bottom: 2px solid var(--bg-dark-v2);
  cursor: default;
}
.has-banexcard .show-banexcard-data::before{
  content: "";
  width: 20px;
  height: 20px;
  top: -24px;
  display: block;
  position: absolute;
  left: 22px;
  border-left: 2px solid var(--bg-dark-v2);
  border-top: 2px solid var(--bg-dark-v2);
  cursor: default;
}

.has-banexcard .left .details.second{
  position: relative;
}
.has-banexcard .reload-countdown > div:first-child{
  position: absolute;
  right: -46px;
  top: -25px;
  background-color: var(--bg-dark-v2);
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
}
.has-banexcard .reload-countdown > div:first-child::after{
  content: "";
  width: 20px;
  height: 20px;
  bottom: -24px;
  display: block;
  position: absolute;
  right: 22px;
  border-right: 2px solid var(--bg-dark-v2);
  border-bottom: 2px solid var(--bg-dark-v2);
  cursor: default;
}
.has-banexcard .reload-countdown p{
  display: block;
  position: absolute;
  right: -102px;
  top: -25px;
  cursor: default;
  font-size: 1.4rem;
  background-color: var(--bg-dark-v2);
  padding: 0.8rem;
  border-radius: 10px;
}
.has-banexcard .right{
  display: grid;
  place-content: center;
  margin: 6rem auto;
  min-width: auto;
  text-align: center;
  padding: 0 1rem;
  max-width: 380px;
}
.has-banexcard .right h3{
  font-size: 2.6rem;
}
.has-banexcard .right p{
  font-size: 1.4rem;
  margin-top: 2rem;
  color: var(--text-gray-v2);
}

.min-height-600{
  min-height: 600px;
}

/* CARD DELIVERED */
.card-delivered{
  height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card-delivered a.btn{
  color: white;
  font-size: 1.6rem;
  border-radius: 1.6rem;
  background-color: var(--select-text-v2);
  display: block;
  padding: 1.5rem;
  margin-top: 4rem;
}
.card-delivered p{
  margin-top: 2rem!important;
}
.card-delivered a{
  color: white;
  font-size: 1.4rem;
}
.loading-spinner-container{
  min-width: 120px;
  max-width: 200px;
  text-align: center;
}
.min-h-544{
  min-height: 544px;
}
.loader-right-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}