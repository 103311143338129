/* Small only */
@media screen and (max-width: 39.9375em) {

    .h1, h1 {
        font-size: 1.8rem !important;
    }

    #root .dark .page-header-nav__icon--menu-toggle {
        color: #fff !important;
    }

    .settings-menu__container {
        z-index: 10 !important;
    }

    .standalone-layout__wrapper div.signup-page__container:before {
        width: 100%;
    }

    .standalone-form__container.signup-page__container {
        margin: auto !important;
        width: 100%;
    }

    .standalone-form__header.signup-page__header {
        width: 100%;
    }

    .standalone-layout__wrapper div.signup-page__container {
        width: 100% !important;
        margin-top: 3em !important;
    }

    .ap-radio__checked {
        width: 1.3rem;
        height: 1.3rem;
        top: 18px;
        left: 8px;
    }

    .ap-radio__check {
        top: 18px;
        left: 8px;
    }

    body #root .dark .problem-logging-in-page__container .ap-input__input-box .form-control, body #root .light .problem-logging-in-page__container .ap-input__input-box .form-control {
        background-color: #f5f7f9!important;
        color: #000 !important;
    }

    .problem-logging-in-page__container {
        width: auto;
        margin: 0;
    }

    .standalone-form__container.two-factor-auth-form__container {
        margin: auto;
    }

    .standalone-form__auto-tab-input .form-group {
        text-align: center;
    }

    .App.container > h1 {
        margin: 1rem auto 0 !important;
    }

    .dashboard-page {
        margin: 1.5rem auto 0;
    }

    a.PendingVerifiedStatus {
        padding: 1em 1em !important;
        margin-top: 1em;
    }

    .incongito_joyride {
        position: absolute !important;
    }

    .settings-page__menu {
        margin-bottom: 1em !important;
    }

    .settings-page__content {
        margin-top: 5em;
    }

    .settings-page__content .pd2 .row {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .kyc-level-banexcoin-old, .kyc-level.otherlevels {
        width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 1em !important;
    }

    .kyc-level__footer {
        height: auto !important;
        margin-bottom: 1em !important;
    }

    .page-footer__footer-left-content {
        width: 100% !important;     
        text-align: left;
    }

    .kyc-settings__header.newheader-block {
        width: 100%;
        padding: 0;
        margin: 0 0 3em 0;
    }

    .kyc-settings__description {
        text-align: justify;
        margin: 0 0 1em 0;
    }

    .settings-page__container {
        margin: 1em 0 0 0 !important;
        width: 100% !important;
    }

    footer.page-footer {
        display: flex;
        margin: 0 !important;
        padding: 2em 1em 1em 1em !important;
    }

    #root .light footer.page-footer {
        background: #f9f9f9 !important;
    }

    .profile-and-security-widget__body {
        min-height: auto;
    }

    .settings-page__content {
        padding-bottom: 10em !important;
    }

    .profile-and-security-widget__body--sm {
        height: auto !important;
    }

    .box-info-header-description {
        text-align: justify !important;
        padding: 1em 0 !important;
    }

    .withdrawals-address-page {
        margin: 1rem auto 0 !important;
    }

    .marketview_container_banex {
        height: auto !important;
        flex-direction: column !important;
        padding: 0 0 0 0px!important;
    }

    .marketview_container_banex > div { 
        width: 100%!important;
        overflow: unset;
    }

    .row-market {
        font-size: 1.3em !important;
    }

    .dashboard-page__market-overview-container-left, .dashboard-page__market-overview-container-right {
        height: auto !important;
        overflow: unset !important;
    }
    .dashboard-page__market-overview-container-right {
        padding-top: 2rem;
    }

    .dashboard-page__market-overview-container-right .dashboard-page__balances-header {
        padding-left: 1rem;
        padding-bottom: 1rem;
    }
    .dashboard-page__market-overview-container-right .balance-item__container {
        padding: 1em 1rem!important;
    }

    .dashboard-page__market-overview-container-right .product-balances__container {
        height: auto!important;
    }
    .dashboard-page__market-overview-container {
        height: auto!important;
        margin-bottom: 2rem!important;
    }

    .card_pair_title {
        font-size: 1.8em !important;
        padding: 1em 0 0 0 !important;
    }

    .error-accessing-message {
        padding-right: 0rem!important;
    }
    .error-accessing-message.easybuy-msj img {
        width: 60%!important;
    }
    .error-accessing-message.easybuy-msj img.vm-mini {
        width: 40%!important;
        margin: 2rem auto 0;
    }
    .error-accessing-message.easybuy-msj .title-soon {
        margin-top: 1.5rem!important;
    }

    .right_info_marketview {
        box-shadow: none;
    }

}

/* Fixed resolucion for Exchange page */

@media screen and (max-width: 968px) {
    .instrument-row .instrument-table {
        flex-direction: row;
        flex-wrap: wrap;
        display: inline-flex;
    }
    .instrument-row .instrument-table .instrument-table__row {
        width:  25%;
        margin: 0.2rem 0rem;
        box-shadow: none;
    }
    .selectorpair .instrument-selector__trigger {
        background: transparent;
        margin: auto 0;
    }
    .selectorpair .ap-popover__visible {
        top: 9rem;
    }
    .trading-layout__container .trading-layout__body .order-entry__container {
       /*  margin: 10px 5px 10px 10px!important; */
        width: auto!important;
    }
    .trading-layout__chart-container {
        height: auto;
    }
    .trading-layout__chart-container .ap-tab__tab.order-history__tab {
        min-height: auto;
        height: auto;
    }
    .trading-layout__container {
        min-height: 100%;
        height: 100%!important;
    }
    .order-entry__form .order-entry__body {
        height: auto!important;
        overflow: unset!important;
    }
    .trading-layout__childer {
        margin-bottom: 1rem;
    }

    .corporatelevel-page .col-xl-4 {
        width: 50%;
    }
    .corporatelevel-page .box-to-select {
        padding: 2% 1rem;
        border: none;
    }
}
@media screen and (max-width: 900px) {
    .modal-dialog {
        margin: 0rem auto;
    }
    .kyc-level-banexcoin-old, .kyc-level.otherlevels {
        margin: 0 0rem 1rem 0rem!important;
    }
    .modal-step-level .modal-dialog {
        width: 100%;
        max-width: 100%!important;
        height: 100%;
    }
    .modal-step-level .modal-dialog .modal-content {
        height: 100%!important;
        border-radius: 0!important;
    }
    .modal-step-level .modal-dialog .modal-content .modal-header .close {
        font-size: 4.5rem!important;
    }
    .modal-container-step-level .left-step {
        background-position: 100% bottom!important;
    }
    .modal-container-step-level .left-step, .modal-container-step-level .right-step {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        height: auto!important;
    }
    
    .modal-step-level .modal-dialog .modal-content .modal-container-step-level {
        padding-right: 0px!important;
    }
    .modal.modal-step-level .modal-dialog .modal-content .modal-container-step-level .left-fix {
        overflow: auto!important;
    }
    .modal-step-level .modal-dialog .modal-content .modal-container-step-level .row .step-container .h-auto .col-xl-6 {
        width: 50%;
    }

    .modal-step-level .modal-dialog .modal-content .info_self.form-group {
        padding: 1rem 0;
    }
    .modal.modal-step-level .modal-dialog .modal-content .modal-container-step-level .left-fix {
        height: 100%!important;
    }

    .modal-container-step-level .step-side-information .crop-src-img {
        width: 35%!important;
    }
    .modal-step-level .modal-dialog .modal-content .form-group .selfie {
        text-align: center;
    }
    
}

@media screen and (max-width: 770px) {
    .instrument-row .instrument-table {
        padding: 0rem 6px 0!important;
    }
    .trading-layout__childer {
        display: block!important;
    }
    
    .trading-layout__childer > div {
        width: 100%!important;
        padding-left: 0!important;
    }
    .trading-layout__childer > div.selectorpair {
    }
    .trading-layout__childer > .instrument-row .popover {
        width: 100%;
        max-width: 100%;
    }
    .trading-layout__chart-container .ap-tab__tab-content.trading-layout__tab-content {
        margin: 1rem -5px!important;
    }
    .tv-chart__chart-container iframe {
        height: 325px!important;
    }

    .settings-page__content {
        overflow: unset;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
    }

    body #root .settings-page__container {
        height: auto!important;
    }
    .affiliate-program-page, .api-keys-page, .trade-report-page {
        padding: 0!important;
    }
    .affiliate-program-page__body .affiliate-program-page__container {
        width: calc(100% - 4rem);
        max-width: calc(100% - 4rem);
        min-width: calc(100% - 4rem);
        margin: 0 2rem 1rem;
    }

    .api-keys-page__header-text, .affiliate-program-page__description {
        text-align: justify;
    }
}

@media screen and (max-width: 768px) {
    .tv-chart__chart-container {
        padding: 0!important;
    }
    .user-settings-page__row .shadowling {
        flex: unset;
    }
    .corporatelevel-page {
        margin: 2rem auto 0;
        width: 100%;
        border-radius: 1.2rem 1.2rem 0 0;
    }
    .corporatelevel-page .row .form-group > .form-label {
        margin-top: 0rem;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .corporatelevel-page .row-header-status {
        margin: 0 0px 0rem!important;
    }
}


@media screen and (max-width: 600px) {
    .corporatelevel-page .status-content {
        padding: 0;
    }
    .corporatelevel-page .percentage-box {
        font-size: 1.5rem;
        text-align: center;
    }
    .corporatelevel-page .percentage-box b {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .standalone-modal__wrapper .standalone-modal__footer {
        padding-left: 2.5rem!important;
        padding-right: 2.5rem!important;
    }
    .standalone-modal__wrapper .standalone-modal__btn {
        width: 100%!important;
    }
}

@media screen and (max-width: 535px) {
    .corporatelevel-page .col-xl-4 {
        width: 100%;
    }
    .corporatelevel-page .box-to-select {
        padding: 2% 0rem;
    }
    .corporatelevel-page .box-to-select .PendingVerifiedStatus {
        background-position: center left;
        font-size: 1.4rem;
    }
    .page-header__header-item--logo .ap-logo .ap-logo__img {
        max-width: unset;
    }
    .page-header__header-item--logo .ap-logo img.ap-logo__img {
        width: auto;
    }
    .page-header__header-item {
        margin: 0 auto;
        right: 0;
        z-index: 0;
    }
    .page-header__header-item--logo .ap-logo {
        width: auto!important;
        margin: 0 auto;
    }
    .incongito_joyride {
        left: 6rem!important;
        top: 16px!important;
    }
    .validation-phone.banner p.subtitle-information {
        font-size: 1.5rem!important;
    }
    .validation-phone.banner .form-control.confirm-phone {
        font-size: 1.5rem!important;
    }
    .validation-phone.banner .form-group .form-label {
        font-size: 1.1rem!important;
    }
    .validation-phone.banner .form-control.confirm-phonecode, .validation-phone.banner .form-control.confirm-twofacode {
        padding: 18px 1.75rem 5px 3rem!important;
    }
    .validation-phone.banner .form-group .invalid-feedback {
        font-size: 95%!important;
    }
    .validation-phone.banner .btn-phone {
        padding: 1.1rem 2rem!important;
    }
}

@media screen and (max-width: 420px) {
    .twofa-message > img {
        width: 60%!important;
    }
}

@media screen and (min-width: 1024px) {
    .page-header__header-item--logo .ap-logo {
        background-image: url(/local/logos/logo-banexcoin-white-middleorange.83388b20.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 16rem;
        height: 22px;
    }
    .page-header__header-item--logo .ap-logo img {
        display: none!important;
    }
}

/* Medium and up */
@media screen and (min-width: 40em) {}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {}

/* Large and up */
@media screen and (min-width: 64em) {}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {}