.simple-exchange-page__container {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: hidden;
  min-height: var(--trading-layout__container-min-height);
}

.simple-exchange-page__row {
  display: flex;
}

.simple-exchange-page__chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.simple-exchange-page__body {
  display: flex;
  border-top: var(--trading-layout__border);
  height: var(--trading-layout__body-height);
  overflow: hidden;
  height: 100%;
}

.simple-exchange-page__chart-header {
  height: var(--component__header-height);
  line-height: var(--component__header-height);
  background: var(--component__header-bg-color);
  padding: 0 1.75rem;
  border-bottom: 1px solid var(--component__header-separator-color);
}

.simple-exchange-page__chart-container .tv-chart--with-depth-chart {
  height: calc(100% - 23.6rem);
}

.simple-exchange-page__title {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: var(--component__header-height);
  line-height: 3.6rem;
  padding: 0 1.6rem;
  background: var(--table__title-bg-color);
  border-bottom: 1px solid var(--table__row-separator-color);
}

.simple-exchange-page__column-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 27rem;
  flex-shrink: 0;
}

.simple-exchange-page__column-container .bulk-cancel-buttons {
  display: none;
}

.trading-layout__chart-header {
  background: transparent;
  padding: 0 1.75rem;
  border-bottom: none;
  font-size: 1.4rem;
  font-weight: 600;
}