.balance-componen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--pc__background-secondary_v2);
    border-radius: 1.6rem;
    padding: 4.4rem 3rem 2rem;
    text-align: center;
    overflow: hidden;
    position: relative;
    width: calc(100% - 416px);
}
.balance-componen .initial-loader-balance {
    margin-top: 2rem;
}
.balance-componen .title-Balance {
    font-size: 1.6rem;
    z-index: 1;
    display: grid;
    grid-template-columns: max-content 70px;
    justify-content: center;
    column-gap: 1rem;
    z-index: 2;
}
.balance-componen .title-Balance.initial-balance-loader {
    grid-template-columns: max-content;
}
.balance-componen .title-Balance .select-balance{
    position: relative;
}
.balance-componen .title-Balance .select-balance .all-items-balance{
    background: var(--bg-dark-v2);
    border-radius: 1.6rem;
    border: 1px solid var(--text-gray-v2);
    padding: .5rem 1rem;
    position: absolute;
    width: 80px;
    cursor: pointer;
    height: 30px;
    overflow: hidden;
    top: -5px;
}
.balance-componen .title-Balance .select-balance .all-items-balance >div{
    display: flex;
    font-size: 1.4rem;
    text-align: left;
    align-items: center;
    height: 21px;
}
.balance-componen .title-Balance .select-balance .all-items-balance .first-item-balance{
    display: grid;
    grid-template-columns: 36px auto;
    column-gap: .5rem;
}
.balance-componen .title-Balance .select-balance .all-items-balance .first-item-balance img{
    transform: rotate(90deg);
    margin-top: -3px;
}
.balance-componen .title-BTC {
    font-size: 3.2rem;
    font-weight: 700;
    padding: 1rem;
    z-index: 1;
}
.balance-componen .title-USD {
    font-size: 2rem;
    z-index: 1;
}
.balance-componen p:last-child {
    font-size: 1.2rem;
    color: var(--text-gray-v2);
    padding-top: 3rem;
    z-index: 1;
}
.balance-componen .circleA {
    left: -44px;
    top: -76px;
    width: 180px;
    height: 180px;
}
.balance-componen .circleB {
    width: 28px;
    height: 28px;
    left: calc(50% + 160px);
}
.balance-componen .circleC {
    rotate: 61deg;
    right: -20px;
    bottom: -59px;
}
@media screen and (max-width: 800px) {
    .balance-componen {
        order: 1;
        width: 100%;
    }
}
@media screen and (max-width: 425px) {
    .balance-componen {
        padding: 3rem 2rem 1rem;
    }
    .balance-componen .initial-loader-balance {
        margin: 1rem auto;
    }
    .balance-componen .circleA {
        width: 50px;
        height: 50px;
        left: 8px;
        top: -10px;
    }
    .balance-componen .circleB {
        width: 15px;
        height: 15px;
        left: auto;
        right: 20px;
        top: 20px;
    }
    .balance-componen .circleC {
        width: 40px;
        height: 40px;
        right: -4px;
        bottom: -22px;
    }
    .balance-componen p:last-child {
        padding-top: 1rem;
        font-size: 1rem;
    }
    .balance-componen .title-Balance {
        font-size: 1.4rem;
    }
    .balance-componen .title-BTC {
        padding-bottom: 0;
    }
}
