.form-control {
  display: block;
  width: 100%;
}

::-webkit-input-placeholder {
  color: var(--font__placeholder-color);
}

::-moz-placeholder {
  color: var(--font__placeholder-color);
}

:-ms-input-placeholder {
  color: var(--font__placeholder-color);
}

::-ms-input-placeholder {
  color: var(--font__placeholder-color);
}

:-moz-placeholder {
  color: var(--font__placeholder-color);
}

.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.form-text {
  display: block;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
}

.btn-group {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: flex;
  vertical-align: middle;
}

.btn-group > .btn {
  position: relative;
  flex: 1;
}

.btn-group > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select {
  margin-left: -1px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Custom Bootstrap */
.form-group label.form-label {
  margin-bottom: 1em;
}

.form-group input[type=number], .form-group input[type=text], .form-group input[type=email], .form-group input#phone-form-control {
  /* padding: 0.7em 1em 1rem 5rem; */
  border-bottom: 1px solid #9999B7;
  height: 4rem;
}
.account-form .form-group input[type=number], .account-form .form-group input[type=text], .account-form .form-group input[type=email] {
  padding: 0.7em 1em 1rem 1rem;
}
form .row {
  margin-top: 2em;
}

form .invalid-feedback.showError{
  display: block;
}

.form-group .form-label span {
  color: #fb9017;
}

.Toastify__toast-body {
  font-size: 1.2em;
}

.react-datepicker-component .react-datepicker-input {
  background: none !important;
  border: 0 !important;
  border-bottom: 1px solid #ccc !important;
}

.react-datepicker-component .react-datepicker-input:hover{
  background: none !important;
  border-bottom: 1px solid #f28d2a !important;
}

input.has-error {
  border-color: #dc3545 !important;
    padding-right: calc(1.5em + .75rem) !important;
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-position: center right calc(.375em + .1875rem) !important;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.invalid-feedback.showFormError{
  display: block;
}

form#easyBuyForm {
  margin-bottom: 4em;
}