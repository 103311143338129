.terms-conditions {
  background: inherit;
  width: 100%;
  padding-bottom: 5rem;
}
.terms-conditions .padding-terms {
  padding: 0 10%;
  font-size: 1.3rem;
}
.terms-conditions .padding-terms div.parrafo {
  font-size: 1.3rem;
}
.terms-conditions > .footer-terms {
  position: absolute;
  bottom: 4rem;
  left: 0;
  right: 0;
  text-align: center;
}
.terms-conditions > .footer-terms label {
  font-size: 1.3rem;
  text-transform: uppercase;
  padding: 0 1rem;
}
/*.terms-conditions .container {
  height: calc(100% - 14em);
  width: calc(100% - 8rem);
  overflow: auto;
  position: absolute;
  margin: 10rem 4rem 4rem 4rem;
  padding: 4rem;
  background: #f3f3f3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}*/
body::-webkit-scrollbar {
  width: 15px;     /* Tamaño del scroll en vertical*/
  height: 15px;    /*Tamaño del scroll en horizontal*/
  /*display: none; *Ocultar scroll*/
}
body::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 0px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
body::-webkit-scrollbar-thumb:hover {
  background: #929292;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
body::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
/* Ponemos un color de fondo y redondeamos las esquinas del track */
body::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 0px;
}

/* Cambiamos el fondo cuando esté en active o hover */
body::-webkit-scrollbar-track:hover,
body::-webkit-scrollbar-track:active {
background: #d4d4d4;
}

.terms-conditions .container a {
  color: #f28E2A;
}
.terms-conditions .container a:hover {
  color: black;
}
.terms-conditions .header {
  display: block;
  margin: 1rem auto;
  text-align: center;
}
.terms-conditions {
  font-family: 'Roboto', sans-serif;
}
.terms-conditions .logo-terms {
  width: 20rem;
}
.terms-conditions .list-nostyle {
  list-style: none;
}
.terms-conditions .list-nostyle > li {
  font-size: 1.3rem;
  margin: 0.5rem 0;
  text-align: justify;
}
.terms-conditions .list-nostyle.menu {
  list-style: none;
  padding: 0;
  border-radius: .3rem;
  position: relative;
}
.terms-conditions .list-nostyle.menu > li {
  padding: .5rem 1rem;
  font-weight: 400;
  font-size: 1.3rem;
  border: 1px solid #bcbcbc;
  background: lightgrey;
  cursor: pointer;
}
.terms-conditions .list-nostyle.menu.sub-menu {
  display: none;
  position: absolute;
  top: 3.5rem;
  left: 0rem;
  background: white;
  padding: 1rem 0;
  cursor: default;
  height: 20rem;
  overflow: auto;
}
.terms-conditions .list-nostyle.menu.sub-menu > li {
  border: none;
  background: transparent;
  padding: 0;
}
.terms-conditions .list-nostyle.menu.sub-menu > li > a {
  padding: .5rem 1rem;
  width: 100%;
}
.terms-conditions .list-nostyle.menu.sub-menu > li > a:hover {
  background: #f3f3f3;
}

.terms-conditions .list-nostyle.menu > li:hover .sub-menu {
  display: block;
}
.terms-conditions .list-nostyle > li > b {
  display: inline-block;
}
.terms-conditions .list-nostyle b {
  margin-right: .5rem;
}
.terms-conditions .subt {
  text-align: center;
}
.terms-conditions h2 {
  font-weight: 500;
  color: #3d558b;
  margin-top: 4rem;
}
.terms-conditions p {
  margin: 1rem 0 1rem 0;
  font-size: 1.3rem;
  text-align: justify;
}
.terms-conditions p a {
  font-size: 1.3rem;
}
.terms-conditions p > b {
  color: #414141;
}
