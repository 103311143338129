
.switch-toggle-bnx-account-label {
    cursor: pointer;
	width: 50px;
	height: 25px;
    background: var(--bg-dark-color);
	display: block;
	border-radius: 100px;
	position: relative;
	margin: auto;
	font-size: 1.4rem;
	text-align: center;
}
.switch-toggle-bnx-account-label p{
	text-align: center;
}

.switch-toggle-bnx-account-label:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 5px;
	width: 16px;
	height: 16px;
	background: var(--text-gray);
	border-radius: 90px;
	transition: 0.3s;
}

.switch-toggle-bnx-account-input:checked + .switch-toggle-bnx-account-label {
	background: var(--bg-dark-color);
}

.switch-toggle-bnx-account-input:checked + .switch-toggle-bnx-account-label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
    background-color: var(--white-color);
}

.switch-toggle-bnx-account-label:active:after {
	width: 6px;
}

.switch-toggle-bnx-account-label{
    display: flex;
    align-items: center;
}
.switch-toggle-bnx-account-label p{
    width: 25px;
    color: white;
	height: 19px;
}
.switch-toggle-bnx-account-label p:nth-child(2){
    color: var(--text-gray);

}

.ap-switch__switch{
  background: transparent!important;
  border: 2px solid var(--success-color-v2);
  width: 60px!important;
  position: relative;
}
.ap-switch__switch.ap-switch__switch{
  border: 2px solid #2e2e4894;
}
.ap-switch__switch.ap-switch__switch--on{
  border: 2px solid var(--success-color-v2);
  box-shadow: 0 3px 3rem 0 var(--switch-component__toggle-box-shadow-color--on);
}
.ap-switch__switch .ap-switch__toggle{
  box-shadow: none;
  background-color: white!important;
}
.ap-switch__toggle{
  width: 22px;
  height: 22px;
  left: -1px;
}
.ap-switch__toggle--on{
  left: 32px;
}
.ap-switch__text{
  position: absolute;
  left: 22px;
	color: white!important;
	text-transform: capitalize;
}
.ap-switch__text--on{
  left: 12px;
}
.settings-enable-two-factor-auth__switch .ap-switch__toggle--on{
  left: 35px;
}