.download-report {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.download-report__container {
  position: relative;
}

.download-report.flex-table {
  background: var(--advanced-settings__bg-color);
}

.download-report__body {
  min-width: 70rem;
}

.download-report__table-header {
  border-bottom: 2px solid var(--table__row-separator-color);
}

.download-report__table-title {
  font-size: var(--settings__card-header-title-font-size);
  color: var(--settings__card-header-title-font-color);
  background: var(--settings__card-header-bg-color);
  letter-spacing: var(--settings__card-header-title-letter-spacing);
  height: var(--settings__body-header-with-border-height);
  padding: var(--settings__card-header-padding);
  border-top-left-radius: var(--settings__card-border-radius-size);
  border-top-right-radius: var(--settings__card-border-radius-size);
}

.download-report__header{
  height: auto;
  flex-direction: column;
  align-items: baseline;
}

.download-report__table-title,
.download-report__header-column--type,
.download-report__column--type {
  padding-left: var(--settings__body-header-title-padding-left);
}

.download-report__header {
  line-height: 3.6rem;
  display: flex;
  align-items: center;
  color: var(--settings__card-header-title-font-color);
  background: var(--settings__card-header-bg-color);
  height: var(--settings__body-header-with-border-height);
  padding: var(--settings__card-header-padding);
  justify-content: space-between;
  border-bottom: var(--settings__card-header-accent-border);
  font-size: var(--settings__body-header-title-font-size);
  letter-spacing: var(--settings__card-header-title-letter-spacing);
}

.download-report__row {
  border-bottom: 1px solid var(--border__color);
  height: 5rem;
}

.download-report__row:last-child {
  border-bottom: none;
}

.download-report__no-reports {
  display: flex;
  align-items: center;
  height: 8.5rem;
  color: var(--font__color-secondary);
}

.download-report__no-reports-row,
.download-report__no-reports-row:hover {
  height: 100%;
  background: transparent;
}

.download-report__no-reports--icon {
  margin-right: 0.5rem;
}

.download-report__column {
  flex: 1;
  color: var(--font__color-primary);
  text-align: left;
}

.download-report__action-btn__btn {
  display: block;
  width: calc(100% - 1.5rem);
  color: var(--button-inline__additive-color--hover);
}

.download-report__action-btn__btn:hover{
  background: var(--button-inline__bg-color);
}

.download-report__action-btn__btn:active{
  background: transparent;
}

.download-report__column--actions,
.download-report__header-column--actions {
  max-width: 16rem;
}

.download-report__column--created,
.download-report__header-column--created {
  max-width: 20rem;
  letter-spacing: normal;
}

.download-report__refresh-reports {
  position: absolute;
  z-index: 1;
  height: 3rem;
  min-width: 3rem;
  right: 2rem;
  background: transparent;
  color: var(--button-inline__additive-color--hover);
}

.download-report__refresh-reports__btn {
  border: none;
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  font-size: 1.1rem;
}

.download-report__refresh-reports__icon {
  color: var(--button-inline__additive-color--hover);
}

.download-report__spinner-container {
  position: relative;
  width: 10rem;
}

.download-report__fixed {
  position: relative;
  margin: 0rem;
}

.download-report__fixed.flex-table__header-cell {
  text-align: left;
}


/* BROWSER QUERIES */
  
/* IE11+ specific styles */
_:-ms-fullscreen, :root .download-report__column--actions .isvg{
  width: 10px;
  height: 10px;
  overflow: hidden;
  margin-bottom: 0;
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 768px) {
  .download-report__refresh-reports__btn{
    border: 1px solid var(--button-inline__border-color);
    background: var(--button-inline__bg-color);
  }
}

@media only screen and (max-width: 480px) {
  .download-report__refresh-reports__btn{
    border: 1px solid var(--button-inline__border-color);
    background: var(--button-inline__bg-color);
  }

  .download-report__column {
    white-space: normal;
    word-break: break-word;
  }

  .flex-table__body.download-report__body {
    min-width: 100%;
  }

  .retail-trade-report__content .download-report {
    min-width: 100%;
  }

  .download-report__table-title,
  .download-report__header-column--type,
  .download-report__column--type {
    width: 60%;
    min-width: 60%;
  }

  .download-report__column--created, .download-report__header-column--created {
    width: 40%;
    max-width: 40%;
    min-width: 40%
  }
}