.breadcrumbs{
  display: flex;
  gap: 5px;
  margin-left: 4rem;
}
.breadcrumbs *{
  color: var(--text-gray-v2);
  font-size: 1.4rem!important;
  text-transform: capitalize;
}
.breadcrumbs a:hover{
  color: var(--text-gray-v2);
}
.breadcrumbs p{
  color: white;
}