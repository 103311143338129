.alert-initial-message{
    width: 100%;
    order: -1;
    margin-bottom: 1.6rem !important;
}
.global-hooks {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    border-radius: 1.6rem;
    text-align: center;
    overflow: hidden;
    position: relative;
    width: 400px;
    min-height: 217px;
}
.global-hooks.initial-data{
    background: var(--pc__background-secondary_v2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.global-hooks .card-level {
    position: relative;
    background-image: url("../images/interior-bga-card.png");
    background-color: var(--pc__background-secondary_v2);
    overflow: hidden;
    border-radius: 1.6rem;
    height: 100%;
    background-size: 100%;
    background-position: center;
}
.global-hooks .level-item,
.global-hooks .level-item .slick-list,
.global-hooks .level-item .slick-list .slick-track,
.global-hooks .level-item .slick-list .slick-track .slick-slide > div {
    height: 100%;
}
.global-hooks .level-item {
    margin: 0;
    overflow: hidden;
}
.global-hooks .level-item .slick-list {
    padding: 0;
}
.global-hooks .level-item .slick-arrow {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: var(--bg-dark-v2);
}
.global-hooks .level-item .slick-prev {
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -46px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 80px;
    height: 80px;
}
.global-hooks .level-item .slick-prev img{
    position: absolute;
    left: 5px;
    width: 20px;
}
.global-hooks .level-item .slick-next {
    display: block;
    position: absolute;
    right: -46px;
    z-index: 1;
    bottom: 0;
    transform: translateY(-50%);
    width: 80px;
    height: 80px;
    top: 50%;
}
.global-hooks .level-item .slick-next img{
    position: absolute;
    left: 8px;
    width: 20px;
}
.global-hooks .level-item .slick-disabled {
    cursor: no-drop;
}
.global-hooks .circle-top-level {
    top: -13px;
    left: -40px;
    width: 70px;
    height: 70px;
    z-index: 1;
}
.global-hooks .circle-bottom-level {
    width: 20px;
    height: 20px;
    right: 32px;
    top: 90px;
    z-index: 1;
}
.global-hooks.card-config-v2{
    width: 100%;
}
.global-hooks.card-config-v2 .LevelForSingle{
    height: 315px !important;
}
.global-hooks.card-config-v2 .card-level{
    background-image: none;
}
.global-hooks.card-config-v2 .LevelForSingle {
    padding: 2rem 4rem !important;
    background: var(--background-primary_v2) !important;
    border-radius: 1.6rem !important;
}
.global-hooks.card-config-v2 .customer-level-card{
    display: none;
}
.global-hooks.card-config-v2 .level-info-title{
    padding-top: 1rem !important;
}
.global-hooks.card-config-v2 .slick-prev {
    bottom: 22px !important;
    right: 72px !important;
}
.global-hooks .min-height-button {
    min-height: 48px;
    font-size: 1.3rem;
    width: 100%;
    max-width: 240px;
    color: var(--text-gray-v2);
}
.global-hooks .card-level .level-item .slick-dots{
    display: flex !important;
    column-gap: .5rem;
    position: absolute;
    right: 2rem;
    bottom: 1.5rem;
}
.global-hooks .card-level .level-item .slick-dots li{
    margin: 0;
    line-height: 0;
}
.global-hooks .card-level .level-item .slick-dots li button, 
.global-hooks .card-level .level-item .slick-dots li.slick-active button{
    width: 25px;
    height: 5px;
    background: var(--text-light)
}
.global-hooks .card-level .level-item .slick-dots li button{
    background: #575877
}
.global-hooks.card-config-v2 .card-level .level-item .slick-dots{
    display: none !important;
}
.coink-details-level ul{
    list-style: circle;
    padding-left: 2rem;
}
.coink-details-level *{
    font-size: 1.2rem;
}
.coink-details-level p{
    color: #a8a8c7;
    margin-top: 1rem;
}
@media screen and (max-width: 800px) {
    .alert-initial-message{
        margin-bottom: 0 !important;
    }
    .global-hooks {
        width: calc(100vw - 4rem);
    }
}
@media screen and (max-width: 425px) {
    .global-hooks{
        width: calc(100vw - 2rem);
    }
    .global-hooks .circle-top-level {
        top: -8px;
        left: -26px;
        width: 50px;
        height: 50px;
    }
    .global-hooks.card-config-v2{
        width: calc(100% + 6rem);
        margin-left: -3rem;
    }
    .global-hooks.card-config-v2 .card-level{
        padding: 0;
    }
}
