.send-receive {
  display: flex;
  border-top: 4px solid var(--tab__border-color--selected);
}

.send-receive__container {
  max-width: initial;
  width: 88.8rem;
}

.send-receive__main {
  width: 54.5rem;
  padding: 3.2rem;
  border-right: 1px solid #2e2e48;
  overflow: auto;
}

.send-receive__main-header {
  height: 13.4rem;
}

.send-receive__main-form {
  border-radius: 6px;
  box-shadow: var(--component__box-shadow);
  border: solid 1px rgba(0, 0, 0, 0.06);
  background-color: var(--wallet-side-pane__bg-color);
}



.send-receive__address-header {
  margin: 5.6rem 0 0 2.5rem;
  padding-bottom: 1.1rem;
  font-size: 1.4rem;
  letter-spacing: -0.5px;
  color: var(--font__color-primary);
  border-bottom: 1px solid var(--border__color);
}

.send-receive__address-instructions {
  padding: 1rem 2.5rem 2.5rem;
  font-size: 1.2rem;
}
.sidepane-details__information-header{
  color: #fff;
  border-bottom: 1px solid;
  width: 100%;
}
.send-receive__send-side-details {
}

.disabled-box {
  border: 2px solid #fc9999;
  padding: 1rem;
  margin: 2rem 0 0;
  background: #ffeeee;
  color: #cf5151;
  border-radius: 0.5rem;
  font-size: 1.3rem;
}


/* MEDIA QUERIES */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .send-receive {
    display: block;
    overflow: auto;
  }
  .send-receive__main {
    width: calc(100% - 10rem);
    border-right: none;
  }
  .send-form__source-item,
  .receive__source-item {
    line-height: 1.5rem;
    padding: 1rem 0;
    height: auto;
  }

 
  .send-receive__side-details,
  .send-receive__send-side-details{
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .sidepane-details__information--top{
    margin-top: 0;
    order: 1;
    width: calc(100% - 12rem);
  }
  .sidepane-details__header--top{
    width: 100%;
    order: 2;
  }
  .sidepane-details__body{
    order:3;
    margin-bottom: 3rem;
    width: calc(100% - 12rem);
  }
  .sidepane-details__divider{
    width: calc(100% - 5rem);
  }
}


@media only screen and (max-width: 576px){
  .send-receive__main {
    width: 100%;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .send-receive__main,
  .sidepane-details__information--top,
  .sidepane-details__body{
    width: 100%;
  }

  .send-receive__main {
    padding: 0;
  }

  .send-receive-header {
    margin-top: 2.6rem;
    margin-left: 2.2rem;
    width: calc(100% - 2.2rem); 
   }
 
  .send-receive-header__logo{
    height: 2.8rem;
    margin-right: 1.6rem;
  }
  
  .send-receive-header__title{
    font-size: 1.8rem;
    padding-top: 0.5rem;
  }

  .send-receive__main-form{
    border-radius: 0;
  }

}

/* Extra Small Devices, Phones - Horizontal */
@media only screen and (max-height: 480px) {
  .send-receive__side-details {
    margin-top: 1.5rem;
  }
}

/* MEDIA QUERIES */
.send-receive__side {
  width: 50%;
}

@media (max-width: 768px) {
  .send-receive__side {
    width: 100%;
  }
  
}
@media (min-width: 769px) and (max-width: 990px) {
  .send-receive__side {
    width: calc(100% + 15rem);
  }
}