.trading-header{
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}
.trading-header .popover.ap-popover__popover.instrument-selector-popover__popover{
  background-color: transparent;
  border: none;
}
.trading-header .instrument-selector__trigger{
  color: white;
  background: transparent!important;
  height: max-content!important;
}
.popup-selector-content,
.popup-selector-content > div{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.popup-selector-content .instrument-selector__symbol{
  font-size: 1.8rem;
  font-weight: lighter;
  margin: 0;
}
.popup-selector-content .secondary-text{
  color: #a8a8c7;
  font-size: 1.4rem;
  font-weight: lighter;
}
.popup-selector-content .principal-value{
  font-size: 2.2rem;
  font-weight: 100;
  margin: 0;
}
.popup-selector-content p.principal-value.green{
  color: var(--success-color-v2);
}
.popup-selector-content p.principal-value.red{
  color: var(--error-color-v2);
}
.popup-selector-content .instrument-table{
  max-width: 425px;
}
.trading-header .popover.ap-popover__popover.instrument-selector-popover__popover  {
  max-width: max-content;
  grid-area: b;
}
.instrument-table{
  flex-wrap: wrap;
  max-width: 460px;
  grid-area: c;
  place-self: center;
}
.instrument-table .instrument-table__row{
  border-right: 1px solid #a8a8c74b;
  padding: 0 1rem;
  width: 39%!important;
}
.ml{
  margin-left: 20px;
}
.icon-title-table{
  margin-right: 2px;
  margin-bottom: 2px;
}
.instrument-table__row.instrument-table__row--24h-min img{
  transform: rotate(180deg);
}
.ap-popover__content.instrument-selector-popover__content{
  left: 167px!important;
  top: 69px!important;
}
.ap-popover__content.instrument-selector-popover__content,
.instrument-selector-popover__popover .instrument-selector-popup__body .instrument-selector-popup__row,
.instrument-selector-popup__table-header:not(.flex-table__header--background),
.instrument-selector-popover__popover .instrument-selector-popup__body:first-of-type,
.flex-table__body.instrument-selector-popup__body{
  max-width: 400px;
  width: 100%;
  background: var(--pc__background-secondary_v2)!important;
  border-radius: 15px;
  padding: 0 2px;
  overflow: hidden;
}
.instrument-selector-popup__table-header:not(.flex-table__header--background){
  background-color: transparent!important;
}
.flex-table__column.instrument-selector-popup__column--coin, .flex-table__header .flex-table__column{
  width: 80px;
  justify-content: flex-start;
}
.flex-table__column.instrument-selector-popup__column--coin p{
  margin: 0;
}
.flex-table__column.instrument-selector-popup__column--coin{
  font-size: 1.4rem;
  font-weight: 400;
  margin-right: .5rem;
}
.flex-table__header .flex-table__column div{
  font-size: 1.2rem;
  font-weight: 400;
  color: #a8a8c7!important;
  margin-right: .5rem;
}
.instrument-selector-popover__content--with-arrow::after{
  display: none;
}
.ap-popover__content{
  margin-top: .5rem;
  padding-top: 0;
}
.flex-table__column.instrument-selector-popup__header-column{
  background-color: var(--pc__background-secondary_v2)!important;
  margin-right: 1rem;
}
.instrument-selector-popover__popover .instrument-selector-popup__body .instrument-selector-popup__row--selected:before{
  padding-right: 10px;
  display: none;
}
.flex-table__wide-row-container{
  background-color: transparent;
}
.flex-table__column.instrument-selector-popup__header-column{
  background-color: transparent!important;
  border-radius: 100px;
}
.flex-table__body.instrument-selector-popup__body{
  overflow: auto;
}
.flex-table__row.instrument-selector-popup__row.instrument-selector-popup__row{
  border: 1px solid transparent;
  border-radius: 3px;
  transition: 10ms all;
  margin-bottom: .5rem;
}
.flex-table__row.instrument-selector-popup__row.instrument-selector-popup__row--selected,
.flex-table__row.instrument-selector-popup__row.instrument-selector-popup__row:hover{
  background-color: #a8a8c728!important;
}
.flex-table__body.instrument-selector-popup__body::-webkit-scrollbar-thumb{
  background-color:var(--bg-dark-v2);
  border-radius: 6px;
}
.flex-table__body.instrument-selector-popup__body::-webkit-scrollbar-track{
  background-color:var(--background-primary_v2);
  border-radius: 6px;
}
.flex-table__column.instrument-selector-popup__column{
  font-size: 1.4rem;
}
.instrument-selector-popup__24h-positive{
  color: var(--success-color-v2)!important;
}
.instrument-selector-popup__24h-negative{
  color: var(--error-color-v2)!important;
}
.trading-header .v2-navbar-bnx{
  width: auto;
  grid-area: d;
}
.logo-banexcoin-exchange-public{
  grid-area: a;
}
.instrument-table__row:last-child{
  width: 40%!important;
}
.trading-header .v2-navbar-bnx{
  margin: 0;
  width: 100%;
  justify-content: flex-end!important;
}
@media (width < 1190px) {
  .trading-header.pt-1{
    display: grid;
    width: 100%;
    grid-template-areas: "a d" "b c";
    justify-content: space-between;
    grid-template-columns: 50% max-content;
  }
  .trading-header .v2-navbar-bnx{
    width: max-content;
    margin: 0;
  }
}
@media (width <  970px) {
  .trading-header.pt-1{
   grid-template-columns: 40% max-content;
  }
  .trading-header .instrument-selector__trigger{
    padding: 0!important;
  }
  .trading-header{
    margin-top: 2rem;
    padding-left: 2rem;
    gap: 2rem 4rem;
  }
  .trading-header .popover.ap-popover__popover.instrument-selector-popover__popover{
    height: 110px;
  }
  .instrument-table{
    row-gap: 3rem;
    column-gap: 1rem;
    max-width: 327px;
  }
  .instrument-table__row{
    border-right: none!important;
    width: 40%;
  }
  .instrument-table__row:last-child{
    width: auto;
  }
}
@media (width <  681px) {
  .trading-header.pt-1{
    grid-template-columns: 35% max-content;
   }
  .ap-popover__content.instrument-selector-popover__content{
    left: 100px!important;
    top:114%!important;
    width: 87vw;
  }
  .popup-selector-content{
    flex-direction: column;
    align-items: flex-start;
  }
  .trading-header .popover.ap-popover__popover.instrument-selector-popover__popover{
    height: auto;
  }
  .ml{
    margin-left: 0
  }
}
@media (width <  550px) {
  .trading-header{
    gap: 2rem;
    
  }
  .instrument-table__title{
    font-size: 1rem!important;
  }
  .instrument-table__value{
    font-size: 1.2rem!important;
  }
  .instrument-table__row,.instrument-selector__trigger{
    padding: 0!important;
  }
}
@media (width < 482px) {
  .trading-header{
    gap: 2rem;
  }
  .instrument-table{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .instrument-table > div{
    width: 50%!important;
  }
  .trading-header .v2-navbar-bnx{
    margin: 0;
    width: 100%;
    justify-content: center;
  }
}
@media (width <  434px) {
  .ap-popover__content.instrument-selector-popover__content{
    left: 0!important;
    top: 150%!important;
  }
}
@media (width <  370px) {
  .trading-header.pt-1 .v2-navbar-bnx{
    gap: 2rem!important;
  }
  .trading-header{
    gap: 2rem;
    align-items: start;
  }
  .trading-header .popover.ap-popover__popover.instrument-selector-popover__popover{
    margin-top: 3rem!important;
  }
  .trading-header .instrument-table__row.instrument-table__row--24h-volume{
    width: 100%!important;
  }
  .trading-header.pt-1{
    padding: 1rem;
    column-gap: 0;
  }
  .instrument-table{
    flex-direction: column;
  }
}