.banexcard-page-loading{
  min-height: calc(100vh - 200px);
  display: grid;
  place-content: center;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}
.banexcard-page-loading svg{
  margin: 0 auto;
}
.banexcard-page-loading p{
  font-size: 1.6rem;
}