.settings-enable-two-factor-auth {
  padding: 2.4rem;
}

.settings-enable-two-factor-auth__on-off-2fa-container {
  display: flex;
  flex-direction: row;
}

.settings-enable-two-factor-auth__info {
  font-size: 1.4rem;
  color: var(--font__color-secondary);
  line-height: 1.29;
}

.settings-enable-two-factor-auth__switch {
  flex-shrink: 0;
  margin-right: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.settings-enable-two-factor-auth .ap-switch__toggle {
  background-color: var(--retail-settings__toggle-color);
}

.settings-enable-two-factor-auth .ap-switch__toggle--on {
  background-color: var(--retail-settings__toggle-color--on);
}

.settings-enable-two-factor-auth .ap-switch__text {
  color: var(--font__color-secondary);
  font-weight: bolder;
}

.settings-enable-two-factor-auth .ap-switch__text--on {
  color: var(--font__color-primary);
}

.settings-enable-two-factor-auth__btn {
  display: block;
  border: 2px solid var(--border__color);
  border-radius: 16px;
  color:var(--font__color-secondary);
  font-weight: bolder;
  background-color: inherit;
}

.settings-enable-two-factor-auth__btn:hover,
.settings-enable-two-factor-auth__btn:focus {
  background: inherit;
  border: 2px solid var(--border__color);
}