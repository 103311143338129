.confirm-block-trade-modal {
  display: flex;
  flex-direction: column;
  width: 32rem;
}

.confirm-block-trade-modal__lwt-container {
  height: 3.2rem;
  line-height: 3.2rem;
  padding: 0 1.2rem;
  background: var(--primary__bg-color);
  border-bottom: 1px solid var(--border__color);
}

.confirm-block-trade-modal__lwt-container:last-child {
  border-bottom: 0;
}
.confirm-block-trade-modal__lwt-text {
  color: var(--text-color);
}

.confirm-block-trade-modal__bought__btn {
  padding: 0 5rem;
  background: var(--success__bg-color);
}

.confirm-block-trade-modal__sold__btn {
  padding: 0 5rem;
  background: var(--danger__bg-color);
}
