.reset-password-container{
  max-width: 500px;
  width: 90%;
  margin: 4rem auto;
  background: var(--pc__background-secondary_v2);
  border-radius: 15px;
  padding: 2rem;
}

.reset-password-container-title{
  font-size: 2rem;
  text-align: center;
}
.reset-password-container form{
  margin-top: 4rem;
}
.standalone-layout__wrapper{
 overflow: hidden;
 overflow-y: auto;
}
.reset-password-container-title.message{
  font-size: 1.5rem;
}
a.default-primary-button{
  margin-top: 2rem;
  color: var(--white-color);
  text-align: center;
}
.reset-password-container .input-element > div{
  background-color: var(--background-primary_v2);
}
.default-primary-button.reset-password:disabled{
  opacity: .5;
  cursor: default;
}