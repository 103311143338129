.expired-document-modal{
    max-width: 500px;
}
.fade.modal-bnx-v2.lighting.modal.show.expired-document-modal .btn.btn-primary{
    max-width: 200px;
    padding: 1.5rem 3rem!important;
    font-size: 1.4rem;
}
.expired-document-modal p{
    color: var(--text-light);
}
.expired-document-modal b{
    font-weight: bold;
}
.modal-disclaimer.expired-document-modal .modal-header .modal-title.h4{
    font-size: 2rem!important;
}