:root {
  /* PC == palette color */

  /* BASE COLORS */
  --pc__branding: 64, 80, 144;
  --pc__branding-gradient-start: 99, 127, 234;
  --pc__branding-gradient-end: 77, 105, 211;
  --pc__font-primary: 61, 71, 84;
  --pc__font-secondary: 24, 24, 24;

  /* BACKGROUND COLORS (order=light-dark)*/
  --pc__background-primary: 252, 252, 252 !important;
  --pc__background-secondary: 229, 229, 229;
  --pc__background-tertiary: 174, 174, 174;
  --pc__background-quaternary: 200, 200, 200;

  /* ACCENT COLORS */
  --pc__accent-additive: 79, 203, 133;
  --pc__accent-subtractive: 255, 65, 103;
  --pc__accent-warning: 249, 133, 48;
  --pc__accent-success: 79, 203, 133;
  --pc__accent-danger: 255, 71, 71;
  --pc__accent-highlight: 85, 118, 250;

  /* TH = theme  */

  /* GENERAL */
  --th__component-box-shadow-color: 76, 100, 138, 0.17;

  /* SETTINGS PAGES*/
  --th-settings__accent-bar-display: block;
  /* SETTINGS -- container */
  --th-settings__body-bg-color: transparent;
  --th-settings__body-max-width: 106rem;
  --th-settings__body-height: auto;
  --th-settings__body-margin: 0;
  --th-settings__body-padding: 3rem;
  /* SETTINGS -- container - header */
  --th-settings__body-header-bg-color: transparent;
  --th-settings__body-header-margin: 2rem;
  --th-settings__body-header-with-border-height: 7rem;
  /* SETTINGS -- container - header title */
  --th-settings__body-header-title-font-size: 2rem;
  --th-settings__body-header-title-font-color: var(--pc_font-primary);
  --th-settings__body-header-title-letter-spacing: 0.05rem;
  --th-settings__body-header-title-padding-bottom: 2rem;
  --th-settings__body-header-title-padding-left: 2.8rem;
  --th-settings__body-header-title-padding-right: 1.6rem;
  /* SETTINGS -- container - header sub title*/
  --th-settings__body-header-sub-title-font-size: 1.2rem;
  --th-settings__body-header-sub-title-font-color: var(--pc_font-primary);
  --th-settings__body-header-sub-title-letter-spacing: 0.05rem;
  --th-settings__body-header-sub-title-line-height: 1.5rem;
  /* SETTINGS -- card */
  --th-settings__card-box-shadow: 0 0.5rem 2rem 0
    rgba(var(--th__component-box-shadow-color));
  --th-settings__card-border-radius-size: 1rem;
  /* SETTINGS -- card - header*/
  --th-settings__card-header-bg-color: rgb(var(--pc__background-primary));
  --th-settings__card-header-accent-border: 2px solid
    rgb(var(--pc__accent-highlight));
  --th-settings__card-header-padding: 2rem;
  --th-settings__card-header-title-font-size: 2rem;
  --th-settings__card-header-title-font-color: rgb(var(--pc__font-primary));
  --th-settings__card-header-title-letter-spacing: 0.05rem;
  /* SETTINGS PAGES --- SPECIFIC SETTINGS*/
  /* SETTINGS -- profile & security */
  --th-settings__profile-and-security-card-max-width: calc(
    (var(--th-settings__body-max-width) / 2) -
      (var(--th-settings__body-padding) * 3)
  );
  --th-settings__profile-and-security-card-margin-r: 3rem;
  --th-settings__profile-and-security-card-margin-b: 4rem;
  --th-settings__profile-and-security-row-width: 100%;
  --th-settings__profile-and-security-card-border-right: none;
  --th-settings__profile-and-security-card-border-bottom: none;
  /* SETTINGS -- kyc */
  --th-settings__kyc-cards-container-margin-l: 0;
  --th-settings__kyc-card-border-radius: var(
    --th-settings__card-border-radius-size
  );
  --th-settings__kyc-card-bg-color: rgb(var(--pc__background-secondary));
  --th-settings__kyc-card-container-border-color: transparent;
  --th-settings__kyc-card-container-border-color--selected: transparent;
  --th-settings__kyc-card-container-border-size: 0;
  --th-settings__kyc-card-container-border-size--selected: 0;
  --th-settings__kyc-card-header-bg-color: rgb(var(--pc__background-primary));
  --th-settings__kyc-card-body-top-border-color: transparent;
  --th-settings__kyc-card-body-top-border-size: 0;
  --th-settings__kyc-card-body-bottom-border-color: rgb(
    var(--pc__background-tertiary)
  );
  --th-settings__kyc-card-body-bottom-border-size: 0.1rem;
  --th-settings__kyc-card-footer-color--verified: rgb(
    var(--pc__accent-success)
  );
  /* SETTINGS -- kyc - star */
  --th-settings__kyc-card-star-display: block;
  --th-settings__kyc-card-star-bg-color: rgb(var(--pc__background-primary));
  --th-settings__kyc-card-star-fill-color: rgb(var(--pc__accent-additive));
  --th-settings__kyc-card-star-box-shadow: 0 0.5rem 4rem 0 rgba(0, 0, 0, 0.2);
  /* SETTINGS -- trade reports*/
  --th-settings__trade-reports-create-new-header-display: flex;
  --th-settings__trade-reports-create-new-actions-holder-padding: 2rem;
  --th-settings__trade-reports-create-new-actions-holder-flex-direction: row;
  --th-settings__trade-reports-create-new-actions-holder-border: 1px solid
    rgba(255, 255, 255, 0.5);
  --th-settings__trade-reports-create-new-actions-btn-width: 45%;
  --th-settings__trade-reports-create-new-actions-top-btn-margin-b: 0;
  --th-settings__trade-reports-create-new-actions-btn-font-color: rgb(
    var(--pc__font-primary)
  );
  --th-settings__trade-reports-create-new-actions-btn-bg-color: var(
    --pc_font-primary
  );
  /* SETTINGS -- API keys*/
  --th-settings__api-keys-create-new-header-display: flex;
  --th-settings__api-keys-create-new-actions-holder-margin: 1.4rem;
  --th-settings__api-keys-create-new-actions-holder-btn-width: 24rem;
  --th-settings__api-keys-create-new-actions-holder-btn-border: 1px solid
    var(--retail-api-keys-form__create-new-border-color);
  --th-settings__api-keys-create-new-actions-documentation-btn-margin-t: 1.6rem;
  --th-settings__api-keys-create-new-actions-documentation-btn-justify-content: center;
  --th-settings__api-keys-create-new-actions-documentation-btn-font-color: var(
    --pc_font-primary
  );
  --th-settings__api-keys-create-new-actions-btn-font-color: rgb(
    var(--pc__font-primary)
  );
  --th-settings__api-keys-create-new-actions-btn-bg-color: var(
    --pc_font-primary
  );
  /* SETTINGS -- TrustPay External Credit Card Form */
  --th-settings__trustpay-form-font-color: 0, 0, 0;

  /* NEW BANEXCOIN SETTINGS */
  --retail-dashboard__card-header-title-font-color: rgb(44, 44, 44);
  --component__color-primary: var(
    --retail-dashboard__card-header-title-font-color
  );
  --font__color-primary: var(--retail-dashboard__card-header-title-font-color);
  --nav-header__bg-gradient: rgb(255, 255, 255);
  --sub-nav-row__item-color: rgb(41, 41, 41);
  --sub-nav-row__item-color-hover: rgb(0, 0, 0);
  --sub-nav-row__border-color--hover: rgb(247, 108, 28);
  --secondary__bg-color: rgb(255, 255, 255);
  /* color de fondo*/
  /* para trading view*/

  /* --table__row-separator-color es para las lineas en el grafico TV*/
  --tertiary__bg-color: rgb(97, 97, 97);
  --tertiary__bg-color-grid: rgba(175, 174, 174, 0.573);

  --tv-chart__grid-vertical-color: transparent;
  --tv-chart__grid-horizontal-color: transparent;
  --tv-chart__bg-color: #2E2E48;
  --tv-chart__scale-font-color:#a8a8c7;

  --exchange-colors__sell: #FF4167;
  --tv-chart__scale-line-color: transparent;
  --tv-chart__scale-bg-color: rgb(255, 255, 255);
  --tv-chart__graph-up-color: #5DD267;
  --tv-chart__graph-down-color: #FF4167;
  --tv-chart__graph-border-up-color: #5DD267;
  --tv-chart__graph-border-down-color: #FF4167;
  /* para trading view*/

  --border-grey:1px solid #2E2E48;
  ;
  /* APP V2 */
  /* BACKGROUND COLORS -- COLOR-FULL*/
  --background-primary_v2: #2E2E48;
  --pc__background-secondary_v2: #383857;
  /* FONT-COLORS */
  --pc_font-primary: #ffffff;
  --pc_font-secundary: #fb8a47;
  --pc-inputs-background: #343551;
  --switch-color: #343451;

  /* COLORS BUTTONS */
  --primary-color-v2: linear-gradient(90deg, #FF9443   0%, hsl(16, 100%, 61%) 90%);
  --secondary-color-v2: rgba(168, 168, 199, 0.2);
  --tertiary-color-v2: rgba(168, 168, 199, 0.1);
  --success-color-v2: #5DD267;
  --success-color-v2-opaque: #5dd2676c;
  --error-color-v2: #FF4167;
  --error-color-v2-opaque: #ff416777;
  --btn-checked-v2:#9999B7;
  --text-gray-v2:#a8a8c7;
  --text-light:white;
  --text-dark:black;
  --border-color: #393950;
  --select-color-v2: #A18AFF;
  --select-text-v2: #5D3DFF;
  --orange-color:#FF703A;
  --opacity-color: #424268;
  --bg-dark-v2:#404060;
  --background-primary_v2_with-opacity : rgba(46,46,72,0.97);
  --text-gray-v2_with-opacity : rgba(168,168,199,0.2);
  --coink-color: #70C02D;
  --hover-color-v2 : #1a1a2f;
  --card-info-color: #2f2f4a;
  --card-text-color: #27273d;
  --border-gray-v2: #a8a8c71c;
}

.nav-menu-vertical__item {
  font-weight: 500;
}

.flex-table__column.activity__column:nth-child(2),
.flex-table__column.activity__header-column:nth-child(2) {
  padding-left: 1rem;
}

.activity__header-column:nth-child(3) .activity__fixed,
.activity__header-column:nth-child(4) .activity__fixed {
  padding-left: 1.6rem;
}

.activity__date,
.activity__status,
.activity__amount,
.activity__action-column {
  padding-right: 1rem;
}

.activity__status,
.activity__date {
  padding-left: 1.6rem;
}

.activity__select-label {
  padding-right: 1rem;
}

small.text-muted.form-text.reduced-text {
  font-size: 0.8rem;
  text-align: left;
}

select.form-control#bank_city,
select.form-control#bank_city option {
  text-transform: capitalize;
}

.percentage-box {
  color: #2fa655;
  font-size: 4rem;
  vertical-align: middle;
  position: relative;
  display: block;
}

.percentage-box b {
  font-size: 2.8rem;
  font-weight: 500;
}

th.header-name-table {
  background: #ffbd5a;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
  color: #474747 !important;
  border-radius: 3px 3px 0 0;
}

tr.header-title-table th {
  background: #e2e2e2;
  color: #505050 !important;
}

/* signup select redes sociales*/
body
  #root
  .dark
  .signup-form__wrapper
  label.ap--label.ap-select__select-label.custom-select__select-label {
  color: black !important;
  font-family: "Montserrat", Sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: -0.5px;
}

body
  #root
  .light
  .signup-form__wrapper
  label.ap--label.ap-select__select-label.custom-select__select-label {
  color: rgb(141, 141, 141) !important;
  font-family: "Montserrat", Sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: -0.5px;
}

body
  #root
  .dark
  .signup-form__wrapper
  select.form-control.ap-select__select.custom-select__select {
  height: 4.3rem;
  background: url(/local/landing-page/images/icons/angle-down-solid.svg) center
    right 1rem no-repeat;
  background-size: 1rem;
}

body
  #root
  .light
  .signup-form__wrapper
  select.form-control.ap-select__select.custom-select__select {
  height: 4.3rem;
  background: url(/local/landing-page/images/icons/angle-down-solid.svg) center
    right 1rem no-repeat;
  background-size: 1rem;
}

body
  #root
  .light
  .standalone-layout__wrapper
  div.signup-page__container::after {
  height: 75rem;
}

/* dark theme */

body #root .light {
  background: #f9f9f9;
}

body #root .light:after {
  content: "";
  background: #f9f9f9;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
html, body {
  background: var(--background-primary_v2) !important;
}

body #root .dark {
  background: var(--background-primary_v2);
  color: white;
}

body #root .dark:after {
  content: "";
  background: #363b37;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

body #root .dark:before {
  content: "";
  display: block;
  left: 0;
  right: 0;
  height: 100%;
  position: absolute;
  background: var(--background-primary_v2);
  z-index: -2;
}

body #root .dark .mon.title,
body #root .dark .dashboard-page__market-header {
  color: white;
}

body #root .dark .dashboard-page__notification-row {
  box-shadow: none;
}

body #root .dark .page-header-user-summary__popover-menu-trigger {
  background: #353a36;
}

body #root .dark .user-summary__popover-menu-trigger-triangle {
  border-top-color: rgb(185, 185, 185);
}

body #root .dark .transfer-request-notification-list__empty {
  background: var(--pc__background-secondary_v2);
  color: white;
}

body #root .dark .LevelBlock .LevelForSingle {
  background: inherit;
  border-radius: .3rem;
  padding: 0;
  margin: 0;
  height: 100%;
  display: grid !important;
  grid-template-rows: max-content auto max-content;
  row-gap: 1rem;
  text-align: left;
  width: 100%;
  padding: 2rem 4.5rem;
  position: relative;
}
body #root .dark .LevelBlock .LevelForSingle .level-info-initial{
  margin: 0;
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  min-height: 21px;
  display: flex;
  column-gap: .5rem;
}
body #root .dark .LevelBlock .LevelForSingle .level-info-initial img{
  width: 20px;
  height: 20px;
}
body #root .dark .LevelBlock .LevelForSingle .level-info-title{
  display: flex;
  column-gap: 1.6rem;
  padding-top: 7.2rem;
}
body #root .dark .LevelBlock .LevelForSingle .level-info-title h2{
  margin: 0;
  font-size: 2.2rem;
}
body #root .dark .LevelBlock .LevelForSingle .level-info-title img{
  width: 27px;
}

body #root .dark .LevelBlock .LevelForSingle .levelHeader .levelTitle {
  text-shadow: 1px 1px 0 var(--pc__background-secondary_v2);
}

body #root .dark .banexcoin_marketview,
body #root .dark .right_info_marketview {
  background: var(--pc__background-secondary_v2);
  border: 1px solid rgba(64, 64, 96, 1);
}

.download-report__table-header:not(.flex-table__header--background),
.schedule-report-list__table-header:not(.flex-table__header--background) {
  background: var(--pc__background-secondary_v2);
  height: 3rem;
}

.download-report__body .flex-table__header-cell,
.schedule-report-list .flex-table__header-cell {
  margin: -1rem 0 0rem;
}

button.ap-inline-btn__btn.ap-inline-btn__btn--subtractive
  span.ap-inline-btn__text {
  padding-left: 0.5rem;
}

button.ap-inline-btn__btn.ap-inline-btn__btn--subtractive svg {
  color: white;
  height: 10px;
}

body
  #root
  .light
  button.ap-inline-btn__btn.ap-inline-btn__btn--subtractive
  svg {
  color: rgb(59, 59, 59);
}

body
  #root
  .light
  .download-report__table-header:not(.flex-table__header--background),
.schedule-report-list__table-header:not(.flex-table__header--background) {
  background: white;
}

body
  #root
  .light
  .flex-table.cyclical-report-class.cyclical-report-class__body.cyclical-report-class__body--single-page {
  background: white;
}

body #root .light .download-report__table-header {
  border-bottom: 2px solid rgb(174, 174, 174);
}

body #root .light .cyclical-report-class__table-header {
  border-bottom: 1px solid rgb(174, 174, 174);
}

body #root .dark .banexcoin_marketview .market-data-cards__card-banex {
  background: var(--background-primary_v2);
}

body #root .dark .dashboard-page__market-overview-container-right {
  background: rgb(47, 47, 47);
}

body #root .dark .dashboard-page__balances-header,
body #root .dark .balance-item__product,
body #root .dark .activity__header-text,
body #root .dark .flex-table__column,
body #root .dark .instrument-table__value {
  color: white;
}

body #root .dark .product-details-page__transfers-header,
body #root .dark .activity .activity__header-bar,
body #root .dark .flex-table__header--background,
body #root .dark .flex-table__row {
  background: var(--pc__background-secondary_v2);
  color: white;
}
body #root .dark .activity__action-text--primary,
body #root .dark .activity__amount-quantity,
body #root .dark .activity__status-id,
body #root .dark .activity__date-date {
  color: white;
}

body #root .dark .activity__action-text--secondary {
  color: rgb(194, 194, 194);
}

body #root .dark .activity-pagination__container,
body #root .dark .activity-pagination__text,
body #root .dark .activity__select-buttons,
body #root .dark .transfers-filters {
  background: var(--pc__background-secondary_v2);
  gap: 10px;
}
body #root .dark .activity-pagination__container{
  background-color: var(--primary-color-v2);
}

body #root .dark .transfers-filters {
  padding: 0 2rem 1rem;
}

body #root .dark .transfers-filters__sb-container {
  background: rgb(84, 84, 84);
}

body #root .dark .transfers-filters .ap-inline-btn__btn {
  border: 2px solid var(--btn-checked-v2);
}

body #root .dark .transfers-filters .ap-inline-btn__btn:hover {
  background: var(--pc__background-secondary_v2);
}

body
  #root
  .dark
  .wallet-details
  .wallet-details__position-row
  .wallet-details__detail {
  border-right: 2px solid #ff8d3f;
}

body
  #root
  .dark
  .wallet-details
  .wallet-details__position-row
  .wallet-details__detail:last-child {
  border-right: none;
}

body #root .dark .activity__select-buttons {
  padding: 1rem 2rem;
}

body #root .dark .transfers__primary-text {
  color: white;
}

body #root .dark .activity__select-buttons .ap--label {
  color: white !important;
}

body #root .dark .activity-pagination__page {
  background: var(--background-primary_v2);
  color: var(--pc_font-primary)!important;
}

body #root .dark .pagination__page--active,
body #root .dark .pagination__page--active:hover {
  background: #2e2e48;
}

body #root .dark .ap-padded-decimal__units {
  color:var(--text-gray-v2);
}

body #root .dark .page-footer,
body #root .dark .settings-page__footer {
  background: transparent;
  color: white;
}

body #root button.ap-button__btn.ap-button__btn--subtractive {
  border: 1.5px solid var(--error-color-v2);
  background: var(--error-color-v2) !important;
  border-radius: 8px;
  color: var(--pc_font-primary);
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: none;
}

.flex-table__column.transfers__column.transfers__column--transfers-action
  .transfers__secondary-text {
  font-size: 1rem;
}

flex-table__column.transfers__column.transfers__column--recipient
  .transfers__secondary-text {
  font-size: 1.2rem;
}

body #root .dark .instrument-row,
body #root .dark .instrument-row .instrument-table {
  background: transparent;
}

body #root .dark .trading-layout__body {
  background: var(--background-primary_v2);
}

body #root .dark .boxst-container {
  background: var(--bg-dark-v2);
}

body #root .dark .trading-layout__chart-header {
  color: white;
}

body #root .dark .recent-trade__container > .flex-table__title {
  background: var(--bg-dark-v2);
}

body #root .dark .orderbook__order-book-body,
body #root .dark .lastprice,
body #root .dark .flex-table__title {
  background: var(--background-primary_v2);
}

body #root .dark .lastprice .bignumber.same {
  color: rgb(173, 173, 173);
}

body #root .dark .ap-tab__menu .ap-tab__menu-item {
  background: var(--background-primary_v2);
}

body #root .dark .ap-tab__menu .ap-tab__menu-item.ap-tab__menu-item--active {
  background: var(--pc__background-secondary_v2);
  color: white;
}

body
  #root
  .dark
  .ap-tab__tab.order-history__tab
  .flex-table__header
  .flex-table__column {
  border: 1px solid #505050;
}

body #root .dark .order-history__tab {
  background: var(--background-primary_v2);
  display: grid;
  grid-template-rows: 55px auto;
  width: 100%;
}
body #root .dark .order-history__tab .ap-tab__menu-item:hover::before{
  display: none;
}
body #root .dark .order-history__tab .ap-tab__menu .ap-tab__menu-item.ap-tab__menu-item{
  font-size: 1.4rem;
  font-weight: 400;
  flex: inherit;
}
body #root .dark .order-history__tab .ap-tab__menu .ap-tab__menu-item.ap-tab__menu-item--active {
  color: #FF9443;
  background: var(--background-primary_v2);
  font-weight: 500;
}
body #root .dark .order-history__tab .ap-tab__menu .ap-tab__menu-item.ap-tab__menu-item--active::before {
  display: none;
}
body #root .dark .order-history__tab .ap-tab__menu .ap-tab__menu-item.ap-tab__menu-item--active::after {
  background: #ff8d3f;
  height: 3px;
  border-radius: 4px;
  width: 30%;
  margin: 0 auto;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (width < 582px){
  body #root .dark .order-history__tab{
    width: max-content;
  } 
}
body #root .dark .order-entry,
body #root .dark .trade-component {
  background: var(--background-primary_v2);
  flex: auto;
}

body #root .dark .wallet-details {
  background: #474c48;
}

body
  #root
  .dark
  .wallet-details
  .wallet-details__position-row
  .wallet-details__detail
  .wallet-details__label,
body #root .dark .wallet-details__wallet-nav-items .wallet-nav-item,
body
  #root
  .dark
  .wallet-details__wallet-nav-items
  .wallet-nav-item
  .icon-button__icon {
  color: white;
}
body
  #root
  .dark
  .wallet-details__wallet-nav-items{
    margin-top: -4px;
  }
body #root .dark .wallet-details__wallet-nav-items .wallet-nav-item:hover,
body
  #root
  .dark
  .wallet-details__wallet-nav-items
  .wallet-nav-item:hover
  .icon-button__icon {
  color: #7b7373;
}

body #root .dark .product-details-page__menu .balances-menu__item-symbol-name,
body
  #root
  .dark
  .product-details-page__menu
  .balances-menu__item-balance-value {
  color: white;
}

body #root .dark .quantity .ap--label,
body #root .dark .limit .ap--label,
body #root .dark .stop .ap--label {
  position: absolute;
  top: 25px;
  font-size: 1.2rem;
  color: #a8a8c7!important;
  margin-left: 10px;
}

body
  #root
  .dark
  .order-entry__buy-sell-radio-tab-wrapper
  .ap-radio-tab__label--subtractive {
  background: transparent;
  color: var(--text-gray-v2);
  font-weight: 400;
  position: absolute;
  right: 0;
  font-size: 1.6rem;
  width: 55%;
  padding-left: 50px;
}

body
  #root
  .dark
  .order-entry__buy-sell-radio-tab-wrapper
  .ap-radio-tab__label--subtractive.ap-radio-tab__label--checked,
  .advanced-order-sidepane__tab.advanced-order-sidepane__tab--sell-selected {
    color: white!important;
    font-size: 1.6rem;
    border: none;
    font-weight: 400;
    background-color: var(--error-color-v2)!important;
    clip-path: polygon(100% 0%, 100% 49%, 100% 100%, 10% 100%, 0% 50%, 10% 0);
    padding: 4px;
  }
  body
  #root
  .dark
  .order-entry__buy-sell-radio-tab-wrapper
  .ap-radio-tab__label--additive.ap-radio-tab__label--checked,
  .advanced-order-sidepane__tab.advanced-order-sidepane__tab--buy-selected {
    color: white!important;
    font-size: 1.6rem;
    border: none;
    font-weight: 400;
    background-color: var(--success-color-v2)!important;
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 0 51%, 0% 0%);
    padding: 4px;
  }
  
  .advanced-order-sidepane__tab.advanced-order-sidepane__tab--buy-selected{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .advanced-order-sidepane__tab.advanced-order-sidepane__tab--sell-selected{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
body #root .dark .instrument-row .popover {
  background: var(--pc__background-secondary_v2);
} 

body #root .dark .instrument-selector__trigger:hover,
body #root .dark .instrument-selector__trigger:focus {
  background: transparent;
}

body #root .dark .order-entry__lwt-text {
  color: white;
}

body #root .dark .ap-input__input-box .form-control,
body
  #root
  .dark
  .order-entry__input-wrapper
  .ap-input__input-box
  .label-in-input {
    font-family: 'Inter UI';
  background-color: var(--pc-inputs-background);
  color: white !important;
}

body #root .dark .ap-input__input-box .form-control::placeholder,
body #root .dark .ap-input__input-box .form-control:-ms-input-placeholder,
body #root .dark .ap-input__input-box .form-control::-ms-input-placeholder {
  color: #d1d1d1;
}

body #root .dark .ap-padded-decimal__decimals {
  color: var(--text-gray-v2);
}

body #root .dark .ap-segmented-button__label {
  color: var(--btn-checked-v2);
  background: var(--background-primary_v2);
  font-size: 1.2rem;
  padding: .5rem 0;
}

body #root .dark .order-entry__select.form-control {
  color: var(--pc_font-primary);
  background: var(--pc-inputs-background);
}

body #root .dark .verification-required,
body #root .dark .verification-required__no-link {
  background: #6a6a6a;
}

body #root .dark .instrument-positions-summary__row:first-of-type > *,
body #root .dark .instrument-positions-summary__row-label,
body #root .dark .instrument-positions-summary__product-available,
body #root .dark .instrument-positions-summary__product-symbol,
body #root .dark .instrument-positions-summary__product-hold,
body #root .dark .instrument-positions-summary__product-pending-deposits,
body #root .dark .instrument-positions-summary__product-total-balance {
  color: white;
}

body #root .dark .bulk-cancel-buttons {
  border-top: 1px solid #2e2e48;
  justify-content: space-between;
}

body #root .dark .bulk-cancel-buttons__label,
body #root .dark .error-accessing-message .text {
  color: white;
}

body #root .dark .nav-menu-vertical__item {
  color: white;
  background: var(--pc__background-secondary_v2);
}

body #root .dark .nav-menu-vertical__item.active,
body #root .dark .nav-menu-vertical__item.active:hover,
body #root .dark .nav-menu-vertical__item:hover {
  background: var(--background-primary_v2);
  color: white;
}

body #root .dark .settings-page__container,
body #root .dark .settings-page__menu,
body #root .dark .account-page .card {
  background: var(--pc__background-secondary_v2);
}

body #root .dark .box-info-header-description,
body #root .dark .user-settings-page__sub-title,
body #root .dark .profile-and-security-widget__header,
body #root .dark .user-profile-form-static__lwt-container .ap--label,
body #root .dark .kyc-settings__description,
body #root .dark .kyc-level__list-item,
body #root .dark .trade-report-page__activity-report-header-text {
  color: var(--text-gray-v2) !important;
}
body #root .dark .trade-report-page__activity-report-header-title,
body #root .dark .kyc-settings__header-text,
body #root .dark .user-settings-page__title,body #root .dark .user-profile-form-static__lwt-container .ap--label {
  color: white!important;
  font-weight: 600;
}

body #root .dark .settings-page__content .kyc-level {
  background-color: var(--text-gray-v2);
}

body #root .dark .card {
  background-color: transparent;
}

body #root .dark .table td,
body #root .dark .table th {
  color: white;
}

body #root .dark .table-bordered td,
body #root .dark .table-bordered th,
.trust-list .table-bordered td,
.trust-list .table-bordered th {
  border: 1px solid #f0f0f0;
  color: var(--pc_font-primary);
}

body #root .dark .table thead th,
.trust-list .table thead th {
  border-bottom: 2px solid var(--text-gray-v2);
  color: var(--pc_font-primary);
}

body #root .dark .login-form__input-box .ap-input__input.login-form__input,
body #root .dark .ap-input__input-box .form-control.signup-form__input {
  background-color: #fff !important;
  color: #242424 !important;
}

body #root .dark .buy-sell__container {
  background: var(--background-primary_v2);
  border: 2px solid var(--background-primary_v2);
}

body #root .dark .buy-sell__container .ap--label,
body #root .dark .buy-sell__amount-header,
body #root .dark .buy-sell__amount-row,
body #root .dark .buy-sell-order-overview__lwt-container .ap--label,
body #root .dark .uy-sell-balances__lwt-label {
  color: white !important;
}

body
  #root
  .dark
  .buy-sell__confirm-button-container
  .ap-button__btn.buy-sell__btn {
  background: rgba(255, 255, 255, 0);
}

body #root .dark .form-group select.form-control {
  color: black;
  text-transform: capitalize;
  font-weight: 400;
}
body #root .dark .order-entry__body .form-group select.form-control{
  border: 1px solid var(--border-gray-v2);
  text-transform: initial;
}
body #root .dark .order-entry__body .form-group select.form-control:focus{
  border: 1px solid var(--orange-color);
}

body #root .dark .order-entry__body .form-group select.form-control,
body #root .dark .order-entry__body .form-group select.form-control:hover {
  color: white;
  outline: none;
  box-shadow: none;
  font-size: 1.2rem;
}

body #root .dark .order-entry__body .form-group select.form-control option {
  color: #fff;
}

body #root .dark .ap--label.buy-sell-balances__lwt-label {
  color: white !important;
}

body #root .dark .form-group select.form-control:hover {
  color: var(--pc_font-primary);
  background-color: var(--pc-inputs-background);
  cursor: pointer;
}

body #root .dark .form-group select.form-control option {
  color: var(--pc_font-primary);
}

body #root .dark .wallet-card {
  background: var(--pc__background-secondary_v2);
}

body #root .dark .wallet-card__activity-link-row {
  background: transparent;
}

.wallet-card__activity-link:hover{
opacity: .5;
color: #ff9443;
}

body #root .dark .wallet-card__symbol-label,
body #root .dark .wallet-card__label,
body #root .dark .wallet-card__amount-text {
  color: white;
}

body #root .dark .wallet-card__icon-nav-buttons .wallet-nav-item {
  background: rgba(255, 255, 255, 0.4);
  color: white;
}

body #root .dark .wallet-card__icon-nav-buttons .icon-button__icon,
body #root .dark .wallet-row__symbol-label {
  color: white;
}

body #root .dark .wallets-page__wallet-list-container {
  border: 2px solid var(--pc__background-secondary_v2);
  border-radius: 10px;
}

body #root .dark .wallet-row {
  background: var(--pc__background-secondary_v2);
}

body #root .dark .wallet-row .wallet-row__symbol-label {
  color: white;
}

body
  #root
  .dark
  .wallet-row__icon-nav-buttons
  > .wallet-nav-item
  .icon-button__icon {
  color: white;
}

body #root .dark .orderbook__spread-row {
  color: white;
  padding: 0 16px;
  font-family: "Montserrat",sans-serif;
}

body #root .dark .reset-password-form__text-info,
body #root .dark .settings-enable-two-factor-auth__info {
  color: VAR(--text-gray-v2);
}

body #root .dark .user-settings-page__container .ap-button__btn {
  background: rgba(255, 255, 255, 0);
}

body #root .dark .api-keys-page__table-header {
  background: var(--background-primary_v2);
  color: white;
  border-radius: 10px 10px 0 0;
}

body #root .dark .popover-menu__item,
body #root .dark .popover-menu__item,
body #root .dark .popover-menu__item {
  color: black !important;
}

body #root .dark .popover-menu__item:hover,
body #root .dark .popover-menu__item:active,
body #root .dark .popover-menu__item:focus {
  background: rgba(241, 241, 241, 0.18);
}

body #root .dark .ap-inline-btn__btn {
  color: white;
  background: var(--primary-color-v2);
  border: none;
  border-radius: .8rem!important;
}
body #root .dark .ap-inline-btn__btn:hover {
  opacity: 0.9;
}
.remove-api-key-modal__text{
  color: #fff;
}
.retail-api-key-list__image{
  margin-right: 0;
  height: 1rem;
}

body #root .dark .account-form .form-control {
  background: #686868;
  color: white;
}

body #root .dark .account-form {
  color: white;
}

body #root .dark .account-form .btn.btn-primary {
  background: rgb(255 255 255 / 13%);
}

body #root .dark .account-form .form-control:hover,
body #root .dark .account-form select.form-control:hover {
  color: rgb(216, 216, 216);
}

body #root .dark .container-lock .overlay-lock {
  background: var(--pc__background-secondary_v2);
}

body #root .dark .affiliate-program-page__container {
  background: var(--background-primary_v2);
  color: white;
}

body
  #root
  .dark
  .affiliate-program-page__container
  .affiliate-program-page__container-header {
  background: var(--background-primary_v2);
  color: white;
  border-bottom: 1px solid var(--pc__background-secondary_v2);
}

body #root .dark .affiliate-program-page__container .affiliate__tag {
  color: white;
}

body #root .dark .account-page .table thead > tr > th,
body #root .dark .account-page .table tbody > tr > td,
body #root .dark .settings-page__container .table thead > tr > th,
body #root .dark .settings-page__container .table tbody > tr > td {
  border: none;
}

body #root .dark .account-page .table tbody > tr:nth-of-type(odd),
body #root .dark .settings-page__container .table tbody > tr:nth-of-type(odd) {
  background-color: 	var(--background-primary_v2);
}

.account-page .table tbody > tr > td,
.settings-page__container .table tbody > tr > td {
  vertical-align: middle;
}

.standalone-form__success {
  color: #f28e2a;
}

body #root .dark .trade-report-page__activity-report-buttons .ap-button__btn {
  background: rgb(255 255 255 / 11%);
}

body #root .reduced-text,
body #root small.text-muted.form-text {
  color: white !important;
}

body #root .light .eotc-buy-sell-layout__make-transaction-container,
body #root .light .buy-sell__container .ap-select__select,
body #root .light .buy-sell__amount-container {
  background-color: white;
}


body #root .light .api-keys-page__documentation a {
  color: #f6961f;
}

.api-keys-page__documentation a {
  color: #f6961f;
}

.api-keys-page__documentation a:hover {
  color: white;
}

body #root .light .api-keys-page__documentation a:hover {
  color: white;
}

body #root .light .api-keys-side-pane-button__btn,
body #root .light .api-keys-side-pane-button__btn:active {
  background: var(--secondary-color-v2);
  border: 1.5px solid var(--secondary-color-v2) !important;
  color: #fff;
  font-weight: 500;
  border-radius: 0.5rem;
  line-height: 1;
  height: 3rem;
  margin-left: 0%;
  margin-bottom: 0%;
  width: 100%;
}

body #root .light .api-keys-side-pane-button__btn:hover {
  opacity: 0.9;
  color: white;
}

body #root .dark .api-keys-side-pane-button__btn,
body #root .light .api-keys-side-pane-button__btn:active {
  background: var(--primary-color-v2);
  color: #fff !important;
  border: none !important;
  color: #f6961f;
  font-weight: 500;
  border-radius:1rem;
  line-height: 1;
  height: 3rem;
  margin-left: 0%;
  margin-bottom: 0%;
  width: 100%;
}

body #root .dark .api-keys-side-pane-button__btn:hover {
  color: white;
  opacity: 0.9;
}

body #root .light .api-keys-page__documentation {
  background: var(--secondary-color-v2);
  border: 1.5px solid var(--secondary-color-v2) !important;
  font-weight: 500;
  border-radius: 0.5rem;
  line-height: 1;
  height: 3rem;
  text-transform: uppercase;
}

body #root .light .api-keys-page__documentation a {
  color: #fff;
}

body #root .light .api-keys-page__documentation:hover {
  opacity: 0.9;
}

body #root .light .container-lock .overlay-lock {
  background: rgb(255 255 255 / 82%);
}

body #root .dark .api-keys-page__documentation {
  background: var(--secondary-color-v2);
  border: 1.5px solid var(--secondary-color-v2) !important;
  color: #fff !important;
  font-weight: 500;
  border-radius:1rem;
  line-height: 1;
  height: 3rem;
  text-transform: uppercase;
}

body #root .dark .api-keys-page__documentation a {
  color: #fff !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
body #root .dark .api-keys-page__documentation:hover{
  opacity: 0.9;
}

.create-api-key__btn {
  color: white;
}

body #root .dark .ap--label {
  color: white !important;
  margin: 0 0 0.5rem 0;
}

body #root .dark label.ap--label.ap-input__label.forgot-password-form__label {
  color: black !important;
  font-weight: 500;
}

body #root .light label.ap--label.ap-input__label.forgot-password-form__label {
  font-weight: 500;
}

body
  #root
  .dark
  input.form-control.ap-input__input.forgot-password-form__input {
  background-color: #fff !important;
  color: black !important;
}

body #root .dark label.ap--label.ap-input__label.reset-password__label {
  color: var(--pc_font-primary) !important;
  font-weight: 500;
}

body #root .light label.ap--label.ap-input__label.reset-password__label {
  font-weight: 500;
}

body #root .dark input.form-control.ap-input__input.reset-password__input {
  background-color: var(-pc-inputs-background) !important;
  color: var(--pc_font-primary) !important;
  border-color: var(--btn-checked-v2)!important;
}

body #root .dark .signup-form__wrapper .ap--label,
body #root .dark .login-form__form .ap--label,
body #root .dark .signup-form__wrapper .agreeded-cont {
  color: black !important;
}

.confirm-single-report__lwt-label {
  width: 11.3rem;
}

body #root .dark .trade-report-page__report-header {
  color: white;
}

body #root .dark .account-page .form-group label {
  color: white;
}

body #root .dark .form-group select.form-control {
  color: white;
}

body #root .dark .account-form .form-control option {
  background: #686868;
  color: white;
}

body #root .dark .corporatelevel-page > h1 {
  color: var(--pc_font-primary);
}

body #root .dark .corporatelevel-page  p {
  margin: 1.5rem 0;
  font-size: 1.3rem;
  color: var(--text-gray-v2)!important;
}

body #root .dark .corporatelevel-page .table {
  border: 1px solid var(--secondary-color-v2);
}

body #root .dark .corporatelevel-page .table > tbody > tr:nth-of-type(odd) {
  background: var(--secondary-color-v2);
}
body #root .dark .corporatelevel-page .table > tbody > tr:nth-of-type(odd) >td {
  color: white;
}

body #root .dark .corporatelevel-page .table-bordered td,
body #root .dark .corporatelevel-page .table-bordered th {
  color: white;
  border: 0px solid #a6a6a6;
}


.confirm-api-key__authenticate-user code,
.confirm-api-key__authenticate-user pre {
  background-color: var(--background-primary_v2);
  color: #fff;
}
.confirm-api-key__authenticate-user p{
  color: #fff;
}

.ap-inline-btn__btn.api-key-list {
  border: 1px solid rgb(184, 184, 184);
}

.ap-inline-btn__text.api-key-list {
  line-height: 1.8;
}

.api-key-list__row:last-child {
  border-bottom: none;
}

.api-key-list__column {
  border-left: none;
}

.api-key-list__row:hover .api-key-list__column:nth-child(n + 2) {
  border-left: none;
}

.instrument-table__value--ask,
.ap-padded-decimal__units--sell,
.ap-padded-decimal__decimals--sell {
  color: var(--error-color-v2) !important;
}

.base-lt__layout-template {
  background-color: transparent;
}

.orderbook__body .orderbook__header .flex-table__column:nth-child(3) {
  display: none;
}

.orderbook__body
  .orderbook__order-book-body
  .orderbook__table-filler
  .flex-table__row
  .orderbook__table-my-size {
  display: none;
}

.orderbook__table-filler > :first-child {
  margin-top: 1px;
}
.orderbook__table-filler{
  background-color: var(--background-primary_v2);
  justify-content: space-between;
}
.orderbook__body .orderbook__table-price {
  min-width: 50%;
  text-align: center;
}

.modalAlertBeta .react-confirm-alert-body {
  width: 65vw;
  height: 90vh;
  position: relative;
  margin: auto;
  border-radius: 2.5rem !important;
}
.react-confirm-alert-body {
  background:var(--pc__background-secondary_v2)!important;
}
.modalAlertBeta .react-confirm-alert-body h1 {
  text-transform: uppercase;
  font-weight: 600 !important;
  text-align: center;
  font-family: "Montserrat", sans-serif !important;
  margin-top: 2rem;
  margin-bottom: 2rem !important;
}

.closeModalBeta {
  position: absolute;
  top: -19px;
  right: -35px;
  border-radius: 2rem !important;
  height: 4.5rem;
  background: white !important;
  box-shadow: 0px 7px 13px 0px rgb(0 0 0 / 26%);
  color: black !important;
  font-weight: 600;
  font-size: 2rem !important;
  font-family: "Montserrat", sans-serif !important;
}
.affiliate__tag-description{
  color: #fff;
}

@media only screen and (max-width: 900px) {
  .closeModalBeta {
    top: 0px;
    right: -10px;
  }
}

.react-confirm-alert.modalAlertBeta {
  align-items: center;
  align-content: center;
  display: flex;
}

.modalAlertBeta .inline-img {
  display: inline-block;
  height: 20px;
  vertical-align: sub;
  margin-right: 3px;
}

.modalAlertBeta .center-img {
  margin: auto;
  position: relative;
  display: block;
  width: 80px;
}

.modalAlertBeta .maintenance-img {
  position: relative;
  width: 30%;
  float: right;
}

.closeModalBeta:hover {
  box-shadow: 0px 7px 13px 0px rgb(0 0 0 / 40%);
  background: #ea6d61 !important;
  color: white !important;
}

.modalAlertBeta .react-confirm-alert-body p {
  font-weight: 600;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1.5rem;
}

.modalAlertBeta .click-here-beta {
  color: orange;
  text-transform: uppercase;
  font-size: 1.4rem;
}

.modalAlertBeta .body_scroll {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 1.65rem;
  color: rgba(0, 0, 0, 0.54);
  padding: 5px 70px 30px 20px !important;
  height: 100%;
}

.team-sign,
.dontshowalert {
  display: inline-block;
  width: 50%;
}

.dontshowalert {
  margin-top: 2.5rem;
  text-align: right !important;
}

.dontshowalert input {
  width: 2rem;
  height: 2rem;
}

.dontshowalert label {
  margin-left: 5px;
  font-weight: 700;
  font-size: 1.5rem;
  vertical-align: top;
}

.custom_fc_frame.fc-widget-small {
  z-index: 98 !important;
}

@media only screen and (max-width: 900px) {
  .modalAlertBeta .react-confirm-alert-body {
    width: 95vw;
    height: 100vh;
    padding: 16px 2px 15px !important;
    border-radius: 0 !important;
  }

  .modalAlertBeta .body_scroll {
    padding: 0px 15px 15px !important;
    font-size: 1.5rem;
  }

  .modalAlertBeta .react-confirm-alert-body h1 {
    margin-top: 1rem;
    margin-bottom: 2rem !important;
    font-size: 1.7rem;
  }

  .modalAlertBeta .center-img {
    width: 70px;
  }

  .modalAlertBeta .maintenance-img {
    margin: auto;
    position: relative;
    display: block;
    width: 30%;
    float: none;
  }

  .team-sign,
  .dontshowalert {
    width: 100%;
  }

  .team-sign {
    text-align: right;
  }
}

/* Chat Soporte */

button.btn_chat {
  font-size: 10px;
  font-weight: bold;
  color: var(--pc_font-primary);
  border-radius: 29px 2px 2px 29px;
  background-color: #5d3dff;
  position: fixed;
  bottom: 62px;
  right: -1px;
  width: 46px;
  height: 36px;
  padding-left: 14px;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  transition-property: width;
  animation-name: example;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  display: block !important;
  text-overflow: inherit;
}

button.btn_chat.forcehide {
  display: none !important;
  visibility: hidden !important;
}

@keyframes example {
  from {
    right: -40px;
  }

  to {
    right: 0px;
  }
}

button.btn_chat:hover {
  width: 10rem;
  padding-left: 5px;
}

.btn_chat span {
  color: white;
  display: contents !important;
}

.custom_fc_frame {
  left: 5px !important;
  bottom: 60px !important;
}

.chart-page .chart-container {
  background-color: transparent !important;
}

a {
  text-decoration: none !important;
}

.fiat-deposit-form__frame {
  padding: 1rem 0rem 0 0rem;
}

.verification-required,
.verification-required:hover,
.verification-required__no-link {
  background: var(--primary-color-v2)!important;
  border-radius: 0.5rem;
}

.verification-required * {
  color: white;
}
.verification-required:hover {
  background: #cfcfcf;
}

.container .page-header__container {
  background: rgb(255, 255, 255);
  margin-bottom: 0px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header-nav__item {
  background: transparent;
  color: rgb(255, 255, 255);
}

.page-header-nav__item--selected,
.page-header-nav__item--selected:hover,
.page-header-nav__item:hover {
  color: var(--pc_font-primary);
  border-left: 2px solid #fe9015;
  background: transparent
}
.page-header-nav__item--selected{
  background: var(--background-primary_v2)
}

.dashboard-page__notification-row {
  border-radius: 0.5rem;
}

.transfer-request-notification-list__empty {
  background: var(--nav-header__bg-gradient);
  height: 5rem;
}

.retail-price-chart {
  background: transparent;
}

.dashboard-page__market-header {
  padding-left: 0;
}

.market-overview__chart-component-container {
  border: 1.5px solid #f1f1f1;
  padding: 10px;
  height: 61.4rem;
  border-radius: 0;
}

.market-overview__market-overview-container {
  margin-left: 40px;
  width: 30%;
}

.market-overview__instrument-selector {
  background: transparent;
}

.market-overview-instrument {
  background: #f1f1f1;
  border: none;
  border-radius: 0;
}

.market-overview-instrument__row {
  background: transparent;
  border: none;
}

.market-overview__instrument-selector .instrument-selector-popover__popover {
  width: auto;
  border: none;
}

.instrument-positions-summary__row {
  border-bottom: none;
}

.instrument-positions-summary__row:first-of-type > *,
.instrument-positions-summary__row-label {
  color: #181818;
}

.instrument-selector__trigger {
  width: auto;
  min-width: 10rem;
}

.boxst-container {
  padding: 10px;
  border: var(--border-grey);
  border-top: none;
  position: relative;
}

.boxst-container.depth {
  padding: 15px;
  border-bottom: none;
}

.instrument-table__row .instrument-table__value {
  order: 2;
  font-weight: 300;
}

.instrument-table__row .instrument-table__title {
  color: var(--text-gray-v2);
  font-size: 1.1rem;
  font-weight: 400;
  order: 1;
}

.market-overview__instrument-selector .instrument-selector__trigger {
  background: white;
}

.market-overview-instrument__title:first-child {
  font-style: normal;
  padding-top: 0;
  font-weight: 500;
  font-size: 1.8rem;
}

.balance-item__container {
  background: transparent;
  border: none;
}

.balance-item__container:hover {
  color: var(--component__color-primary);
  background: rgba(0, 0, 0, 0.09);
  border-radius: 0.5rem;
}

body #root .dark .balance-item__position {
  color: #fff;
}

.activity {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.activity__header-bar {
  background-color: transparent;
  border-bottom: none;
  padding-left: 1rem;
}

.activity__header-text {
  font-size: 2rem;
  font-weight: 500;
}

.activity__table-header {
  padding: 0 2rem;
}

.dashboard-page__recent-activity-container {
  padding: 10px 0 10px 0px;
}

.activity__date-time,
.activity__status-text,
.activity__amount-value,
.activity__action-text--secondary {
  color: var(--text-gray-v2);
}

.flex-table {
  background: var(--background-primary_v2);
}

.flex-table__header--background {
  background: white;
  border-top: none;
  border-bottom: none;
}

.flex-table__header .flex-table__column {
  border: 0px solid #c2c2c2;
  border-right: none;
  background-color: transparent;
}

.ap-tab__tab.order-history__tab .flex-table__header .flex-table__column {
  border: 1px solid #c2c2c2;
  border-right: none;
  border-left: none;
}

.flex-table__header .flex-table__column:last-child {
}

.flex-table__column {
  color: var(--retail-dashboard__card-header-title-font-color);
}

.flex-table__column.activity__header-column {
  border: none;
}

.form-group small span.contenedor img {
  width: 100%;
}

.flex-table__row {
  background: white;
  border-bottom: none;
}

.flex-table__row:hover {
  background: rgb(246, 246, 246);
}

.activity__action-text--secondary {
  color: #5f5f5f;
  font-weight: 400;
  font-size: 1.1rem;
}

.form-group.invalid input.form-control {
  background-color:var(--pc-inputs-background);
  border-color: transparent;
}

.was-validated .form-group.invalid .invalid-feedback {
  display: block;
}

.activity__action-text--secondary-new {
  color: #171717;
  font-weight: 500;
  font-size: 1.1rem;
}

.page-header-nav__item {
  padding-left: 3rem;
  padding-right: 1.5rem;
  min-height: var(--nav-header__height);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  border-top: none;
  -ms-flex-order: 2;
  order: 2;
}

.page-header-nav__item {
  width: 100%;
  height: 5.2rem;
}

.page-header-nav__label {
  display: none;
  width: 100%; 
  overflow: hidden; 
  white-space: pre; 
}

.page-header-nav__item:focus {
  border-right: none;
}

.ap-icon.ap-icon--exchange,
.ap-icon.ap-icon--compra-venta,
.ap-icon.ap-icon--easybuy {
  width: 2.5rem;
}

.ap-icon.ap-icon--exchange svg,
.ap-icon.ap-icon--compra-venta svg {
  margin: 0 auto;
}

#root .container.logged {
  width: calc(100% - 8rem);
  margin-left: 8rem
}

#root,
.App.container {
  padding: 0;
}

#root .container.logged.hide-menu {
  width: 100%;
  max-width: 100%;
  margin-left: 0;
}

.LevelBlock {
  display: flex;
  position: relative;
  margin: 0 -0.5rem 2.5rem;
}

.LevelBlock .slick-track {
  display: flex;
}

.LevelBlock .slick-initialized .slick-slide {
  margin: 0 1rem;
  display: inline-flex;
}

.LevelBlock .LevelForSingle {
  width: 25%;
  height: 17rem;
  padding: 20px 15px 20px 15px;
  margin: 0 0.5rem;
  border-radius: 1rem;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 85%;
  background-color: white;
  -webkit-transition: box-shadow 1s;
  transition: box-shadow 0.5s;
}

.LevelBlock .LevelForSingle.hide-verified .levelFooter, .LevelBlock .LevelForSingle.hide-verified .verifiedMed {
  display: none;
}
.LevelBlock .LevelForSingle.qty-1 {
  width: 100%;
}

.LevelBlock .LevelForSingle.qty-2 {
  width: 50%;
}

.LevelBlock .LevelForSingle.qty-3 {
  width: 33%;
}

.LevelBlock .LevelForSingle.qty-4 {
  width: 25%;
}

.LevelBlock .LevelForSingle.qty-5 {
  width: 20%;
}

.LevelBlock.minified .LevelForSingle {
  height: 7.5rem;
  padding: 12px 10px 12px 8px;
  border-radius: 0rem;
  background-size: 42%;
}

.LevelBlock.minified .LevelForSingle .levelContent {
  display: none;
}

.LevelBlock .LevelForSingle .levelHeader {
  display: flex;
}

.LevelBlock .LevelForSingle .levelHeader .verifiedMed {
  width: 3rem;
  height: 3rem;
  background-image: url(/local/landing-page/images/levels-bars/correct.svg);
  background-repeat: no-repeat;
  margin-top: -0.5rem;
}

.LevelBlock .LevelForSingle .levelHeader .levelTitle {
  font-weight: 600;
  font-size: 1.7rem;
  width: 100%;
}

.LevelBlock .LevelForSingle .levelContent {
  padding: 0;
  height: max-content;
  color: var(--text-gray-v2);
  font-size: 1.3rem;
  width: 85%;
  line-height: 1.4;
}
.LevelBlock .LevelForSingle .levelContent p{
  color: var(--text-light);
}

.LevelBlock .LevelForSingle .VerifiedStatus,
.settings-page__content .otherlevels .VerifiedStatus {
  font-size: 1.4rem;
  padding: 1rem 2rem;
  background: var(--hover-color-v2);
  color: var(--text-light);
  border: none;
  border-radius: 1.6rem;
  width: max-content;
  cursor: default;
  display: flex;
  column-gap: .5rem;
}
.LevelBlock .LevelForSingle .VerifiedStatus span,
.settings-page__content .otherlevels .VerifiedStatus span {
  width: 17px;
  margin-top: -1px;
}
.LevelBlock .LevelForSingle .VerifiedStatus span svg,
.settings-page__content .otherlevels .VerifiedStatus span svg{
  width: 17px;
  height: 17px;
}

.settings-page__content .otherlevels .VerifiedStatus:focus {
  outline: none;
}

.LevelBlock .LevelForSingle .MustVerifiedStatus,
.settings-page__content .otherlevels .MustVerifiedStatus {
  border: 1.5px solid rgb(173, 173, 173);
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  color: rgb(173, 173, 173);
  font-size: 1.2rem;
  font-weight: 500;
}

.LevelBlock .LevelForSingle .PendingVerifiedStatus,
.settings-page__content .otherlevels .PendingVerifiedStatus {
  background: var(--primary-color-v2);
  padding: 0.7rem 1rem;
  border-radius: 1rem;
  color: var(--pc_font-primary);
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  width: max-content;
  border-radius: 1.6rem;
}

.LevelBlock .LevelForSingle .PendingVerifiedStatus:hover,
.settings-page__content .otherlevels .PendingVerifiedStatus:hover {
  opacity: 0.8;
}

.LevelBlock .LevelForSingle .PendingVerifiedStatus.hidden {
  display: none;
}

.LevelBlock .LevelForSingle .PendingVerifiedStatus.inprogress {
  background: inherit !important;
  color: #FF9443  !important;
  border: 1px #FF9443 solid !important;
  font-size: 1.4rem;
  padding: 1rem 2.2rem;
  border-radius: 1.6rem;
  width: max-content;
}

.LevelBlock .LevelForSingle .PendingVerifiedStatus.rejected,
.LevelBlock .LevelForSingle .PendingVerifiedStatus.incorrect_information {
  background: var(--error-color-v2);
  border-color: transparent;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 1.6rem;
  width: max-content;
}
.alert.alert-v2 {
  background-color: #ff7d00;
  border-color: transparent;
  color: white;
  font-size: 1.6rem;
  padding: 1.35rem 1.5rem;
  margin-bottom: 3rem;
  border-radius: 1.2rem;
}

.settings-page__content .otherlevels .PendingVerifiedStatus.inREJECTED {
  background-color: #fbac0a;
  border-color: #ff6c00;
}

.settings-page__content .otherlevels .PendingVerifiedStatus.inINPROGRESS {
  background-color: #0a7afb;
  border-color: #002fff;
}

.settings-page__content .otherlevels .PendingVerifiedStatus.isCLOSED {
  pointer-events: none;
}

.TourInfoBox p {
  font-size: 1.5rem;
}

.advanced-order-form__input-wrapper .label-in-input {
  margin-top: 8px !important;
}

.advanced-order-form__main-section,
.advanced-order-form__main-section > div {
  position: relative;
  width: 100%;
}

.error-page {
  text-align: center;
}

.error-page p a {
  font-size: 3.7rem;
  font-weight: 500;
  color: #f28e2a;
}

.error-page img {
  width: 50%;
  margin: 0 auto;
}

/* header user */
.popover {
  z-index: 99 !important;
}

.page-header__right-panel .popover {
  border: none;
}

.user-summary__popover-menu-trigger:hover {
  background: rgb(250, 250, 250);
}

.user-summary__popover-menu-trigger:focus {
  background: rgb(250, 250, 250);
}

.sub-patrimonio {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 2rem;
  align-content: center;
}

.sub-patrimonio .money-crypt {
  margin-top: 4px;
}
.rtooltip{
  background-color: var(--pc__background-secondary_v2) !important;
}
.rtooltip::after{
  border-bottom-color: var(--pc__background-secondary_v2) !important;
}
.rtooltip2{
  background-color: var(--background-primary_v2) !important;
  max-width: 250px;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
  text-align: center;
}
.rtooltip2::after{
  border-bottom-color: var(--background-primary_v2) !important;
}
.textWithPointSuspense{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.textWithPointSuspense.maxWidth{
  width: max-content;
}

.sub-patrimonio .mon {
  display: block;
  font-weight: 600;
  font-size: 1.2rem;
  border-left: 2px solid #ff8f15;
  padding-left: 0.5rem;
}

.sub-patrimonio .mon.title {
  border-left: none;
}

.sub-patrimonio .mon .money-amount {
  font-weight: 500;
}

.toggle-bar {
  border-left: 2px solid rgb(76, 154, 255);
  opacity: 0;
  height: calc(100% + 7.2rem);
  right: -10px;
  top: -7.2rem;
  position: absolute;
  width: 10px;
  z-index: 9;
  -webkit-transition: opacity 200ms ease 0s;
  -o-transition: opacity 200ms ease 0s;
  transition: opacity 200ms ease 0s;
}

.toggle-bar:hover {
  opacity: 1;
  cursor: ew-resize;
}

.instrument-selector__trigger {
  border: none;
}

.instrument-selector__trigger:hover,
.instrument-selector__trigger:focus {
  background: #ebebeb;
}

.instrument-row {
  background: #f9f9f9;
  padding-left: 20px;
}

.instrument-row.barhor {
  min-height: 7rem !important;
  padding-left: 0;
}

.instrument-row.selectorpair {
  padding-top: 0.1rem;
}

.instrument-row .popover {
  border: none;
  background: #f9f9f9;
  font-family: "Montserrat", Sans-serif;
  z-index: 2 !important;
}

.instrument-selector__trigger {
  color: #fe9015;
}

.trading-layout__body {
  border-top: none !important;
  background: #f9f9f9;
  grid-template-rows: auto auto;
}

.trading-layout__chart-container .boxst-container .tv-chart {
  min-height: 460px;
  height: 460px;
}

@media only screen and (min-width: 1280px) {
  .trading-layout__body {
    grid-template-columns: 55% 25% auto;
    grid-template-areas:
      "chart orderbook recenttradesorderentry"
      "activity activity activityorderentry";
  }
}

.order-entry__container {
  grid-area: auto;
  border-left: none;
  border-top: 1px solid var(--pc__background-secondary_v2);
  border-right: 1px solid var(--pc__background-secondary_v2);
}

.trading-layout__chart-header {
  padding: 0 1rem;
  color: #616161;
  font-family: "Montserrat", Sans-serif;
}

.trading-layout__chart-container {
  padding: 0px;
  overflow-y: none;
  display: grid;
  grid-template-rows: max-content 18% auto;
}

.sidepane-details__lwt-container:nth-child(2) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(3) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(4) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(5) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(6) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(7) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(8) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(9) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(10) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(11) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(12) label,
.sidepane-details__lwt-container:nth-child(13) label,
.sidepane-details__lwt-container:nth-child(14) label,
.sidepane-details__lwt-container:nth-child(15) label,
.sidepane-details__lwt-container:nth-child(16) label,
.sidepane-details__lwt-container:nth-child(17) label {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
}

.sidepane-details__lwt-container:nth-child(2) .sidepane-details__lwt-text {
  color: var(--pc_font-primary);
}

.sidepane-details__lwt-container:nth-child(3) .sidepane-details__lwt-text {
  color: #ff365f;
}

.sidepane-details__lwt-container:nth-child(4) .sidepane-details__lwt-text {
  color: #00b871;
}

.sidepane-details__lwt-container:nth-child(5) .sidepane-details__lwt-text {
  font-weight: 600;
  color: var(--pc_font-primary);
}

.sidepane-details__lwt-container:nth-child(5) {
  margin-bottom: 30px;
}

.sidepane-details__lwt-container:nth-child(7) .ap--label:before {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
  content: "1\00a0\00a0\00a0-\00a0";
  padding-right: 1rem;
}

.sidepane-details__lwt-container:nth-child(8) .ap--label:before {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
  content: "2 -";
  padding-right: 1rem;
}

.sidepane-details__lwt-container:nth-child(9) .ap--label:before {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
  content: "3 -";
  padding-right: 1rem;
}

.sidepane-details__lwt-container:nth-child(10) .ap--label:before {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
  content: "4 -";
  padding-right: 1rem;
}

.sidepane-details__lwt-container:nth-child(11) .ap--label:before {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
  content: "5 -";
  padding-right: 1rem;
}

.sidepane-details__lwt-container:nth-child(12) .ap--label:before {
  color: var(--pc_font-primary) !important;
  font-weight: 400;
  content: "6 -";
  padding-right: 1rem;
}

.instrument-selector__symbol {
  margin-right: 5px;
  font-weight: 500;
}

.flex-table__title,
.orderbook__spread-row {
  background: transparent;
  border-bottom: none;
  border-top: none;
  font-size: 1.6rem;
  font-weight: 500;
}

.bulk-cancel-buttons {
  background: transparent;
  border-top: 2px solid #ededed;
  padding: 0 0.8rem;
}

div.order-entry__container {
  background: transparent;
}

.bulk-cancel-buttons__btn {
  margin-left: 0.25rem;
  padding: 0 0.3rem;
  width: 8rem;
}

.bulk-cancel-buttons__label {
  color: rgb(92, 92, 92);
}

.trade-component__section {
  border-top: none;
  margin-top: 0.75rem;
}

.order-book__container {
  border-left: none;
}

.orderbook__order-book-body {
  font-family: "Roboto", sans-serif;
}

.ap-inline-btn__btn {
  color: rgb(59, 59, 59);
  background: transparent;
}

.recent-trade__container .flex-table__body {
  font-family: "Roboto", sans-serif;
}

.activity__action-text--primary,
.activity__amount-quantity,
.activity__date-date,
.activity__order-type,
.activity__price,
.activity__quantity,
.activity__status-id {
  font-size: 1.4rem;
  font-weight: 500;
}

.flex-table__body.instrument-selector-popup__body {
  margin: 0 0%;
  width: 100%;
  padding-left: 0;
}

.ap-inline-btn__btn:hover {
  background: #2E2E48;
}

.recent-trade__container {
  border-left: none;
  width: 100%;
}

.blockchain-link {
  text-align: left;
}

.flex-table__column.activity__header-column:first-child,
.flex-table__column.activity__column:first-child {
  flex-grow: 2;
}

.flex-table__column.activity__column:nth-child(3) {
  flex-grow: 2;
}

.dashboard-page__accent-bar,
.eotc-buy-sell-layout__accent-bar,
.wallets-page__accent-bar {
  height: 21.5rem;
  margin-bottom: -21.5rem;
  width: 100%;
  flex-shrink: 0;
  background: transparent;
}

.order-entry__buy-sell-radio-tab-wrapper .ap-radio-tab__label--additive,.order-entry__buy-sell-radio-tab-wrapper .ap-radio-tab__label--subtractive {
  color: var(--text-gray-v2);
  padding: 11px 34px;
  height: 35px;
  border: none !important;
  background-color: transparent;
  position: absolute;
  width: 55%;
  font-size: 1.4rem!important;
  transition: 20ms all!important;
  padding-top: 7px!important;
}


.ap-modal.confirm-block-trade-modal__bought {
  background: white;
}

.ap-modal.confirm-block-trade-modal__bought .ap-modal__header {
  color: #f28e2a;
  font-weight: 400;
}

.ap-modal.confirm-block-trade-modal__bought .ap-modal__content {
  padding: 0 2.5rem;
}

.confirm-block-trade-modal__lwt-container {
  background: var(--background-primary_v2);
  border-bottom: none;
}

.confirm-block-trade-modal__lwt-container:nth-child(2n + 1) {
  background: var(--background-primary_v2)
}

.ap-modal.confirm-block-trade-modal__bought .ap-modal__footer {
  border-top: none;
  background: var(--background-primary_v2);
  margin-left: 0rem;
}

.ap-modal.confirm-block-trade-modal__bought .ap-modal__footer .ap-button__btn {
  height: 3.5rem;
  margin: 0 auto;
}

.order-entry__buy-sell-radio-tab-wrapper .ap-radio-tab__label--subtractive {
  background: #f1f1f1;
  color: var(--text-gray-v2);
  font-weight: bold;
  padding: 11px 34px;
  height: 35px;
  border: none !important;
  width: 40%;
}

.order-entry__buy-sell-radio-tab-wrapper
  .ap-radio-tab__label--additive.ap-radio-tab__label--checked,
.order-entry__buy-sell-radio-tab-wrapper
  .ap-radio-tab__label--subtractive.ap-radio-tab__label--checked {
  background-color: var(--pc__background-secondary_v2) !important;
}

.ap-tab__menu-item.trading-layout__menu-item {

  margin-right: 0rem;
  margin-top: 0rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 1px solid var(--pc__background-secondary_v2);
}

.btn-group label.btn.ap-radio-tab__label {
  font-size: 1.2rem;
}

.order-entry {
  background: white;
  padding: 0 2rem;
}

.order-entry__buy-sell-wrapper {
  border-bottom: none;
}

.ap--label {
  color: var(--pc_font-primary);
  margin: 0 0 1.5rem 0;
}

.ap--label.verification-required__not-verified {
  width: fit-content;
}

.ap-input__input-box .label-in-input,
.report-block-trade-form__input-box .label-in-input {
  margin-top: -2px;
}

input.form-control.ap-input__input.receive-form__input,
input.form-control.ap-input__input.send-form__input {
  border-width: 0 0 2px 0;
  border-color: transparent;
  border-radius: 10px;
}

input.form-control.ap-input__input.send-form__input,
.send-form__input,
.send-form__source,
.receive__source {
  background: var(--background-primary_v2);
  border-radius: 40px;
}

input.form-control.ap-input__input.send-form__input:hover {
  background: var(--pc-inputs-background);
}

textarea.form-control.ap-input__text-area.receive-form__text-area.ap-input__input.receive-form__input {
  border-color: transparent;
  border-width: 1.5px;
}

.form-group select.form-control {
  padding: 0.1em 1em;
  height: 40px;
  border-width: 0;
}

.form-control[type="date"].is-valid,
.was-validated .form-control[type="date"]:valid {
  background-position: right calc(0.5em + 2rem) center;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-position: right calc(0.5em + 1.5rem) center;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(0.375em + 1.5rem) center;
}

.form-group .form-control.is-invalid:valid {
  border-color: #dc3545;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(0.375em + 1.5rem) center !important;
}

.ap-input__input-box .advanced-order-form__input.form-control,
.report-block-trade-form__input-box
  .report-block-trade-form__input.form-control {
  padding-left: 4rem;
}

.container-all {
  overflow: auto;
  width: 100%;
  height: 100%;
  border:var(--border-grey);
}

.box-info-header-description {
  font-size: var(--settings__body-header-sub-title-font-size);
  color: black;
  letter-spacing: var(--settings__body-header-sub-title-letter-spacing);
  line-height: var(--settings__body-header-sub-title-line-height);
  letter-spacing: 0.5px;
  padding: 0 0 1rem 0;
}

.recent-trade__container > .flex-table__title {
  padding: 2.5rem 1.5rem;
  background: #f1f1f1;
}

@media (min-width: 1024px) {
  div.recent-trade__container {
    height: 43%;
  }

  div.order-book__container {
    height: 57%;
  }
}

@media (min-width: 1024px) {
  .order-book__container {
    width: 100%;
  }
}

.login-form__input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.signup-form__container .form-group:first-child .signup-form__error {
  height: auto;
}

.react-datepicker-component .react-datepicker-input {
  height: 38px;
}

.react-datepicker-component .react-datepicker-input,
.react-datepicker-component .react-datepicker-input:hover,
.react-datepicker-component .react-datepicker-input:focus {
  border: none !important;
  box-shadow: none;
}

.manual-deposit-workflow-component .form-control:focus {
  box-shadow: none;
}

.react-datepicker-component .react-datepicker-input input,
.manual-deposit-workflow-component .form-control[type="text"] {
  border-bottom: 2px solid #eaeaea;
  background: white;
  font-size: 1.2rem;
  border-radius: 0;
}

.react-datepicker-component .react-datepicker-input input:hover,
.react-datepicker-component .react-datepicker-input input:focus,
.manual-deposit-workflow-component .form-control[type="text"]:focus,
.manual-deposit-workflow-component .form-control[type="text"]:hover {
  border-bottom: 2px solid #ff9013;
}

.react-datepicker-component .react-datepicker-input input {
  padding: 1.8em 1em;
}

.recent-trades__table-header-time,
.recent-trades__table-time {
  padding: 0 0rem 0 1rem;
  text-align: center;
}

.recent-trades__table-header-price,
.recent-trades__table-price,
.recent-trades__table-header-qty,
.recent-trades__table-qty {
  padding: 0 0 0 1rem;
}

.recent-trades__table-qty {
  text-align: center;
}

.ticker {
  display: flex;
  justify-content: space-around;
  width: 13.33%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.test-class {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
  z-index: 999;
  top: 0;
  left: 0;
}

.test-class > div {
  width: 70%;
  background: white;
  vertical-align: middle;
  height: 90vh;
  margin: 5vh auto;
  box-shadow: 0 0 10px #000;
  border-radius: 8px;
  overflow: hidden;
  max-width: 720px;
}

.test-class > div > div {
  position: relative;
  display: contents;
}

.test-class > div > div > iframe {
  width: 100%;
  height: 100%;
}

.add-right-btn {
  color: white;
  background: inherit;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid;
  float: right;
  z-index: 9;
  position: relative;
  margin-top: -1rem;
}

.add-right-btn:hover {
  opacity: 0.9;
}

.modal-class {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
  z-index: 999;
  top: 0;
  left: 0;
  display: inline-grid;
}

.modal-class > div {
  width: 70%;
  background: white;
  vertical-align: middle;
  height: auto;
  padding: 2rem;
  margin: auto auto;
  border: 2px solid #f28e2a;
  border-radius: 0.5rem;
  overflow: hidden;
  max-width: 720px;
}

.modal-class > div > div {
  position: relative;
}

.modal-class > div > div > a {
  z-index: 99;
}

.modal-class > div .btn.btn-primary {
  background: #f28e2a;
  border-color: #f28e2a;
}

.modal-class > div .form-label {
  font-weight: 500;
  color: #515151;
}

.send-receive-confirm-modal__lwt-label {
  text-align: left;
  margin: 1em 0;
}

.send-receive-confirm-modal__lwt-container {
  border-top: none;
}

.ExternalAddress-input__input-box {
  width: 100%;
}

.ExternalAddress-input__input {
  padding: 0.1em 1em;
  height: 40px !important;
  border-width: 0 0 2px 0;
  position: relative;
  top: 8.5rem;
  z-index: 9;
  width: -webkit-calc(100% - 4rem);
  width: -moz-calc(100% - 4rem);
  width: calc(100% - 4rem);
  background: #e5ff97;
  color: #619930;
  font-weight: 500;
  font-size: 1.3rem;
}

.ExternalAddress-input__error {
  display: none;
}

.form-group select.form-control[name="ExternalAddress"] {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 60%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 19px) calc(1em + 4px),
    calc(100% - 14px) calc(1em + 4px), calc(100% - 0.7em) 0.7em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  color: transparent;
}

.form-group select.form-control[name="ExternalAddress"]:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 14px) 1.1em, calc(100% - 19px) 1.1em,
    calc(100% - 0.7em) 0.7em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

.form-group select.form-control[name="ExternalAddress"] option {
  color: var(--pc_font-primary);
}

@media screen and (max-width: 1070px) {
  .ticker {
    width: 100%;
  }
}

.ticker-scrolling {
  width: 300%;
  display: flex;
  line-height: 30px;
  animation: marquee 10s linear infinite forwards;
}

.ticker-scrolling:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-33.3%);
  }
}


.ap-padded-decimal__units {
}

.ap-padded-decimal__padding {
  color: rgba(61, 71, 84, 0.53);
  visibility: hidden;
}

.instrument-table {
  flex-basis: 100%;
}

.order-entry__input-wrapper .ap-input__input-box .label-in-input {
  font-weight: 600;
  color: #4b4b4b !important;
  margin: 0;
  height: 3.8rem;
  line-height: 4;
}

.order-entry__input-wrapper
  .ap-input__input-box
  .form-control.ap-input__with-label {
  border-width: 0px 0px 2px 0;
  border-color: #eaeaea;
  padding-left: 4.25rem;
}

.order-entry__input-wrapper
  .ap-input__input-box
  .form-control.ap-input__with-label:hover,
.order-entry__input-wrapper
  .ap-input__input-box
  .form-control.ap-input__with-label:focus {
  background: transparent;
  border-color: #ff9013;
  outline: none;
  box-shadow: none;
}

.order-entry__input-wrapper,
.order-entry__select-wrapper {
  padding: 1rem 0;
  margin: 0;
}

.ap-select__select.order-entry__select {
  width: 100%;
  padding: 0rem 1rem;
  height: 3rem;
  border-color: var(--border-gray-v2)!important;
}

.order-entry__triangles-container {
  bottom: 2.1rem;
  right: 1rem;
}

.order-entry__triangles-container .triangle-up {
  border-bottom: 0.4rem solid rgb(185, 185, 185);
}

.order-entry__triangles-container .triangle-down {
  border-top: 0.4rem solid rgb(185, 185, 185);
}

.report-block-trade-sidepane__container
  .report-block-trade-form__btn.report-block-trade-form__btn--additive.ap-button__btn {
  background: #ff9013;
  border: 1.5px solid #ff9013;
  color: white;
  font-weight: 600;
}

.report-block-trade-sidepane__container
  .report-block-trade-form__btn.report-block-trade-form__btn--additive.ap-button__btn:hover,
.report-block-trade-form__btn.report-block-trade-form__btn--additive.ap-button__btn:focus {
  background: white;
  color: #ff9013;
}

.ap-select__select.order-entry__select option {
  background: #343551;
}
.order-history-table__buy{
  color: var(--success-color-v2);
  font-size: 1.2rem;
}
.order-history-table__sell{
  color: var(--error-color-v2);
  font-size: 1.2rem;
}

.trade-component__section .ap-button__btn {
  background: var(--success-color-v2) !important;
  border: 1.5px solid var(--secondary-color-v2) !important;
  color: var(--pc_font-primary) !important;
  border-radius: 8px;
  text-transform: capitalize;
  font-weight: 400!important;
  font-size: 1.4rem;
  height: 40px;
}

.trade-component__section .ap-button__btn:disabled {
  opacity: 0.5;
}

.order-history__tab {
  background: transparent;
  border-top: none;
  min-height: 15rem;
  background: var(--secondary-color-v2) ;
  border-radius: 0.3rem;
}

.pagination__container {
  border-top: none;
  background-color: var(--bg-dark-v2);
}
.pagination__container *{
  font-size: 1.4rem;
}

.ap-tab__menu-item {
  color: var(--tab__font-color--selected);
  font-weight: 500;
  border-right: none;
}

.ap-tab__menu .ap-tab__menu-item {
  background: var(--pc__background-secondary_v2);
  color: var(--text-gray-v2);
  padding: 1rem 1rem;
  height: 3.5rem;
}

.ap-tab__menu .ap-tab__menu-item.ap-tab__menu-item--active {
  background: white;
  color: black;
}

.order-history-table__header-column {
  border-left: none;
}

.slide-pane__header{
  background: var(--background-primary_v2);
  padding-top: 2em;
}

.advanced-order-sidepane__tab-container,
.report-block-trade-sidepane__tab-container {
  background: #404060!important;
  border-radius: 6px;
  margin: 2rem 2rem 0;
}
.report-block-trade-sidepane__tab{
  font-size: 1.4rem;
  color: white;
}

.slide-pane__header .slide-pane__title {
  font-size: 2.5rem;
}

.advanced-order-sidepane__tab {
  color: var(--text-gray-v2);
}

.advanced-order-sidepane__tab:hover {
  color: #a8a8c7
}

.advanced-order-form__limit-price-block {
  background: var(--background-primary_v2);
  border: 1px solid var(--border-gray-v2)!important;
}

.advanced-order-form__limit-price-block-label {
  color: var(--pc_font-primary);
}

.ap-input__input-box .advanced-order-form__input {
  border-bottom: 2px solid rgb(227, 227, 227);
}

.ap-input__input-box .label-in-input {
  background: transparent;
  color: var(--pc_font-primary);
}

.ap-input__input-box .advanced-order-form__input:hover {
  border-bottom: 2px solid #ff9013;
  background: var(--pc-inputs-background);
}

.ap-inline-btn__btn:disabled,
.ap-inline-btn__btn:disabled:hover {
  color: rgba(39, 39, 39, 0.82);
}

.trading-layout__tab-content {
}

.trading-layout__tabs-container {
  border-left: none;
}

.order-entry__body {
  height: 100%;
}

.order-entry__body .trade-component__section {
  padding: 1rem 0rem 0;
}

.order-entry__body .trade-component__section .order-entry__item-button {
  color: #ff9443;
}

.order-entry__body .trade-component__section .order-entry__item-button:hover {
  background: transparent;
}

.ap-segmented-button {
  background: white;
}

.ap-segmented-button__label {
  color: #535353;
  background: white;
}

.order-entry__order-type-wrapper .ap--label {
  font-weight: 600;
}

.ap-segmented-button__label--checked {
  background: white;
  color: #FF9443 !important;
}
.ap-segmented-button__label--checked::after{
  background: #ff8d3f;
  height: 3px;
  border-radius: 4px;
  width: 30%;
  margin: 0 auto;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
} 

.standalone-modal__wrapper {
  background: #151a2f;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.standalone-modal__wrapper .standalone-modal__btn {
  width: auto;
}

.standalone-modal__wrapper
  .standalone-modal__btn.standalone-modal__btn--subtractive {
  color: white;
}

.standalone-modal__container {
  width: 70rem;
  border-radius: 1rem;
  overflow: hidden;
  height: 100%;
  padding: 2rem;
  background: #41415c;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.standalone-modal__body {
  color: #f28e2a;
  background: white;
  height: auto;
  min-height: 5rem;;
}

.standalone-modal__body .standalone-modal__body-text {
  color: #f28e2a;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.standalone-modal__container .standalone-modal__footer {
  border-top: none;
  text-align: center;
  background: white;
  margin: 0;
}

.standalone-modal__container .standalone-modal__footer .standalone-modal__btn {
  margin: 0 auto;
  padding: 0.5rem 1rem;
  height: 44px;
  font-size: 1.4rem !important;
  border-radius: 1.55rem;
  font-weight: 500;
  min-width: 220px;
}

.standalone-modal__logo {
  margin: 2.8rem 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: var(--standalone-modal__logo-color);
  right: 0;
  text-align: center;
}

@media (max-width: 578px) {
  .standalone-modal__wrapper {
    justify-content: center !important;
  }

  .standalone-modal__logo {
    position: relative;
    text-align: center;
    width: 100%;
  }
}

.standalone-modal__container
  .standalone-modal__footer
  .standalone-modal__btn.standalone-modal__btn--additive {
  background: #5d3dff;
  color: white;
  font-size: 1.6rem;
}

.standalone-modal__container > .standalone-modal__body,
.standalone-modal__container > .standalone-modal__footer {
  background: #41415c;
}

.standalone-modal__container
  > .standalone-modal__body
  .standalone-modal__body-text {
  color: white;
}

.standalone-form__form.signup-form__form {
  margin: -1rem 0.75rem 0 0.75rem;
}

.standalone-form__form .login-form__btn--additive {
  border: 1.5px solid #f28e2a !important;
  background: white;
  color: #f28e2a;
  font-weight: 500;
}

.standalone-form__form .login-form__btn--additive:hover,
.standalone-form__form .login-form__btn--additive:disabled {
  background: #f28e2a;
  color: white !important;
}

.wallet-card__icon-label-row {
  position: relative;
  padding-bottom: 2rem;
}

.wallet-card__top-border {
  top: 0;
  width: 65%;
  display: block;
  position: absolute;
}

.wallet-card__symbol-label {
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1rem;
  line-height: 2;
  font-size: 1.4rem;
}

.wallet-card {
  background: white;
  -webkit-box-shadow: 2px 2px 4px 1px rgba(89, 89, 89, 0.11);
  box-shadow: 2px 2px 4px 1px rgba(89, 89, 89, 0.11);
}

.wallet-card__activity-link-row {
  background: white;
}

.wallet-card__label {
  color: black;
  font-weight: 500;
}

.wallet-card__amount-text {
  font-weight: 600;
}

.wallet-card__balance {
  padding: 1rem 1rem 1rem 1.5rem;
}

.wallet-card__balance:nth-child(2) {
  border-right: none;
}

.wallet-card__activity-link {
  color: #ff9443;
}

.wallet-card__symbol-label-single {
  float: right;
  padding: 0.1rem 1rem;
  font-weight: 500;
  font-size: 1.5rem;
}

.wallet-card__icon {
  margin-left: 1.2rem !important;
}

.wallet-card__icon-nav-buttons .wallet-nav-item {
  background: white;
  border: none;
}

.wallet-card {
  width: 26.2rem;
  height: 19.8rem;
  border-radius: 0.4rem;
  margin: 0 1rem 4rem 1rem;
}

.wallet-card__icon-nav-buttons .icon-button__icon {
  color: #555555;
}

select[name="ExternalAddress"] {
  color: #619930;
  font-weight: 500;
  font-size: 1.3rem;
}

.button-trust-list {
  display: inline-block;
  margin-bottom: 1.4rem;
  width: 100%;
}

.cloud-info {
  padding: 1.5rem;
  background: #17a2b8;
  border-radius: 1rem;
  margin-bottom: 2.5rem;
  display: flex;
  color: white;
}

.send-receive-confirm-modal__details .cloud-info {
  display: block;
  width: 50%;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.send-receive-confirm-modal__details .cloud-info span {
  padding: 0 0 0 0rem;
  text-align: justify;
}

.cloud-info svg {
  font-size: 4rem;
  display: inline-block;
}

.cloud-info span {
  font-size: 1.4rem;
  display: inline-block;
  padding: 0 0 0 1.5rem;
  text-align: justify;
}

.wallets-page__wallet-list-container-header {
  padding: 3rem 0rem 3.6rem;
  font-weight: 500;
}

.wallet-row {
  background: white;
  border-bottom: none;
}

.wallet-row:hover {
  background: rgb(240, 240, 240);
}

.wallet-row__activity-link {
  border-left: none;
}

.wallet-row-list {
  border-radius: 0.4rem;
  overflow: visible;
  max-height: 100%;
}

.wallet-row__icon-nav-buttons > .wallet-nav-item {
  background: transparent;
  height: 2.5rem;
  border: none;
}

.wallet-row__icon-nav-buttons > .wallet-nav-item .icon-button__icon {
  color: #555555;
}

a.wallet-row__activity-link {
  color: #ff9013;
}

a.wallet-row__activity-link:hover {
  opacity: 0.7;
}

.ap-popover__content > div {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.popover-menu__item {
  border-bottom: none;
  background: #e8e8e8;
}

.popover-menu__item:hover,
.popover-menu__item:active,
.popover-menu__item:focus {
  background: rgb(241, 241, 241);
}

.wallet-row-list .wallet-row {
  min-height: 5rem;
}

.wallets-page__wallet-list-container {
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: visible;
  border: 2px solid #efefef;
  border-radius: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #3a3c39;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ff9013;
  font-weight: 500;
  background: inherit
}

.parentsubboxes-sk {
  display: flex;
  margin-bottom: 0.5rem;
}

.parentsubboxes-sk .subboxes-sk {
  display: inline-box;
  width: 33.3%;
}

.create-api-key__title {
  color: var(--pc_font-primary);
}

.create-api-key__checkbox label {
  color: #fff;
}

.create-api-key__checkbox label:after {
  background: var(--bg-dark-v2);
  left: -3px;
}
.ap-checkbox__checkbox label{
  color: #b8b8b8;
}
.create-api-key__checkbox label svg {
  color: #fff;
}

.ap-checkbox__checkbox input[type="checkbox"]:checked ~ label,
.ap-checkbox__checkbox label:hover {
  color: white;
  font-weight: 500;
}

.ap-checkbox__icon {
  left: 5px;
  color: var(--input__checkbox-icon-color);
  left: 1px;
}
.ap-checkbox__icon svg{
  color: white;
}
.create-api-key__footer {
  border-top: none;
}

.page-footer {
  background-color: white;
  margin-bottom: 0rem;
  border-top: none !important;
  z-index: 2;
  position: relative;
  min-height: 6.3rem;
  padding: 1.4rem 4.4rem 0 4.4rem;
  color: rgb(58, 58, 58);
  justify-content: center;
}

.page-footer__footer-left-content {
  width:auto;
  font-size: 1.2rem;
  background-image: url(/local/landing-page/images/isotipo.png);
  background-size: 18px;
  background-repeat: no-repeat;
  padding: 1px 0 0 22px;
}

.page-footer__footer-right-content {
  text-align: right;
}

.page-footer__footer-nav {
  width: 100%;
  align-items: flex-end;
}

.footer-nav-single-link {
  width: 100%;
  margin-left: auto;
  border: 0px solid #d1d0d0;
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 0.5rem;
  text-align: center;
  color: #666666;
  font-weight: 500;
  cursor: pointer;
}

.footer-nav-single-link:hover {
  color: #fc911a;
  background: rgb(238, 237, 237);
}

.footer-nav-container {
  width: 8rem;
  display: inline-flex;
}

.footer-nav-container:hover .footer-nav-cloud {
  display: block;
}

.footer-nav-single-link .triangle-more {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid;
  display: inline-block;
  margin-left: 1rem;
  vertical-align: middle;
}

.footer-nav-cloud {
  display: none;
  position: absolute;
  right: 4.5rem;
  bottom: 4.8rem;
  width: 14.9rem;
  padding-bottom: 1rem;
}

.footer-nav-cloud-children {
  padding: 1rem 0;
  box-shadow: 0 0 2px 0 rgba(79, 79, 79, 0.54);
  border: 0px solid #cacaca;
  border-radius: 0.5rem;
  background: white;
}

.footer-nav-cloud ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-nav-cloud ul > li > a {
  display: block;
  text-align: left;
  color: #787878;
  padding: 0.5rem 0.8rem;
  font-weight: 600;
}

.footer-nav-cloud ul > li > a:hover {
  background: rgb(245, 244, 244);
}

.page-footer {
  margin-top: 6rem;
}

.settings-page__container {
  display: block;
  -ms-flex: none;
  flex: none;
  height: auto;
  padding-bottom: 8rem;
  width: 95%;
  max-width: 1200px;
  margin: 4rem auto 0;
  background: white;
  border-radius: 2rem !important;
}

.settings-page__content {
  display: block;
  height: 100%;
}

.settings-page__menu {
  display: block;
  border-radius: 2rem 2rem 0 0;
  border: none;
}

.nav-menu-vertical {
  margin: var(--settings__body-margin);
  padding: 0 0px;
}

.nav-menu-vertical__list {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.nav-menu-vertical__list li,
.nav-menu-vertical__list li:last-child {
  border-bottom: none;
}

.nav-menu-vertical__item {
  border-left: none;
  border-bottom: 2px solid transparent;
  color: rgb(61, 61, 61);
  height: 4rem;
  width: auto;
}

.nav-menu-vertical__item.active,
.nav-menu-vertical__item.active:hover,
.nav-menu-vertical__item:hover {
  background: var(--background-primary_v2);
  border-left: none;
  border-bottom: 2px solid var(--secondary-color-v2);
  color: black;
}

.user-settings-page__page-header:before,
.kyc-settings__header:before {
  background-image: none;
}

.user-settings-page__title,
.user-settings-page__sub-title,
.kyc-settings__header-text,
.kyc-settings__description,
.trade-report-page__activity-report-header-title,
.trade-report-page__activity-report-header-text {
  color: black;
}

.user-settings-page__page-header h1,
.kyc-settings__header-text {
  font-weight: 600;
}

.trade-report-page__report-header {
  background: transparent;
}

.trade-report-page__activity-report-buttons .ap-button__btn {
  background: var(--secondary-color-v2) !important;
  border: 1.5px solid var(--secondary-color-v2) !important;
  color: #fff;
  font-weight: 500;
  border-radius: 1rem;
  line-height: 1;
  height: 3rem;
}

.trade-report-page__activity-report-buttons .ap-button__btn:hover,
.trade-report-page__activity-report-buttons .ap-button__btn:focus {
  opacity: 0.9;
}

.profile-and-security-widget__layout-template {
  box-shadow: none;
  -webkit-box-shadow: none;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.profile-and-security-widget__layout-template:first-of-type {
  margin-right: 1rem;
}

.profile-and-security-widget__header {
  border-bottom: none;
}

.profile-and-security-widget__header .profile-and-security-widget__header-text {
  font-weight: 600;
  color: white!important;
}

.reset-password-form__text-info,
.settings-enable-two-factor-auth__info {
  color: var(--pc_font-primary);
}

.reset-password-form {
  padding: 2rem;
}

.ap-switch__switch {
  border: none;
  background: #ebebeb;
  height: 26px;
  width: 80px;
}

.settings-enable-two-factor-auth {
  padding: 0.4rem 0 0 1.4rem;
}

.settings-enable-two-factor-auth .ap-switch__text {
  color: black;
  font-weight: 500;
  font-size: 1.3rem;
}

.settings-enable-two-factor-auth .ap-switch__toggle {
  background-color: #9d9d9d;
  margin: 0 2px 0 0;
}
.settings-enable-two-factor-auth__btn {
  background: var(--secondary-color-v2) !important;
  border: 1.5px solid var(--secondary-color-v2) !important;
  color: #fff;
  font-weight: 500;
  border-radius: 1.2rem;
  font-size: 12px;
  width: max-content;
  text-transform: capitalize !important;
}

.settings-enable-two-factor-auth__btn:hover {
  opacity: 0.9 !important;
}
.settings-enable-two-factor-auth__btn:focus {
  background: var(--secondary-color-v2) !important;
  border: 1.5px solid var(--secondary-color-v2) !important;
  color: #fff;
}

.user-settings-page__maincontainer {
  display: flex;
  padding-top: 30px;
}
.user-settings-page__maincontainer.security {
  padding-top: 0;
}
.user-settings-page__container {
  background: transparent;
  width: 602px;
  padding-top: 0;
  padding-bottom: 0;
}
.user-settings-page__container2 {
  border-left: 1px solid #a8a8c781;
  margin: var(--settings__body-margin);
  padding: var(--settings__body-padding);
  padding-top: 1.5rem;
  padding-bottom: 0;
  width: 50%;
}
.user-settings-page__container2.security {
  border-left: none;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5rem 10rem;
  width: 100%;
}
.content-security-switch{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.content-security-switch button{
  background: var(--primary-color-v2);
  margin-top: 1.5rem;
}
.content-security-switch button:disabled{
  background:  var(--secondary-color-v2) !important;
  cursor: no-drop;
}
.content-security-switch .container-switch{
  align-items: center;
  display: grid;
  grid-template-columns: 120px max-content;
}
.content-security-switch .container-switch >p,
.content-security-switch .container-switch >div{
  margin: 0;
  padding-top: 0
}
.user-settings-page__container2 >div >div{
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.user-settings-page__container .ap-button__btn {
  background: var(--secondary-color-v2) !important;
  border: 1.5px solid var(--secondary-color-v2) !important;
  color: #fff;
  font-weight: 500;
  border-radius:1.2rem;
  font-size: 12px;
}
.user-settings-page__container .ap-button__btn.reset-password-form__btn {
  background: var(--primary-color-v2) !important;
  border: none !important;
}
.user-settings-page__container .ap-button__btn:hover {
  opacity: 0.9;
}

.user-settings-page__container .ap-label-with-text__text {
  border-bottom: 1px solid var(--text-gray-v2);
}

.user-profile-form-static__lwt-label,
.region-settings__lwt-label,
.theme-settings__lwt-label {
  font-size: 1.2rem;
  padding: 0rem 0;
}

.profile-and-security-widget__header {
  font-weight: 600;
}

.enable-2fa-modal__text-item,
.disable-2fa-modal__text-item,
.ap-auto-tab-input__label {
  color: black;
  margin: 1rem 0;
}

.enable-2fa-modal .ap-auto-tab-input {
  border: 1px solid var(--btn-checked-v2);
}

.forgot-password-form {
  color: black;
}

.ap-auto-tab-input {
  background: white;
  border: 1px solid rgb(197, 197, 197);
}

.ap-radio__label {
  width: auto;
  height: auto;
}

.buy-sell__amount-row .ap-radio .ap-radio__label {
  width: 22px;
  height: 22px;
}

.standalone-form__separator {
  border-top: none;
}

.ap-modal {
  border-radius: 1rem;
}

.send-receive-confirm-modal__header {
  padding: 1.1rem 1.8rem;
}

.ap-modal.send-receive-confirm-modal .send-receive-confirm-modal__content {
  overflow: auto;
  padding: 1rem 2.8rem 2rem 2.8rem;
}

.send-receive-confirm-modal__title {
  margin-bottom: 1rem;
}

.ReactModal__Content--after-open .ap-modal__header {
  padding: 2.5rem 2.5rem 0;
}

.ReactModal__Content--after-open
  .ap-modal__content
  > .send-receive-two-factor-auth-modal {
  padding-left: 0rem;
}

.ReactModal__Content--after-open
  .ap-modal__content
  > .send-receive-two-factor-auth-modal
  > .send-receive-two-factor-auth-modal__text-item {
  color: black;
  text-align: center;
}

.ReactModal__Content--after-open
  .ap-modal__content
  > .send-receive-two-factor-auth-modal
  > .form-group {
  text-align: center;
}

.ReactModal__Content--after-open
  .ap-modal__content
  > .send-receive-two-factor-auth-modal
  > .form-group
  > .ap-auto-tab-input:last-child {
  margin: 0.5rem 0rem 0.5rem 0;
}

.ReactModal__Content--after-open .send-receive-two-factor-auth-modal__footer {
  border-top: none;
  padding-bottom: 0rem;
}

.ap-modal__header,
.ap-modal__content {
  background: white;
}

.ap-modal__header {
  font-weight: 600;
  font-size: 1.7rem;
}

.ap-modal .ap-button__btn,
.forgot-password-form__btn,
.custom__btn.orange_custom,
.reset-password__btn {
  background: var(--primary-color-v2) !important;
  border: none;
  color: var(--text-light)!important;
  font-weight: 500;
  border-radius: 0.5rem;
  margin: auto;
}

.forgot-password-form__btn:disabled,
.custom__btn.orange_custom:disabled {
  color: #616160 !important;
  opacity: 0.7;
  border-color: #616160 !important;
  border-width: 1.5px !important;
  background: white;
}

.ap-modal .ap-button__btn:hover,
.forgot-password-form__btn:hover,
.custom__btn.orange_custom:hover,
.custom__btn.orange_custom:focus,
.custom__btn.orange_custom:not(.disabled):active,
.reset-password__btn:hover,
.reset-password__btn:focus {
  color: white;
  opacity: 0.9;
}

.form-group .form-label .info_alert {
  color: red !important;
  font-weight: 500;
  font-size: 9px;
  line-height: 1;
  padding-left: 3px;
  padding-right: 3px;
  border: 1.5px solid red;
  border-radius: 20px;
  margin-left: 4px;
}

.standalone-form__footer .login-form__link {
  color: #f6961f;
}

.ap-select__select option {
  background: white;
}

.ap-modal__close-icon,
.ap-icon--close {
  color: black;
}

.send-receive-confirm-header__title {
  font-size: 1.7rem;
}

.ap-modal__close-icon {
  padding: 1.8rem 1.75rem;
}

.ap-input__input:hover,
.ap-select__select:hover,
.ap-datepicker__input:hover,
.react-phone-number-input__input.ap-phone-input__input:hover {
  background: #2e2e48;
}

.ap-label-with-text,
.user-profile-form-static__lwt-text,
.region-settings__lwt-text,
.theme-settings__lwt-text,
.reset-password-form__text-info,
.settings-enable-two-factor-auth__info {
  font-size: 1.2rem;
  text-align: justify;
  padding-right: 10px;
}

.ap-radio input[type="radio"]:checked ~ label > .ap-radio__check {
  background: #ed8300 !important;
}

.minhfix {
  min-height: 25rem;
}

.react-confirm-alert-overlay {
  background: var(--background-primary_v2);
  z-index: 1310 !important;
}

.react-confirm-alert-overlay .react-confirm-alert-body {
  border-radius: 0.5rem;
  padding: 16px 24px;
  font-size: 1.7rem;
  color: var(--pc_font-primary);
  text-align: justify;
}

.react-confirm-alert-overlay .react-confirm-alert-body > h1 {
  font-size: 2.35rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  color: var(--pc_font-primary);
  margin-bottom: 2rem;
}

.kyc-level .kyc-settings__levels .kyc-level:first-child {
  display: none;
}

.kyc-level.otherlevels.initialLevel .kyc-settings.kyc-settings--star {
  border-radius: var(--settings__kyc-card-border-radius);
}

.kyc-level .ap-icon.ap-icon--star {
  display: none;
}

.kyc-level,
.kyc-level--selected {
  height: min-content !important;
}

.settings-page__content .kyc-level {
  background-image: url(/local/landing-page/images/levels-bars/basico.svg);
  background-color: white;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 85%;
}

.kyc-level__header-title {
  background: transparent;
  color: #00a79d;
  font-weight: 600;
  font-size: 1.9rem;
}

.kyc-level__body {
  background: transparent;
  border: none;
}

.kyc-level__sub-title {
  display: none;
}

.kyc-level__list {
  list-style: none;
  padding: 0;
}

.kyc-level__item-description,
.kyc-level__list-item {
  color: black;
}

.kyc-level__item-description {
  white-space: pre-line;
  background: white;
  padding: 0px 22px;
  border-radius: 0 0 0.5rem 0.5rem;
  border: 0 solid #d1d1d1;
  border-top: none;
  color: #727272;
  overflow-y: auto;
  -webkit-transition: height 0s, opacity 0.5s linear;
  -o-transition: height 0s, opacity 0.5s linear;
  transition: height 0s, opacity 0.5s linear;
  height: 0;
}

.kyc-level-banexcoin-old.active .kyc-level__item-description,
.kyc-level.otherlevels.active .kyc-level__item-description {
  padding: 10px 22px;
  height: auto;
}

.kyc-level__footer--verified {
  background: transparent;
  color: #494949 !important;
  border-radius: 0 0 4px 4px !important;
  padding: 2rem;
}

.kyc-settings.kyc-settings--star .kyc-settings__header {
  display: none;
}

.kyc-settings.kyc-settings--star {
  border-left: 5px solid #00a79d;
  padding: 0 0rem 0 3rem;
}

.kyc-settings__header.newheader-block {
  width: 100%;
}

.kyc-level .kyc-level__change__btn {
  border: 1.5px solid #7bc13d !important;
  background-color: #7bc13d;
  padding: 0rem 2rem;
  border-radius: 0.5rem;
  color: var(--pc_font-primary);
  width: fit-content;
  height: auto;
  display: block;
  margin-top: -2px;
  font-size: 1rem;
  font-weight: 500;
}

.kyc-level .kyc-level__change__btn:hover {
  opacity: 0.8;
}

.kyc-level,
.kyc-level--selected {
  margin-bottom: 0rem;
}

/* New levels */
.explorerLevel-bg {
  background-image: url("/local/landing-page/images/v2levels/explorer.png");
}

.amateurLevel-bg {
  background-image: url("/local/landing-page/images/v2levels/amateur.png");
}

.intermediateLevel-bg {
  background-image: url("/local/landing-page/images/v2levels/intermediate.png");
}

.advancedLevel-bg {
  background-image: url("/local/landing-page/images/v2levels/advanced.png");
}

.masterLevel-bg {
  background-image: url("/local/landing-page/images/v2levels/master.png");
}

/* Para nivel zero */
.zeroLevel,
.kyc-level.zeroLevel {
  border-left: 5px solid #cad223;
}

.zeroLevel .levelHeader .levelTitle {
  color: #cad223;
}

/* Para nivel inicial */
.initialLevel,
.kyc-level.initialLevel {
  border-left: 5px solid #00a79d;
}

.initialLevel .levelHeader .levelTitle {
  color: #00a79d;
}

/* Para nivel intermedio */
.intermediateLevel,
.kyc-level.intermediateLevel {
  border-left: 5px solid #b997d4;
}

.intermediateLevel .levelHeader .levelTitle,
.kyc-level.intermediateLevel .kyc-level__header-title {
  color: #b997d4;
}

/* Para nivel maestro */
.masterLevel,
.kyc-level.masterLevel {
  border-left: 5px solid #ff8f15;
}

.masterLevel .levelHeader .levelTitle,
.kyc-level.masterLevel .kyc-level__header-title {
  color: #ff8f15;
}

option.title_select {
  font-weight: 600;
}

.select_depent.form-control {
  margin-top: 3rem;
  display: inline-block;
  width: 50%;
}

label.select_depent.form-label {
  display: inline-block;
  width: 50%;
  pointer-events: none;
  cursor: default;
  vertical-align: bottom;
}

label.select_indepent.form-label {
  display: inline-block;
  width: 50%;
  pointer-events: none;
  cursor: default;
  vertical-align: bottom;
}

label.option_worker.form-label {
  display: none;
}

label.option_worker {
  background: #fe9f3c;
  border-radius: 0.5rem;
  padding: 2.5rem 1rem;
  display: inline-block;
  margin: 15rem 0;
  color: white;
  width: 18rem;
  height: 8rem;
  font-size: 1.5rem;
  cursor: pointer;
  line-height: 1.5rem;
  text-align: center;
  vertical-align: middle;
}

label.option_worker:hover {
  background-color: #f28e2a;
}

label.others.form-label {
  display: none;
}

.Master-Logo .step-container.paso1 button[type="button"] {
  display: none;
}

.Master-Logo .step-container.paso1 .form-group {
  margin: 0 auto;
  text-align: center;
}

button.form-control.uploader-btn.select_depent_button.btn.btn-info {
  width: 50%;
  display: inline-block;
  margin-top: 3rem;
  border-radius: 1rem;
}

label.select_depent_button.form-label {
  width: 50%;
  font-size: 0;
}

label.select_indepent_button.form-label {
  width: 50%;
  font-size: 0;
}

button.form-control.uploader-btn.select_depent.btn.btn-info {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
  margin-left: 50%;
}

button.form-control.uploader-btn.select_depent_button.btn.btn-success {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
}

button.form-control.uploader-btn.select_depent.btn.btn-success {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
  margin-left: 50%;
}

.select_depent.col-xl-12 .form-label {
  position: absolute;
  top: 4rem;
  pointer-events: none;
  cursor: default;
}

.FileDown.col-xl-6 label.form-label {
  position: absolute;
  pointer-events: none;
  cursor: default;
}

.select_indepent.col-xl-12 label.form-label {
  width: 50%;
  position: absolute;
  top: 4rem;
}

button.form-control.uploader-btn.select_depent_button.btn.btn-danger {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
}

button.form-control.uploader-btn.select_depent.btn.btn-danger {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
  margin-left: 50%;
}

button.form-control.uploader-btn.select_indepent.btn.btn-info {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
  margin-left: 50%;
}

button.form-control.uploader-btn.select_indepent_button.btn.btn-success {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
}

button.form-control.uploader-btn.select_indepent.btn.btn-success {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
  margin-left: 50%;
}

button.form-control.uploader-btn.select_indepent_button.btn.btn-danger {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
}

button.form-control.uploader-btn.select_indepent.btn.btn-danger {
  margin-top: 3rem;
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
  margin-left: 50%;
}

.select_indepent_button.col-xl-12 .invalid-feedback {
  float: right;
  width: 50%;
  font-size: 1rem;
  margin-top: -2rem;
}

.select_indepent.col-xl-12 {
  margin-bottom: 5rem;
}

.select_depent_button.invalid-feedback {
  margin-left: 50%;
  margin-top: -1.5rem;
  width: 50%;
}

.select_depent.invalid-feedback {
  margin-left: 50%;
  margin-top: -1.5rem;
  width: 50%;
}

.select_indepent.col-xl-12 .invalid-feedback {
  float: right;
  width: 50%;
  font-size: 1rem;
  margin-left: 50%;
  margin-top: -2rem;
  margin-bottom: 3rem;
}

.dj_indepent.col-xl-12 .form-label {
  position: absolute;
  top: 2rem;
  pointer-events: none;
  cursor: default;
}

.dj_indepent.col-xl-12 {
  margin-top: -3.5rem;
}

button.form-control.uploader-btn.dj_indepent.btn.btn-info {
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
  margin-left: 50%;
}

button.form-control.uploader-btn.dj_indepent.btn.btn-success {
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
  margin-left: 50%;
}

button.form-control.uploader-btn.dj_indepent.btn.btn-danger {
  width: 50%;
  display: inline-block;
  border-radius: 1rem;
  margin-left: 50%;
}

.dj_indepent.col-xl-12 .text-danger.form-text {
  width: 50%;
  text-align: justify;
  line-height: 1.5rem;
  font-size: 1rem;
  position: absolute;
  margin-left: 50%;
}

.dj_indepent.col-xl-12 .invalid-feedback {
  margin-top: 0.5rem;
  margin-left: 50%;
  width: 50%;
  float: right;
  position: absolute;
}

.others.form-group > div {
  margin-left: 50%;
  width: 50%;
}

.select_depent.form-group > div {
  margin-top: 0.8rem;
  width: 50%;
  margin-left: 50%;
}

.select_depent.col-xl-12 .invalid-feedback {
  margin-top: -1.5rem;
  margin-left: 50%;
  width: 50%;
  float: right;
}

.select_indepent.col-xl-12 .text-danger.form-text {
  float: right;
  width: 50%;
  text-align: justify;
  line-height: 1.5rem;
  font-size: 1rem;
}

.select_depent.col-xl-12 .text-danger.form-text {
  float: right;
  width: 50%;
  font-size: 1rem;
}

.select_indepent_button.col-xl-12 .text-danger.form-text {
  float: right;
  width: 50%;
  font-size: 1rem;
}

.select_depent_button.col-xl-12 .text-danger.form-text {
  float: right;
  width: 50%;
  font-size: 1rem;
}

.select_depent_button.col-xl-12 .invalid-feedback {
  margin-top: -1.5rem;
  margin-left: 50%;
  width: 50%;
  float: right;
}

.col-8.right-step.Master-Logo {
  background-image: url(/local/landing-page/images/levels-bars/maestro.svg) !important;
  background-repeat: no-repeat;
  background-size: 350px;
  background-position: bottom right;
}

button.form-control.uploader-btn.FileDown.btn.btn-info {
  display: none;
}

button.form-control.uploader-btn.FileDownDJ.btn.btn-info {
  display: none;
}

label.FileDown.form-label {
  margin-top: -4.5rem;
  display: block;
  pointer-events: none;
  cursor: default;
}

label.FileDownDJ.form-label {
  margin-top: -2.5rem;
  display: block;
  pointer-events: none;
  cursor: default;
}

a.FileDown {
  background-image: url(/local/landing-page/images/file_pdf.svg);
  background-repeat: no-repeat;
  background-size: 3rem;
  color: #333333;
  font-weight: 600;
  width: 3rem;
  height: 3rem;
  margin: -9.5rem 27rem;
  margin-bottom: 3rem;
  display: block;
}

a.FileDownDJ {
  background-image: url(/local/landing-page/images/file_pdf.svg);
  background-repeat: no-repeat;
  background-size: 3rem;
  color: #333333;
  font-weight: 600;
  width: 3rem;
  height: 3rem;
  margin: -7.5rem 27rem;
  margin-bottom: 3rem;
  display: block;
}

@media screen and (max-width: 1024px) {
  .select_depent.col-xl-12 label.select_depent.form-label {
    margin-top: -3rem;
  }

  .FileDown.col-xl-6 label.FileDown.form-label {
    margin-top: -3rem;
  }

  small#subInfodependent_down_file a.FileDown {
    margin-left: 9rem !important;
    margin-top: -8rem !important;
  }

  label.FileDown.form-label {
    width: 50%;
    margin-top: -5rem;
    display: block;
  }

  a.FileDown {
    margin: -6rem 9rem;
  }
}

@media screen and (max-width: 1024px) {
  label.select_indepent.form-label {
    margin-top: -3rem;
  }

  .dj_indepent.col-xl-12 label.dj_indepent.form-label {
    margin-top: -4rem;
    width: 50%;
  }

  .select_indepent.col-xl-12 label.select_indepent.form-label {
    top: -6.5rem;
  }

  .step-container.active.paso2 a.FileDownDJ {
    margin-left: 9rem !important;
    margin-top: -6rem !important;
  }

  label.FileDownDJ.form-label {
    width: 50%;
    margin-top: -5rem;
    display: block;
  }

  a.FileDownDJ {
    margin: -6rem 9rem;
  }
}

.step-container.active.paso3 .FileDownCorp.col-xl-6 {
  margin-top: -4rem;
}

.step-container.active.paso3 .Corporate_FileDownCorp.form-group {
  top: -5.5rem;
}

button.form-control.uploader-btn.FileDownCorp.btn.btn-info {
  display: none;
}

.step-container.active.paso3 label.FileDownCorp.form-label {
  margin-top: -4.5rem;
  display: block;
  pointer-events: auto !important;
  margin-left: 52%;
  color: #ff9015 !important;
}

.FileDownCorp.col-xl-12 small#subInfocorporate_down_file a {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  margin-left: 60%;
  position: relative;
  top: -45px;
}
.FileDownCorp.col-xl-12 small#subInfocorporate_down_file a b{
  color: #FF9443;
  font-weight: 600;
}
.FileDownCorp.col-xl-12 small#subInfocorporate_down_file a:hover {
  opacity: 1;
}

@-moz-document url-prefix() {
  .FileDownCorp.col-xl-12 small#subInfocorporate_down_file a {
    margin-top: -5rem !important;
  }

  .step-container.active.paso3 label.FileDownCorp.form-label {
    margin-top: -1.5rem;
  }

  .step-container.active.paso3 {
    margin-top: 3rem;
  }

  .btn_constitution_act.col-xl-12 .invalid-feedback {
    margin-top: -2.5rem !important;
  }

  @media screen and (max-width: 1024px) {
    .btn_constitution_act.col-xl-12 .invalid-feedback {
      margin-top: -6.5rem !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .FileDownCorp.col-xl-12 small#subInfocorporate_down_file a {
    margin-right: 0rem !important;
    width: 50%;
    margin-top: -3.5rem;
  }

  label.btn_constitution_act.act_const.form-label {
    top: 0rem !important;
  }

  span.btn_constitution_act {
    display: list-item;
    margin-top: -4.5rem;
    margin-bottom: 3rem;
    color: #ff9443;
  }

  label.Corporate_FileDownCorp.form-label {
    width: 50%;
    margin-top: -5rem;
    display: block;
  }

  a.Corporate_FileDownCorp {
    margin: -6rem 9rem;
  }

  .modal-container-step-level .right-step .form-group label.form-label {
    top: -3.5rem;
  }

  .btn_constitution_act.col-xl-12 label.btn_constitution_act.form-label {
    margin-top: 2rem;
  }

  .btn_constitution_act.act_const.col-xl-12
    label.btn_constitution_act.act_const.form-label {
    margin-top: 0rem !important;
  }

  .btn_constitution_act.affidavit.col-xl-12
    label.btn_constitution_act.affidavit.form-label {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 320px) {
  .modal-step-level
    .modal-dialog
    .modal-content
    .modal-container-step-level
    .left-fix {
    display: inline-flex;
    height: 50% !important;
    padding-right: 10px;
  }
}

@media screen and (max-width: 375px) {
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }

  .modal-step-level .modal-dialog {
    max-width: 70%;
  }
}

.to_correct {
  border: 1px solid var(--error-color-v2)!important;
  box-shadow: 0px 0px 5px var(--error-color-v2)!important;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
}
.to_correct.file {
  display: flex !important;
  align-items: center;
}

.modal-container-step-level .col-8.right-step {
  background-image: url(/local/landing-page/images/levels-bars/corporativo.svg);
  background-repeat: no-repeat;
  background-size: 350px;
  background-position: bottom right;
}

.daas-step-box {
  display: none;
  margin: 0 auto;
  width: 50%;
}

.daas-step-box.showed {
  display: block;
}

.daas-step-box .cloud {
  text-align: center;
}

.daas-step-box .cloud .circle {
  border-radius: 5rem;
  border: 2px solid #828282;
  background: #e5e5e5;
  width: 5rem;
  height: 5rem;
  margin: 0 1rem;
  text-align: center;
  line-height: 2;
  font-size: 2.3rem;
  font-weight: 500;
  color: #3c3c3c;
  cursor: default;
  display: inline-block;
}

.daas-step-box .cloud .circle.ok {
  background: #51c13d;
  border-color: #0e8273;
}

.corporateLevel,
.kyc-level.corporateLevel {
  border-left: 5px solid #16ce3b;
}

.corporateLevel .levelHeader .levelTitle,
.kyc-level.corporateLevel .kyc-level__header-title {
  color: #16ce3b;
}

.corporatelevel-page .footer-actions {
  margin: 0 0 1.5rem;
}

.corporatelevel-page .btn {
  margin-right:0;
}

.corporatelevel-page .btn.btn-primary {
  background: var(--primary-color-v2);
  color: white;
  font-size: 1.4rem;
  border: 1.5px solid transparent;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: 450ms all;
}

.corporatelevel-page .btn.btn-primary:hover,
.corporatelevel-page .btn.btn-primary:focus {
  opacity: .5;
}

.corporatelevel-page .btn.btn-secondary {
  background: rgb(251, 251, 251);
  color: #f28e2a;
  font-size: 1.4rem;
  border: 1.5px solid #f28e2a;
  font-weight: 500;
  border-radius: 0.5rem;
}

.corporatelevel-page .btn.btn-secondary:hover,
.corporatelevel-page .btn.btn-secondary:focus {
  background: #f28e2a;
  color: white;
}

.daas-footer.footer-form {
  display: flex;
  justify-content: space-between;
}

.daas-footer.footer-form .btn {
  min-width: inherit;
  width: inherit;
}

.daas-descript-icon {
  text-align: center;
}

.daas-descript-icon img {
  width: 8rem;
  margin: auto;
}

.daas-descript-title .header-titlesub {
  font-size: 2.4rem;
  font-weight: 400;
}

.parent-inline {
  display: flex;
  text-align: center;
}

.parent-inline input.form-control,
.parent-inline .percentage-inline {
  font-size: 8rem;
  font-weight: 400;
  height: 15rem;
  background: transparent;
  border-bottom: 2px solid #f28e2a;
}

.parent-inline input.form-control {
  text-align: right;
  width: 55%;
}

.parent-inline input.form-control::placeholder {
  color: rgb(148, 148, 148);
}

.parent-inline .percentage-inline {
  width: 45%;
  font-size: 4rem;
  line-height: 3.1;
  text-align: left;
  font-weight: 500;
}

.download-document-box {
  margin: 1rem 0;
}

.document-image-download {
  width: 3rem;
}

.daas-modal-ui.modal {
  background: white;
  padding: 0;
  margin: 0;
  height: 100%;
}

.daas-modal-ui.modal .modal-dialog {
  display: table;
  height: 100%;
}

.daas-modal-ui.modal .modal-dialog .modal-content {
  display: table-cell;
  vertical-align: middle;
  border: none;
}

.daas-modal-ui.modal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
}

.daas-modal-ui.modal
  .modal-dialog
  .modal-content
  .modal-header
  .modal-title.h4 {
  font-size: 4.2rem;
  font-weight: 400;
  color: #5b5b5b;
}

.daas-modal-ui.modal .modal-dialog .modal-content .modal-header button {
  padding: 0;
}

.daas-modal-ui.modal .modal-dialog .modal-content .modal-header button span {
  font-size: 5rem;
  font-weight: 400;
}

.daas-modal-ui.modal .modal-dialog .modal-content .modal-footer {
  display: flex;
  flex-direction: row;
}

.daas-modal-ui.modal .modal-dialog .modal-content .modal-footer a {
  width: 48%;
  margin: 0 1%;
}

.daas-modal-ui.modal .modal-dialog .modal-content .modal-footer img {
  width: 100%;
}

button.more-btn {
  width: stretch;
  margin: 0 5rem;
  border: 2px solid #e9e9e9;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  color: var(--text-gray-v2);
  font-weight: 600;
  margin-top: -4rem;
}

button.more-btn:hover {
  border: 2px solid #ff8f15;
  color: #ff8f15;
  opacity: 0.5;
}

.more-btn.normal {
  position: absolute;
  bottom: -5.5rem;
}

.error-accessing-message {
  text-align: center;
}

.error-accessing-message button {
  color: white;
  background: var(--primary-color-v2);
  display: block;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 1.5px solid transparent;
  text-align: center;
  margin: 1.5rem auto;
  font-size: 2.1rem;
}
.error-accessing-message button:hover {
 opacity: 0.9;
}

.error-accessing-message img {
  width: 10rem;
}

.error-accessing-message.easybuy-msj img {
  width: 48rem;
}

.error-accessing-message .text {
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--pc_font-primary);
}

.sidepane-details__information-text {
  padding-bottom: 1rem;
  color: #fff !important;
}

.sidepane-details__information-text--deposit:first-child {
  padding-bottom: 0.5rem;
}

.sidepane-details__information-text.sidepane-details__information-text--text-external-box {
  padding: 0 0 1rem;
  text-align: justify;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: var(--text-gray-v2)!important;
}

.sidepane-details__information--top {
  margin-top: 0rem;
  padding: 2rem 1.5rem;
}

.success-msj {
  color: white;
  background: rgb(51, 163, 98);
  font-size: 1.4rem;
  font-weight: 500 !important;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
}

.important-msj {
  color: white;
  background: rgb(236, 166, 61);
  font-weight: 400 !important;
  font-size: 1.3rem;
  margin: 1rem 0 0 0;
  padding: 1.5rem;
  text-align: center;
}

.important-msj:last-child {
  margin-top: 0;
}

.bg-orange {
  background: rgb(236, 166, 61);
  margin-left: 15px;
  margin-right: -15px;
  margin-top: 10px;
  padding: 2rem;
  color: white;
}

.bg-orange:last-child {
  margin-left: -15px;
  margin-right: 15px;
}

.bg-orange img {
  display: block;
  margin: auto;
}

.important-msj-image {
  background: rgb(236, 166, 61);
  text-align: center;
  padding: 1.5rem;
  margin: 0 0 1rem 0;
}

.sidepane-details__information-text--deposit:nth-child(2n + 1) {
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.blankspace {
  height: 56px;
}

/* FORMS */
.intermediatelevel-page .form-group label.form-label,
.easybuy-page .form-group label.form-label {
  font-weight: 600;
}

.intermediatelevel-page .btn.btn-primary,
.easybuy-page .btn.btn-primary,
.account-form .btn.btn-primary,
.withdrawals-address-page .btn.btn-primary,
.trust-list-page .btn.btn-primary {
  background: var(--primary-color-v2);
  border: 1.5px solid #f6961f !important;
  color: #fff;
  font-weight: 500;
  border-radius: 0.5rem;
  min-width: 23rem;
  font-size: 1.5rem;
}

.trust-list-page .btn.btn-primary {
  font-size: 1.3rem !important;
  line-height: 1 !important;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.intermediatelevel-page .btn.btn-primary:hover,
.easybuy-page .btn.btn-primary:hover,
.account-form .btn.btn-primary:hover,
.withdrawals-address-page .btn.btn-primary:hover,
.trust-list-page .btn.btn-primary:hover {
  opacity: 0.9;
  color: white;
}

.intermediatelevel-page .btn.btn-secondary {
  border: 1.5px solid #f6961f !important;
  background: #f6961f;
  color: white;
  font-weight: 500;
  border-radius: 0.5rem;
  min-width: 23rem;
  font-size: 1.5rem;
}

.intermediatelevel-page .btn.btn-secondary:hover {
  color: #f6961f;
  background: white;
}

.intermediatelevel-page input.form-control,
.easybuy-page input.form-control {
  border-width: 0 0 2px 0;
  border-color: rgb(227, 227, 227);
  border-radius: 0;
}

.intermediatelevel-page input.form-control:hover,
.intermediatelevel-page input.form-control:focus,
.easybuy-page input.form-control:hover,
.easybuy-page input.form-control:focus {
  border-color: #ff9013;
}

.intermediatelevel-page select.form-control {
  padding: 0.8em 1em;
  height: 3.8rem;
  border: 0;
  border-bottom: 2px solid #ccc;
}

.intermediatelevel-page select.form-control:focus,
.intermediatelevel-page select.form-control:hover {
  box-shadow: none;
  border-color: #ff9013;
}

.intermediatelevel-page input:focus,
.easybuy-page input:focus {
  outline: none;
  box-shadow: none;
}

.intermediatelevel-page .react-tel-input .flag-dropdown,
.easybuy-page .react-tel-input .flag-dropdown {
  background: transparent;
  border: none;
}

.intermediatelevel-page .react-tel-input .flag-dropdown:hover,
.easybuy-page .react-tel-input .flag-dropdown:hover {
  background: rgba(170, 170, 170, 0.18);
}

.react-tel-input .form-control {
  width: 100%;
  border-color: var(--pc-inputs-background);
}

.react-tel-input .form-control:disabled {
  background-color: var(--pc-inputs-background);
}

.react-tel-input .flag-dropdown:hover .selected-flag,
.react-tel-input .flag-dropdown:focus .selected-flag {
  background: var(--pc-inputs-background);
}

.form-group input[type="password"] {
  padding: 2em;
}

.easybuy-page .form-check-inline {
  vertical-align: middle;
}

.label-for {
  margin-right: 0.75rem;
}

.currency-block-check {
  display: block;
}

.currency-formicon {
  margin-right: 0.5rem;
}

.easybuy-page .card {
  border: none;
}

.card_banking {
  padding: 1.5rem;
  background: #fcfbfb;
  line-height: 1.8;
}

.card_banking > div > b {
  color: var(--pc_font-primary);
  font-weight: 500;
  font-size: 1.3rem;
  text-transform: uppercase;
}

.card_banking > div > div {
  text-align: right;
  font-size: 1.3rem;
}

.card_banking.v2-databank {
  padding: 3rem 0 1.5rem;
  background: transparent;
  margin: -2.1rem 0 -1.8rem !important;
}

.card_banking.v2-databank #bbk_bank_logo {
  display: none;
}

.card_banking.v2-databank [id^="bbk_"] {
  font-weight: 700;
  font-size: 1.2rem;
  position: relative;
  color:rgb(168, 168, 199);
}
.card_banking.v2-databank [id^="bbk_"] > span {
  font-weight: normal;
  color: white;
}

.card_banking.v2-databank [id^="bbk_"] .copy-btn {
  width: 2.5rem;
  opacity: 0.6;
  cursor: pointer;
  margin-left: 0.2rem;
  padding: 0.5rem;
  display: inline-block;
  filter: invert(1);
}

.card_banking.v2-databank [id^="bbk_"] .cloud-text {
  position: absolute;
  background-color: transparent;
  color: white;
  padding: 0.4rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 0.5rem;
  top: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear, background-color 0.3s linear;
  cursor: default;
}

.card_banking.v2-databank [id^="bbk_"] .cloud-text.active {
  visibility: visible;
  opacity: 1;
  background-color: rgb(0 0 0 / 66%);
}

.card_banking.v2-databank [id^="bbk_"] b {
  font-weight: 450;
  font-size: 1.1rem;
}

.card_banking.v2-databank [id^="bbk_rules"] {
  display: none;
}

.account-page .card {
  box-shadow: 0 0 7px rgba(151, 151, 151, 0.25);
  background: white;
  border-radius: 0.3rem;
}

.account-page .form-group label {
  font-size: 1.2rem;
  color: #727171;
  font-weight: 500;
}

.account-page .form-group div.form-control {
  padding: 1rem 0.75rem;
  font-size: 1.2rem;
  line-height: 0.7;
}

.account-page .card .card-body > .col-lg-12,
.withdrawals-address-page .card .card-body > .col-lg-12 {
  padding: 0;
}

.not-selected.form-control {
  opacity: 0.8;
}

.fiat-withdraw-form input#amount::-webkit-outer-spin-button,
.fiat-withdraw-form input#amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fiat-withdraw-form input#amount {
  -moz-appearance: textfield;
}

.linked-container {
  background: inherit;
  color: #fff;
  text-align: center;
  margin: 32px 0;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.3rem;
}

.linked-container:hover {
  color: white;
}
.linked-container > b > a {
  color: white;
  font-weight: 500;
  font-size: inherit;
  color: #FB8A47;
}
.linked-container > b > a:hover {
  text-decoration: underline;
}
.form-group {
  text-align: left;
}

.form-group .form-label {
  font-size: 1.2rem;
}

.errormsg-text {
  color: #dc3545;
  padding-top: 1rem;
}

.noomomomomomo1280 {
  position: relative;
  display: none;
}

.lastprice {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.5rem 6rem 0.5rem 3rem;
  top: 24rem;
  position: absolute;
  background: #fff;
}

.lastprice .bignumber {
  font-size: 1.4rem;
  margin-right: 1rem;
}

.lastprice .bignumber.up {
  color: var(--success-color-v2);
}

.lastprice .bignumber.down {
  color: var(--error-color-v2);
}

.lastprice .bignumber.same {
  color: rgb(63, 63, 63);
}

.modal-content .modal-footer .btn-primary {
  border: 1.5px solid #f6961f !important;
  background: white;
  color: #f6961f;
  font-weight: 500;
  border-radius: 0.5rem;
}

.modal-content .modal-footer .btn-primary:hover {
  color: white;
}

.form-control option {
  background: var(--pc-inputs-background);
}

.product-details-page__container {
  margin: auto;
  width: 95%;
  max-width: 1200px;
}

.product-details-page__menu {
  border-right: none;
  margin-top: var(--wallet-details__body-margin);
  margin-bottom: var(--wallet-details__body-margin);
}

.product-details-page__menu .balances-menu,
.trade-component.menu-component.balances-menu {
  background: white;
}

.product-details-page__menu .menu-component__item {
  height: 5rem;
  color: #ff9013;
  border-bottom: solid 1px #cdcdcd;
  font-size: 1.4rem;
}

.product-details-page__menu .balances-menu__item-symbol-name {
  color: var(--text-gray-v2)!important;
  font-weight: 500;
}
.product-details-page__menu .balances-menu__item-balance-value{
  color: white!important;
}

.product-details-page__menu .menu-component__item--selected {
  background: var(--background-primary_v2);
}

.product-details-page__menu .menu-component__item:hover,
.product-details-page__menu .menu-component__item:active {
  background-color: var(--pc__background-secondary_v2);
}

.wallet-details {
  background-color: white;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.12);
}

.wallet-details .wallet-details__product-actions-row {
  border-bottom: 3px solid #ff8d3f;
}

.wallet-details .wallet-details__position-row .wallet-details__detail {
  border-right: 2px solid #e9e9e9;
  padding: 15px 30px;
  border-bottom: none;
}

.wallet-details
  .wallet-details__position-row
  .wallet-details__detail
  .wallet-details__label {
  color: black;
}

.wallet-details__wallet-nav-items .wallet-nav-item {
  background: transparent;
  border: none;
  color: black;
}

.wallet-details .wallet-details__product-icon {
  padding: 15px 15px;
}

.wallet-details__wallet-nav-items .wallet-nav-item:hover,
.wallet-details__wallet-nav-items .wallet-nav-item:hover .icon-button__icon {
  background: whitesmoke;
  color: #ff8d3f;
}

.wallet-details__wallet-nav-items .wallet-nav-item .icon-button__icon {
  color: #484848;
}

.activity-pagination__container {
  background: white;
  border-top: none;
}

.activity-pagination__text {
  border-color: transparent;

}

.activity-pagination__page--active {
  background: #ff8d3f;
}

.pagination__page,
.pagination__page:last-child {
  border-color: #b0b0b0;
}

.pagination__page--active,
.pagination__page--active:hover {
  border: 1px solid var(--btn-checked-v2);
  color: white !important;
  background: #ff8d3f;
}

.product-details-page__transfers-header,
.activity .activity__header-bar {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  background: var(--pc_font-primary);
  border-bottom: 3px solid var(--secondary-color-v2);
  padding: 0rem 1.5rem 0rem 3.5rem;
}

.product-details-page__transfers,
.activity {
  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
}

.transfers-filters,
.activity__select-buttons {
  background: #fafafa;
}

.transfers-filters .transfers-filters__sb-container {
  border: none;
}
.transfers-filters .transfers-filters__sb-container .ap-segmented-button__label {
  padding: 1rem .5rem !important;
}
.transfers-filters
  .transfers-filters__sb-container
  .ap-segmented-button__label {
  padding: 0.5rem 0.5rem;
}

.transfers-filters .ap-inline-btn__btn {
  border: 2px solid rgb(229, 229, 229);
  height: 3rem;
}

.transfers__header-column {
  border-right: 1px solid #e3dcdc;
}

.transfers__wrapper {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: var(--background-primary_v2);
}

.transfers__wrapper .transfers {
  border-radius: 0 0 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@keyframes glow {
  to {
    box-shadow: 0 0 10px var(--secondary-color-v2);
  }
}

.row .shadow-info {
  border-radius: 0.5rem;
  padding: 15px;
  animation: glow 0.5s infinite alternate;
}

.activity__from__dpk-label {
  margin: 0 0.5rem 0 1rem;
  letter-spacing: 0px;
}

.activity__from__dpk-input,
.activity__to__dpk-input {
  background: rgb(237, 237, 237);
  max-width: unset;
  border: 1px solid #b3b3b3;
}

.activity__select {
  background: rgb(237, 237, 237);
  border: 1px solid #b3b3b3;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  width: auto;
  padding: 0.1em 2em 0.1em 1em !important;
}

.activity__triangles-container {
  left: 95%;
  position: absolute;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider-beta {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-beta:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 0px;
  bottom: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0px 15px rgba(32, 32, 32, 0.25);
  background: #626262 url(/local/landing-page/images/night.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 66%;
}

input:checked + .slider-beta {
  background-color: #3d3d3d;
}

input:focus + .slider-beta {
  box-shadow: 0 0 1px #3d3d3d;
}

input:checked + .slider-beta:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
  background: white url("/local/landing-page/images/sunny.png");
  background-repeat: no-repeat;
  background-position: center;
}

/* Rounded sliders */
.slider-beta.round {
  border-radius: 34px;
}

.slider-beta.round:before {
  border-radius: 50%;
}

/* sidepane */
.fiat-sidepane-header {
  margin-top: 0.1rem;
  margin-bottom: 2.7rem;
  text-align: center;
  vertical-align: middle;
  align-items: center;
}

.fiat-sidepane-header .fiat-sidepane-header__logo {
  width: 22px;
  height: auto;
  display: inline-block;
  margin: 5px 1.2rem 0 auto;
}

.fiat-sidepane-header .fiat-sidepane-header__title {
  padding-top: 0.5rem;
  display: inline-block;
  margin-right: auto;
  font-size: 2.1rem;
  font-weight: 600;
  color: var(--pc_font-primary);
}

.slide-pane__content {
  background: var(--background-primary_v2);
  overflow: inherit;
  padding-bottom: 0rem;
}

.slide-pane__content .advanced-order-form__body .ap--label{
  color: var(--text-gray-v2);
  position: absolute;
  font-size: 1.4rem;
  margin: 1.5rem;
  font-weight: 400!important;
}
.advanced-order-sidepane__container .slide-pane__content {
  overflow: auto;
  max-height: calc(100vh - 64px);
}

.fiat-sidepane {
  border-top: none;
  height: calc(100% + 4rem);
  overflow: inherit;
}

.fiat-sidepane .fiat-sidepane__main {
  padding: 1.5rem 2.2rem;
  border-right: 1px solid #2e2e48;
  overflow: auto!important;
  background: var(--pc__background-secondary_v2);
}

.fiat-sidepane .fiat-sidepane__main::-webkit-scrollbar,
.fiat-sidepane .fiat-sidepane__side::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background: rgb(240, 240, 240);
}

.fiat-sidepane .fiat-sidepane__main::-webkit-scrollbar-thumb,
.fiat-sidepane .fiat-sidepane__side::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #c2c9d2;
}

.fiat-sidepane .fiat-sidepane__main::-webkit-scrollbar-thumb:hover,
.fiat-sidepane .fiat-sidepane__side::-webkit-scrollbar-thumb:hover {
  background: #adb4bd;
}

.fiat-sidepane .fiat-sidepane__side {
  overflow-y: auto;
  height: 100vh;
  background: var(--pc__background-secondary_v2);
}

.fiat-sidepane .fiat-sidepane__side .progress-bar-container,
.fiat-sidepane .fiat-sidepane__side > .container {
  padding-right: 30px;
  padding-left: 30px;
}

.fiat-sidepane__main-form {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
}

.fiat-tabs {
  min-height: 3.2rem;
  height: 3.2rem;
  margin-bottom: 1rem;
}

.fiat-tabs__tab {
  height: 4rem;
  line-height: 4.4rem;
  border-bottom: transparent;
  position: relative;
  font-weight: 600;
}

.fiat-tabs__tab .fiat-tabs__tab-icon {
  display: none;
}

.fiat-tabs__tab.fiat-tabs__tab--selected {
  color: #ff8d3f;
}

.fiat-tabs__tab.fiat-tabs__tab--selected::after {
  background: #ff8d3f;
  height: 3px;
  border-radius: 4px;
  width: 20%;
  margin: 0 auto;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fiat-tabs__tab:first-child {
  border-right: none;
}

.fiat-tab #bbk_rules_for_receiving_funds_in_this_account_value {
  white-space: pre-line;
}

/* alert bar */
.snackbar--warning {
  background-color: #ee3f50;
}

.snackbar--warning .ap-icon--close {
  color: white;
}

.page-header-nav__icon--menu-toggle {
  color: var(--pc_font-primary);
}

.trading-layout__childer {
  display: flex;
}

.trading-layout__childer .instrument-row.selectorpair {
  padding-left: 2.5rem;
  width: auto;
}

.trading-layout__container {
  overflow: inherit;
  min-height: 107rem;
}

.trading-layout__container .instrument-row {
  min-height: 5rem;
}

#depthchart {
  height: 500px !important;
  object-fit: contain;
}
.trade-component.depth-chart{
  height: 500px!important;
}
.depth-chart__zoom-label, .depth-chart__label{
  font-size: 1.4rem;
}
.depth-chart__zoom-label{
  padding-top: .5rem;
}
.depth-chart__label{
  padding-top: 1rem;
}
.trade-component {
  background: transparent;
  padding-bottom: 0rem;
}

.instrument-row .instrument-table {
  background: #f9f9f9;
  padding: 0rem 4px 0;
}

.sidepane-details__divider {
  background: transparent;
}

.sidepane-details__information-text {
  color: black;
}

.sidepane-details__lwt-container {
  border-top: none;
}

.kyc-settings.kyc-settings--star {
  padding: 0 0rem 0 0rem;
  height: auto;
  margin-bottom: -4rem;
}

.kyc-level-banexcoin-old {
  min-height: 29rem;
}

.kyc-level-banexcoin-old,
.kyc-level.otherlevels {
  width: calc(22% - 2rem);
  position: relative;
  margin: 0 0rem 0 1rem;
  height: min-content;
}

.otherlevels .more-btn.default {
  bottom: -9.5rem;
}

.kyc-level-banexcoin-old {
  margin-left: 2rem;
}

.kyc-level-banexcoin-old .kyc-level .kyc-level__body,
.kyc-level-banexcoin-old .kyc-level .kyc-level__header-title,
.kyc-level-banexcoin-old .kyc-level .kyc-level__footer {
  width: calc(100% - 3rem);
}

.kyc-level-banexcoin-old .kyc-level {
  max-width: 100% !important;
  min-width: 100% !important;
}

.kyc-level-banexcoin-old .VerifiedStatus {
  width: stretch;
  margin: 0 5rem;
  border: 2px solid #e9e9e9;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  color: var(--text-gray-v2);
  font-weight: 600;
  margin-top: 1.5rem;
  text-align: center;
}

.kyc-level-banexcoin-old .kyc-level__footer--verified .isvg {
  display: none;
}

.kyc-level-banexcoin-old .kyc-level__footer.kyc-level__footer--verified {
  border: 1.5px solid #7bc13d;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem !important;
  color: #7bc13d !important;
  font-size: 1.2rem;
  font-weight: 500;
  width: fit-content;
  height: 3rem;
  margin: 0 0 2.5rem 1.5rem;
  cursor: default;
}

.kyc-form__section {
  margin-top: 0rem;
  padding-top: 0rem;
  border-top: none;
}

.kyc-form__section .ap-input__input-box .form-control,
.ap-datepicker__input-wrapper .form-control,
.react-phone-number-input__input.ap-phone-input__input {
  background-color: #343551;
  border-width: 0;
  border-radius: 0;
}

.kyc-form__section .ap-input__input-box .form-control:hover,
.ap-datepicker__input-wrapper .form-control:hover,
.react-phone-number-input__input.ap-phone-input__input:hover {
  background-color: #343551;
  border-width: 0;
  border-color: #ff9013;
}

.ap-datepicker__input-wrapper .form-control:focus {
  box-shadow: none;
  outline: none;
}

.kyc-form__error:not(.ap-upload__error) {
  top: 6.1rem;
  margin-left: 0;
}

.kyc-form__section .kyc-form__input-box .react-phone-number-input__row,
.react-phone-number-input__input.ap-phone-input__input {
  background: transparent;
}

.kyc-form__section select option,
.pika-single .pika-select.pika-select-year option,
.pika-single .pika-select.pika-select-month option {
  background: white;
}

.kyc-form__input-box .react-phone-number-input__row {
  border: none;
}

.kyc-form__section .ap-upload__wrapper {
  background: transparent;
}

.kyc-form__section .ap-upload__wrapper .ap-upload__btn {
  border: 1.5px solid #7bc13d !important;
  background-color: #7bc13d;
  color: white;
}

.kyc-form__btn:not(.ap-upload__btn) {
  width: auto;
}

.kyc-confirm-level__section-field {
  background: rgb(233, 233, 233);
  border-bottom: none;
}

.kyc-confirm-level__section-field-label {
  color: #292929;
  font-weight: 500;
}

.ap-modal__footer {
  border-top: none;
}

.kyc-confirm-level__sections-wrapper,
.ap-modal__footer.kyc-confirm-level__footer {
  border-top: none;
}

.kyc-form__section button.ap-button__btn.kyc-form__btn--general {
  color: white;
  background: #ff7d00;
  border-radius: 0.5rem;
  border: 1.5px solid #ff7d00 !important;
}

.kyc-form__section button.ap-button__btn.kyc-form__btn--general:hover {
  color: #ff7d00 !important;
  background: white;
}

.kyc-form__section button.ap-button__btn.kyc-form__btn--general:disabled {
  color: white !important;
  opacity: 0.5;
}

.ap-datepicker__error {
  display: none;
}

.kyc-form__section .kyc-form__label {
  color: black;
}

.send-form__input-box .form-control.send-form__with-label {
  padding-left: 6rem;
}

.send-receive {
  border-top: none;
}

.send-receive-header {
  margin-bottom: 2rem;
}

.send-receive__main {
  padding: 1.5rem 2.2rem;
  border-right: 1px solid #c0c0c0;
  background-color: var(--pc__background-secondary_v2);
}

.send-receive__main-form {
  border: solid 1px var(--pc__background-secondary_v2);
  background-color: var(--pc__background-secondary_v2);
  color: black;
  box-shadow: none;
}

.send-receive-tabs__tab-icon {
  display: none;
}

.send-receive-tabs__tab-text,
.receive-address__description,
.receive-address__copy-address-text {
  color: var(--btn-checked-v2);

}

.receive__source-item {
  height: 4rem;
  margin: 0rem;
  background: inherit;
  color: #9999b7;
  font-weight: 500;
  border-radius: 2.0rem;
}

.send-receive .receive {
  padding: 1.7rem 0rem;
}

.receive-address__copy-icon--copy {
  color: #f28e2a;
  margin-left: 1rem;
}

.receive__source {
  border: none;
  padding: 10px 12px;
  display: flex;
  column-gap: 20px;
}

.receive__source-item-with-border {
  border-right: none;
}

.receive__source-item--active,
.receive__source-item:hover {
  background: var(--pc__background-secondary_v2);
  color: white;
  transition: none;
  border-radius: 20px
}

.receive-address {
  color: var(--pc_font-primary);
}

.receive-address__address {
  max-width: 100%;
}
.incongito_joyride {
  position: fixed;
  left: 2.7rem;
  bottom: 25px;
  background: #636363;
  color: white;
  z-index: 999;
  padding: 4px;
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 05rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1;
}

.incongito_joyride:hover {
  opacity: 0.8;
}

.login-form__input-wrapper .ap--label,
.signup-form__additional__label,
.signup-form__label {
  color: rgb(141, 141, 141) !important;
  font-family: "Montserrat", Sans-serif;
  font-weight: 500;
}

.standalone-form__container.login-form__container .form-control,
.standalone-form__container.signup-page__container .form-control,
.standalone-form__container.problem-logging-in-page__container .form-control,
.standalone-form__container.reset-password__container .form-control {
  background-color: var(-pc-inputs-background) !important;
  border: 1.5px solid var(-pc-inputs-background) !important;
  color: var(--pc_font-primary) !important;
  border-radius: 10px;
  border-color: var(-pc-inputs-background);
}

.standalone-form__container.login-form__container .form-control:focus,
.standalone-form__container.signup-page__container .form-control:focus,
.standalone-form__container.problem-logging-in-page__container
  .form-control:focus,
.standalone-form__container.reset-password__container .form-control:focus {
  background-color: var(-pc-inputs-background) !important;
  border: 1.5px solid var(-pc-inputs-background) !important;
  color: var(--pc_font-primary) !important;
  border-radius: 10px;
  box-shadow: none;
}
.form-control:focus{
  background: var(--background-primary_v2);
  color: #fff;
  border: 1px solid var(--btn-checked-v2);
}

.reset-password__separator {
  margin: 1rem -2.8rem 3rem -2.8rem;
  padding: 0px 3rem 2rem;
  border: none;
}

.reset-password__separator span.newsep {
  padding: 1rem 1rem;
  display: block;
  margin: -1rem 0 0 0;
  background: #f16d6d;
  color: white;
  border-radius: 0.5rem;
}

.reset-password__input-wrapper {
  padding-bottom: 1.5rem;
}

.standalone-form__password-strength {
  color: var(--error-color-v2);
  line-height: 3;
}

.standalone-form__message {
  background: var(--pc__background-primary);
  font-size: 1.6rem;
}

.standalone-form__message-footer {
  border-top: none;
}

.standalone-form__message-footer .standalone-form__btn--subtractive {
  background: #f6961f;
  color: white;
  font-size: 1.4rem;
  border-radius: 0.5rem;
  height: 5rem;
  line-height: 4.2rem;
}

.standalone-form__message-footer .standalone-form__btn--subtractive:hover,
.standalone-form__message-footer .standalone-form__btn--subtractive:focus {
  background: white;
  color: #f6961f;
  border: 2px solid #f6961f !important;
}

.reset-password__container .standalone-form__message-footer {
  height: 8.2rem;
}

.rightbtn {
  margin-left: 2rem;
  font-weight: 500;
  font-size: 1.5rem;
  border-radius: 1.5rem;
  width: 31px;
  height: 31px;
  opacity: 0.8;
}

.slide-pane__content .nav-tabs {
  margin-top: 1rem;
}

.slide-pane__content .nav-tabs .nav-link {
  padding: 1rem 1rem;
}

.slide-pane__content .nav-tabs .nav-link:first-child::before {
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  bottom: 0;
  width: 1px;
  height: 80%;
  background: inherit;
}

.modal-open .modal {
  z-index: 1040;
  right: 0;
  top: 0;
  left: 0;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  box-shadow: none;
  padding: 0 !important;
  min-height: 200px;
}

.modal-open .modal .modal-header {
  padding: 2.5rem 1rem;
}

.modal-open .modal .modal-header .modal-title.h4 {
  font-size: 1.9rem;
  font-weight: 600;
}

.modal-open .modal .modal-body {
  padding: 3rem 1rem;
  font-size: 1.4rem;
}

.modal-open .modal .modal-dialog {
  vertical-align: middle;
}

.modal-open .modal .modal-footer .btn {
  font-size: 1.4rem;
}

.instrument-selector-popup__body{
background-color: var(--primary-color-v2);
}

.instrument-selector-popover__popover
  .instrument-selector-popup__body
  .instrument-selector-popup__row:hover {
  background: rgba(63, 63, 63, 0.04);
}

.instrument-selector-popup__table-header {
  background: #2E2E48;
}

.instrument-selector-popup__header-column {
  border-bottom: none;
}

.footer-form {
  display: flex;
  margin-bottom: 2rem;
}

.footer-form .btn {
  display: inline-block;
  margin-left: 1rem;
}

.user-summary__content > div {
  background: darkgrey;
}

.popover-menu__item-divider {
  background: rgb(255, 255, 255);
}

.page-header__content-wrapper {
  height: 6.2rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  z-index: 3;
}

.App.container > h1 {
  width: 95%;
  max-width: 1200px;
  margin: 2rem auto 0;
}

.App.container > div.cloud-info {
  width: 95%;
  max-width: 1200px;
  margin: 4rem auto 0;
}

.send-form {
  padding: 1.7rem 0rem;
}

.send-form__source {
  border: none;
  padding: 10px 12px;
  display: flex;
  column-gap: 20px;
}

.send-form__source-item {
  height: 4rem;
  margin: 0;
  font-size: 1.4rem;
  border: 1.5px solid transparent;
  background: inherit;
  color: #9999b7;
  font-weight: 500;
  border-radius: 20px;
}

.send-form__source-item-with-border {
  border-right: none;
}

.send-form__source-item--selected,
.send-form__source-item:hover {
  background: var(--pc__background-secondary_v2);
  color: white;
  border: 1.5px solid transparent;
  transition: none;
  border-radius: 20px;
}

.receive-form__label-text {
  color: #fff;
  font-size: 1.4rem;
}

.sidepane-details__header--top {
  border-top: 0px solid #c0c0c0;
}

.ap-sidepane__close-button.retail-sidepane-with-details__close-button {
  z-index: 9995;
  background: var(--pc__background-secondary_v2);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-receive__side,
.send-receive__main {
  overflow-y: auto;
  height: 100vh;
  background-color: var(--pc__background-secondary_v2);
}

.send-receive__side-details .sidepane-details__header {
  padding-left: 1.5rem !important;
  margin-top: 3rem;
  font-weight: 600;
  color: #fff;
}

.sidepane-details__information-text .b-transf {
  margin-top: 2rem;
  color: var(--text-gray-v2);
}

.sidepane-details__information-text .p-transf {
  color: var(--text-gray-v2);
}

.sidepane-details__information-text .e-wall {
  margin-top: 2rem;
  color: var(--text-gray-v2);
}

.flex-table,
.flex-table__title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}


.recent-trades__table-time {
  color: var(--text-gray-v2);
}

.send-form__label-text {
  color: var(--pc_font-primary);
  line-height: 1px;
  font-size: 1.4rem;
}

.send-form__label-text .form-group {
  display: inline-block;
  vertical-align: middle;
}

.send-form__label-text .ap-input__input-box .input-checkbox {
  margin: -6px 5px 7px 0;
}

.send-form__label-text .form-group .ap-input__input-box .ap-input__error {
  position: absolute;
  margin-top: 3.5rem;
}

.send-form__label-text > .input-checkbox {
  color: #f28E2A;
}

.agreeded-cont {
  line-height: 1px;
  margin-bottom: 3rem;
}

.agreeded-cont .form-group {
  display: inline-block;
  vertical-align: middle;
}

.agreeded-cont .ap-input__input-box #accept-terms {
  margin: -6px 4px 7px 0;
}

.agreeded-cont .form-group .ap-input__input-box .ap-input__error {
  position: absolute;
  margin-top: 3.5rem;
}

.agreeded-cont .terms-condition {
  color: #f28e2a;
}
.page-header__header-item--logo .ap-logo img.ap-logo__img {
  width: 13rem;
}
@media screen and (max-width: 1023px) {
  .page-header__header-item--logo .ap-logo img.ap-logo__img {
    margin-left: 24px;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 535px) {
   .page-header__header-item--logo .ap-logo img.ap-logo__img {
      margin-left: -71px;
  }
  .page-header__header-item--logo .ap-logo {
    overflow: visible!important;
  }
}
.nav-logo-link .ap-logo__img {
  width: 19rem;
  margin: -1.8rem 0;
}

.nav-link.dropdown-toggle:after {
  display: none;
}

.standalone-layout__wrapper {
  background-color: var(--background-primary_v2);
  align-items: center;
  justify-content: center;
}

.confirm-order-modal__lwt-container {
  background: white;
  border-bottom: none;
}

.confirm-order-modal__lwt-container{
  background: transparent;
}

.KYC_IMFormStepComponent-page form {
  padding-bottom: 2rem;
}

.KYC_IMFormStepComponent-page label.form-label {
  font-weight: 500;
  line-height: 1.4;
}

.KYC_IMFormStepComponent-page .btn.btn-info,
.KYC_IMFormStepComponent-page .btn.btn-primary,
.intermediatelevel-page .btn.btn-info,
.intermediatelevel-page .btn.btn-primary,
.intermediatelevel-page .btn.btn-secondary,
.masterlevel-page .btn.btn-info,
.masterlevel-page .btn.btn-primary {
  background: #f28e2a;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  border-radius: 0.5rem;
  border: 1.5px solid #f28e2a;
  height: 40px !important;
  line-height: 0 !important;
}

.KYC_IMFormStepComponent-page .btn.btn-info:hover,
.KYC_IMFormStepComponent-page .btn.btn-primary:hover,
.intermediatelevel-page .btn.btn-info:hover,
.intermediatelevel-page .btn.btn-primary:hover,
.intermediatelevel-page .btn.btn-secondary:hover,
.masterlevel-page .btn.btn-info:hover,
.masterlevel-page .btn.btn-primary:hover {
  background: rgb(251, 251, 251);
  color: #f28e2a;
}

.KYC_IMFormStepComponent-page .btn.btn-warning,
.intermediatelevel-page .btn.btn-warning,
.masterlevel-page .btn.btn-warning,
.masterlevel-page .btn.btn-warning:focus {
  background: rgb(251, 251, 251);
  color: #f28e2a;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  border-radius: 0.5rem;
  border: 1.5px solid #f28e2a;
}

.KYC_IMFormStepComponent-page .btn.btn-warning:hover,
.intermediatelevel-page .btn.btn-warning:hover {
  background: #f28e2a;
  color: white;
}

.KYC_IMFormStepComponent-page .tab-content .row p:first-child {
  font-weight: 600;
}

.ReactModalPortal {
  z-index: 2;
}

.Toastify {
  z-index: 9;
}

.slide-pane__overlay.ReactModal__Overlay {
  z-index: 9998;
}

.react-datepicker .react-datepicker-container .react-datepicker-top {
  background: #f28e2a;
  border-top: 1px solid #d17a23;
  border-left: 1px solid #d17a23;
  border-right: 1px solid #d17a23;
}

.react-datepicker:not(.position-top)
  .react-datepicker-container
  .react-datepicker-top::after {
  border-bottom-color: #d17a23;
}

.card-master b {
  font-weight: 500;
  color: white;
}

.card-master .center-card {
  color: white;
  margin: auto;
  background: #373936;
  padding: 0;
  cursor: default;
}

.middle-row {
}

.binance-btn {
  background: rgb(243, 186, 47);
  border: 1px solid rgb(243, 186, 47);
  font-size: 1.4rem;
  padding: 0.55rem 32px;
}

.binance-btn:hover {
  background: rgb(209, 159, 37);
  border: 1px solid rgb(209, 159, 37);
}

.binance-btn:focus,
.binance-btn:not(:disabled):not(.disabled):active:focus,
.binance-btn:not(:disabled):not(.disabled):active {
  background: rgb(199, 152, 38);
  border: 1px solid rgb(199, 152, 38);
  box-shadow: 0 0 0 0.2rem rgba(199, 152, 38, 0.5);
}

.binance-btn.darker {
  background: rgb(30, 189, 83);
  border: 1px solid rgb(30, 189, 83);
  font-size: 1.4rem;
  padding: 0.385rem 32px;
}

.binance-btn.darker:hover {
  background: rgb(24, 160, 69);
  border: 1px solid rgb(24, 160, 69);
}

.binance-btn.darker:focus,
.binance-btn.darker:not(:disabled):not(.disabled):active:focus,
.binance-btn.darker:not(:disabled):not(.disabled):active {
  background: rgb(21, 143, 61);
  border: 1px solid rgb(21, 143, 61);
  box-shadow: 0 0 0 0.2rem rgba(21, 143, 61, 0.5);
}

.binance-btn.switcher {
  border: 1px solid rgb(162 161 161);
  background-color: white;
  color: rgb(162 161 161);
  font-weight: 600;
}

.binance-btn.switcher.first {
  border-radius: 0.35rem 0 0 0.35rem;
}

.binance-btn.switcher.last {
  border-radius: 0 0.35rem 0.35rem 0;
  border-left: none;
}

.logo-binance {
  text-align: center;
  width: 75%;
  margin: 0 auto;
  display: block;
}

.binance-container {
  margin: auto;
}

.binance-container .amount-container .form-control {
  font-size: 3.4rem;
  height: 7rem;
  text-align: center;
}

.binance-container .amount-container .form-control::-webkit-outer-spin-button,
.binance-container .amount-container .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.binance-container .amount-container input[type="number"].form-control {
  -webkit-appearance: none;
  margin: 0;
}

.binance-container .amount-container .form-text {
  font-size: 1.4rem;
  margin-top: 1.25rem;
}

.binance-step {
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  background: #eaeaea;
  border-radius: 0.7rem;
  box-shadow: inset 0px 1px 0px 1px rgb(152 152 152 / 41%);
  margin-top: 2rem;
}

.binance-step.current {
  opacity: 1;
  height: auto;
}

.binance-container .card-prices {
  background: #276dc5;
  margin: 0 15px;
  padding: 25px;
  border-radius: 0.6rem;
  color: white;
  box-shadow: inset 0px 0px 0 1px rgb(54 56 108 / 45%);
  border: 1px solid white;
}

.binance-container .card-prices h2 {
  text-align: left;
  font-weight: 500;
  text-shadow: 0px 1px 2px rgb(37 36 98 / 51%);
  font-size: 1.7rem;
  text-transform: capitalize;
}

.binance-container .card-prices h4 {
  text-align: left;
  font-weight: 500;
  font-size: 1.2rem;
  color: #f0f0f4;
}

.binance-container .card-prices .buy-number {
  background: #1d489d;
  border-radius: 0.3rem;
  padding: 15px 0;
  margin: 15px 0 5px 0;
  border: 1px solid #271685;
}

.binance-container .card-prices .buy-number p {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #b9b5e6;
  margin: 0;
}

.binance-container .card-prices .buy-number h3 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #edecf8;
}

.binance-container .card-prices .row {
  border-bottom: 1px solid #bfc1ff;
  padding: 1.5rem 0;
  margin: 0;
}

.binance-container .card-prices .row div {
  font-size: 1.5rem;
  padding: 0;
}

.binance-container .card-prices.details {
  background: transparent;
  padding: 15px 0 0;
  box-shadow: none;
  border: none;
  color: #5c5c5c;
}

.binance-container .card-prices.details .row .col.text-left {
  font-weight: 600;
}

.binance-container .card-prices.details .row .col.text-right {
  overflow: hidden;
}

.binance-container .form-control.noteAction {
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  text-align: left;
}

.schedule-report-list .flex-table__header-cell,
.download-report__body .flex-table__header-cell {
  margin: 1.5rem 0;
}

.box-to-select {
  padding: 5% 25%;
  border: 0.5px solid #b9b9b9;
  border-radius: 1.5rem;
}

.box-to-select:hover {
  background: var(--pc-inputs-background)!important;
  border: 0.5px solid #acd3ab;
}

.box-to-select .PendingVerifiedStatus {
  width: 100%;
  padding: 2rem 1rem;
  background: url(/local/landing-page/images/levels-bars/corporativo.svg) ,var(--primary-color-v2) ;
  background-repeat: no-repeat;
  border-radius: 1rem;
  color: white;
  font-size: 1.7rem;
  font-weight: 500;
  outline: none;
}

.box-to-select .PendingVerifiedStatus:hover {
  background: url(/local/landing-page/images/levels-bars/corporativo.svg) ,var(--primary-color-v2) ;
  background-repeat: no-repeat;
  background-position: center right;
}

.corporatelevel-page .send-request-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}


.corporatelevel-page .send-request-content .send-request-button {
  width: 100%;
  height: 42px;
}

.corporatelevel-page .alert.alert-warning {
  font-size: 1.7rem;
  padding-left: 0;
  background: var(--bg-dark-v2);
  border-color: transparent;
  color: white;
  font-weight: 600;
}

.corporatelevel-page .form-group label.form-label {
  color: white;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 1rem;
}

.corporatelevel-page .form-group a {
  height: 4rem;
  line-height: 1.5;
  padding: 0.375rem 0rem;
  display: table-cell;
  vertical-align: middle;
  color: #ff9443;
  word-break: break-all;
  background: var(--background-primary_v2);
  border-radius: 0.5rem;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 10px;
}
.corporatelevel-page .form-group a:hover {
  opacity: .8;
  color: #ff9443;
}
.correct-fields{
  border: 1px var(--error-color-v2) solid;
}
.shareholde-table td{
  padding-top: 1.3rem;
}

.corporatelevel-page .form-group input[type="text"] {
  background-color: var(--pc-inputs-background);
  border: 1px solid var(--pc_font-primary) 91;
  border-bottom-width: 1px;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  padding: 0.375rem 10px;
  font-weight: 500;
  outline: none;
  box-shadow: none;
  cursor: default;
}

.corporatelevel-page .row-header-status {
  margin: 0 0px 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #2e2e48;
}

.corporatelevel-page .row-header-status .col-xl-6 {
  padding: 0;
}

.corporatelevel-page .status-content {
  display: grid;
}

.corporatelevel-page .review_status {
  width: 100%;
  background: #e0e0e0;
  padding: 0;
  border-radius: 0.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  color: white;
  align-items: center;
  vertical-align: middle;
  line-height: 5rem;
  background-color: transparent;
}

.row-header-status.row {
  color: white;
}

.status-content.col-xl-3 h3 {
  font-size: 2rem;
  text-align: center;
}

.info_newRecord {
  color: white;
  text-align: center;
}

.corporatelevel-page .review_status.NEW_RECORD {
  color: #73afe0;
}

.corporatelevel-page .review_status.REVIEW_REQUESTED {
  color: #ce73e0;
}

.corporatelevel-page .review_status.ACCEPTED {
  color: #73e0a0;
}

.corporatelevel-page .review_status.INCORRECT_INFORMATION {
  color: var(--error-color-v2);
}

.corporatelevel-page .add-shareholder-content {
  padding: 2rem 0;
  border-top: 1px solid var(--pc-inputs-background);
  margin: 2rem 0px 1rem;
}

.corporatelevel-page .add-shareholder-content.row .col-xl-6 {
  text-align: center;
}

.corporatelevel-page .add-shareholder-content.row .col-xl-6 button {
  background: #f28e2a;
  border-radius: 0.5rem;
  padding: 1.5rem;
  font-size: 1.4rem;
  color: white;
  outline: none;
}

.corporatelevel-page .add-shareholder-content.row .col-xl-6 button:hover {
  opacity: 0.8;
}

.corporatelevel-page .add-shareholder-content.row .col-xl-6 button:focus {
  opacity: 1;
  background: #d67d24;
}

.modal-step-level .modal-dialog {
  max-width: 70%;
}

.modal-step-level .modal-dialog .modal-content {
  height: 94vh;
  padding: 0 0 0 15px;
  border-radius: 1rem;
  overflow: hidden;
  border: none;
  background: transparent;
}

.modal-step-level .modal-dialog .modal-content .modal-container-step-level {
  height: 100%;
  padding-right: 5px;
}
.modal-step-level .modal-dialog .modal-content .modal-container-step-level form button{
  width: 250px;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  .left-fix {
  height: 100%;
  padding-right: 10px;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  > .right-step
  > .row {
  height: 100%;
  padding-right: 5px;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  .row
  .step-container {
  padding: 0 15px;
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  .row
  .step-container.active {
  opacity: 1;
  height: auto;
  overflow: inherit;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  .row
  .step-container
  .h-auto {
  height: auto;
  padding: 1rem 0;
  z-index: 2;
  margin: 0 -15px;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  .row
  .step-container
  .text-warning.form-text {
  color: inherit;
  padding-top: 0.2rem;
  padding-bottom: 0rem;
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
  font-size: 1.3rem;
  line-height: 1.4rem;
}

small.text-muted.form-text {
  color: #9999B7 !important;
  font-size: 1.3rem;
  line-height: 1.3rem;
  text-align: justify;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  .row
  .step-container
  .text-warning.form-text
  a {
  padding-top: 0.5rem;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  .row
  .step-container
  .text-warning.form-text
  .important {
  background: #fe9f3c;
  color: white;
  padding: 1rem 1rem;
  border-radius: 0.3rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.4rem;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  .row
  .step-container
  .text-warning.form-text
  .important:hover {
  background: #df8b32;
}

.modal-step-level
  .modal-dialog
  .modal-content
  .modal-container-step-level
  .row
  .step-container
  .text-warning.form-text
  .important
  img {
  width: 100%;
  margin-top: 1rem;
}

.modal-step-level .modal-dialog .modal-content .modal-header {
  position: absolute;
  right: 0;
  top: 0;
  padding: 18px 18px 0 0;
  z-index: 9;
  border-bottom: none;
}

.modal-step-level .modal-dialog .modal-content .modal-header .close {
  font-size: 2.5rem;
  font-weight: 600;
}

.modal-header .close {
  padding: 0rem 1rem;
  margin: -1rem -1rem 0 auto;
}

.modal-container-step-level .left-step {
  background-color: var(--pc__background-secondary_v2);
  height: 100%;
}

.modal-container-step-level .left-step,
.modal-container-step-level .right-step {
  padding: 20px 0px 0px 30px;
}

.modal-container-step-level .step-side-information {
  vertical-align: top;
  margin-top: 0rem;
  padding-right: 3rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.modal-container-step-level .left-step .step-side-information {
  padding-bottom: 2rem;
}

.modal-container-step-level .step-side-information .crop-src-img {
  width: 50%;
  margin: 0 auto;
  display: block;
}

.modal-container-step-level .step-side-information > span {
  height: auto;
  position: relative;
  display: block;
}

.modal-container-step-level .step-side-information h3 {
  color: #ff9016;
  font-weight: 600;
  font-size: 1.9rem;
}

.modal-container-step-level .step-side-information h4 {
  color: var(--text-gray-v2);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
  margin-bottom: 1rem;
  text-align: justify;
}

button.form-control.uploader-btn.btn_constitution_act.btn.btn-info {
  width: 250px !important;
  display: inline-block;
  margin-left: 60%;
  margin-top: -1rem;
  background: var(--secondary-color-v2);
  border-color: transparent;
}

button.form-control.uploader-btn.btn_constitution_act.btn.btn-success,button.form-control.uploader-btn.btn_constitution_act.btn.btn-success:focus {
  width: 30% !important;
  display: inline-block;
  margin-left: 60%;
  margin-top: -1rem;
  border-color: transparent;
  background-color: var(--pc-inputs-background);
}

button.form-control.uploader-btn.btn_constitution_act.btn.btn-danger {
  width: 50% !important;
  display: inline-block;
  margin-left: 50%;
  margin-top: -1rem;
}

label.btn_constitution_act.form-label {
  width: 50%;
  display: inline-block;
  position: absolute;
}

span.btn_constitution_act {
  display: block;
  margin-top: -3.5rem;
  margin-bottom: 4rem;
  width: 50%;
  color: #ff9443;
}

.btn_constitution_act.col-xl-12 .invalid-feedback {
  width: 50%;
  margin-left: 50%;
  margin-top: -6.5rem;
  margin-bottom: 6rem;
  position: absolute;
}

.modal-container-step-level .step-side-information h4.title-image {
  margin: 1rem 0;
  text-transform: capitalize;
  text-align: center;
}

small.selfie.form-text img {
  width: auto;
  max-height: 350px;
}

.info_self.form-group {
  padding: 10% 0;
}

.modal-container-step-level .step-side-information .btn-primary {
  background:var(--primary-color-v2);
  border-color: #ff9016;
  padding: 0.775rem 1.5rem;
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

.modal-container-step-level .step-side-information .btn-primary:hover,
.modal-container-step-level .step-side-information .btn-primary:focus,
.modal-container-step-level .step-side-information .btn-primary:active {
  background: var(--primary-color-v2);
  border-color: #da7913;
  outline: none;
  box-shadow: none !important;
}

.modal-container-step-level .right-step {
  position: relative;
  height: 100%;
  overflow-y: auto;
  background: var(--pc__background-secondary_v2);
}

.modal-container-step-level .right-step > .row > form > div > .row,
.modal-container-step-level
  .right-step
  > .row
  > form
  > div
  > .row
  > .step-container
  > .row {
  margin-top: 0em;
}

.modal-container-step-level .right-step .alert {
  font-size: 1.5rem;
  background-color: var(--pc__background-secondary_v2);
  padding-left: 0;
  color: white;
  font-weight: 600;
  border: none;
}

.modal-container-step-level .right-step h3 {
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--pc_font-primary);
  padding-bottom: 1.5rem;
}

small#subInfofaces_images img {
  width: auto;
  max-height: 400px;
}

.corporatelevel-page .add-shareholder-content.row .col-xl-4 {
  text-align: center;
}

.corporatelevel-page .add-shareholder-content button {
  background: var(--secondary-color-v2);
  border-radius: 0.5rem;
  padding: 1.5rem;
  font-size: 1.4rem;
  color: white;
  outline: none;
}

.modal-container-step-level .right-step > .row > form,
.modal-container-step-level .right-step > .row > form .step-container {
  width: 100%;
}

.modal-container-step-level .right-step > .row {
  width: 100%;
}

.modal-container-step-level .right-step > .row .col-xl-6 {
}

.modal-container-step-level .left-step .logo-step {
  position: absolute;
  width: 40%;
  margin-top: 48rem;
  margin-left: 0.3rem;
  opacity: 15%;
}

.modal-container-step-level .right-step .btn {
  height: 4rem;
  font-size: 1.2rem;
  width: 100%;
  border-radius: 1rem;
  color: var(--pc_font-primary);
}

.btn_constitution_act.col-xl-12 .text-danger.form-text {
  width: 50%;
  margin-left: 50%;
}

.modal-container-step-level .right-step .btn.btn-primary {
  background: var(--primary-color-v2);
  border-color: var(--primary-color-v2);
}

.modal-container-step-level .right-step .form-group .btn {
  width: 100%;
}

.modal-container-step-level .right-step .form-group .btn.uploader-btn {
  margin-top: 1rem;
  background: var(--secondary-color-v2);
  border-color: transparent;
}

.modal-container-step-level .right-step .text-danger {
  font-size: 1.3rem;
  padding: 1rem 0 1.5rem;
  color: var(--error-color-v2) !important;
}

.modal-container-step-level .right-step .form-group label.form-label {
  font-weight: 600;
  color: var(--pc_font-primary);
  line-height: 1.2;
  pointer-events: none;
}

.modal-container-step-level .right-step .form-group input[type="number"],
.modal-container-step-level .right-step .form-group input[name="birthday"],
.modal-container-step-level .right-step .form-group input[type="text"],
.modal-container-step-level .right-step .form-group input[type="email"],
.modal-container-step-level .right-step .form-group input[type="date"],
.modal-container-step-level .right-step .form-group select,
.modal-container-step-level .right-step .form-group input#phone-form-control {
  background-color: var(--pc-inputs-background);
  border: 1px solid transparent;
  border-bottom-width: 1px;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 2px rgb(0 0 0 / 28%);
}
.modal-container-step-level .right-step .form-group input[type="text"]::placeholder,
.modal-container-step-level .right-step .form-group input[type="text"]::-webkit-input-placeholder,
.modal-container-step-level .right-step .form-group input[type="text"]::-moz-placeholder,
.modal-container-step-level .right-step .form-group input[type="text"]::-ms-input-placeholder{
  color: #9999B7;
}

.modal-container-step-level
  .right-step
  .form-group
  input[type="number"]:disabled,
.modal-container-step-level
  .right-step
  .form-group
  input[name="birthday"]:disabled,
.modal-container-step-level .right-step .form-group input[type="text"]:disabled,
.modal-container-step-level
  .right-step
  .form-group
  input[type="email"]:disabled,
.modal-container-step-level
  .right-step
  .form-group
  input[type="email"]:disabled,
.modal-container-step-level .right-step .form-group select:disabled,
.modal-container-step-level
  .right-step
  .form-group
  input#phone-form-control:disabled {
  background-color: var(--pc-inputs-background);
  border: 1px solid transparent;
  border-bottom-width: 1px;
  border-radius: 0.5rem;
}

.modal-container-step-level .right-step .form-group input.markaseditable,
.modal-container-step-level .right-step .form-group select.markaseditable,
.modal-container-step-level .right-step .form-group div.markaseditable {
  -webkit-text-fill-color: inherit;
  border-radius: 0.5rem;
  box-shadow: none;
  padding: 1rem;
}
.modal-container-step-level .right-step .form-group input.markaseditable.file-image {
  border: 2px solid var(--error-color-v2);
}

.modal-container-step-level .btn-info {
  background: var(--primary-color-v2);
  border-color: #fe9f3c;
}

.modal-container-step-level .btn-info:hover {
  background: var(--primary-color-v2);
  border-color: #df892d;
}
.step-container .btn-info:first-child {
  background: var(--secondary-color-v2);
  border-color: transparent;
}

.step-container .form-control.markaseditable {
  box-shadow: 0 0 2px 3px rgb(255, 171, 74);
}

.modal-container-step-level .right-step .form-group input[type="date"] {
  height: 4rem;
  padding: 0.375rem 0.75rem;
}

.modal-container-step-level .right-step .dropdown-bnx {
  width: 100%;
}

.modal-container-step-level .right-step .form-group .date-input-bnx svg {
  top: 1.2rem;
}

.modal-container-step-level .right-step .form-group .date-input-bnx > div {
  width: 100%;
}

.modal-container-step-level .right-step .form-group input#phone-form-control {
  width: 100%;
}

.modal-container-step-level .right-step .form-group input[type="number"]:focus,
.modal-container-step-level .right-step .form-group input[type="text"]:focus,
.modal-container-step-level .right-step .form-group input[type="email"]:focus,
.modal-container-step-level .right-step .form-group select:focus,
.modal-container-step-level
  .right-step
  .form-group
  input#phone-form-control:focus {
  background-color: var(--pc-inputs-background);
  border: 1px solid var(--btn-checked-v2);
}

@media (max-width: 940px) {
  .card-master .center-card {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media (max-width: 780px) {
  .card-master .center-card {
    flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (max-width: 660px) {
  .card-master .center-card {
    flex: 0 0 80%;
    max-width: 80%;
  }
}

@media (max-width: 560px) {
  .card-master .center-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.card-master .center-card .card-time {
  font-size: 1.7rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding: 1.5rem 0 0 0;
}

.card-master .center-card .chart-container {
  overflow: hidden;
}

.card-master .center-card .chart-container canvas {
  width: calc(100% + 10px) !important;
  margin-left: -10px;
  margin-bottom: -30px;
}

.card-master .center-card .chart-title {
  background: #b5b5b5;
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem 0;
}

.card-master .center-card .chart-info-container {
  background: var(--pc_font-primary);
  font-size: 2rem;
  padding: 2rem 3rem;
}

.chart-info-container .separator-c {
  padding: 1.2rem 0;
}

.card-master .btn-primary {
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
  background: #fd7a22;
  border-color: rgb(224, 100, 17);
  margin: 1rem 0;
}

.card-master .center-card .chart-info-container .chart-info-title .col {
  color: #b5b5b5;
  font-size: 1.5rem;
  font-weight: 600;
}

.card-master .center-card .chart-info-container .chart-info-sub .col {
  color: #fd7a22;
  font-size: 2rem;
  font-weight: 600;
}

.card-master .center-card .chart-info-footer {
  background-image: url(/local/landing-page/images/chart-footer.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: white;
  background-position: left bottom;
  height: 12rem;
}

.card-master .center-card .card-row {
  padding: 1.5rem 3rem 1.5rem;
}

.card-master .center-card .side-logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 7rem;
}

.card-master .center-card .cant-letter {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  line-height: 3.7rem;
}

.card-master .center-card .cant-letter.righted {
  line-height: 2.5rem;
}

.card-master .center-card .cant-letter .colored {
  color: #858383;
}

.card-master .center-card .cant-letter .coloredBtcUp {
  color: #45c734;
}

.card-master .center-card .cant-letter .coloredBtcDown {
  color: #ff0000;
}

#generateimage canvas {
  margin: 0 auto;
  display: block;
  outline: solid 0px transparent;
}

.box-calculator {
  color: white;
  width: 96%;
  margin: 4rem 0 0 auto;
}

.box-calculator div {
  font-size: 1.4rem;
}

.box-calculator .footercalc {
  text-align: center;
}

.box-calculator .header {
  background-color: #fd7a22;
  border-radius: 12px 12px 0 0;
  text-align: center;
}

.box-calculator .header b {
  font-weight: 500;
}

.box-calculator .header .rw-bd {
  display: flex;
}

.box-calculator .header .rw-bd:first-child {
  border-radius: 8px 8px 0 0;
}

.box-calculator .header .rw-bd:nth-child(2n-1) {
  background-color: #2d2d2d;
}

.box-calculator .header .rw2-bd {
  display: flex;
}

.box-calculator .header .rw-s6 {
  width: 50%;
  padding: 1rem;
  text-align: center;
}

.box-calculator .header .rw-s6:first-child {
  border-left: none;
  border-bottom: none;
}

.box-calculator .header .rw-s6:last-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.box-calculator .header .rw2-s6 {
  width: 50%;
}

.box-calculator .header .rw-s12 {
  width: 100%;
  padding: 1rem;
}

.box-calculator .prcolor {
  color: #ffdec7;
  font-size: 1.1rem;
}

.box-calculator .pd3 {
  padding: 1.8rem 2rem !important;
}

.box-calculator .body {
  background-color: #f2f5f7;
  border-radius: 0 0 20px 20px;
  padding: 1.5rem 1.5rem;
  box-shadow: 0px 2px 4px 1px rgba(90, 90, 90, 0.29);
}

.box-calculator .body .form-cont {
  width: 90%;
}

.box-calculator .body .form-bd {
  display: flex;
  margin: 1rem 0;
  position: relative;
}

.box-calculator .body .form-bd:hover label {
  color: #f07e2e;
}

.box-calculator .body .form-bd label {
  color: #4c4c4c;
  position: absolute;
  left: 2rem;
  top: 1rem;
}

.box-calculator .body .form-bd .inpt {
  width: 100%;
  border-radius: 20px 0 0 20px;
  box-shadow: 0px 2px 2px 0px #aaaaaa;
  padding: 2.5rem 3rem 1rem 2rem;
  font-size: 1.4rem;
  color: #717171;
  font-weight: 700;
}

.box-calculator .body .form-bd .inpt::-webkit-outer-spin-button,
.box-calculator .body .form-bd .inpt::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.box-calculator .body .form-bd .inpt[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
  width: 60%;
}

.box-calculator .body .form-bd .inpt:hover {
  box-shadow: 0px 2px 2px 0px #818181;
}

.box-calculator .body .form-bd .inpt:focus {
  box-shadow: 0px 2px 2px 0px #fb9552;
}

.box-calculator .body .form-bd .tlt-right {
  min-width: 40%;
  width: auto;
  background-color: #fd7922;
  border-radius: 0 20px 20px 0;
  vertical-align: middle;
  line-height: 5.5rem;
  font-weight: 600;
  font-size: 1.3rem;
  box-shadow: 0px 0px 5px 1px #fb9552;
  cursor: default;
}

.box-calculator .body .form-bd .tlt-right select {
  vertical-align: middle;
  display: inline-block;
  background: transparent;
  border: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0 20px 20px 0;
  height: 100%;
  cursor: pointer;
  width: auto;
  -webkit-appearance: none;
}

.box-calculator .body .form-bd .tlt-right select option {
  color: black;
}

.box-calculator .body .revrs-cont {
  position: relative;
  width: 10%;
}

.box-calculator .body .revrs-arr {
  height: 50%;
  padding: 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  vertical-align: middle;
  text-align: center;
  border: 2px solid #bbbcbb;
  border-left: none;
}

.box-calculator .body .btn-rvrs {
  background-color: #fd7a22;
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 1px #fb9552;
  outline: none;
  color: white;
  font-size: 1.2rem;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
  left: 6px;
}

.box-calculator .body .btn-rvrs:hover {
  color: #fd7a22;
  background-color: var(--pc_font-primary);
}

.box-calculator .body .bodyfull {
  width: 100%;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.box-calculator .body .footercalc a {
  margin: 2rem 0;
  background-color: #fd7922;
  border-radius: 15px;
  font-weight: 600;
  font-size: 1.4rem;
  vertical-align: middle;
  line-height: 5rem;
  padding: 0 3rem;
  color: white;
}

.box-calculator .body .footercalc a:hover,
.box-calculator .body .footercalc a:focus {
  box-shadow: 0px 0px 5px 1px #fb9552;
  transform: scale(1);
}

.box-calculator .btn-header {
  width: 100%;
}

.box-calculator .btn-header button {
  margin: auto 0.3rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: grey;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  border: 2px solid transparent;
}

.box-calculator .btn-header button:hover,
.box-calculator .btn-header button.here {
  border: 2px solid #fd7a22;
  color: #fd7a22;
}

@media (max-width: 440px) {
  .box-calculator {
    margin: 0rem auto 0;
  }

  .box-calculator .header .rw2-s6 {
    width: 100%;
    text-align: center !important;
    margin-bottom: 2px;
  }

  .box-calculator .header .rw2-bd {
    display: block;
  }
}

/* BNX BETA START */
.bnx-hero {
  background-color: #3a3c39;
}

.container-hero {
  max-width: 1200px;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

.bnx_navlink {
  color: white;
  cursor: pointer;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.bnx_navlink svg {
  font-size: 15px;
}

.theme-light {
  background-color: #f7f9fb;
}

.menu.open .bnx_navlink {
  color: rgba(32, 19, 13, 0.55);
}

.menu.open .bnx_navlink:hover {
  color: #ff9b36;
}

.header_top-wrapper {
  padding-top: 30px !important;
  padding-bottom: 20px !important;
}

.logo-block {
  -webkit-box-ordinal-group: -98;
  -ms-flex-order: -99;
  order: -99;
  margin-right: 3rem;
}

.logo-block img {
  width: 160px;
}

.accordion--advanced-filters
  .tab-list--menu.open
  .nav
  .date-range-popular-selection,
.eu-cookie-compliance-banner
  .tab-list--menu.open
  .nav
  .eu-cookie-compliance-default-button,
.tab-list--menu.open
  .nav
  .accordion--advanced-filters
  .date-range-popular-selection,
.tab-list--menu.open .nav .cta,
.tab-list--menu.open
  .nav
  .eu-cookie-compliance-banner
  .eu-cookie-compliance-default-button,
.tab-list--menu.open
  .nav
  .theme-dark
  .search-api-autocomplete-search
  .search-all-link,
.tab-list--menu.open
  .nav
  .theme-light
  .search-api-autocomplete-search
  .search-all-link,
.theme-dark
  .search-api-autocomplete-search
  .tab-list--menu.open
  .nav
  .search-all-link,
.theme-light
  .search-api-autocomplete-search
  .tab-list--menu.open
  .nav
  .search-all-link {
  color: rgba(12, 21, 46, 0.36);
}

.accordion--advanced-filters .date-range-popular-selection,
.autocomplete-related-tags,
.bordered-box--event-details .atcb-link:focus::after,
.breadcrumb__item-content a,
.breadcrumb__item.has-group .breadcrumb__item-group .breadcrumb__item-content a,
.code-snippet-copy,
.grid-images--partners .image__wrapper,
.pr-2,
.px-2,
.social-links--share a {
  padding-right: 10px !important;
}

.accordion--advanced-filters .date-range-popular-selection,
.cta,
.eu-cookie-compliance-banner .eu-cookie-compliance-default-button,
.theme-dark .search-api-autocomplete-search .search-all-link,
.theme-light .search-api-autocomplete-search .search-all-link {
  padding: 16px 32px 17px;
  font-size: 15px;
  line-height: 15px;
  display: inline-block;
  border: none;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-decoration: none;
}

.accordion--advanced-filters .date-range-popular-selection,
.cta--tertiary,
.theme-dark .search-api-autocomplete-search .search-all-link,
.theme-light .search-api-autocomplete-search .search-all-link {
  line-height: 20px;
  letter-spacing: 0.2px;
  padding: 0;
  background: 0 0;
}

.card-link-container_body:hover {
  background-color: #fff;
  border-color: rgba(12, 21, 46, 0.08);
  -webkit-box-shadow: 0 2px 5px -3px rgba(12, 21, 46, 0.08);
  box-shadow: 0 2px 5px -3px rgba(12, 21, 46, 0.08);
}

.image__container {
  margin-right: 20px !important;
  font-size: 3rem;
}

.image__container svg {
  color: #f28e2a;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.autocomplete-related-tags button,
.badge-tag button,
.d-flex,
.text-with-fa-icon--inline,
.theme-dark .search-autocomplete .related-videos-wrapper,
.theme-light .search-autocomplete .related-videos-wrapper {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.theme-dark a,
.theme-dark button {
  color: #fff;
}

.theme-dark a:focus,
.theme-dark a:hover,
.theme-dark button:focus,
.theme-dark button:hover {
  color: #fff;
}

.tab-pane {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.tab-list__content {
  position: absolute;
  z-index: -999;
  top: 93px;
  left: 0;
  width: 100%;
  background: #f7f9fb;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 1px solid rgba(12, 21, 46, 0.08);
  opacity: 0;
}

.menu.row.open .tab-list__content {
  z-index: 999;
  opacity: 1;
}

.primary-beta {
  background-color: #f28e2a;
  border-radius: 0.5rem;
}

.primary-beta:hover {
  opacity: 0.7;
}

.tab-content > div > .tab-pane {
  display: none;
}

.tab-content > div > .active {
  display: block;
}

.tab-list__content.tab-content > div > .active {
  opacity: 1;
}

.paragraph-text {
  margin: 0;
  color: rgba(12, 21, 46, 0.64);
  font-size: 15px;
}

.paragraph-text--strong {
  color: #0c152e;
  margin: 0;
  font-weight: 600;
}

.pb-12,
.py-12 {
  padding-bottom: 60px !important;
}

.pt-10,
.py-10 {
  padding-top: 50px !important;
}

.heading-body .paragraph-text {
  margin-top: 15px;
}

.page-section--hero .content-layout .heading-body .paragraph-text p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: rgb(214, 214, 214);
}

.page-section.theme-light {
  background-color: #f7f9fb;
}

.page-section.bnx-hero.theme-dark {
  background-image: url(/local/landing-page/images/isotipo.svg);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center left;
}

.container-ticket-beta {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.container-ticket-beta .ticker {
  width: auto;
}

.container-ticket-beta .ticker .ticker-block {
  background-color: #4c4c4c;
  border-radius: 0.8rem;
  box-shadow: 0px 0px 18px 5px #434343;
}

.container-ticket-beta .ticker .ticker-block .ticker-last-trade {
  margin-left: 0rem;
}

.container-ticket-beta .ticker .ticker-block .ticker-last-trade::before {
  content: "";
}

.theme-dark .theme-light .title,
.theme-light .title,
.title {
  color: var(--pc_font-primary);
}

.title p {
  margin: 0;
  font-weight: 700;
}

.title h2 {
  font-size: 32px;
  line-height: 45px;
}

.card-default {
  text-align: center;
}

.card-icons-on-the-side--hover:hover .card-icons-on-the-side__container {
  background-color: #fff;
  border-color: rgba(12, 21, 46, 0.08);
  -webkit-box-shadow: 0 2px 5px -3px rgba(12, 21, 46, 0.08);
  box-shadow: 0 2px 5px -3px rgba(12, 21, 46, 0.08);
}

.small .card-default .image {
  max-height: 68px;
  max-width: 68px;
}

.card-default .heading-body .title {
  margin: 0;
}

.small .card-default .image__wrapper {
  margin-bottom: 15px !important;
}

.small .card-default .heading-body .paragraph-text {
  margin-top: 10px !important;
}

.small .card-default .title * {
  font-size: 18px;
  line-height: 25px;
}

.paragraph-text a {
  font-size: 15px;
  font-weight: 500;
  color: #ff9b36;
}

.content__container {
  font-weight: 600;
  overflow: hidden;
  height: 45px;
  padding: 0 15px;
}

.content__container:before {
  content: "[";
  left: 0;
}

.content__container:after {
  content: "]";
  position: absolute;
  right: 0;
}

.content__container:after,
.content__container:before {
  position: absolute;
  top: 0;
  color: #f28e2a;
  font-size: 42px;
  line-height: 40px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: opacity;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.content__container__text {
  display: inline;
  float: left;
  margin: 0;
  font-size: 3rem;
  line-height: 4.6rem;
  color: white;
}

.content__container__text b {
  font-weight: 500;
  color: #f28e2a;
}

.content__container__list {
  margin-top: 3px;
  padding-left: 240px;
  text-align: left;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.content__container__list__item {
  line-height: 40px;
  font-size: 3rem;
  color: white;
  cursor: default;
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%,
  12.66%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    -webkit-transform: translate3d(0, -75%, 0);
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  12.66%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    -webkit-transform: translate3d(0, -75%, 0);
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
  }
}

/* BNX BETA END */

.drop_btn {
  padding: 10px;
  display: block;
  background: #e4e4e4;
  font-weight: 600;
  font-size: 1.5rem;
  border-radius: 3px;
  margin: -37px 0 10px auto;
  color: #db7e7e;
}

.drop_btn:hover {
  background-color: rgb(139, 139, 139);
  color: white;
}

.sidepane-details__body {
  padding: 0;
}
.new-side-component .sidepane-details__body {
  width: 100%;
  padding: 0 0 0 .5rem;
}
.new-side-component .sidepane-details__body >div:first-child label,
.new-side-component .sidepane-details__body >div:first-child span {
  margin-top: 0rem !important;
}
.new-side-component .sidepane-details__body >div:last-child{
  margin-bottom: 0 !important;
}
.new-side-component .sidepane-details__body >div:nth-child(2){
  padding-bottom: 1rem;
}
.new-side-component .sidepane-details__information {
  padding: 0;
}
.new-side-component.type-crypto{
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.new-side-component.type-crypto .sidepane-details__body >div:nth-child(5){
  margin-bottom: 1rem;
}

.ap-sidepane {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.sidepane-details__lwt-container {
  padding: 0 1rem;
  margin-right: 1rem;
  display: block;
  height: auto;
}

.sidepane-details__lwt-container:nth-child(4) {
  display: block;
  padding: 1rem 1rem;
  height: auto;
}

.sidepane-details__lwt-container:nth-child(4) .ap--label {
  font-size: 1.2rem;
}

.sidepane-details__lwt-container:first-child {
  color: white;
}

.sidepane-details__lwt-container:first-child .ap--label {
  margin-top: 3em;
  padding: 0 0 1.5rem 1.5rem;
  font-size: 1.4rem;
  margin-left: -1.5rem;
  color: var(--pc_font-primary) !important;
}

.sidepane-details__lwt-container:first-child .sidepane-details__lwt-text {
  color: Black;
  float: right;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 4.2rem;
  color: var(--pc_font-primary) !important;
}

.sidepane-details__lwt-text {
  font-weight: 400;
  font-size: 12px;

  letter-spacing: -0.4px;

  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .App.container > h1 {
    margin: 7rem auto 0;
  }
}

@media (max-width: 760px) {
  .standalone-modal__container {
    width: 100%;
  }

  .standalone-modal__body .standalone-modal__body-text {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1280px) {
  .trading-layout__chart-tabs-container {
    height: auto;
  }

  .order-history__tab {
  }
}

@media only screen and (min-width: 1025px) {
  .menu_hover .page-header__header-item--logo .ap-logo {
    width: 15rem;
  }

  .menu_hover:hover + .dashboard-page__title {
    display: none;
  }
}

@media only screen and (max-width: 968px) {
  .instrument-row .instrument-table .instrument-table__row {
    border-bottom: none;
  }
}

@media only screen and (max-width: 1024px) {
  #root .container.logged {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0rem !important;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1121px;
  }
}

@media only screen and (max-width: 1024px) {
  .page-header-nav__menu-toggle {
    position: relative;
    width: var(--nav-header__height);
    -ms-flex-pack: center;
    justify-content: center;
    right: 0;
    display: -ms-flexbox;
    display: flex;
  }

  .page-header-nav__item:not(.page-header-nav__item--selected) {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: var(--pc__background-secondary_v2);
    padding: 1.8rem 2rem !important;
    height: 0rem !important;
  }

  .page-header-nav__menu-toggle {
    border-right: none;
  }

  .page-header-nav__menu-toggle:hover,
  .page-header-nav__menu-toggle:focus {
    color: white;
  }

  .page-header-nav__icon--menu-toggle svg {
    margin-top: 0.2rem;
    display: block;
  }

  .page-header__right-panel {
    z-index: 500;
    height: 6.2rem;
  }

  .page-header__right-panel .popover {
    background: transparent;
  }

  .user-summary__popover-menu-trigger,
  .page-header__right-panel .popover {
    background: transparent;
  }

  .page-header__right-panel .popover svg {
    fill: #575757;
    color: #575757;
  }

  .alert-top-bar {
    text-align: left;
  }

  .alert-top-bar > p {
    display: flex;
  }

  .alert-top-bar > p > a {
    height: 4.5rem;
    text-align: center;
    margin: -1.5rem 0rem 0 6rem;
  }
}

@media only screen and (max-width: 1024px) {
  .page-header-nav__menu-toggle {
    display: inline;
  }

  .page-header-nav__container {
    order: -1;
    width: 100%;
  }

  .page-header__header-item--logo {
    order: 0;
  }

  .incongito_joyride {
    left: 12rem;
    top: 18px;
  }
}
.menuly-child{
  margin-top: 0;
}
@media screen and (min-width: 1024px) {
  .menuly-child:hover .__react_component_tooltip {
    visibility: hidden;
    display: none;
    opacity: 0;
  }
  .menuly-child{
    margin-top: 10.3rem;
  }
}

@media screen and (max-width: 1024px) {
  .page-header__header-item--logo {
    background: transparent;
    padding-top: 1.5rem;
    margin-left: 4rem;
  }
}

@media screen and (max-width: 1320px) and (min-width: 200px) {
  .LevelBlock .LevelForSingle {
    padding: 20px 10px 20px 20px;
  }
}

@media screen and (max-width: 1249px) and (min-width: 530px) {

  .LevelBlock .LevelForSingle {
    min-width: 70%;
    width: 48%;
    margin-bottom: 2rem;
  }

  .LevelBlock {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 529px) {
  .LevelBlock {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding-bottom: 1rem;
  }

  .LevelBlock .LevelForSingle {
    min-width: 88%;
    width: 88%;
    background-size: 22%;
  }

  .LevelBlock .LevelForSingle .PendingVerifiedStatus {
    margin-top: 0em;
    padding: 1rem 2rem !important;
  }
}

@media screen and (max-width: 529px) and (min-width: 200px) {
  .transfer-request-notification-list__empty {
    padding: 0.5rem 1.5rem;
    height: 6rem;
  }
}

@media only screen and (max-width: 990px) {
  .banexcoin_marketview .market-data-cards__card-banex {
    width: 47%;
  }

  .banexcoin_marketview,
  .dashboard-page__market-overview-container-left {
    margin-right: 10px;
  }

  .marketview_container_banex {
    height: 90%;
  }

  .banexcoin_marketview {
    overflow-y: scroll;
  }

  .dashboard-page__market-overview-container-right {
    width: 31.2rem;
  }

  .right_info_marketview {
    width: 35rem;
  }

  .LevelBlock {
    margin: 0 0rem 2.5rem;
  }
}

@media only screen and (max-width: 720px) {
  .dashboard-page__market-overview-container-right {
    width: 100%;
  }

  .eotc-buy-sell-layout__make-transaction-col-1,
  .eotc-buy-sell-layout__make-transaction-col-2 {
    width: 100%;
  }

  .page-header-nav__item--selected .page-header-nav__label {
    font-weight: 600;
  }
  .page-header-nav__item .page-header-nav__label {
    font-size: 2.2rem;
  }

  .user-settings-page__container {
    padding: 10px;
    width: 100%;
  }

  .nav-menu-vertical {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 920px) {
  .settings-page__content .container {
    width: 100%;
    max-width: 100%;
  }

  .nav-menu-vertical__item {
    padding: 0 1rem 0 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .settings-menu__menu-toggle {
    background: var(--background-primary_v2);
    color: white;
  }
}

@media only screen and (max-width: 720px) {
  .settings-page__menu {
    margin-bottom: 6rem;
  }

  .nav-menu-vertical {
    padding: 0 0px;
  }
}

@media only screen and (max-width: 480px) {
  .nav-menu-vertical__list {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.37);
  }
}

@media only screen and (max-width: 575px) {
  .alert-top-bar > p > a {
    margin: -1.5rem 0rem 0 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .standalone-layout__wrapper div.login-form__container {
    width: 100%;
  }

  .login-form__form {
    width: calc(100% - 4rem);
    margin: 3rem 2rem 0;
  }

  .standalone-layout__wrapper div.login-form__container:before {
    width: 100%;
  }

}

.top-info-bar {
  background: #e9d916;
  padding: 1rem;
  text-align: center;
  font-size: 1.2rem;
  color: #303030;
}

@media only screen and (max-width: 1024px) {
  .user-summary__icon {
    display: block;
  }

  .user-summary__username-in-display {
    display: none;
  }

  .page-header__right-panel {
    -ms-flex-order: 3;
    order: 3;
    -ms-flex-positive: unset;
    flex-grow: unset;
    position: absolute;
    right: 0;
    height: var(--nav-header__height);
  }

  .page-header-nav__container {
    -ms-flex-order: 1;
    order: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: var(--z-index__navigation);
    position: absolute;
    height: var(--nav-header__height);
    -ms-flex-positive: unset;
    flex-grow: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
    padding-top: 1rem;
  }

  .page-header__header-item {
    -ms-flex: 1 1;
    flex: 1 1;
  }

  .top-info-bar + .my-header {
    margin-top: 99px;
  }
}

.onepage-warning {
  background: white;
  height: 100%;
}

.onepage_image_content {
  max-width: 400px;
  margin: 2rem auto 0;
  text-align: center;
  vertical-align: middle;
}

.onepage_image_content h1 {
  margin-top: 1rem;
}

.onepage_image_content h3 {
  font-weight: 400;
  font-size: 1.5rem;
}

.onepage_image_content img {
  width: 30%;
  margin: 1rem auto 2rem;
}

.onepage_image {
  display: block;
  background-repeat: no-repeat;
  width: 341px;
  height: 262px;
  margin: 0 auto;
}

.onepage_image {
  background-image: url(/local/landing-page/images/oneTab.png);
  background-size: contain;
  background-position: center;
}

.twoimgs > a,
.twoimgs > img {
  display: inline-block;
}

.twoimgs > a > img {
  filter: invert(100%);
}

.twoimgs img {
  width: 50%;
}

.send-address__section .form-group select[name="crypto_code"] {
  font-size: 1.4rem;
}

.send-address__section .form-group input[name="address"],
.send-address__section .form-group input[name="label"] {
  font-size: 1.4rem;
}

.send-address__section .form-group input[name="address"]:-webkit-autofill,
.send-address__section .form-group input[name="label"]:-webkit-autofill,
.send-address__section .form-group input[name="address"]:-webkit-autofill:hover,
.send-address__section .form-group input[name="label"]:-webkit-autofill:hover,
.send-address__section .form-group input[name="address"]:-webkit-autofill:focus,
.send-address__section .form-group input[name="label"]:-webkit-autofill:focus,
.send-address__section
  .form-group
  input[name="address"]:-webkit-autofill:active,
.send-address__section .form-group input[name="label"]:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--pc-inputs-background) inset !important;
}

.crypto-invalid .valid-feedback,
.operation-invalid .valid-feedback,
.alias-invalid .valid-feedback,
.address-invalid .valid-feedback,
.email-invalid .valid-feedback {
  color: #ee3131;
  font-weight: 500;
  font-size: 1.2rem;
}

.Toastify__toast {
  border-radius: 0.7rem;
}

.Toastify__toast .Toastify__close-button {
  align-self: center;
}

.Toastify__toast.Toastify__toast--success.notify-message {
  background: #fffbe7;
  border-radius: 0.5rem;
}

.Toastify__toast.Toastify__toast--success.notify-message .Toastify__toast-body {
  color: #d54731;
  font-size: 1.6rem;
  text-align: justify;
}

.activity-pagination__page {
  color: #585858;
}

.activity-pagination__text--disabled {
  background: #ececec;
  color: #585858;
}

.container-lock {
  position: relative;
}

.container-lock.ready .overlay-lock {
  display: none;
}

.container-lock .overlay-lock {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  backdrop-filter: blur(2px);
  text-align: center;
}

.container-lock .overlay-lock .spinner-grow {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 5rem;
  height: 5rem;
}

.lateral-image {
  align-content: center;
  align-items: center;
  display: flex;
}

.lateral-image > img {
  float: unset !important;
  width: 100% !important;
}

.lateral-right p {
  font-size: 1.4rem;
}

.lateral-right p a {
  color: #fe9015;
  font-size: inherit;
}

.lateral-right p a:hover {
  opacity: 0.8;
}

@media screen and (max-width: 600px) {
  .react-confirm-alert-overlay .react-confirm-alert-body {
    margin: 0 auto;
  }
}

@media screen and (max-width: 450px) {
  .react-confirm-alert-overlay .react-confirm-alert-body {
    width: 100%;
    margin: 0 auto;
  }
}

.validation-phone {
  padding: 2.5rem 2rem;
}

.validation-phone.banner {
  background-color: transparent;
}

.validation-phone.banner h1 {
  color: rgb(41, 41, 41);
  font-weight: 600;
}

body #root .dark .validation-phone.banner h1 {
  color: rgb(238 238 238);
}
.validation-phone.banner {
  max-width: 500px;
  width: 100%;
}
.validation-phone.banner p {
  font-weight: 500;
  font-size: 1.2rem;
}

body #root .dark .validation-phone.banner p {
  color: var(--pc_font-primary);
}

.validation-phone.banner p.subtitle-information {
  font-size: 15px;
  font-weight: 400;
  margin-top: 2rem;
  color: var(--text-light);
}

.validation-phone.banner p.label-phone-form {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 2px;
}

.validation-phone.banner .btn-link {
  font-weight: 400;
  font-size: inherit;
  margin: 1rem 0;
  display: block;
  color: rgb(31, 31, 31);
  text-decoration: none;
}

.validation-phone.banner .btn-link > b {
  cursor: pointer;
  color: #fa8125;
}

.validation-phone.banner .btn-link > b:hover {
  opacity: 0.7;
  text-decoration: none;
}

body #root .dark .validation-phone.banner .btn-link {
  color: rgb(230, 230, 230);
}

.validation-phone.banner .form-control.is-invalid {
  border-color: #dc3545 !important;
}

.validation-phone.banner .form-control.confirm-phone {
    padding: 12px 10px 5px 47px !important;
    height: 50px;
    letter-spacing: 0.6rem;
    font-size: 15px;
    border-radius: 17px;
    background-image: none;
}

body #root .dark .validation-phone.banner .form-control.confirm-phone,
body #root .dark .validation-phone.banner .form-control.confirm-twofacode {
  background-color: transparent;
  color: white;
}

.validation-phone.banner .form-control.confirm-twofacode {
  height: 78px;
  font-size: 2.2rem;
  border-radius: 0 0 17px 17px;
  border: 2px solid #dedede;
  border-top: none;
  padding: 18px 1.75rem 5px 3.375rem;
  letter-spacing: 0.5rem;
  background-image: none;
}

.validation-phone.banner .form-control.confirm-phonecode {
  height: 78px;
  font-size: 2.2rem;
  border-radius: 17px 17px 0 0;
  border: 2px solid #dedede;
  padding: 18px 1.75rem 5px 3.375rem;
  letter-spacing: 0.5rem;
  background-image: none;
}

body #root .dark .validation-phone.banner .form-control.confirm-phonecode {
  background: transparent;
  color: white;
}

.validation-phone.banner .form-control.confirm-phone:-webkit-autofill,
.validation-phone.banner .form-control.confirm-phone:-webkit-autofill:hover,
.validation-phone.banner .form-control.confirm-phone:-webkit-autofill:focus,
.validation-phone.banner .form-control.confirm-phone:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--pc-inputs-background) inset !important;
}

.validation-phone.banner .flag-dropdown {
  border-right: none;
  border-radius: 17px 0 0 0;
  background: transparent;
  border-color: transparent !important;
}

.validation-phone.banner .form-control.confirm-phone,
.validation-phone.banner .flag-dropdown {
  border-width: 2px;
  border-color: #dedede;
}

.validation-phone.banner .selected-flag {
  padding: 0 10px 0 15px;
  width: 50px;
  background: transparent;
}

.validation-phone.banner .flag-dropdown.open .selected-flag {
  background: transparent;
}

.validation-phone.banner .country-list {
  border-radius: 10px;
  margin: 0px 0 10px -1px;
}

.validation-phone.banner .country-list > .country {
  text-align: left;
  background-color: white;
  color: black;
}

.validation-phone.banner .country-list > .country:hover {
  background-color: rgb(247, 247, 247);
}

.validation-phone.banner .country-list > .search {
  display: flex;
  padding: 10px 5px 5px;
}

.validation-phone.banner .country-list > .search .search-emoji {
  line-height: 3;
}

.validation-phone.banner .country-list > .search .search-box {
  width: 100%;
  border-radius: 10px;
  padding: 10px 10px;
}

.validation-phone.banner .btn-phone {
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  background: var(--primary-color-v2);
  border: none !important;
  border-radius: 2rem;
  box-shadow: none !important;
}
.validation-phone .col-md-8.offset-md-2.p-0.form-group{
  margin: 0;
}
.validation-phone .subtitle-footer{
  margin: 0;
  display: flex;
  justify-content: space-around;
}
.validation-phone .error-msg{
  margin-top: 1rem;
  color: var(--error-color-v2)!important;
}
.validation-phone .pt-5.pl-0.pr-0.col-md-8.offset-md-2.col{
  margin-top: 1rem;
}
.validation-phone.banner .btn-phone:not(:disabled):not(.disabled):active {
  opacity: 0.9;
  border: none !important;
  background: var(--primary-color-v2);
}

.validation-phone.banner .btn-phone:focus {
  opacity: 0.9;
  border: none !important;
}

.validation-phone.banner .btn-phone:hover {
  opacity: 0.9;
  border: none !important;
}

button.btn-phone.btn.btn-primary:disabled {
  opacity: 0.6;
}

.validation-phone.banner .form-group .form-label {
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 1.1em;
  position: absolute;
  z-index: 1;
  left: 15px;
  text-transform: uppercase;
  pointer-events: none;
  transition: none;
}

.validation-phone.banner .form-group .react-tel-input .selected-flag .flag {
  top: 54%;
}

.validation-phone.banner .form-group .invalid-feedback {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.3;
}

body #root .dark .validation-phone.banner .form-group .invalid-feedback {
  color: #f46a78 !important;
}

.validation-phone.banner .success-animation {
  margin: 0 auto 1rem auto;
}

.validation-phone.banner .success-animation .checkmark__circle {
  fill: #f8f8f8;
}

body
  #root
  .dark
  .validation-phone.banner
  .success-animation
  .checkmark__circle {
  fill: #353a36;
}

.validation-phone.banner .success-animation .checkmark {
  right: 0px;
  left: 0px;
}

body #root .dark .loading-overlay.bnx, 
body .loading-overlay {
  background-color: rgba(45, 45, 45, 0.4);
}

.twofa-message {
  position: relative;
}

.twofa-message button{
  background-color: var(--primary-color-v2)!important;
}
.twofa-message > img {
  width: 40%;
}

.react-confirm-alert.confirm-phone-box .react-confirm-alert-body {
  padding: 0px 15px 28px;
  text-align: center;
}

.react-confirm-alert.confirm-phone-box .react-confirm-alert-body h1 {
  text-align: left;
  font-size: 1.8rem;
  margin-bottom: 2.2rem;
}

.react-confirm-alert.confirm-phone-box .react-confirm-alert-body h3 {
  color: rgb(22, 22, 22);
  font-size: 14px;
}

.react-confirm-alert.confirm-phone-box .react-confirm-alert-body .phone-number {
  color: white;
  font-size: 2.4rem;
  font-weight: 500;
}

.react-confirm-alert.confirm-phone-box .react-confirm-alert-button-group {
  text-align: center;
  margin-top: 25px;
}

.react-confirm-alert.confirm-phone-box .button-footer-group {
  display: inline-flex;
  margin: 0 auto;
}

.btn-confirm-alert,
.btn-close-alert {
  padding: 1.2rem 1.6rem;
  background: var(--secondary-color-v2);
  color: var(--pc_font-primary);
  font-weight: 600;
  font-size: 1.4rem;
  border-radius: 1.6rem;
  width: 100px;
}

.btn-confirm-alert:hover,
.btn-close-alert:hover {
  opacity: 0.9;
}

.btn-confirm-alert:focus,
.btn-close-alert:focus {
  outline: none;
}

.btn-confirm-alert {
  background: var(--primary-color-v2);
  color: var(--pc_font-primary);
  margin-right: 0.5rem;
}

.btn-confirm-alert:hover {
  opacity: 0.9;
}

@media screen and (max-width: 1200px) {
  .modalAlertBeta .body_scroll {
    padding: 5px 1rem 30px !important;
  }

  .lateral-image > img {
    width: 50% !important;
    margin: auto;
  }

  .modalAlertBeta .react-confirm-alert-body h1 {
    margin-top: 2rem;
  }
}

.message-topbox {
  padding: 1.5rem 2rem;
}

.message-topbox.warning {
  background-color: #f8da25;
}

@media only screen and (max-width: 880px) {
  .message-topbox {
    padding: 1.5rem 2rem 3rem;
    white-space: nowrap;
    overflow: hidden;
  }

  .message-topbox .message-topbox--text {
    display: block;
    position: absolute;
    width: auto;
    left: 0;
    animation: marquee__wrap 10s linear infinite;
  }

  @keyframes marquee__wrap {
    from {
      margin-left: 100%;
    }

    to {
      margin-left: 0%;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .menuly-desactivado {
    top: 10.2rem !important;
    height: calc(100% - 10.2rem) !important;
  }
}

/* step coink */
.animated {
  animation-duration: 0.8192s;
  animation-fill-mode: backwards;
  transform-style: preserve-3d;
}

/** intro */
@keyframes intro {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(0, 0, -50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.intro {
  animation: intro 1s ease-out;
}

/** enterRight */
@keyframes enterRight {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterRight {
  animation-name: enterRight;
}

/** enterLeft */
@keyframes enterLeft {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterLeft {
  animation-name: enterLeft;
}

/** exitRight */
@keyframes exitRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(100%, 0, -100px);
  }
}

.exitRight {
  animation-name: exitRight;
}

/** exitLeft */
@keyframes exitLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(-100%, 0, -100px);
  }
}

.exitLeft {
  animation-name: exitLeft;
}

.error-empty-bnx {
  background-color: white;
  margin: 2rem;
  padding: 3rem;
  border: 2px solid orange;
  border-radius: 1rem;
  font-size: 1.5rem;
}

.body-step-bnx {
  height: 100vh;
  overflow-x: hidden;
  padding: 0rem 0 2rem;
  display: flex !important;
  flex-flow: column;
}

.body-step-bnx.jc-c {
  height: 100% !important;
  justify-content: center;
}

.body-step-bnx.just-cont-cent {
  justify-content: space-between !important;
}

.body-step-bnx .step-bnx {
  color: #373a39;
  font-size: 2rem;
  text-align: center;
  height: 100%;
  padding: 0 0.5rem 2rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.body-step-bnx .step-bnx .poster_cover {
  width: auto;
  margin: 2.5rem auto;
  display: block;
}

.body-step-bnx .step-bnx .parr-spc > div {
  font-size: 2rem;
}

.body-step-bnx .buttons-footer {
  display: flex;
  flex-direction: column;
}

.body-step-bnx .btn.btn-bnx {
  border-color: transparent;
  border-radius: 1.6rem;
  padding: 1.3rem 0.75rem;
  margin: 1rem 0;
  font-size: 1.8rem;
  font-weight: 500;
}

.body-step-bnx .btn.btn-primary.btn-bnx {
  background-color: #ff9015;
}

.body-step-bnx .btn.btn-secondary.btn-bnx {
  background: var(--primary-color-v2);
  color: white;
}

.body-step-bnx .btn.btn-dark.btn-bnx {
  background-color: #202121;
  color: white;
}

.coink-app .top-header {
  text-align: center;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  width: 100%;
  padding: 0 2em;
  margin: auto;
  align-items: center;
}

.coink-app .top-header > img {
  width: 45px;
  margin: auto;
}


.body-step-bnx .simple {
  display: block;
  color: #707070;
  font-weight: 500;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 1.8rem;
}

.body-step-bnx .simple:hover {
  color: rgb(70, 70, 70);
}

.body-step-bnx .stepper-body {
  margin: 1.5rem auto 2.5rem;
}

.body-step-bnx .stepper-body > span {
  width: 13px;
  height: 13px;
  background-color: #f0f0f0;
  display: inline-block;
  margin-right: 1rem;
  border-radius: 1.5rem;
}

.body-step-bnx .stepper-body > span.here {
  background-color: #fd7f23;
}

.body-step-bnx .list-bnx {
  list-style: none;
  padding: 0;
  text-align: left;
}

.body-step-bnx .list-bnx > li {
  font-size: 1.5rem;
}

.body-step-bnx .ttl {
  font-weight: 700;
  font-size: 2.6rem;
}

.body-step-bnx .ttl.dark-bnx {
  color: #000000;
}

.body-step-bnx .ttl.orange-bnx {
  color: #ff7a00;
}

.body-step-bnx h3 {
  text-align: center;
  color: #373a39;
}

.body-step-bnx h3.loading-text {
  font-size: 2.7rem;
  color: #373a39;
}

.body-step-bnx h3 > b {
  color: var(--text-light);
  font-weight: inherit;
}

.body-step-bnx .dashboard h3 {
  font-weight: 600;
  font-size: 2.2rem;
}

.body-step-bnx .dashboard h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

.body-step-bnx .bnx-form {
  padding: 1rem 1.5rem 1rem;
  position: relative;
}

.body-step-bnx .bnx-form .btn-bnx {
  width: 100%;
}

.body-step-bnx .error-bnx {
  color: #fd365f;
  font-size: 1.3rem;
  font-weight: 600;
}

.body-step-bnx .coink-nav {
  width: 100%;
  flex-wrap: unset;
  position: relative;
  height: 0px;
  background-color: black;
}

.body-step-bnx .coink-nav > .nav-item {
  width: 100%;
  display: block;
}

.body-step-bnx .coink-nav > .nav-item.dropdown {
  width: auto;
}

.body-step-bnx .coink-nav > .nav-item .nav-link.dropdown-toggle {
  padding: 1rem 1.5rem 1rem;
  color: white;
}

.body-step-bnx
  .coink-nav
  > .nav-item
  .nav-link.dropdown-toggle
  > svg.svg-inline--fa {
  font-size: 3rem;
}

.body-step-bnx .coink-nav > .nav-item.show > .nav-link.dropdown-toggle {
  background-color: transparent;
}

.body-step-bnx .coink-nav > .nav-item .nav-link {
  padding: 1rem 1rem 1rem;
  color: white;
}

.body-step-bnx .coink-nav > .nav-item .dropdown-menu {
  background-color: white;
  margin-right: 3px;
}

.body-step-bnx .coink-nav > .nav-item .dropdown-menu button,
.body-step-bnx .coink-nav > .nav-item .dropdown-menu a {
  color: black;
  text-transform: inherit;
  font-size: 1.3rem;
}

.body-step-bnx .coink-nav > .nav-item .dropdown-menu a:hover {
  background: rgba(203, 203, 203, 0.23);
}

.body-step-bnx .top-rows .tlt {
  font-size: 1.3rem;
  color: #373a39;
  font-weight: 600;
}

.body-step-bnx .top-rows .tlt-value {
  font-size: 1.6rem;
  color: #373a39;
  font-weight: 700;
}

.body-step-bnx .top-rows .btn-top > div:first-child {
  padding-right: 10px;
}

.body-step-bnx .top-rows .btn-top > div:last-child {
  padding-left: 10px;
}

.body-step-bnx .top-rows .btn-top .btn {
  width: 100%;
  padding: 1.175rem 1.55rem;
  font-size: 1.4rem;
  border-radius: 1.7rem;
  border: none;
  font-weight: 600;
}

.body-step-bnx .top-rows .btn-top .btn.btn-selected.status-buy {
  background-color: #00b871;
  color: white;
}

.body-step-bnx .top-rows .btn-top .btn.btn-selected.status-sell {
  background-color: #fc5454;
  color: white;
}

.body-step-bnx .top-rows .btn-top .btn.btn-secondary {
  background-color: #f5f7f9;
  color: #202121;
}

.body-step-bnx .bnx-bg-closed {
  position: absolute;
  z-index: 99;
  background: rgb(191 191 191 / 70%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 1.5rem;
}

.body-step-bnx .bnx-bg-closed > .bnx-text {
  color: white;
  font-weight: 600;
  font-size: 1.3rem;
  width: 80%;
  margin: 28% auto;
  padding: 1.5rem;
  border: 2px dashed #454545;
  border-radius: 1.5rem;
  background: #ff912c;
}

.body-step-bnx .step-bnx .error-description {
  color: #ff4248;
}

.body-step-bnx .spinner-bnx.text-primary {
  color: #fd9025 !important;
  font-size: 4rem !important;
  width: 4rem;
  height: 4rem;
}

.body-step-bnx .bg_block-bnx {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.body-step-bnx .censor-balance {
  cursor: pointer;
  margin-left: 0.5rem;
  color: #7f7d7d;
  font-size: 1.9rem;
}

.body-step-bnx .censor-balance:hover {
  color: #585757;
}

.body-step-bnx .censored-text {
  font-size: 2.9rem;
  font-weight: 600;
  margin-bottom: -19px;
  display: block;
}

.body-step-bnx.jc-t {
  justify-content: flex-start;
  padding: 1.5rem 1.5rem;
  height: auto;
}

.body-step-bnx.jc-t2 {
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  height: 100%;
}

.body-step-bnx.jc-t.super-pb {
  padding: 1.5rem 1.5rem 4.5rem 1.5rem;
}

.form-group.bnx-field.dash .form-control {
  border: 2px solid #202121;
  background-color: white;
  font-size: 1.4rem;
  font-weight: 600;
}

.form-group.bnx-field.dash .form-control::placeholder {
  color: #202121;
}

.form-group.bnx-field.dash .form-control:hover,
.form-group.bnx-field.dash .form-control:focus {
  border: 2px solid #ff7800;
  box-shadow: none;
}

.form-group.bnx-field.dash .form-control:read-only {
  cursor: default;
  background-image: none;
}

.form-group.bnx-field {
  padding: 0.5rem 0 1rem;
}

.form-group.bnx-field .field-control {
  position: relative;
}

.form-group.bnx-field .form-label {
  color: #202121;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.form-group.bnx-field .form-control {
  background-color: #f5f7f9;
  border-radius: 11pt;
  border: 1px solid transparent;
  height: 5rem;
  padding: 0.375rem 1.75rem;
}

.form-group.bnx-field .form-control.is-invalid {
  background-position: right calc(0.375em + 4.5rem) center !important;
  background-size: calc(0.75em + 1.875rem) calc(0.75em + 1.875rem) !important;
}

.form-group.bnx-field .form-control.is-invalid:valid {
  border-color: #fd365f;
}

.form-group.bnx-field .field-control > img.icon {
  position: absolute;
  font-size: 2.5rem;
  right: 2rem;
  top: 14px;
  pointer-events: none;
  width: 23px;
  opacity: 0.95;
}

.form-group.bnx-field .invalid-feedback {
  margin-top: 1.1rem;
  font-size: 1.3rem;
  color: #fd365f !important;
  font-weight: 500;
}

body #root .form-group.bnx-field .field-control > small.text-muted.form-text {
  margin-top: 1.2rem;
  color: #3a3c39 !important;
}

.rsw_2f {
  display: none;
}

.rsw_2f.rsw_3G {
  display: block;
}

.characters {
  height: 50px;
}

.character {
  line-height: 50px;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: var(--pc__background-secondary_v2);
  border: 2px solid var(--btn-checked-v2);
  border-radius: 15px;
  color: var(--pc_font-primary);
  margin-left: 4px;
  margin-right: 4px;
  text-align: center;
}

.character--inactive {
  background-color: var(--pc__background-secondary_v2);
  box-shadow: none;
}

.character--selected {
  border: 2px solid #ff7a00;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  margin: 3rem 2rem 0 0;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media only screen and (max-width: 1024px) {
  .video-responsive {
    margin: 3rem 2rem 0;
  }
}

@media only screen and (max-width: 445px) {
  .body-step-bnx .step-bnx .poster_cover {
    width: 55%;
  }

  .body-step-bnx .step-bnx {
    font-size: 1.5rem;
  }

  .body-step-bnx .step-bnx .parr-spc > div {
    font-size: 1.5rem;
  }

  .body-step-bnx .btn.btn-bnx {
    padding: 1.1rem 0.75rem;
    font-size: 1.5rem;
  }
}

.sidepane-details__information {
  padding: 0rem 1.5rem 0 1.5rem;
  text-align: justify;
}

.sidepane-details__information.sidepane-details__information--box-deposit-bnx
  .sidepane-details__information-text--deposit:nth-child(2n + 1) {
  font-weight: inherit;
}

.sidepane-details__information.sidepane-details__information--box-deposit-bnx
  .sidepane-details__information-text--deposit {
  margin: 0 0 1.5rem 0;
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: justify;
}

.sidepane-details__information.sidepane-details__information--box-deposit-bnx
  .sidepane-details__information-text--deposit:nth-child(1):before {
  color: #373a39 !important;
  font-weight: 400;
  content: "1\A0\A0\A0-\A0";
  padding-right: 1rem;
}

.sidepane-details__information.sidepane-details__information--box-deposit-bnx
  .sidepane-details__information-text--deposit:nth-child(2):before {
  color: #373a39 !important;
  font-weight: 400;
  content: "2\A0\A0\A0-\A0";
  padding-right: 1rem;
}

.sidepane-details__information.sidepane-details__information--box-deposit-bnx
  .sidepane-details__information-text--deposit:nth-child(3):before {
  color: #373a39 !important;
  font-weight: 400;
  content: "3\A0\A0\A0-\A0";
  padding-right: 1rem;
}

/* clearfix hack */

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

/* close commented backslash hack */

/* Form V2 */
.container-form {
  padding: 0 10px 3rem;
}

.container-form > .row,
.container-form > .collapse > .row,
.container-form > .collapsing > .row {
  margin-top: 0.8em;
}

.container-form > .row > .col-xl-6,
.container-form > .row > .col-xl-12 {
  padding-right: 0px;
  padding-left: 0px;
}

.v2-field.form-group > .form-label {
  font-weight: 700;
  color: var(--pc_font-primary);
}

.v2-field.radius-group .form-check {
  margin-bottom: 0.8rem;
}

.v2-field.radius-group .form-check .form-check-label {
  color: var(--pc_font-primary);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7;
}

.v2-field.form-group > .form-control,
.v2-field.form-group > .oneline-box > .form-control {
  border: 2px solid transparent;
  background-color: transparent !important;
  background-image: none !important;
  border-radius: 1.5rem;
  height: 4.5rem;
  color: white;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 0.375rem 1.25rem;
}

.v2-field.form-group > select.form-control {
  padding: 0.375rem 1.4rem 0.375rem 1.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1.3em + 2px),
    calc(100% - 15.5px) calc(1.3em + 2px);
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

.v2-field.form-group > .form-control.react-datepicker-component {
  background-color: var(--pc-inputs-background)!important;
}

.v2-field.form-group
  > .form-control.react-datepicker-component
  .react-datepicker-input
  > input {
  padding: 0;
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 1.3rem;
  color: white;
}

.v2-field.form-group
  > .form-control.react-datepicker-component
  .react-datepicker-input
  > input[type="date"]::-webkit-inner-spin-button,
.v2-field.form-group
  > .form-control.react-datepicker-component
  .react-datepicker-input
  > input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.v2-field.form-group
  > .form-control.react-datepicker-component
  .react-datepicker-input
  > input::placeholder {
  font-weight: 500;
  font-size: 1.3rem;
}

.v2-field.form-group
  > .form-control.react-datepicker-component
  .react-datepicker.floating {
  right: 0;
}

.v2-field.form-group > .form-control.btn {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: var(--tertiary-color-v2) !important;
}

.v2-field.form-group.with-icon > .oneline-box {
  display: flex;
  border-radius: 1.5rem;
  background-color: var(--pc-inputs-background);
  border: 2px solid transparent !important;
  transition: 300ms all;
}

.v2-field.form-group.with-icon > .oneline-box > .form-control {
  border-radius: 1.5rem 0 0 1.5rem;
}

.v2-field.form-group.nooneline > .form-control {
  background-color: var(--pc-inputs-background) !important;
}
.v2-field.form-group.nooneline > .form-control::placeholder{
  color: var(--text-gray-v2);
}

.v2-field .label-inside {
  display: none;
}

.v2-field.inside-container .label-inside {
  display: block;
  position: absolute;
  margin-top: 1.4rem;
  margin-left: 1.358em;
  pointer-events: none;
}

.v2-field.inside-container .form-control {
  padding: 1.575rem 1.25rem 0.225rem !important;
  height: 5.8rem !important;
  line-height: 1.9;
}

.v2-field.form-group.nooneline .MuiFormControl-root {
  width: 100%;
}

.v2-field.form-group.nooneline .MuiFormControl-root .MuiInput-underline:before,
.v2-field.form-group.nooneline .MuiFormControl-root .MuiInput-underline::after,
.v2-field.form-group.nooneline .MuiFormControl-root input[type="text"] {
  border-bottom: none;
}

.v2-field.form-group.nooneline .MuiFormControl-root input[type="text"] {
  border: 2px solid transparent;
  background-color: #f5f7f9 !important;
  padding: 0.375rem 1.4rem 0.375rem 1.25rem;
  font-weight: 500;
  font-size: 1.3rem;
  border-radius: 1.5rem;
  height: 3.35rem;
  font-family: "Montserrat", Sans-serif;
}

.v2-field.form-group.nooneline .MuiFormControl-root input[type="text"]:hover {
  background-color: #e8eaec !important;
  border: 2px solid #202121 !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: #fd873a !important;
}

.MuiPickersToolbar-toolbar .MuiButton-root .MuiTypography-subtitle1 {
  font-size: 1.5rem;
}

.MuiPickersCalendarHeader-switchHeader .MuiTypography-body1 {
  font-size: 1.4rem;
}

.MuiPickersCalendar-week .MuiIconButton-label .MuiTypography-body2 {
  font-size: 1.5rem;
}

.v2-field.form-group.with-icon > .oneline-box > .form-control:-webkit-autofill {
  box-shadow: 0 0 0 30px #f5f7f9 inset;
}

.v2-field.form-group.with-icon
  > .oneline-box
  > .form-control:focus-within:-webkit-autofill {
  box-shadow: 0 0 0 30px #e8eaec inset;
}

.v2-field.form-group.with-icon
  > .oneline-box
  > .form-control:focus-within
  .oneline-box {
  background-color: #e8eaec;
}

form.was-validated .v2-field.form-group.nooneline.invalid > .form-control {
  border: 2px solid #fc5c5c !important;
}


form.was-validated
  .v2-field.form-group.with-icon
  > .oneline-box
  > .form-control,
form.was-validated
  .v2-field.form-group.with-icon
  > .oneline-box
  > .input-group-append {
  border-color: transparent !important;
  background: transparent;
}

form.was-validated
  .v2-field.form-group.invalid
  > .form-control.react-datepicker-component {
  border: 2px solid #fc5c5c !important;
}

.v2-field.form-group.with-icon > .oneline-box > .input-group-append {
  border-radius: 0 1.5rem 1.5rem 0;
  border: 2px solid transparent;
  background-color: transparent;
  margin-left: -2px;
  height: 4.5rem;
  transition: color 0.1s ease, background 0.1s ease, border 0.1s ease;
  margin-bottom: 0;
}

.v2-field.form-group.with-icon > .oneline-box > .input-group-append.selectable {
  cursor: pointer;
}

.v2-field.form-group.with-icon > .oneline-box > .input-group-append > span {
  border-radius: 0 1.5rem 1.5rem 0;
  border: none;
  background: none;
  padding: 1.15rem;
  height: 46px;
}

.v2-field.form-group.with-icon > .oneline-box > .input-group-append svg:first-child {
 color: #fff;
}
.v2-field.form-group.with-icon > .oneline-box > .input-group-append svg {
  width: 2rem;
}

.v2-field.form-group.invalid > .invalid-feedback {
  line-height: 1.2;
  margin-top: 0.55rem;
  color: #fc5c5c !important;
  cursor: default;
  font-weight: 450;
  display: block;
}

form.was-validated .v2-field.form-group.with-icon.invalid > .oneline-box {
  border: 2px solid #fc5c5c !important;
}

.label-bnxv2 {
  font-weight: 700;
  color: var(--pc_font-primary);
  font-size: 1.2rem;
}

.label-bnxv2 .important {
  color: #fd9025;
  font-size: 1.3rem;
  font-weight: 600;
}

.v2-field.form-group .form-check-input {
  display: none;
}

.v2-field.form-group .form-check-input[type="checkbox"] + *::before {
  border-radius: 0.5rem;
}

.v2-field.form-group .form-check-input[type="radio"] + *::before {
  border-radius: 2.5rem;
  line-height: 1.5;
  width: 2.2rem;
  height: 2.2rem;
}

.v2-field.form-group .form-check-input + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1.6rem;
  height: 1.6rem;
  border: 2px solid var(--btn-checked-v2);
  margin: 0 6px 0 -2.5rem;
}
.v2-field.invalid.form-group .form-check-input + *::before {
  border-color: var(--error-color-v2)!important;
}


.v2-field.form-group .form-check-input:hover + *::before {
  border: 2px solid var(--btn-checked-v2);
}

.v2-field.form-group .form-check-input:checked + *::before {
  content: "✓";
  color: white;
  text-align: center;
  background: #ff9015;
  border-color: #ff9015;
}

.v2-field.form-group .form-check-input:checked + * {
  color: var(--pc_font-primary);
}

form.was-validated .v2-field.form-group.invalid .form-check-input + *::before {
  border: 2px solid #fc5c5c;
}

form.was-validated .v2-field.form-group.valid .form-control,
form.was-validated .v2-field.form-group.valid .dropdown-toggle,
form.was-validated .v2-field.form-group.valid .input-group-append {
  background-color: #353652;
  border-color: #47c65d;
}

form.was-validated .v2-field.form-group.valid .form-control {
  border-color: #47c65d;
  border: 2px solid #47c65d !important;
}

form.was-validated .v2-field.form-group.valid input.form-control {
  background-image: none;
}

.v2-field.form-group > .btn-group {
  width: 100%;
}

.v2-field.form-group > .btn-group > .dropdown-toggle {
  background-color: var(--pc-inputs-background);
  border-radius: 1.5rem;
  height: 4.5rem;
  color: var(--pc_font-primary);
  text-align: left;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0.375rem 1.25rem;
}

.v2-field.form-group > .btn-group > .dropdown-toggle > .flex {
  width: 100%;
  display: flex;
  align-items: center;
}

.v2-field.form-group > .btn-group > .dropdown-toggle > .flex > span {
  text-align: right;
}

.v2-field.form-group > .btn-group > .dropdown-toggle > .flex > b {
  font-size: 1.2rem;
  font-weight: 500;
}

.v2-field.form-group > .btn-group > .dropdown-toggle > .flex > span > img {
  width: 50%;
  margin-left: auto;
  margin-right: 1.5rem;
}

.v2-field.form-group > .btn-group .dropdown-menu {
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 18%);
  width: 100%;
}

.v2-field.form-group > .btn-group .dropdown-menu > .dropdown-item {
  background-color: var(--pc__background-secondary_v2);
  color: black;
}

.v2-field.form-group > .btn-group .dropdown-menu > .dropdown-item:hover {
  background-color: var(--btn-checked-v2);
}

.v2-field.form-group
  > .btn-group
  .dropdown-menu
  > .dropdown-item
  > .item-selector-item {
  display: flex;
}

.v2-field.form-group
  > .btn-group
  .dropdown-menu
  > .dropdown-item
  > .item-selector-item
  > div {
  width: 50%;
}

.v2-field.form-group
  > .btn-group
  .dropdown-menu
  > .dropdown-item
  > .item-selector-item
  > .item-selector-title {
  padding: 0.7rem 0;
}

.v2-field.form-group
  > .btn-group
  .dropdown-menu
  > .dropdown-item
  > .item-selector-item
  > .item-selector-title
  span {
  display: block;
  margin-bottom: 0.7rem;
}

.v2-field.form-group
  > .btn-group
  .dropdown-menu
  > .dropdown-item
  > .item-selector-item
  > .item-selector-title
  > .title {
  font-weight: 600;
}

.v2-field.form-group
  > .btn-group
  .dropdown-menu
  > .dropdown-item
  > .item-selector-item
  > .item-selector-title
  > .subtitle {
  font-weight: 600;
  color: var(--pc_font-primary);
  font-size: 1.2rem;
}

.v2-field.form-group
  > .btn-group
  .dropdown-menu
  > .dropdown-item
  > .item-selector-item
  > .item-selector-logo {
  display: flex;
  align-items: center;
  text-align: right;
}

.v2-field.form-group
  > .btn-group
  .dropdown-menu
  > .dropdown-item
  > .item-selector-item
  > .item-selector-logo
  > img {
  width: 100px;
  margin-left: auto;
}

.v2-field.form-group > .form-control:hover,
.v2-field.form-group > .form-control:focus {
  box-shadow: none;
}

.v2-field.form-group > .form-label .important,
.bnx-v2 .important-solo {
  color: #fd9025;
}

.v2-field.form-group > .form-label .important,
.bnx-v2 .important-message-text {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: justify;
  color: var(--text-gray-v2);
}

.v2-field.form-group > .form-label .important,
.bnx-v2 .important-message-text {
  font-size: 1.4rem;
  font-weight: 400;
  color: rgb(168, 168, 199);
}
.important-message-text{
  display: list-item;
  margin-left: 13px;
}
.v2-field.form-group > .form-label .important,
.bnx-v2 .important-message u {
  font-size: 1.4rem;
  font-weight: 600;
  color: #ff9015;
}

.v2-field.form-group > .form-label .important,
.bnx-v2 .important-message-text u {
  font-size: 1.4rem;
  font-weight: 600;
  color: #ff9015;  
}

.v2-field.form-check {
  margin: 0 0 0.9rem 0;
}

.v2-field.form-check > .form-check-input {
  transform: scale(1.5);
}

.v2-field.form-check > .form-check-label {
  margin-left: 0.8rem;
  font-weight: 500;
  font-size: 1.4rem;
  margin-top: 1px;
}

.v2-field.form-check > .form-check-label > span {
  color: var(--pc_font-primary);
  line-height: 1.2;
}

.v2-field.form-check > .form-check-label > span > a {
  font-size: inherit;
  color: var(--pc_font-secundary);
}

.v2-field.form-check > .form-check-label > span > b.remarked {
  font-size: inherit;
  color: #e47018;
}

.v2-field.form-check > .form-check-label > span > a:hover {
  opacity: 0.7;
}

.v2-field.form-check > .form-check-label > span > b {
  color: var(--pc_font-primary);
  font-weight: 500;
}

.v2-field.btn-bnxv2 {
  padding: 1.4rem 1.75rem;
  border-radius: 1.55rem;
  font-size: 1.6rem;
  font-weight: 500;
  width: 100%;
}

.v2-field.btn-bnxv2.btn-success {
  background: var(--primary-color-v2);
  border: none;
  border-radius: 1.2rem;
}

.v2-field.btn-bnxv2.btn-dark,
.v2-field.btn-bnxv2.btn-light {
  background: var(--primary-color-v2);
  color: white !important;
  border: none;
}

.v2-field.btn-bnxv2:disabled {
  opacity: 0.5;
  cursor: default;
}

.v2-field.btn-bnxv2.btn-primary {
  background: var(--secondary-color-v2);
  border: none;
  color: white !important;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}

.v2-field.btn-bnxv2.btn-primary:hover {
  opacity: 0.9;
}

.bnx-v2.container-workflow .important-alert {
  background-color: #ffce00;
  padding: 1.5rem;
  border-radius: 1.6rem;
  text-align: center;
  font-weight: 600;
}

.bnx-v2.container-workflow .important-alert.danger {
  background-color: var(--pc__background-secondary_v2);;
  padding: 0;
  font-weight: 500;
  text-align: justify;
  color: #fc5c5c !important;
}

.bnx-v2.container-workflow .title-step {
  font-size: 1.3rem;
  font-weight: 650;
  color: var(--pc_font-primary);
}

.bnx-v2.container-workflow .subtitle-step {
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 0.7rem;
}

.bnx-v2.container-workflow h3.subtitle-step {
  font-size: 1.3rem;
  color: var(--text-gray-v2);
  font-weight: 400;
}

.bnx-v2.container-workflow .submit-button {
  width: 100%;
  padding: 1.3rem 1.25rem;
  font-size: 1.3rem;
  background: var(--primary-color-v2);
  border-radius: 1.5rem;
}
.bnx-v2.container-workflow .submit-button:hover, .bnx-v2.container-workflow .submit-button:focus{
  opacity: 0.9;
}
.bnx-v2.container-workflow .submit-button:disabled {
  opacity: 0.5 !important;
  cursor: no-drop;
}

.bnx-v2.container-workflow .btn-Withdraw {
  width: 100%;
  padding: 1.3rem 1.25rem;
  font-size: 1.3rem;
  background: var(--primary-color-v2);
  border-radius: 1.5rem;
  color: #fff;
  border-color: #343a40;
}
.bnx-v2.container-workflow .btn-Withdraw:hover{
  opacity: 0.9;
}
.bnx-v2.container-workflow .btn-Withdraw:disabled{
  opacity: 0.5;
  border: none;
  cursor: no-drop;
}

.bnx-v2.container-workflow .expand-button {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.8rem 1rem;
  font-size: 1.4rem;
  border-radius: 1.3rem;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 19px) calc(1.1em + 1px),
    calc(100% - 14.5px) calc(1.1em + 1px), calc(100% - 0.7em) 0.55em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  background-color: #9999B7;
}

.bnx-v2.container-workflow .btn.collapse-btn {
  position: absolute;
  top: -3px;
  right: 0px;
}

.bnx-v2.container-workflow .collapse,
.bnx-v2.container-workflow .collapsing {
  margin: 0 -15px;
}

.fiat-sidepane .fiat-sidepane__side.v2bnx-side .limit-details-title {
  font-size: 1.2rem;
  color: var(--text-gray-v2)!important;
}

.fiat-sidepane .fiat-sidepane__side.v2bnx-side .alert.alert-secondary {
  background-color: var(--pc__background-secondary_v2);
  border-color: var(--pc__background-secondary_v2);
  margin: 2rem 0;
  font-weight: 450;
  font-size: 1.3rem;
  text-align: center;
  color:var(--pc_font-primary);
}

.fiat-sidepane .tab-v2bnx {
  flex-direction: row;
  border: none;
  padding-top: 1.5rem;
  margin-top: 2rem;
  border-top: 1px solid var(--text-gray-v2);
}

.fiat-sidepane .tab-v2bnx a {
  width: 50%;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 660;
  border: none;
  position: relative;
  color: var(--text-gray-v2);
}

.fiat-sidepane .tab-v2bnx a.active {
  font-weight: 660;
}

.fiat-sidepane .tab-v2bnx a.active::after {
  background: #ff8d3f;
  height: 3px;
  border-radius: 4px;
  width: 20%;
  margin: 0 auto;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fiat-sidepane .v2bnx.details_bank {
  padding: 1rem 2rem 2rem 0;
  list-style: none;
}

.fiat-sidepane .v2bnx.details_bank li {
  text-transform: uppercase;
  color: var(--text-gray-v2);
  font-size: 1.2rem;
}

.fiat-sidepane .v2bnx.details_bank li b {
  text-transform: none;
  font-size: 1.3rem;
  color: white;
}

.fiat-sidepane
  .v2bnx.details_bank
  .title_rules_for_receiving_funds_in_this_account {
  font-weight: 400;
  margin: 1.5rem 0 0.5rem;
}
.fiat-sidepane
  .v2bnx.details_bank
  .title_rules_for_receiving_funds_in_this_account.sub_Tit{
  font-size: 1.05rem;
}

.fiat-sidepane
  .v2bnx.details_bank
  .children_rules_for_receiving_funds_in_this_account
  b {
  font-weight: 500;
}

.fiat-sidepane .v2bnx.details_bank .children_list {
  padding: 0.5rem 0;
  list-style: inside;
  color: rgb(168, 168, 199);
}

.fiat-sidepane .v2bnx.details_bank .children_list b {
  color: var(--pc_font-primary) !important;
  font-size: 1.3rem;
  font-weight: bolder;
}

.fiat-sidepane .v2bnx.details_bank.normal {
  padding: 1rem 2rem 0 2rem;
}

.fiat-sidepane .v2bnx.details_bank.normal li {
  text-transform: none;
  padding: 0.5rem 0;
  list-style: decimal;
  font-weight: 500;
  text-align: justify;
}

.fiat-sidepane .vnt-left {
  padding: 0 2rem 0 1.9rem;
  text-align: justify;
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--pc_font-primary);
}

.fiat-sidepane .vnt-left.title {
  font-size: 1.3rem;
  font-weight: 550;
  text-transform: uppercase;
}

@media only screen and (max-width: 1300px) {
  .fiat-sidepane {
    overflow-y: auto;
  }

  .fiat-sidepane .fiat-sidepane__side,
  .fiat-sidepane .fiat-sidepane__main {
    overflow-y: unset;
  }

  .fiat-sidepane .fiat-sidepane__main {
    border-right: none;
  }
  
}
@media only screen and (max-width: 890px) {
  .app-modal-help-text{
    top: 45px !important;
    right: 15px !important;
  }
}

.item-selector-title.selected .title {
  display: block;
}

.item-selector-title.selected .subtitle {
  display: block;
}

.item-selector-title.selected-right .title {
  display: block;
}

.item-selector-title.selected-right .subtitle {
  display: block;
}

.v2-field.form-group > .btn-group > .dropdown-toggle > .flex.selected-flex {
  display: flex;
  justify-content: space-between;
}

.crypto-address-v2 {
  display: grid;
  grid-template-columns: 370px auto;
  gap: 2.5rem;
  margin-top: 0;
  margin-bottom: 0;
  background: var(--pc__background-secondary_v2);
  padding: 2rem;
}
.crypto-address-v2 .crypto-left-section {
  margin: 0;
}
.crypto-address-v2 .crypto-left-section > p,
.crypto-initial-title {
  margin-top: 0;
  margin-bottom: 2rem;
}
.crypto-address-v2 .crypto-left-section label {
  font-size: 1.1rem;
  margin-bottom: 1.6rem;
}
.crypto-address-v2 .crypto-left-section b, 
.crypto-address-v2 .crypto-left-section .subtitle {
  font-size: 1.1rem !important;
}
.crypto-address-v2 .crypto-left-section label p{
  color: var(--btn-checked-v2);
}
.crypto-address-v2 .crypto-right-section .addressV2 {
  font-size: 1.3rem;
  margin: 0;
  flex-wrap: wrap;
  align-items: center;
}
.crypto-address-v2 .crypto-right-section .addressV2 .cryptoAddress {
  font-size: 1.4rem;
}
.crypto-address-v2 .crypto-right-section .addressV2 > span:first-child {
  font-size: 1.1rem;
  color: var(--btn-checked-v2);
  margin-right: 1rem;
}
.crypto-address-v2 .crypto-right-section .addressV2 span:last-child > span {
  margin: 0 0 0 10px;
  position: relative;
  top: -2px;
}
.crypto-address-v2 .crypto-right-section .qr-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}
.crypto-address-v2 .crypto-right-section .qr-section p {
  font-size: 1.1rem;
  margin: 0;
}
.crypto-address-v2 .crypto-right-section .qr-section div {
  margin: 0;
}
.crypto-address-v2 .crypto-right-section .qr-section div canvas {
  margin: 0;
  height: 120px !important;
  width: 120px !important;
  padding: 1rem;
}
.only-item button {
  pointer-events: none;
}
.only-item button::after {
  display: none;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, 
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):focus, .show>.btn-primary.dropdown-toggle {
  border-color: #f28e2a !important;
  box-shadow: none !important;
}
.product-text-v2 {
  color: var(--text-gray-v2);
  font-size: 1.2rem;
  margin-top: 2rem;
}
.description-text-v2 {
  font-size: 12px;
}
.address-text {
  font-size: 1.4rem;
}
@media screen and (max-width: 1380px) {
  .crypto-address-v2 {
    grid-template-columns: auto;
  }
}
@media only screen and (max-width: 768px) {
  .selected.dropdown.btn-group {
    width: 100%;
  }
  .modal-step-level .modal-dialog .modal-content .modal-container-step-level form .h-auto.row .col-xl-6{
    display: flex;
    flex-wrap: wrap;
    text-align: center !important;
  }
  .modal-step-level .modal-dialog .modal-content .modal-container-step-level form .h-auto.row .col-xl-6 button{
    margin: auto !important;
  }
}

p.warning-red {
  font-weight: normal;
  padding-top: 14px;
  margin-bottom: -2px;
  line-height: 15px;
}
.send-receive p.warning-red{
  color: var(--text-gray-v2);
  padding-top: 2rem;
  margin-bottom: -1rem;
}

span.subtitle.text-right {
  margin-left: -9.5rem;
}

p.send-form__label-text.red {
  background-color: #f4f7fa !important;
  border-radius: 10px;
  border: 2px solid #c6b4ce;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  padding: 5px 8px 0px;
}

section.receive-address__network {
  background-color: #f4f7fa !important;
  border-radius: 10px;
  border: 2px solid #c6b4ce;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  padding: 5px 8px 0px;
  width: 100%;
}

label.ap--label.ap-label-with-text__label.send-form__label-title.red__lwt-label {
  font-weight: 700;
}

p.receive-address__label-text.red {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

p.receive-address__label-text.red.right {
  margin-left: 8rem;
}

.selected.dropdown.btn-group[name="network-deposit"] ::after {
  color: transparent;
}

.noSelect {
  margin-bottom: 2.5rem;
}

.noSelect button.dropdown-toggle {
  pointer-events: none;
}

.dropdown-item .item-selector-item.text {
  text-transform: none;
}

.sidepane-details__information-text .font-info {
  font-weight: 600;
  color: white;
}

.App.fluid.container .marquee-container,
.App.fluid.container .rfm-marquee-container {
  background-color: #fd8f25;
  height: 35px;
  font-weight: 500;
  margin-left: -9.5rem;
  width: 100vw;
  position: relative;
  top: 0;
  z-index: 0;
}

.App.fluid.container .marquee-container .marquee,
.App.fluid.container .rfm-marquee-container .marquee {
  font-size: 12px;
  padding-right: 10em;
  padding-left: 10em;
}
@media (width < 1025px) {
.App.fluid.container .rfm-marquee-container {
  margin-left: 0;
}
}

.App.fluid.container .marquee-container .marquee .child p,
.App.fluid.container .rfm-marquee-container .rfm-marquee .rfm-child p{
  font-size: 1.4rem;
}

.App.fluid.container .marquee-container .marquee .child a,
.App.fluid.container .rfm-marquee-container .rfm-marquee .rfm-child a{
  font-size: 1.5rem;
  color: rgba(var(--text-light));
  text-decoration: underline!important;
}

.modal-dialog .modal-content {
  background: transparent !important;
  border: none;
}

.modal-dialog .carousel-inner {
  border-radius: 10px;
}

.modal-open .modal .modal-header {
  padding: 0px !important;
  border-bottom: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
}

.modal-dialog .modal-content .close {
  font-size: 3rem;
  color: white;
  font-weight: 200;
  opacity: 100;
  position: absolute;
  z-index: 100;
  margin: 0px;
  top: -3px;
  padding-right: 1rem;
}

.modal-backdrop.show {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  background-color: var(--background-primary_v2);
  opacity: 0.95;
}

.modal-content .carousel-indicators {
  top: 100%;
}

.modal-content .carousel-control-next {
  display: none !important;
}

.modal-content .carousel-control-prev {
  display: none !important;
}

.carousel-inner .cbx_modal {
  color: blue;
}

.cbx_modal label {
  color: #fff;
  font-weight: 600;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 376px) {
  .modal-dialog .modal-content {
    display: block;
  }
}
.second-logo{
  display: none;
}
@media (max-width: 1024px) {
  .marquee-container + .my-header,
  .rfm-marquee-container + .my-header {
    top: 20px !important;
  }
  .second-logo{
    display: block;
    margin-top: 4px;
    margin-left: 5px;
  }
}
@media (max-width: 1170px) {
  .user-settings-page__maincontainer {
    flex-direction: column;
  }
  .user-settings-page__container2{
    border-left: none;
  }
  .user-settings-page__container2{
    padding-left: 5rem;
  }
}
@media (max-width: 721px) {
  .user-settings-page__container2{
    padding-left: 3rem;
  }
}

.carousel-indicators li {
  background-color: #c6c6c6 !important;
  opacity: 100 !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  margin-top: 3px;
}

.carousel-indicators .active {
  background-color: #fd9025 !important;
}

.page-header-nav__container .fix-menu {
  top: 7.5rem;
}
.marquee-container,
.rfm-marquee-container {
  padding-top: 11px !important;
  overflow-y: hidden;
}

.marquee-container .marquee > div,
.rfm-marquee-container .marquee > div {
  font-size: 1.1em;
}

.marquee-container .marquee > div > p > a,
.rfm-marquee-container .marquee > div > p > a {
  color: #fff;
  text-decoration: underline !important;
  font-weight: 600;
}

.product-details-page__menu .menu-component__item--selected:hover {
  background-color: var(--background-primary_v2);
}

.transfers-filters {
  background-color: var(--background-primary_v2) !important;
}

.wallet-details {
  background-color: var(--background-primary_v2) !important;
}

.form-group.ap-select__select-wrapper.activity__select-wrapper {
  margin-right: 0;
}

.form-control.ap-select__select.activity__select {
  background-color: var(--pc__background-secondary_v2);
  padding-right: 10px;
}

.retail-api-key-list {
  border-radius: 0 0 10px 10px;
}

.ReactModalPortal .ap-modal__overlay {
  opacity: 1.2;
}

.ReactModalPortal .ap-modal {
  background: var(--background-primary_v2);
  box-shadow: none;
  margin: auto;
}

.ap-modal__header,
.ap-modal__content {
  background: transparent;
}

.ReactModalPortal .ap-modal__header {
  color: var(--pc_font-primary);
  background-color: transparent;
}

.ReactModalPortal .enable-2fa-modal__text-item,
.disable-2fa-modal__text-item,
.ap-auto-tab-input__label {
  color: var(--pc_font-primary);
}

.ReactModalPortal .ap-modal__close-icon,
.ap-icon--close {
  color: var(--pc_font-primary);
}

.ReactModalPortal .ap-auto-tab-input {
  background: var(--background-primary_v2);
  color: var(--pc_font-primary);
}

.flex-table__header.flex-table__header--background.orderbook__header{
  padding: 10px;
}

.ap-vertical-scroller.ap-vertical-scroller--at-rest.orderbook__vertical-scroller-body{
  justify-content: flex-start;
  background-color: var(--pc__background-secondary_v2);
  padding: 0 1rem;
}
.orderbook__order-book-body >div:first-child{
  border-bottom: 1px solid var(--border-gray-v2);
}
.orderbook__table-qty{
  text-align: center;
}

.flex-table__header.flex-table__header--background.orderbook__header{
  text-align: center;
}

.flex-table__header.recent-trades__table-header{
  text-align: center;
}

.flex-table__body.recent-trades__body::-webkit-scrollbar{
  background-color: var(--pc__background-secondary_v2);
}
.flex-table__body.recent-trades__body::-webkit-scrollbar-thumb{
  border-radius: 20px;
  background-color: var(--background-primary_v2);
}
.flex-table.recent-trades.recent-trades__body{
  background-color: var(--pc__background-secondary_v2);
  padding-bottom: 15px;
}

.order-book__container .flex-table__title,.orderbook__spread-row {
  background: var(--bg-dark-v2);
    border-top: 1px solid #2e2e48;
}

.flex-table__column.orderbook__table-my-size{
  display:none;
}

.ap-padded-decimal__decimal-separator{
  color: var(--text-gray-v2)!important;
}
.ReactModalPortal .create-api-key__form-body {
  background: var(--background-primary_v2);
}

.ReactModalPortal .create-api-key__header {
  color: var(--pc_font-primary) !important;
}

.form-control {
  background: var(--background-primary_v2) !important;
  color: var(--pc_font-primary) !important;
}

a.pagination__text.activity-pagination__text{
  color: var(--pc_font-primary);
  background-color: var(--background-primary_v2) !important;
  border-color: var(--secondary-color-v2);
}

.buy-sell-make-transaction__content .buy-sell__tab {
  color: var(--btn-checked-v2);
}

.buy-sell-make-transaction__content .buy-sell__tab.buy-sell__tab--sell-selected{
  color: var(--pc_font-primary);
}
.buy-sell-make-transaction__content .buy-sell__tab.buy-sell__tab--buy-selected{
  color: var(--pc_font-primary);
}

.triangle-up {
  border-bottom: var(--dropdown__arrow-size) solid var(--pc_font-primary);
  margin-bottom: var(--dropdown__arrow-size);
}
.triangle-down {
  border-top: var(--dropdown__arrow-size) solid var(--pc_font-primary);
  margin-top: var(--dropdown__arrow-size);
}

.buy-sell-order-confirmation__header{
  color: var(--pc_font-primary);
}

.ap-label-with-text__text {
  color: var(--pc_font-primary);
}


.buy-sell-order-confirmation__order-type__lwt-container, .buy-sell-order-confirmation__lwt-container{
  border-top: 1px solid transparent;
}
.buy-sell-order-confirmation .ap-label-with-text.buy-sell-order-confirmation__order-type__lwt-container > label,
.buy-sell-order-confirmation .ap-label-with-text.buy-sell-order-confirmation__lwt-container > label{
  color: var(--text-gray-v2);
  font-weight: 400;
}
.buy-sell-order-confirmation .ap-label-with-text.buy-sell-order-confirmation__order-type__lwt-container > span,
.buy-sell-order-confirmation .ap-label-with-text.buy-sell-order-confirmation__lwt-container > span{
  font-weight: 600;
}

button.dropdown-toggle.btn.btn-primary{
  border-color: var(--pc-inputs-background)
}

.send-receive-tabs__tab.send-receive-tabs__tab--selected>span{
  color: var(--pc_font-primary);
}

.ap-datepicker__input:hover, .ap-input__input:hover, .ap-select__select:hover, .react-phone-number-input__input.ap-phone-input__input:hover{
  background-color: var(--pc-inputs-background);
}

label.btn.ap-segmented-button__label.ap-segmented-button__label--additive.ap-segmented-button__label--checked.ap-segmented-button__label--checked-additive.transfers-filters__rt-label.transfers-filters__rt-label--additive.transfers-filters__rt-label--checked.transfers-filters__rt-label--checked-additive{
  color: var(--pc_font-primary);
}

.alert-danger{
  color: var(--pc_font-primary);
  background-color: var(--pc-inputs-background);
  border-color: var(--pc-inputs-background);
  border-radius: 10px;
}

.flex-table__empty-icon, .flex-table__column--empty{
  justify-content: center;
}

.pagination__text--disabled:hover, .pagination__text--disabled{
  color: var(--btn-checked-v2)!important;
}

.bulk-cancel-buttons__image{
  display: flex;
  align-items: center;
}

.ap-inline-btn__text.bulk-cancel-buttons__text{
  padding-left: 0!important;
}

.standalone-layout__wrapper div.login-form__container > .login-form__header .login-form__header-text, .standalone-layout__wrapper div.signup-page__container > .signup-page__header .signup-page__header-text, .standalone-layout__wrapper div.problem-logging-in-page__container > .problem-logging-in-page__header .problem-logging-in-page__header-text, .standalone-layout__wrapper div.reset-password__container > .reset-password__header .standalone-form__header-text {
  background: var(--pc__background-secondary_v2);
  color: var(--pc_font-secundary);
}

.standalone-layout__wrapper div.login-form__container > .login-form__header, .standalone-layout__wrapper div.signup-page__container > .signup-page__header, .standalone-layout__wrapper div.problem-logging-in-page__container > .problem-logging-in-page__header, .standalone-layout__wrapper div.reset-password__container > .reset-password__header{
  background: var(--pc__background-secondary_v2);
  border: none;
   border-radius: none;
}

.standalone-form__container.login-form__container, .standalone-form__container.problem-logging-in-page__container{
  background: var(--pc__background-secondary_v2);
}

body #root .dark .ap-input__input-box .form-control, body #root .dark .order-entry__input-wrapper .ap-input__input-box .label-in-input{
  border-color: var(--btn-checked-v2)!important;
  font-family: "Roboto"!important;
}
body #root .dark .order-entry__input-wrapper .ap-input__input-box .label-in-input{
  margin: 1px;
  right: 3px;
  background-color: transparent!important;
  font-size: 1.2rem;
  font-weight: 400;
  top: 5px;
}

.standalone-layout__wrapper {
  background: none;
}

.standalone-form__container{
  box-shadow: none;
}

.block.btn.btn-light {
  background: var(--primary-color-v2);
  color: var(--pc_font-primary);
  border-color: transparent;
}

.send-email.btn.btn-light {
  background: var(--secondary-color-v2);
  color: var(--pc_font-primary);
  border-color: transparent;
}

.popover-header{
  background-color: var(--background-primary_v2);
  color: var(--pc_font-primary);
}

.text-muted{
  color: var(--pc_font-primary)!important;
}

.popover-body {
  background-color: var(--pc-inputs-background);
}

table.rdp-calendar.text-center{
  color: var(--pc_font-primary)!important;
}

.divider.divider--dark.divider--advanced-order-side-pane-size-small,.divider.divider--dark.divider--advanced-order-side-pane-size{
  background-color: #3a3a56;
}
.advanced-order-form__body .ap--label{
  margin-right: 10px;
}

.reports-sidepane__body .report-form__body{
  background-color: #2e2e48;
}

.reports-sidepane__body .reports-sidepane__header{
  color:white;
  padding-left: 0!important;
}
.ap-tab__menu .ap-tab__menu-item.ap-tab__menu-item--active{
  background-color: #2e2e48;
  color:#ff9443;
}

.reports-sidepane__menu-item:hover{
  color: #ff9443;
}
.confirm-order-modal__bought__footer{
  background-color: #2e2e48;
  text-align: center;
}

.confirm-order-modal__sold__footer{
  background-color: #2e2e48;
  margin-left: 0;
  text-align: center;
}

.report-form__triangles-container{
  margin-top: 10px;
}

.form-group select.form-control{
  border: none;
  cursor: pointer;
}
.report-form__footer{
  border-top-color:var(--bg-dark-v2)!important ;
}

.ap-sidepane__close-button.reports-sidepane__close-button{
 right: 20px;
}

.affiliate-modal__input-wraper{
  color: white;
}
.affiliate-modal__input-wraper input{
  border-bottom: 0!important;
}

.ReactModal__Content--after-open .ap-modal__header {
  padding-top: 3em;
}

.report-form__footer .ap-button__btn--additive{
background: linear-gradient(90deg,#FF9443 ,hsl(16, 100%, 61%))!important;
}
.create-api-key__footer .ap-button__btn--additive{
background: linear-gradient(90deg,#FF9443 ,hsl(16, 100%, 61%))!important;
}
.report-form__footer .ap-button__btn--additive[disabled]{
  background: linear-gradient(90deg,#FF9443 ,hsl(16, 100%, 61%))!important;
}

.create-api-key__header {
  padding-left: 0;
}
.send-form__amounts input.form-control.ap-input__input.send-form__input{
  border: none;
  border-radius: 1rem;
}

.send-receive__main .v2-field.form-group > .btn-group > .dropdown-toggle{
  border-radius: 1rem;
}
.send-receive__main textarea{
  border-radius: 1rem;
}
.send-receive__main{
  border-right: 1px solid #2e2e48!important;
}
.send-receive__main-form .form-control.ap-input__input.send-form__input{
  border: none;
  border-radius: 1rem;
}

.send-address__form-body .test{
  border: none;
  background-color: var(--bg-dark-v2);
}

.send-address__form-body .form-group input{
  border: none!important;
  border-width: 1rem;
}
.send-address__form-body .form-group label{
  color: white;
}
.withdrawals-address-form {
  padding-top: 20px;
}
.ap-segmented-button{
  background-color: #2e2e48;
}
.transfers-filters__select-buttons{
  background-color: #2e2e48;
}
body #root .dark .transfers-filters__sb-container{
  background-color: #2e2e48;
}
body #root .dark .transfers-filters .ap-inline-btn__btn{
  border: none;
}
body #root .dark .transfers-filters .ap-inline-btn__btn:hover{
  background: var(--primary-color-v2);
}
.wallet-details__wallet-nav-items .wallet-nav-item{
  border: 1px solid transparent;
}
.wallet-details__wallet-nav-items .wallet-nav-item:hover{
  border: 1px solid var(--bg-dark-v2);
  background-color: #2e2e48;
}
.wallet-details__wallet-nav-items .wallet-nav-item:hover svg,.wallet-details__wallet-nav-items .wallet-nav-item:hover span{
  color: white;
  background-color: #2e2e48!important;
}
.wallet-details__wallet-nav-items .wallet-nav-item:hover .icon-button__icon{
  background-color: #2e2e48;
}
.bnxv2-box-rounded.fix-height form{
  border: 1px solid #39395e;
  padding: 2rem;
  border-radius: 20px;
  position: relative;
  backdrop-filter: blur(20px);
}
.v2-bnx form.was-validated .form-group.error-field-group > .rounded-circle-box{
  border-width: 1px;
}
.region-confirm__confirm-block{
color:var(--pc_font-primary);
}

.confirm-single-report__title{
  color:var(--pc_font-primary);
}

.confirm-single-report__lwt-container{
  border-top: 1px solid var(--btn-checked-v2);
}
.bnxv2-box-rounded.fix-height{
  position: relative;
}

body #root .dark .account-page .table-withdrawalsAddress .table thead > tr > th,
body #root .dark .account-page .table tbody > tr > td,
body #root .dark .settings-page__container .table thead > tr > th,
body #root .dark .settings-page__container .table tbody > tr > td {
  border: 1px solid var(--pc_font-primary);
}

.ReactModal__Content--after-open .ap-modal__content > .send-receive-two-factor-auth-modal > .send-receive-two-factor-auth-modal__text-item{
  color: var(--pc_font-primary);
}

.react-confirm-alert-button-group>button {
  background: var(--primary-color-v2);
  font-weight: 600;
}
.transfers-pagination__text{
  background: var(--background-primary_v2);
  border: 1px solid var(--secondary-color-v2);
}

.pagination__container{
  background: var(--background-primary_v2);
}

.pagination__text{
  color: var(--pc_font-primary);
}

.pagination__text:hover{
  color: var(--pc_font-primary);
}

.pagination__page, .pagination__page:last-child{
  background: var(--background-primary_v2);
}


.pagination__page, .pagination__page:last-child{
  color: var(--pc_font-primary)!important;
}

a.pagination__page.pagination__page--active.transfers-pagination__page.transfers-pagination__page--active{
  background: var(--primary-color-v2)!important;
}

a.pagination__page.pagination__page--active.activity-pagination__page.activity-pagination__page--active{
  background: var(--primary-color-v2)!important;
}

.ReactModal__Content.ReactModal__Content--after-open.ap-modal.retail-api-keys-modal{
  width: 48rem;
}

.confirm-api-key {
  width: 42.8rem;
}

.confirm-api-key__authenticate-user>p>span>a{
color: var(--pc_font-secundary);
}

.ap-modal__header {
  justify-content: center;
}

.alert-info {
  color: var(--pc_font-primary);
  background-color: var(--pc__background-secondary_v2);
  border-color: var(--pc__background-secondary_v2);
}
.confirm-api-key__lwt-container{
  border-top: 1px solid var(--pc_font-primary);
}
.send-receive-tabs__tab--selected{
  border-bottom: none;
  position: relative;
}
.send-receive-tabs__tab--selected span{
 color:#FF9443!important
}

.send-receive-tabs__tab.send-receive-tabs__tab--selected::after {
  background: #ff8d3f;
  height: 3px;
  border-radius: 4px;
  width: 20%;
  margin: 0 auto;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.limit-order-confirmation{
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.limit-order-confirmation__text {
  padding-bottom: 2rem;
}

/* Estilo para el defaultOption en el componente FormSelector*/
body #root .dark .form-group select.form-control.selectOption-select{
  color: #9999B7 !important;
}

.recovery-2fa-modal__recovery-code-container{
  color: var(--pc_font-primary);
  }

  .react-confirm-alert-overlay .react-confirm-alert-body > .react-confirm-alert-button-group > .btn_no_show {
    background: var(--secondary-color-v2);
}
.react-confirm-alert-overlay .react-confirm-alert-body > .react-confirm-alert-button-group > button:nth-child(2){
    background: var(--secondary-color-v2);
}

.react-tel-input .selected-flag .arrow{
  border-top-color: white;
}
.react-tel-input .selected-flag{
  background-color: var(--pc-inputs-background);
}
.react-tel-input .flag-dropdown.invalid-number{
  border-color: var(--error-color-v2);
}
.react-tel-input .form-control.invalid-number,.react-tel-input .form-control.invalid-number:focus{
  background-color: var(--pc-inputs-background);
  border-color: transparent;
}
.form-pay-phone-custom.form-control{
  border-color: transparent;
  background-color: var(--pc-inputs-background);
}
.form-pay-phone-custom.form-control:focus{
  border-color: var(--btn-checked-v2);
}

.btnsendprimary{
  background: var(--primary-color-v2);
}

.modal-step-level .modal-dialog .modal-content .modal-container-step-level .row .step-container .h-auto div:nth-child(2) button{
  background: var(--primary-color-v2);
  border-color: transparent;
}

.corporatelevel-page .row .card{
  border-color: #b8b8b8;
  border-bottom-color: transparent;
}
.corporatelevel-page .row .card:last-child{
  border-bottom-color: #b8b8b8;
}

.send-request-content h3{
  font-size: 14px;
}
.modal-step-level .modal-dialog .modal-content .modal-container-step-level .row .step-container .text-warning.form-text a{
  color: #ff9443;
}

.modal-step-level .modal-dialog .modal-content .modal-container-step-level .row .step-container .text-warning.form-text a:hover{
  color: #ff9443;
  opacity: .5;
}
.form-control-file{
  color: white;
}
.text-warning.form-text{
  margin-top: 1rem;
}
.text-warning.form-text span{
  color: #FF9443!important;
}
.table-addlevelrepresentative th.header-name-table{
  background: var(--background-primary_v2);
}
.table-addlevelrepresentative .header-title-table th{
  background: var(--pc__background-secondary_v2);
  color: white!important;
}

body #root .dark .corporatelevel-page .table-bordered th {
  color: white!important;
}

.secondary-color{
  background: var(--secondary-color-v2)!important;
}
.PendingVerifiedStatus.btn.btn-primary{
  background: var(--secondary-color-v2)!important;
}

.withdrawals-address-page .btn-info{
  background-color: var(--error-color-v2);
  border-color: var(--error-color-v2);
}

.trust-list-page .btn-info,
.trust-list .btn-info {
  background-color: var(--error-color-v2);
  border-color: var(--error-color-v2);
}

.trust-list .card {
  background-color: var(--background-primary_v2);
  color: white !important;
}

.defaultOptionSelectForm.form-control:focus{
  color: #9999B7;
}
.defaultOptionSelectForm.form-control option{
  color: #fff !important;
}
.defaultOptionSelectForm{
  color: #9999B7;
}
.step-bnx.text-center button{
  width: 55%!important;
}

.form-control.ap-datepicker__input.activity__from__dpk-input{
  margin-left: 10px;
}
.recovery-2fa-modal .form-group .ap-auto-tab-input:nth-child(2){
  margin-left: 12px;
}
.download-report__refresh-reports__icon{
  color: #ff9443!important;
}

.form-group.ap-datepicker__input-wrapper.activity__to__dpk-wrapper{
  gap: 10px!important;
}

span.input-group-text{
  background-color: var(--pc-inputs-background);
  border-color:transparent ;
  color:  var(--pc-inputs-background);
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 2px;
  cursor: default;
}

.ap-button__btn.ap-button__btn--additive.ap-modal.send-receive-confirm-modal__btn.ap-modal.send-receive-confirm-modal__btn--additive{
  width: 60%;
  margin: auto;
  height: auto!important;
  padding: 0.5rem;
}

.react-confirm-alert-overlay .react-confirm-alert-body > .react-confirm-alert-button-group > button{
  width: 48%!important;
  border-radius: 10px !important;
  padding: 10px !important;
  margin: auto;
}

.table-addlevelrepresentative .btn-info,
.table-addlevelrepresentative .btn-info:hover{
  background-color: var(--error-color-v2);
  border-color: var(--error-color-v2);
}

body #root .dark .product-details-page__content .ap-segmented-button__label{
  font-size: 12px!important;
  width: 100%;
  max-width: 140px;
}
.btn-group.btn-group-toggle.ap-segmented-button.transfers-filters__rt-wrapper{
  gap: 2px;
  justify-content: center;  
}

.flex-table__header.transfers__table-header > div > div{
  font-size: 12px!important;
  font-weight: bold;
}
body #root .dark .transfers__primary-text{
  font-size: 12px!important;
}
.ap-modal__header.send-receive-confirm-modal__header{
  padding-bottom: 2rem;
}

.ReactModal__Content.ReactModal__Content--after-open.ap-modal.send-receive-confirm-modal{
  max-width: 500px;
}
.step-bnx.text-center button{
  width: 60%!important;
}

.disable-2fa-modal .disable-2fa-modal__text-item{
  margin: 0 auto 1em auto;
  text-align: center;
}
.disable-2fa-modal .ap-auto-tab-input__label{
  text-align: center;
  margin: 10px auto;
}
.disable-2fa-modal .form-group input:nth-child(2){
  margin-left: 2em;
  margin-top: 1em;
}

.v2-step .step-bnx.text-center .pt-5.pb-5 button{
  width: auto!important;
}

.modal.modal-bnx-v2.lighting .modal-content{
  background-color: var(--bg-dark-v2)!important;
}
.loginv2 .login-container .bnxv2-box-rounded{
  width: 100% !important;
  max-width: 472px;
  padding: 2.5rem 3rem;
  position: relative;
}
.loginv2 .login-container .bnxv2-box-rounded .title-form{
  font-weight: 550;
  font-size: 2.3rem !important;
}
.loginv2 .login-container .bnxv2-box-rounded .title-form b{
  font-weight: 550;
  font-size: 2.3rem !important;
  color: var(--pc_font-secundary);
}
.loginv2 .login-container .bnxv2-box-rounded .ilustration-img {
  margin: 2rem 0 3rem;
  width: 60%;
}
@media screen and (max-width: 425px){
  body #root .dark .LevelBlock .LevelForSingle {
    padding: 2rem 4rem 1rem;
  }
  .loginv2 .login-container .bnxv2-box-rounded{
    padding: 2.5rem 0.5rem;
  }
  .app-modal-information{
    padding: 2rem !important;
  }
}
.arrowback{
  position: absolute; 
  left: 15px;
  top: 15px;
}
.arrowback span{
  width: 100%;
  max-width: 30px;
  cursor: pointer;
}
.arrowback span svg{
  height: auto;
  width: 100%;
}
.v2-field.form-group > .btn-group .dropdown-menu > .dropdown-item > .item-selector-item > .item-selector-title{
  width: 90%;
}
.v2-field.form-group > .btn-group .dropdown-menu > .dropdown-item > .item-selector-item > .item-selector-title .title {
  word-wrap: break-word; 
  white-space: pre-wrap; 
  word-break: normal;
  line-height: 15px;
}

.bnx-v2.container-workflow.deposit .dropdown-toggle.btn.btn-primary,.bnx-v2.container-workflow.withdraw .dropdown-toggle.btn.btn-primary{
  width: 100%;
}

.bnx-v2.container-workflow.deposit .dropdown-toggle.btn.btn-primary b,.bnx-v2.container-workflow.withdraw .dropdown-toggle.btn.btn-primary b{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: .7;
}
.bnx-v2.container-workflow.deposit .dropdown-toggle.btn.btn-primary span,.bnx-v2.container-workflow.withdraw .dropdown-toggle.btn.btn-primary span{
  flex: .3;
}

.fade.modal-bnx-v2.lighting.modal.show .btn.btn-primary{
  background:var(--primary-color-v2);
  color: white;
  border-color:transparent!important;
}

body #root .dark .buy-sell-order-overview__content .ap--label.ap-label-with-text__label.buy-sell-order-overview__lwt-label{
  font-weight: 400;
  color: var(--text-gray-v2)!important;
}
.buy-sell-order-overview__content .ap-label-with-text__text.buy-sell-order-overview__lwt-text{
  font-weight: 600;
}

body #root .dark .eotc-buy-sell-layout__balances-container .ap--label.ap-label-with-text__label.buy-sell-balances__lwt-label{
  font-weight: 400;
  color: var(--text-gray-v2)!important;
}
.ap-label-with-text.sidepane-details__lwt-container label{
  color: var(--text-gray-v2)!important;
}
.card_banking.v2-databank.collapse.show b{
  color: var(--text-gray-v2);
}

#card_banking_box > div:nth-child(2n){
  margin-bottom: 10px;
}
#card_banking_box > div:nth-child(2n) > b{
  font-weight: bold;
}

.v2bnx.details_bank .title_bank_name,
.v2bnx.details_bank .title_in_the_name_of{
  margin-top: 10px;
  color: var(--text-gray-v2)!important;
}
.v2bnx.details_bank .children_list{
  color: var(--pc_font-primary)!important;
}
.v2bnx.details_bank .title_bank_name.limit-details-title{
  color: var(--pc_font-primary)!important;
  font-size: 1.3rem;
  font-weight: bolder;
}

body #root .dark .wallet-details .wallet-details__position-row .wallet-details__detail .wallet-details__label{
  color: var(--text-gray-v2);
}
body #root .dark .base-lt__header.base-lt__header--sm.profile-and-security-widget__header.profile-and-security-widget__header--sm{
  color: white!important;
}
body #root .dark .api-keys-page__header-title{
  font-weight: 600;
  color: white!important;
}
body #root .dark .api-keys-page__header-text{
  color: var(--text-gray-v2)!important;
}
body #root .dark .affiliate-program-page__header{
  color: white!important;
  font-weight: 600;
}


body #root .dark .affiliate-program-page__description {
  color: var(--text-gray-v2)!important;
  line-height: 1.4;
  white-space: pre-line;
}

.buy-sell-order-overview .ap-label-with-text__text.buy-sell-order-overview__lwt-text{
  color: white;
}
.LevelBlock.minified .LevelForSingle .levelContent{
  color: var(--text-gray-v2);
}
.bnx-v2-general-container .bnxv2-box-rounded p.title-form-normal{
  color: var(--text-gray-v2);
  font-weight: 400;
}

body #root .dark .sidepane-details__information-text.sidepane-details__information-text--top{
  color: var(--text-gray-v2)!important;
}
body #root .dark .dashboard-page__balances-header{
  font-weight: bold;
}

.bnx-v2.container-workflow.deposit .important-message{
  color: var(--text-gray-v2)!important;
}
.bnx-v2.container-workflow.withdraw.mb-5 .important-message{
  color: white;
  font-size: 14px;
  font-weight: normal;
}

.activity__action-text.activity__action-text--secondary-new,.activity__action-text.activity__action-text--secondary{
  color: var(--text-gray-v2);
}
.activity__action-text.activity__action-text--secondary{
  font-weight: bold;
}
.txid-link{
  color: #f07e2e;
  transition: 450ms all;
}
.txid-link:hover{
  color: #f07e2e;
  opacity: .5;
}
.trade-component__header{
  background-color: transparent!important;
  color: transparent;
  border-bottom-color: var(--background-primary_v2);
}

.carouselNews .carousel-inner{
  border-radius: 0.6rem;
}

.carouselNews .carousel-control-prev-icon{
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8ZyBpZD0idnVlc2F4X2xpbmVhcl9hcnJvdy1sZWZ0IiBkYXRhLW5hbWU9InZ1ZXNheC9saW5lYXIvYXJyb3ctbGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSI+CiAgICA8ZyBpZD0iYXJyb3ctbGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSI+CiAgICAgIDxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik05LjUyNCwyMS4wNTEuNzc1LDEyLjM4NmEyLjYyMSwyLjYyMSwwLDAsMSwwLTMuNzIxTDkuNTI0LDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwLjUwOCA1LjQ3NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgICAgPHBhdGggaWQ9IlZlY3Rvci0yIiBkYXRhLW5hbWU9IlZlY3RvciIgZD0iTTAsMEgzMlYzMkgwWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIgMzIpIHJvdGF0ZSgxODApIiBmaWxsPSJub25lIiBvcGFjaXR5PSIwIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K)!important;
      color: #000;
}

.carouselNews .carousel-control-next-icon{
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8ZyBpZD0idnVlc2F4X2xpbmVhcl9hcnJvdy1yaWdodCIgZGF0YS1uYW1lPSJ2dWVzYXgvbGluZWFyL2Fycm93LXJpZ2h0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIj4KICAgIDxnIGlkPSJhcnJvdy1yaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSI+CiAgICAgIDxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0wLDIxLjA1MWw4Ljc0OS04LjY2NWEyLjYyMSwyLjYyMSwwLDAsMCwwLTMuNzIxTDAsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuODQ4IDUuNDc1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgICA8cGF0aCBpZD0iVmVjdG9yLTIiIGRhdGEtbmFtZT0iVmVjdG9yIiBkPSJNMCwwSDMyVjMySDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMiAzMikgcm90YXRlKDE4MCkiIGZpbGw9Im5vbmUiIG9wYWNpdHk9IjAiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=)!important;
      color: #000;
}

.carouselNews .carousel-control-next, .carousel-control-prev {
    position: absolute!important;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carouselNews .carousel-control-next-icon, .carousel-control-prev-icon{
     width: 32px;
    height: 32px;
}

.carouselNews .carousel-indicators li{
    border-radius:0% ;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    width: 30px!important;
    height: 3px!important;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

#root, .App.container {
  background: var(--background-primary_v2);
}
.v2-field.form-group .placeHolderSelect {
  color: var(--text-gray-v2) !important;
}
.transfer-request-notification-item, .transfer-request-notification-item:hover {
  background-color: var(--pc__background-secondary_v2);
}

.wallet-nav-item__label{
  padding-left: 0 !important;
}
.icon-button.wallet-nav-item{
  display: flex;
  column-gap: 3px;
  color: var(--text-light) !important;
}
.icon-button.wallet-nav-item span{
  width: auto !important;
}
.icon-button.wallet-nav-item:hover span{
  color: var(--text-light) !important;
  transition: none;
}

.message-alert-accounts-item span{
  background: var(--primary-color-v2);
  padding: 5px 10px;
  border-radius: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
  line-height: 16px;
  display: inline-block;
  font-weight: 400;
  color: var(--text-light);
  font-size: 11px;
  max-width: 388px;
}
.app-modal-information{
  background-color: var(--pc__background-secondary_v2);
  color: var(--text-light);
  padding: 2rem 6rem;
  border-radius: 24px;
  margin: 0 3rem;
}
.app-modal-information.success-register{
  padding: 6rem;
  margin: 0;
}
.app-modal-information.success-register p{
  font-size: 15px;
  text-align: center;
  margin-bottom: 4rem;
}
.app-modal-information.success-register p b{
  color: var(--text-light);
}
.app-modal-information.success-register a{
  font-size: 15px;
}
.app-modal-information .container-image-alert{
  text-align: center;
  padding: 1.5rem 0 2.5rem;
}
.app-modal-information .button-modal-close{
  position: absolute;
  right: 14px;
  top: -19px;
  background-color: var(--pc__background-secondary_v2);
  border-radius: 15px;
  font-size: 25px;
  height: 40px;
  width: 40px;
  box-shadow: -4px 4px 15px -5px rgb(0 0 0 / 40%);
}
.app-modal-information .button-modal-close button{
  padding-right: 8px;
  font-size: 37px !important;
  font-weight: 100 !important;
}
.app-modal-information .header-modal-title{
  font-size: 18px;
  text-align: center;
  margin-bottom: 2.5rem;
  font-weight: 600;
}
.app-modal-information p{
  font-size: 12px;
  color: var(--text-gray-v2);
}
.app-modal-information .footer-modal-text{
  text-align: center;
  color: var(--text-light);
  margin-top: 5rem;
}
.app-modal-information .footer-modal-text a{
  color: var(--pc_font-secundary);
  text-decoration: underline !important;
}
.app-modal-help-text{
  position: absolute;
  background-color: var(--pc__background-secondary_v2);
  top: 0;
  right: -192px;
  padding: 1.2rem;
  border-radius: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  opacity: 0;
  transition: opacity .3s;
  width: 197px;
}
.app-modal-help-text.show{
  opacity: 1;
  z-index: 1;
}
.app-modal-help-text .itm-option-v2{
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.app-modal-help-text p{
  margin: 0;
  font-size: 1.3rem;
}
.main-select-email{
  position: absolute;
  z-index: 1;
  right: 18px;
  top: 3px;
  width: 100%;
  max-width: 160px;
}
@media (max-width: 402px) {
  .main-select-email{
    max-width: 130px;
  }
  .fiat-sidepane .fiat-sidepane__side{
    width: 100%;
  }
}
.main-select-email button{
  width: 100%;
  color: var(--text-light);
}
.main-select-email button p{
  width: 100%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding: 1rem 0;
  text-align: left;
}
.main-select-email .header-itm{
  height: 49px;
  background-color: var(--background-primary_v2);
  border-radius: 1.5rem;
  padding: 0.375rem;
  padding-left: 1.25rem;
  border: 1px solid var(--border-color);
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.main-select-email .header-itm input{
  background-color: inherit;
  color: var(--text-light);
  height: 37px;
  width: 100%;
  border-radius: 1.5rem;
}
.main-select-email .header-itm input:read-only{
  cursor: pointer;
}
.main-select-email .header-itm span{
  height: 100%;
  display: flex;
  align-items: center;
  width: 24px;
  filter: invert(100%);
}
.main-select-email p{
  margin: 0;
}
.main-select-email .cont-itm-select{
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 1.5rem;
  border: 1px solid var(--border-color);
  background-color: var(--background-primary_v2);
  display: none;
}
.main-select-email .cont-itm-select.active-select{
  display: block;
}
.main-select-email .cont-itm-select p{
  padding: 1.1rem 1.25rem;
  cursor: pointer;
}
.main-select-email .cont-itm-select p:hover{
  background-color: var(--select-color-v2);
  border-radius: 1.5rem;
  color: var(--select-text-v2);
}
.input-mail-V2{
  padding-right: 20rem !important;
}
.message-error{
  color: var(--error-color-v2);
  font-size: 12px;
  font-weight: 600;
}
.new-select-date-v2 p{
  position: absolute;
  top: 5px;
  z-index: 1;
  font-size: 11px;
  color: var(--text-gray-v2);
  left: 25px;
}
.new-select-date-v2 .v2-field.form-group > .form-control.react-datepicker-component{
  padding: 0.375rem 0.8rem !important;
}
.new-select-date-v2 .react-datepicker-component .react-datepicker-input{
  top: 17px;
  height: 20px;
}
#new-select-date >div:nth-child(2) >div:first-child{
  border: none!important;
}
#new-select-date input::placeholder{
  color: var(--text-light);
}
#new-select-date input{
  height: 20px;
}
.new-select-date-v2 .react-datepicker-input .has-value{
  padding: 0.375rem 0.8rem;
}

.flex-table__row.api-key-list__row.retail-api-key-list__row{
  height: auto!important;
  padding: 0 1rem;
}
.isvg.loaded.ap-icon.ap-icon--bigKey.api-key-list__icon.retail-api-key-list__icon.api-key-list__icon.retail-api-key-list__icon--bigKey{
  width: auto!important;
}

.flex-table__row.api-key-list__row.retail-api-key-list__row{
  gap: 20px;
}
.api-key-list__text-item.retail-api-key-list__text-item{
  font-size: 1.2rem;
}
.flex-table__header.api-key-list__table-header.retail-api-key-list__table-header{
  gap: 6rem;
}
.settings-enable-two-factor-auth__description-button{
  color: var(--text-gray-v2);
  margin-top: 2rem;
  font-size: 1.2rem;
  text-align: justify;
}
.ap-label-with-text__text.confirm-single-report__lwt-text{
  font-size: 11px;
  text-align: left;
}
.danger-message-v1{
  color: var(--error-color-v2);
}
.title-register-section{
  margin: 1rem 0 3rem;
}
h1.title-register-section{
  font-size: 2.5rem!important;
}

.register-form,.input-register-date .react-datepicker-component.form-control{
  height: 56px!important;
}
.submit-button.register-field:disabled{
  opacity: .5;
  cursor: default;
}
.p-1-5{
  padding: 0 1.5rem!important;
}
.link-register-bottom .title{
  font-size: 1.4rem;
}
.link-register-bottom a{
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--orange-color)!important;
}
.link-register-bottom a:hover{
  color: var(--orange-color)!important;
}
.input-register-date .react-datepicker-component.form-control input{
  font-size: 1.6rem!important;
  margin-top: 0.3rem;
}
.input-register-date{
  display: flex;
  align-items: center;
  position: relative;
}
.input-register-date > p{
  padding-left: 0.8rem!important;
}
.input-register-date > div:nth-child(3){
  width: 100%;
}
.icon-right-register-date{
  position: absolute;
  z-index: 999;
  bottom: 25px;
  left: 5px;
}

.new-select-date-v2.input-register-date .v2-field.form-group > .form-control.react-datepicker-component{
  width: 100%;
  padding-left: 3.3rem!important;
}

.bottom-box-v2.problems-text.col-xl-0 span,
.bottom-box-v2.problems-text.col-xl-0 a{
  font-size: 14px;
}

.bottom-box-v2.problems-text.col-xl-0 a{
  color: var(--orange-color)!important;
  font-weight: 500;
}
.bottom-box-v2.problems-text.col-xl-0.login-problem a{
  color: var(--orange-color)!important;
  font-weight: 500;
}
  .bottom-box-v2.problems-text.col-xl-0.login-problem a:hover{
  color: var(--orange-color)!important;
}
.placeholder-color input::placeholder {
  color: var(--text-gray-v2) !important;
}
.link-color-v2 {
  color: var(--pc_font-secundary)!important;
  font-size: 1.3rem;
  font-weight: bolder;
  text-decoration-line: underline !important;
}
.standalone-form__container.reset-password__container{
  background-color: var(--pc__background-secondary_v2);
  border-radius: 15px;
}
.standalone-form__message-text{
  color: var(--text-light);
}
.default-primary-button{
  width: 100%;
  padding: 1.3rem 1.25rem;
  font-size: 1.6rem;
  background: var(--primary-color-v2);
  border-radius: 1.5rem;
  color: var(--text-light);
}
.default-primary-button:disabled{
  opacity: .5;
  cursor: no-drop;
}
.two-fa-text-h4{
  font-size: 2rem;
}
.justify-content-center.error-msg{
  text-align: center;
  color: var(--error-color-v2);
}
.justify-content-center.error-msg p{
  font-size: 1.2rem;
}
.modal-confirm-phone .modal-content .validation-phone.banner .btn-phone:disabled{
  opacity: .5;
  cursor: default;
}
.flex-table__header.order-history-table__table-header .flex-table__column.order-history-table__header-column:first-child,
.flex-table__row.order-history-table__row .flex-table__column.order-history-table__column:first-child{
  flex: .5;
}
.flex-table__header.order-history-table__table-header .flex-table__column.order-history-table__header-column:nth-child(3),
.flex-table__row.order-history-table__row .flex-table__column.order-history-table__column:nth-child(3){
  flex: .7;
}
.flex-table__header.order-history-table__table-header .flex-table__column.order-history-table__header-column:nth-child(2),
.flex-table__row.order-history-table__row .flex-table__column.order-history-table__column:nth-child(2),
.flex-table__header.order-history-table__table-header .flex-table__column.order-history-table__header-column:nth-child(4),
.flex-table__row.order-history-table__row .flex-table__column.order-history-table__column:nth-child(4),
.flex-table__header.order-history-table__table-header .flex-table__column.order-history-table__header-column:nth-child(5),
.flex-table__row.order-history-table__row .flex-table__column.order-history-table__column:nth-child(5){
  flex: .7
}

.new-buy-sell-component {
  display: grid;
  grid-template-rows: max-content auto max-content;
  row-gap: 1rem;
  width: 95%;
  max-width: 1200px;
  margin: 2rem auto 0;
}
.new-buy-sell-component >div:nth-child(2){
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.new-buy-sell-component >div:last-child,
.new-buy-sell-component >div:last-child .page-footer{
  margin-top: 0;
}
.change-switch{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 2rem;
  font-size: 1.2rem;
}
.switch-toggle-bnx-account-label {
  cursor: pointer;
  width: 50px;
  height: 25px;
  background-color: var(--switch-color);
  display: block;
  border-radius: 100px;
  position: relative;
  margin: 0;
  font-size: 1.4rem;
  text-align: center;
  border-top: 1px solid var(--opacity-color)
}

.switch-toggle-bnx-account-label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 5px;
  width: 16px;
  height: 16px;
  background-color: var(--text-gray-v2);
  border-radius: 90px;
  transition: 0.3s;
}

.switch-toggle-bnx-account-input:checked + .switch-toggle-bnx-account-label {
  background-color: var(--switch-color);
}

.switch-toggle-bnx-account-input:checked + .switch-toggle-bnx-account-label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
  background-color: var(--text-light);
  box-shadow: 0px 0px 16px var(--text-light);
}
.switch-toggle-bnx-account-label:active:after {
	width: 6px;
}

.switch-toggle-bnx-account-label{
    display: flex;
    align-items: center;
}
.flex-table__column.activity__header-column:last-child, .flex-table__column.activity__column:last-child{
  place-content: center;
}
.title-form-normal.description-reset-password{
  font-size: 1.4rem;
  margin-top: 2rem;
  color: var(--text-gray-v2);
  text-align: left;
}
.bottom-options-terms{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.bottom-options-terms > a{
  width: 100%;
  color: var(--text-gray-v2)!important;
  font-size: 1.2rem;
  margin-bottom: .5rem;
}

@media(max-width:768px) {
  .change-switch{
    align-self: center;
    flex-wrap: wrap;
    width: 230px;
    place-content: center;
    row-gap: 1rem;
    margin-bottom: 3rem;
  }
}
.v2-group-fields .row .text-center.mt-2.mb-2.col-xl-12 img{
  display: none;
}
.recommendations {
  display: flex;
  gap: 1rem 3rem;
  flex-wrap: wrap;
}
.recommendations div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.recommendations p{
  font-size: 1.4rem;
}
.recommendations img{
  width: 100%;
  max-width: 200px;
}
.bnxv2-box-rounded.fix-height.problem-login{
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 85vh;
}
@media screen and (max-width:680px){
  .recommendations div:nth-child(2) {
    width: 100%;
  }
}
.error-file{
  padding: 0 1rem 1rem;
  font-weight: 600;
  font-size: 100%;
  color: var(--error-color-v2) !important;
}
.display-none{
  display: none;
}
.text-link{
  font-size: 1.5rem;
  color: var(--text-light);
}
.text-link.email{
  text-decoration: underline !important;
}
.text-link:active, .text-link:focus{
  color: var(--text-light) !important;
}
.ekyc_container{
  display: grid;
  grid-template-rows: max-content auto;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.ekyc_container .ekyc_card{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  text-align: center;
}
.coink-button{
  background: var(--coink-color)!important;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}
.coink-button:disabled{
  cursor: crosshair;
  opacity: .5!important;
}
.submit-button.coink-button.deposit-withdraw{
  background-color: var(--coink-color)!important;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  height: 45px!important;
  margin-top: 2rem;
}
.separator-button{
  padding: 1rem 0;
  text-align: center;
  font-size: 1.6rem;
}
.have-account-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  height: 100vh;
}
.have-account-message > div{
    max-width: 600px;
    width: 90%;
    border: 1px solid var(--border-color);
    padding: 4rem;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}

.logo-mobile{
  display: none;
}
.standalone-modal__container .spinner__container {
  left: 50%;
  transform: translateX(-50%);
}
.standalone-modal__container .spinner__container .spinner__text{
  color: var(--text-light);
  font-size: 1.6rem;
}

@media screen and (max-width: 871px) {
  .logo-mobile{
    display: block;
    margin-bottom: 1rem!important;
  }
  .title-login-desktop{
    display: none;
  }
}
.checkbox_form_v2{
  margin: .8rem .5rem 1rem;
  color: var(--text-light) !important;
  font-weight: bold;
  font-size: 1.3rem;
}
.information_form_v2{
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--text-gray-v2);
}
.center_control{
  top: 1rem;
}
.form_file_v2{
  height: auto !important;
}
.button_V2{
  font-size: 1.2rem;
  padding: 1rem 2.2rem;
  background: var(--primary-color-v2);
  color: var(--text-light);
  border: none;
  border-radius: 1.6rem;
  width: max-content;
  font-weight: 500;
  cursor: pointer;
}
.button_V2:disabled{
  opacity: .5;
  cursor: no-drop;
}
.button_V2:hover{
  color: var(--text-light);
}
.button_V2.purple{
  background: var(--select-text-v2) !important;
}
.button_V2.grey{
  background: var(--tertiary-color-v2);
}
.page-header-nav__menu-toggle, .page-header-nav__menu-toggle {
  left: -23px;
}
.banexcard-button:disabled {
  opacity: .5 !important;
  cursor: no-drop !important;
}
.card-increment .terms-conditions {
  color: var(--orange-color);
  cursor: pointer;
}
.terms-conditions_v2 {
  margin-bottom: 3rem;
}
.terms-conditions_v2 label {
  display: flex;
  gap: 1rem;
}
.invalid-feedback.show-complete-error {
  display: block;
}
.modal-general-base {
  background-color: #1a1a2ff2;
  z-index: 1040;
  right: 0;
  top: 0;
  left: 0;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  box-shadow: none;
  padding: 0 !important;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_buttons_modal {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 2rem;
  padding-top: 4rem;
}
.container_buttons_modal button,
.container_buttons_modal a{
  width: 100%;
  text-align: center;
  padding: 1.5rem;
}
.form-check-inline .form-check-input[type='checkbox']:disabled {
  cursor: no-drop;
}
@media (max-width:480px) {
  .container_buttons_modal{
    flex-direction: column;
    row-gap: 1rem;
  }
}
.page-header-nav__item--selected:hover, .page-header-nav__item--selected{
  z-index: 0;
  border-color: #fe9015;
}
.overflow-hidden {
  overflow: hidden;
}
.wallets-page__title, .settings-page__title{
  max-width: 1200px;
  margin: auto;
  width: 95%;
}
.page-header-nav__menu-toggle{
  left: -4px!important;
  top: -7px!important;
}
.tooltip-buysell {
  opacity: 1 !important;
}
.tooltip-buysell .buysell-text{
  background: var(--hover-color-v2);
  padding: .7rem 1rem;
  width: 100%;
  max-width: 120px;
  text-align: left;
  font-size: 1rem;
}
.tooltip-general {
  background: var(--hover-color-v2);
  padding: .7rem 1rem;
  width: 100%;
  max-width: 140px;
  text-align: left;
}
.container-help-text {
  position: relative;
}
.container-help-text img{
  width: 16px;
  margin-left: 1rem;
  margin-bottom: .3rem;
}
.progress_bar{
  -webkit-appearance:none;
  position: absolute;
  top: 2px;
  width: 100%;
}
.progress_bar::-webkit-progress-bar{
  background-color:var(--pc-inputs-background) ;
  border-radius: 999px;
  height: 15px;
  -webkit-height: 15px;
}
.progress_bar::-webkit-progress-value{
  background-color: #FF8701;
  -webkit-background-color: #FF8701;
  border-radius: 999px;
  -webkit-border-radius: 999px;
}
.buttonsContainer.buy-sell-range-buttons{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  columns: white;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 4rem;
  margin-top: -1.5rem;
}
.buttonsContainer.buy-sell-range-buttons button{
  background: var(--background-primary_v2);
  padding: .5rem;
  border-radius: 2rem;
  color: white;
  font-size: 1.6rem;
}
.buttonsContainer.buy-sell-range-buttons button.selected-buySell{
  background: var(--text-light);
  color: var(--text-dark);
}
.wallets-page__title, .settings-page__title{
  max-width: 1200px;
  margin: 2rem auto;
  width: 95%;
}
.page-header-nav__menu-toggle{
  left: -4px!important;
  top: -7px!important;
}
.flex-table, .flex-table__title {
  margin-top: 2.4rem;
  box-shadow: none;
}
.no-activity {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  width: 100%;
  height: 100%;
  background: var(--pc__background-secondary_v2);
  cursor: no-drop;
}
.no-activity img{
  width: 90px;
}
.no-activity p{
  font-size: 1.7rem;
}
.icon-share {
  display: inline-block;
  padding-left: 1rem;
  position: relative;
  top: -3px;
}
.icon-share.copy {
  padding-left: .3rem;
}
.icon-share span {
  margin: 0;
  width: 100%;
  cursor: pointer;
}
@media screen and (max-width: 425px) {
  body #root .dark .LevelBlock .LevelForSingle .level-info-initial {
    font-size: 1.2rem;
  }
  .LevelBlock .LevelForSingle .levelContent {
    font-size: 1.2rem;
  }
}
.title-register-section.coink{
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.desc-coink{
  color: var(--text-gray-v2);
  font-size: 1.4rem;
  text-align: left;
}

.subtitle-inputs{
  margin-top: 10px;
  padding: 0 15px;
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  
}
.logo-mobile.coink{
  position: relative;
}
.arrow-back img{
  position: absolute;
  transform: rotate(180deg);
  width: 25px;
  left: 1.2rem;
  z-index: 2;
  margin-top: -1rem;
}
.checkbox-items{
  margin-top: 40px!important;
}
.page-header-nav__container.page-header-nav__container--closed{
  width: 50px;
}
.v2-navbar-bnx-exchange{
  max-width: 100%!important;
}
.wallet-details__product-actions-row{
  background: var(--pc__background-secondary_v2)
}
.balances-menu, .trade-component.menu-component.balances-menu{
  height: max-content;
  overflow: hidden;
}
.market-actions-button-container{
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
}
.market-actions-button{
  color: #FF9443;
  font-weight: 300;
  font-size: 1.6rem;
  transition: 300ms all;
}
.market-actions-button:hover{
  color: #FF9443;
  text-decoration: underline!important;
}
.empty-exchange{
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}
.empty-exchange p{
  font-size: 2rem;
  font-weight: bold;
}
.v2-field.form-group.nooneline.invalid > .form-control,
.v2-field.form-group.invalid .dropdown-toggle{
  border: 2px solid #fc5c5c !important
}
.coink-deposit-finish-container{
  width: 90%;
  margin: auto;
  margin-top: 20vh;
}
.coink-deposit-finish-container .statusTransaction{
  margin: 2rem auto;
}
.coink-deposit-finish-container .contVertBTN{
  width: 400px;
  max-width: 90%;
  margin: auto;
}
.alert.warning-withdraw {
  background-color: #FFCE00;
  font-size: 1.4rem;
  padding: 1.8rem 2.5rem;
  border-radius: 2rem;
  color: #202121;
  font-weight: 500;
  text-align: justify;
}
.alert.warning-withdraw h4{
  text-align: center;
}
.alert.warning-withdraw p{
  font-size: 1.2rem;
  text-align: left;
}
.alert.warning-withdraw p a{
  display: inline;
  font-size: 1.2rem;
  color: var(--orange-color);
  font-weight: 800;
}
.coink-account-field p:first-child{
  font-size: 1.2rem;
  color: #a8a8c7;
  cursor: not-allowed;
}
.coink-account-field p:first-child span{
  font-size: 1.4rem;
  font-weight: 600;
  color: #ff9015;
  margin-left: 1px;
}
.coink-account-field p:last-child{
  font-size: 1.6rem;
  color: white;
  background-color: var(--pc-inputs-background);
  padding: 1.4rem 1.3rem 1.3rem;
  border-radius: 1.5rem;
  height: 56px;
}
.orderbook__spread-row{
  font-size: 1.2rem!important;
}
.flex-table, .flex-table__title{
  margin: 0!important;
}
.container-inputs-buy-sell {
  position: relative;
}
.container-inputs-buy-sell input{
  padding-right: 12.5rem !important;
}
.container-inputs-buy-sell .dropdown-buy-sell {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 1rem 1.5rem;
  background: var(--hover-color-v2);
  z-index: 1;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 44px;
  overflow: hidden;
  cursor: pointer;
} 
.container-inputs-buy-sell .dropdown-buy-sell.one-item {
  cursor: default;
} 
.container-inputs-buy-sell .dropdown-buy-sell .first-item {
  align-items: center;
  column-gap: .8rem;
  font-size: 1.6rem;
  display: grid;
  grid-template-columns: 24px 34px 20px;
}
.container-inputs-buy-sell .dropdown-buy-sell .other-item {
  align-items: center;
  column-gap: .8rem;
  font-size: 1.4rem;
  display: grid;
  grid-template-columns: 24px auto;
}
.container-inputs-buy-sell .dropdown-buy-sell .first-item .arrow-down-item {
  transform: rotate(90deg);
  object-fit: contain;
}
.qr-code-container{
  margin-top: 1rem;
  background: var(--background-primary_v2);
  border-radius: 2rem;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: auto max-content max-content;
  font-size: 1.8rem;
  align-items: center;
  color: var(--text-gray-v2);
}
.qr-code-container >div:nth-child(1){
  overflow: hidden;
  padding-top: .4rem;
}
.qr-code-container >span:nth-child(2){
  padding-left: 1rem;
}
.qr-code-container >img:nth-child(3){
  width: 38px;
  padding: 1rem;
}
.item-copy-v2 {
  width: 30rem;
}
.item-copy-v2 .form-group {
  padding-top: 1rem;
}
.minimum-amount-text{
  margin-top: -2rem;
  font-size: 1.4rem;
  color: #f55331;
  margin-bottom: 2rem;
}
.cripto-container-max{
  position: relative;
}
.cripto-container-max input{
  padding-right: 10rem;
}
.cripto-container-max select{
  position: absolute;
  top: 25px;
  right: 10px;
}
.fiat-container-max{
  position: relative;
}
.fiat-container-max input{
  padding-right: 10.5rem !important;
}
.fiat-container-max button, .fiat-container-max button:hover, .fiat-container-max button:active {
  position: absolute;
  top: 32px;
  right: 23px;
  background: var(--secondary-color-v2);
  color: var(--white);
  padding: .5rem 2rem;
  font-size: 1.1rem;
  font-weight: 300;
  border-radius: .25rem;
  height: 26px;
  border: none;
}
.select-statement-funds-selector > div::after{
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid white;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.select-statement-funds-selector > div.invalid::after{
  bottom: 40px;
}
.text-app.link{
  color: white;
}
.spinner-container{
  position: absolute;
  right: 18.9rem;
  top: 15px;
  margin-bottom: -1.5rem;
}
.spinner-container svg{
  margin-bottom: -1.5rem;
}
.spinner-container img{
  margin-top: .2rem;
}
.subtitle-form.problem{
  font-size: 1.8rem;
  font-weight: normal;
}
.error-message-kyc {
  color: var(--text-gray-v2);
  margin: 0;
}
.error-message-kyc a {
  color: var(--text-light) !important;
}
@media (max-width:536px) {
  .text-app.link, .line2{
    display: none;
  }
}

.wallet-details__value{
  font-size: 2rem;
}
#fc_frame, #fc_frame.fc-widget-small{
  min-height: 130px!important;
  min-width: 120px!important;
  right: 0!important;
}
#p-freshchat{
    margin-top: -11.5rem;
    font-size: 1.6rem;
    color: #F4F5F7;
    padding: 3px;
    margin-left: 15px;
    border-radius: 25px;
    z-index: 999;
    width: 75px;
    background-color: var(--background-primary_v2);
    text-align: center;
}

.register-preview-container{
  width: calc(100vw - 820px);
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-preview-image{
  z-index: 999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.register-preview-image img.preview, .register-preview-image iframe{
  width: 45vw;  
  height: 470px;
  max-width: 1200px;
  border-radius: 25px;
  object-fit: cover;
  margin-top: -5vh;
  cursor: pointer;
}
.register-preview-image img:last-child{
  position: absolute;
  top: 39%;
  left: 53%;	
  bottom: 0;
  z-index: 999999;
  padding: 1rem;
  border-radius: 50%;
  background-color: var(--background-primary_v2);
}
.subtitle-help{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subtitle-help p{
  display: none;
}
.limit-order-confirmation__modal__footer{
  text-align: center;
}
.customer-level-card {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  height: 70px;
  padding-left: 4.5rem;
  border-bottom: 1px solid #a8a8c736;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.customer-level-card h2{
  font-size: 1.8rem;
  margin: 0;
  padding-top: .5rem;
}
.customer-level-card img{
  width: 20px;
  padding-top: .5rem;
}
.customer-level-card p{
  color: var(--text-gray-v2);
  font-size: 1.3rem;
}
.shareholder-list-btn{
  display: flex;
  column-gap: 1rem;
  padding: .75rem !important;
}
.message-in-process{
  display: none;
  position: absolute;
  bottom: 80px;
  color: var(--text-gray-v2) !important;
  padding: 0 4rem;
  line-height: 1.1;
  font-size: 1.2rem;
}
.global-hooks.card-config-v2 .message-in-process{
  display: block;
}
.global-hooks.card-config-v2 .message-in-process.coink{
  bottom: 64px!important;
}

.recomendation-container{
  padding: 4rem 3rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.recomendation-container >div:first-child b,
.recomendation-container >div:last-child p,
.recomendation-container >div:last-child u{
  font-size: 1.2rem !important;
  font-weight: 400;
}
.text-step-two{
  color: var(--text-light) !important;
}
.sidepane-details__information-header {
  padding-top: 3rem;
}
.initial-loader-general{
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transfer-title{
  text-align: center;
  color: #FF8D3F;
  font-size: 1.8rem;
  margin-top: -1rem;
  margin-bottom: 3rem;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.input-birthday input{
  top: 10px !important;
  text-transform: uppercase !important
}
.input-birthday input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(76%) sepia(9%) saturate(667%) hue-rotate(201deg) brightness(88%) contrast(89%);
  font-size: 2rem;
}
.input-birthday p{
  font-size: 1.1rem !important;
  top: -34px !important;
}
.validate-input-text {
  position: relative;
}
.validate-input-text .input-element.first>div,
.validate-input-text .react-tel-input >input{
  padding-right: 4rem;
}
.validate-input-text .spinner-container{
  right: 8px;
}
.general-btn{
  height: 44px !important;
  font-size: 1.4rem !important;
}  
.statusTransaction .react-tel-input .form-control{
  width: inherit;
}
.message-confirm-phone {
  background-color: var(--background-primary_v2);
  z-index: 999999999;
  right: 0;
  top: 0;
  left: 0;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  box-shadow: none;
  padding: 0 !important;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.message-confirm-phone.initial {
  z-index: 999;
}

.message-confirm-phone.toconfirm {
  z-index: 9999;
}

.message-confirm-phone .statusTransaction {
  padding: 56px 0px 0;
  max-width: 600px;
}
.message-confirm-phone .statusTransaction .validation-phone {
  padding: 0;
}
.react-confirm-content{
  width: 100%;
  padding-right: 5rem;
  padding-left: 5rem;
  color: var(--text-gray-v2);
}
.react-confirm-alert.confirm-phone-box .react-confirm-alert-body {
  background: transparent!important;
}
.message-confirm-phone h2{
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.5rem;
}
.message-confirm-phone span{
  color: #ff9013;
}
.validation-phone .p-0.col{
  display: flex;
  justify-content: center;
}
.message-confirm-phone .react-confirm-alert-body{
  box-shadow: none;
  display: contents;
}
.react-confirm-alert.confirm-phone-box{
  padding: 0 2rem;
}
.subtitle-form-header{
  display: flex;
  justify-content: center;
}
.react-confirm-alert-button-group .container_buttons_modal, .group-buttons {
  padding-top: 2rem;
}
.validation-phone .success-msg {
  margin-top: 1rem;
  margin-bottom: 0;
}
.message-confirm-phone .secondType_INFO img {
  width: 35px;
}
.react-confirm-container {
  margin: 1rem 0 2rem;
}
.container_buttons_modal.group-buttons.col{
  margin-bottom: 2rem;
}
.button-required{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  padding: 2rem;
  margin-top: 2rem;
}
.button-required img{
  width: 15px;
}
.button-required p{
  margin: 0;
}

.validation-phone .container_buttons_modal{
  padding: 2rem 0 2rem ;
}

.btn-link{
  display: flex;
  flex-direction: column;
}

.disabled-resend{
  cursor: not-allowed;
}

.btn-link .disabled-resend-code {
  color: #999!important;
  pointer-events: none;
}
.container_buttons_modal .disabled-resend-code {
  color: #999!important;
  pointer-events: none;
}
@media (max-width: 767px){
  .validation-phone.banner .form-control.confirm-phone{
    letter-spacing: 0.6rem;
  }
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;

}
::-webkit-scrollbar-thumb{
  background-color:var(--bg-dark-v2);
  border-radius: 6px;
}
::-webkit-scrollbar-track{
  background-color:var(--background-primary_v2);
  border-radius: 6px;
}
.left-15{
  margin-left: 15px;
}
.tooltip-general {
  background: var(--hover-color-v2);
  padding: .7rem 1rem;
  width: 100%;
  max-width: 140px;
  text-align: left;
}
.title-max{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.content-loading-data-treatment{
  width: 244px;
}
.title-max p{
  margin-top: 2rem;
}
.title-max span{
  color: white;
  font-size: 1.4rem;
  margin-right: 10px;
  background-color: #5B5B75;
  border-radius: 4px;
  padding: 2px 8px;
  cursor: pointer;
}
.select-currencies-withdraw, .select-currencies-withdraw option{
  background-color: var(--background-primary_v2);
  border-radius: 4px;
  padding: 2px 8px;
  color: white;
  font-size: 1.4rem;
}
.form-control.ap-input__input.send-form__input-amount-crypto__input{
  font-size: 1.4rem;
  border-radius: 10px;
  border: none;
}
.form-control.ap-input__input.send-form__input-amount-crypto__input:focus{
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.input-select{
  border-radius: 10px;
}
.ap-input__error.send-form__input-amount-crypto__error{
  margin-top: 3rem;
  margin-left: 10px;
}
.conversion-p{
  margin-left: 5px;
  position: absolute;
  color: #a8a8c7;
  top: 64px;
  font-size: 1.2rem;
}
.network-selector-container{
  margin-top: 2rem;
}
.note-optional{
  margin-top: 4rem;
}
.ap-button__btn.ap-button__btn--additive.send-form__btn.send-form__btn--additive,
.ap-button__btn.ap-button__btn--additive.receive-form__btn.receive-form__btn--additive{
  border-radius: 10px;
}
.input-birthday input{
  top: 10px !important;
  text-transform: uppercase !important
}
.input-birthday input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(76%) sepia(9%) saturate(667%) hue-rotate(201deg) brightness(88%) contrast(89%);
  font-size: 2rem;
}
.input-birthday p{
  font-size: 1.1rem !important;
  top: -34px !important;
}
.validate-input-text {
  position: relative;
}
.validate-input-text .input-element.first>div,
.validate-input-text .react-tel-input >input{
  padding-right: 4rem;
}
.validate-input-text .spinner-container{
  right: 8px;
}
.general-btn{
  height: 44px !important;
  font-size: 1.4rem !important;
}
body #root .dark .loading-overlay.bnx, body .loading-overlay{
  background-color: #1b1c24c4;
}
@media (max-width: 1700px) {
  .register-preview-image{
    right: -80px;
  }
}
@media (max-width: 1400px) {
  .register-preview-image{
    display: none;
  }
  .subtitle-help p{
    display: block;
    font-size: 1.4rem;
    color: var(--orange-color);
    margin: 0 0 15px;
    cursor: pointer;
  }
}
@media (max-width: 1296px) {
  .register-preview-image{
   right: -200px;
  }
}

@media (max-width: 1749px) {
  .register-preview-image img.preview, .register-preview-image iframe{
    height: 350px;
  }
}

@media (min-width:2161px) {
  .register-preview-image img.preview, .register-preview-image iframe{
    height: 600px;
    width: 47vw;  
  }
}
.product-details-container .activity{
  margin: 0 !important;
  width: 100% !important;
}
.product-details-container .prod-header{
  justify-content: flex-start;
  column-gap: 2rem;
}
.product-details-container .prod-header >div{
  font-size: 2rem;
  font-weight: 500;
  cursor: pointer;
  opacity: .5;
  line-height: 2;
}
.product-details-container .prod-header >div.tab-active{
  opacity: 1;
  text-align: center;
  background: var(--bg-dark-v2);
  padding: 0 1rem;
}
.ap-button__btn.ap-button__btn--additive.ap-modal.confirm-order-modal__bought__btn.ap-modal.confirm-order-modal__bought__btn--additive{
  padding-top: 1px;
  height: 40px;
  text-transform: none;
  font-size: 1.4rem;
  font-weight: 400;
}
.form-control.ap-input__with-label.advanced-order-form__with-label.ap-input__input.advanced-order-form__input{
  border: 1px solid var(--border-gray-v2)!important;
  font-size: 1.2rem;
  text-align: right;
  padding-right: 40px;
  padding-left: 153px;
}
.label-in-input.ap-input__label-in-input.advanced-order-form__label-in-input{
  right: 0;
  font-size: 1.2rem;
}
.report-block-trade-sidepane__tab:hover{
  color: rgb(255, 136, 0);
}
.report-block-trade-sidepane__tab.report-block-trade-sidepane__tab--bought-selected {
  color: var(--success-color-v2);
}
.report-block-trade-sidepane__tab.report-block-trade-sidepane__tab--sold-selected {
  color: var(--error-color-v2);
}
.report-block-trade-sidepane__container--with-tabs .divider.divider--dark.divider--side-pane-size{
  background: var(--border-gray-v2)!important;
}
.report-block-trade-form__inputs-container, 
.ap-label-with-text.report-block-trade-form__lwt-container,
.report-block-trade-form__user-balance-container{
  padding: 0 3rem;
}
.report-block-trade-form__user-balance-container .user-balance.user-balance--with-small-padding{
  padding-bottom: 2rem;
}
.form-control.ap-input__input.report-block-trade-form__input,
.form-control.ap-select__select.report-block-trade-form__select,
.form-control.ap-input__with-label.report-block-trade-form__with-label.ap-input__input.report-block-trade-form__input{
  border: 1px solid var(--border-gray-v2)!important;
  font-size: 1.4rem;
}
.report-block-trade-form__input-box .report-block-trade-form__input.form-control{
  padding-left: 8px;
}
.ap--label.ap-input__label.report-block-trade-form__label, 
.ap--label.ap-select__select-label.report-block-trade-form__select-label,
.ap--label.ap-label-with-text__label.report-block-trade-form__lwt-label,
.ap--label.ap-label-with-text__label.user-balance__lwt-label
{
  color: var(--text-gray-v2);
  font-weight: 400;

}
.ap-input__input-box .label-in-input, .report-block-trade-form__input-box .label-in-input {
  margin-top: 7px;
  font-size: 1.4rem;
  margin-right: 8px;
  right: 0;
}
.form-control.ap-input__with-label.report-block-trade-form__with-label.ap-input__input.report-block-trade-form__input{
  text-align: right;
  padding-right: 50px;
}
.form-control.ap-input__input.report-block-trade-form__input{
  text-align: right;
}
.ap-label-with-text__text.user-balance__lwt-text,
.ap-label-with-text__text.report-block-trade-form__lwt-text{
  font-size: 1.4rem;
}
.ap-button__btn.ap-button__btn--subtractive.report-block-trade-form__btn.report-block-trade-form__btn--subtractive,
.ap-button__btn.ap-button__btn--subtractive.report-block-trade-form__btn.report-block-trade-form__btn--subtractive:hover,
.ap-button__btn.ap-button__btn--additive.report-block-trade-form__btn.report-block-trade-form__btn--additive,
.report-block-trade-sidepane__container .report-block-trade-form__btn.report-block-trade-form__btn--additive.ap-button__btn:hover{
  border: none;
  border-radius: 8px;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: capitalize;
  position: absolute;
  right: 50px;
}
.ap-button__btn.ap-button__btn--subtractive.report-block-trade-form__btn.report-block-trade-form__btn--subtractive,
.ap-button__btn.ap-button__btn--subtractive.report-block-trade-form__btn.report-block-trade-form__btn--subtractive:hover{
  background-color: var(--error-color-v2);
}
.flex-table__body.orderbook__body{
  height: calc(69vh - 64px);
  flex: auto;
}
.flex-table__row.orderbook__order-row.orderbook__order-row--buy,
.flex-table__row.orderbook__order-row.orderbook__order-row--sell{
  background-color: var(--background-primary_v2)!important;
  padding: 1rem 0;
  height: 2.5rem!important;
}
.trade-component,
.trade-component > div{
  height: 100%!important;
}
.ap-padded-decimal, .ap-padded-decimal--sell, .ap-padded-decimal--buy{
  font-size: 1.4rem!important;
  font-weight: 300;
}
.ap-vertical-scroller.ap-vertical-scroller--at-rest.orderbook__vertical-scroller-body{
  background-color: var(--background-primary_v2);
}
.flex-table__header-cell.flex-table__header-cell--absolute.order-history-table__fixed{
  padding: 0 2rem;
}
.ap--label.ap-label-with-text__label.order-entry__lwt-label{
  color: var(--text-gray-v2)!important;
}
.display-none{
  display: none;
}
body #root .dark .order-entry .ap--label.verification-required__not-verified{
  color: white!important;
} 
@media only screen and (max-width: 480px){
  .pagination__pages {
    display: initial!important;
  }
}
@media only screen and (max-width: 785px){
  body #root .dark .order-history__tab{
    padding-bottom: 7rem;
  }
}
.click-here-coink{
  color: var(--orange-color);
  cursor: pointer;
  font-weight: bold;
}
.clic-here{
  color: var(--orange-color);
  cursor: pointer;
}
.custom-notice{
  width: 90%;
}
.custom-ui.custom-notice button{
  width: 100%;
}
.flex-table__row.activity__row{
  cursor: pointer;
}
.flex-table__row.activity__row:hover{
  background-color: var(--bg-dark-v2)!important;
}
.modal-open .modal .modal-footer .btn.product-details-modal{
  background: var(--secondary-color-v2);
  border: none!important;
  font-weight: 400;
}
.text-danger{
  color: var(--error-color-v2)!important;
}
.text-success{
  color: var(--success-color-v2)!important;
}
@media (width >= 600px) {
  .do-you-need-help{
    display: none!important;
  }
}
.receive-address .selected.dropdown.btn-group{
  margin-top: 2rem;
  margin-bottom: -1rem; 
}
.coink-input-amount *{
  text-align: right;
}
.coink-input-amount p{
  text-align: left;
  font-size: 1.6rem!important;
  top: -20px!important;
}
.coink-input-amount label input.inputnotdate{
  top: 4px!important;
  padding-left: 9rem;
}
.content-success-kyc{
  margin-top: 10rem;
}
.button-succes-delete.try-again-button{
  background: var(--tertiary-color-v2)!important;
}
.container-coink-buttons-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: row-reverse;
  width: 100%;
}
.container-coink-buttons-flex .contVertBTN{
  width: 45%!important;
}

@media (max-width:480px){
  .container-coink-buttons-flex{
    flex-direction: column;
  }
  .container-coink-buttons-flex .contVertBTN{
    width: 90%!important;
  }
  
}
.kyc-pagev2 .LevelForSingle.qty-1.levelForSingle-coink{
  height: 342px!important;
}
.sidepane-details__information.sidepane-details__information--text-external-box{
  width: 95%;
}
.receive-address__qr-code canvas{
  width: 100%!important;
  height: 100%!important;
  max-width: 240px;
  max-height: 240px;
  margin-top: 2rem;
}
@media (max-width:770px) {
  .send-receive__main{
    width: 100%;
    height: max-content;
  }
}
.coink-withdraw-confirmation .modal-header .modal-title.h4{
  margin-top: 4rem;
}
.one-line{
  max-width: 196px!important;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  text-align: right;
}

@media (max-width:480px) {
  .try-again-button, .button-succes-delete{
    margin-top: 0!important;
  }
  #p-freshchat{
    display: none;
  }
  .product-details-container .prod-header >div{
    font-size: 1.6rem;
  }
  .wallet-details__wallet-nav-items .wallet-nav-item:first-child {
    margin-bottom: 4px!important;
  }
  .product-details-page__transfers.product-details-container .pagination__pages .pagination__content.activity-pagination__content{
    width: max-content;
  }
 .product-details-page__transfers.product-details-container{
    margin: 2rem;
 }  
}
.rfm-marquee{
  animation-delay: 0;
}
.App.fluid.container.clearfix.logged.dark{
  overflow: hidden;
}
.pagination__page.order-history-table-pagination__page{
  border: none;
}
.instrument-selector-popover__content::after{
  border-bottom-color: transparent!important;
}

.form-control.ap-input__with-label.order-entry__with-label.ap-input__input.order-entry__input{
  border-width: 1px;
}
.advanced-order-form__main-section{
  padding: 0;
}
.advanced-order-form,
.advanced-order-form > div,
.advanced-order-form__inputs-container{
  width: 100%;
}
.ap-input__input-box .advanced-order-form__input.form-control, .report-block-trade-form__input-box .report-block-trade-form__input.form-control{
  height: 4rem;
  padding-left: 112px;
}
.available-amount-text{
  text-align: right;
  color: var(--text-gray-v2);
}
.language_v2_items.dropdown-menu.show{
  height: 100px!important;
  inset: 0!important;
}
.page-header-nav__item--selected, .page-header-nav__item--selected:hover, .page-header-nav__item:hover {
  background:  var(--background-primary_v2);
}
.slide-pane.slide-pane_from_right.ap-sidepane__container.ap-sidepane__container--with-tabs.advanced-order-sidepane__container.advanced-order-sidepane__container--with-tabs{
  width:100%!important;
  max-width: 400px;
}
.flex-table__row.orderbook__order-row.orderbook__order-row--sell *,
.flex-table__row.orderbook__order-row.orderbook__order-row--buy *{
  font-size: 1.2rem;
}
.line-gradient{
  background: linear-gradient(90deg, rgba(238,174,202,0) 0%, rgb(255 255 255 / 60%) 49%, rgba(148,187,233,0) 100%);
  height: 2px;
}
.base-lt__body{
  overflow: inherit!important;
}
.order-history__tab-content{
  margin-top: -15px; 
}
.d-none{
  display: none!important;
}
.external-link{
  color: var(--pc_font-secundary);
  font-size: 1.4rem; 
}
.order-book-section-title{
  background-color: #a8a8c736;
  border-radius: 20px;
  margin: 10px auto;
  padding: 5px 10px;
  width: max-content;
}
.trade-component, .trade-component > div{
  height: auto!important;
}
.tabs-orderbook-recent-trades{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
}
.tabs-orderbook-recent-trades *{
  font-size: 1.4rem;
  cursor: pointer;
}
.tabs-orderbook-recent-trades > div.active span{
  color: var(--pc_font-secundary);
}
.tabs-orderbook-recent-trades > div.active{
  position: relative;
}
.tabs-orderbook-recent-trades > div.active ::before{
  content: '';
  width: 60%;
  text-align: center;
  margin: auto;
  height: 2px;
  background-color: var(--pc_font-secundary);
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
}
.second-column .flex-table__wide-row-container{
  height: auto;
}

