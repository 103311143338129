.orders-book-title{
  font-size: 1.6rem;
  padding: .5rem 0 1rem 2rem;
  margin-bottom: -13px;
}
.orderbook__vertical-scroller-body::-webkit-scrollbar-thumb{
  background-color:var(--background-primary_v2);
  width: 0;
}
.orderbook__vertical-scroller-body::-webkit-scrollbar-track{
  background-color:var(--background-primary_v2);
  width: 0;
}
.flex-table__body.order-history-table__body{
  padding: 1rem 2rem;
}
.flex-table__row.order-history-table__row >div:nth-child(8){
  margin-left: 4rem!important;
}
.flex-table__row.order-history-table__row >div:nth-child(3){
  margin-left: 2rem!important;
}
.flex-table__row.order-history-table__row >div:nth-child(5),
.flex-table__row.order-history-table__row >div:nth-child(6){
  margin-left: 2.5rem!important;
}
.flex-table__header.order-history-table__table-header > div:nth-child(8){
  margin-left: 0!important;
}
.ap-vertical-scroller.ap-vertical-scroller--at-rest.orderbook__vertical-scroller-body, .ap-vertical-scroller.orderbook__vertical-scroller-body{
  padding: 0 1rem!important;
}