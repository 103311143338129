.circle-bxn {
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background: linear-gradient(120deg, rgb(254, 148, 67) 40%, rgb(253, 125, 59) 70%);
    box-shadow: 0 0 100px #ff993aa8;
}
.circle-bxn::after,
.circle-bxn::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: 50%;
}
.circle-bxn::after {
    background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0) 55%, rgba(255, 198, 137, 0.8) 80%);
}
.circle-bxn::before {
    background: radial-gradient(circle at 50% 30%, rgba(255, 255, 255, 0) 55%, rgba(255, 198, 137, 0.8) 80%);
}
.circle-bxn.purple {
    background: rgb(98, 13, 249);
    box-shadow: 0 0 100px #db3affa8;
}
.circle-bxn.purple::after {
    background-image: radial-gradient(circle at 50% 15%, rgba(0, 0, 0, 0) 30%, rgb(219, 58, 255) 70%);
}
.circle-bxn.purple::before {
    content: none;
}
