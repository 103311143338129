.ap-icon {
  display: inline-block;
  width: 10px;
}

.ap-icon--success {
  color: var(--success);
}

.ap-icon--info {
  color: var(--information__color);
}

.ap-icon--danger {
  color: var(--danger__color);
  height: 1rem;
}

.ap-icon--big {
  width: 14px;
  height: 14px;
}

.ap-icon--center {
  width: 12px;
}

.ap-icon--deposit-arrow,
.ap-icon--withdraw-arrow {
  width: 11px;
}

.ap-icon--exchange,
.ap-icon--balance,
.ap-icon--settings {
  width: 16px;
  margin-top: 3px;
  margin-bottom: -3px;
}

.ap-icon--generate-report {
  width: 12px;
}

.ap-icon--key {
  width: 16px;
}

.ap-icon--menu {
  width: 24px;
}

.ap-icon--refresh {
  width: 15px;
}

.ap-icon--widthraw-arrow {
  width: 11px;
}
