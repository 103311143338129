.wallet-card-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -2rem;
  min-height: 5rem;
  margin-bottom: 4rem;
}

.wallet-card-grid__header {
  padding: 3rem 3rem 3.6rem;
  font-size: 2.4rem;
}

/* Mobile */
@media (min-width: 320px) and (max-width: 767px) {
  .wallet-card-grid__header {
    font-size: 1.8rem;
    padding: 3rem 2.4rem 1.6rem;
  }

  .wallet-card-grid {
    margin: 0;
  }
}
