.fiat-withdraw-modal {
  display: flex;
  flex-direction: column;
  min-width: 40rem;
  border-radius: 1rem;
}

.fiat-withdraw-modal__content {
  padding: 2.8rem;
}

.fiat-withdraw-modal__lwt-container {
  height: 3.2rem;
  line-height: 3.2rem;
  border-top: 1px solid var(--border__color);
  justify-content: unset;
  font-size: 1.2rem;
}

.fiat-withdraw-modal__lwt-text {
  color: var(--text-color);
}

.fiat-withdraw-modal__lwt-label {
  min-width: 13.5rem;
  margin-right: 1rem;
}

.fiat-withdraw-modal__footer {
  margin-right: 2.5rem;
}

.fiat-withdraw-modal__btn {
  width: 100%;
  align-items: center;
  border-radius: 0.5rem;
  background: var(--success__bg-color);
  font-size: 1.4rem;
  height: 5rem;
  justify-content: center;
}

.fiat-withdraw-modal__title {
  font-size: 1.4rem;
  color: var(--font__color-primary);
  margin-bottom: 2rem;
}

.fiat-withdraw-modal__icon {
  margin-right: 1.6rem;
  width: 3.6rem;
  height: 3.6rem;
  color: var(--font__color-secondary);
}

.fiat-withdraw-modal__header {
  border-bottom: 2px solid var(--tab__border-color--selected);
  padding: 1.8rem;
}

.fiat-withdraw-modal__header-content {
  display: flex;
  align-items: center;
}

.fiat-withdraw-modal__header-title {
  font-size: 2rem;
  color: var(--font__color-primary);
}

.fiat-withdraw-modal__close-icon {
  color: var(--font__color-primary);
  padding: 1.4rem 1.1rem;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .fiat-withdraw-modal {
    min-width: 32rem;
    max-width: 32rem;
  }

  .fiat-withdraw-modal__content {
    max-height: 35rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
