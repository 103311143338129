.delivery-physical-card-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
}
.tooltip-recharge p {
  max-width: 100% !important;
}
.delivery-physical-card-page .address-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.delivery-physical-card-page .address-form h2 {
  text-align: center;
  font-size: 2rem;
}
.delivery-physical-card-page .address-form h3 {
  background-color: #2c2e44;
  width: 100%;
  padding: 2rem;
  border-radius: 1.5rem;
  font-size: 1.8rem;
  color: var(--text-gray-v2);
  font-weight: 300;
  margin: 1rem 0;
}
.delivery-physical-card-page .address-form .address-list {
  gap: 4rem;
  max-width: 500px;
}
.description-text {
  font-size: 1.4rem;
  color: var(--text-gray-v2);
}
.delivery-physical-card-page .address-form .address-list .list-remove {
  list-style: none;
  padding: 0;
}
.delivery-physical-card-page .address-form .address-list .list-remove .list-remove-option {
  padding: 1.5rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
  background: var(--background-primary_v2);
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  text-align: left;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto max-content;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.delivery-physical-card-page .address-form .address-list .list-remove .list-remove-option.selected {
  background-color: var(--bg-dark-v2);
}
.delivery-physical-card-page .address-form .address-list .list-remove .list-remove-option .option-info div {
  font-size: 1.4rem;
  color: var(--text-light);
  margin-bottom: 1rem;
}
.delivery-physical-card-page .address-form .address-list .list-remove .list-remove-option .option-info p {
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  margin: 0;
}
.delivery-physical-card-page .recharge-modal {
  width: 476px;
}
.delivery-physical-card-page .recharge-modal h2 {
  display: flex;
  gap: 0.5rem;
}
.delivery-physical-card-page .recharge-modal .container-text {
  margin-top: 2rem;
}
.delivery-physical-card-page .recharge-modal .container-text p {
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  text-align: center;
}
.delivery-physical-card-page .recharge-modal.confirmation .container-text p {
  text-align: left;
}
.delivery-physical-card-page .recharge-modal.confirmation .container-text p.address,
.delivery-physical-card-page .recharge-modal .container-text p b {
  color: var(--text-light);
  text-align: center!important;
}
.provider-available-location-province{
  max-width: 600px;
  margin-top: 2rem;
}
.other-location-first{
  max-width: 500px;
  margin-top: 2rem;
}
.provider-available-location-province .my-location,
.other-location-first .my-location{
  background: var(--background-primary_v2);
  width: 100%;
  padding: 1.5rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
  border: 1px solid #a8a8c72d;
}
.provider-available-location-province .my-location *,
.other-location-first .my-location *{
  font-size: 1.4rem;
}
.provider-available-location-province .my-location p,
.other-location-first .my-location p{
  color: var(--text-gray-v2);
  margin-top: 1rem;
}
.provider-available-location-province strong,
.other-location-first strong,
.other-location-delivery-form strong{
  margin-bottom: 2rem;
  margin-left: 10px;
  font-size: 1.4rem;
  font-weight: 500;
}
.provider-available-location-province .card-gradient,
.other-location-first .card-gradient{
  align-items: flex-start;
}
.delivery-scrollable-list{
  max-height: 323px;
  overflow-x:hidden;
  overflow-y: auto;
  padding-right: 1rem;
}
.remove-banexcard-page h2,
.title-main{
  font-size: 2.2rem;
  text-align: center;
  font-weight: 400;
  margin: 2rem 0 0;
}
.description-main{
  background-color: #2c2e44;
  width: 100%;
  padding: 2rem;
  border-radius: 1.5rem;
  font-size: 1.8rem;
  color: var(--text-gray-v2);
  font-weight: 300;
  margin: 1rem 0;
}
.other-location-delivery-form .input-element > div{
  background-color: var(--background-primary_v2);
}
.other-location-delivery-form .form{
  width: 100%;
}
.form-top,
.form-bottom{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 2.5rem;
  width: 100%;
}
.other-location-delivery-form.incomplete ul{
  margin: 2rem 0;
}