.activity {
  border-radius: 1rem;
  background: red;
  overflow: hidden;
  box-shadow: var(--component__box-shadow);
}

.activity__body {
  min-width: 80rem;
  max-height: 52rem;
  -webkit-overflow-scrolling: touch;
}

.fix-left-fluid-layout__main .activity:not(.activity__body) {
  margin-bottom: var(--wallet-details__body-margin);
  width: 100%;
}

.activity__header-column .activity__fixed {
  line-height: 3.3rem;
  margin-top: -3.3rem;
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  font-weight: 600;
  opacity: .5;
}

.activity__header-column:nth-child(2) .activity__fixed {
  padding-right: 2.8rem;
}

.activity__header-column:nth-child(3) .activity__fixed,
.activity__header-column:nth-child(4) .activity__fixed {
  padding-left: 2.6rem;
}

.flex-table__header.activity__table-header:nth-child(2) {
  height: 3.3rem;
  margin-top: -3.3rem;
}

.flex-table__column.activity__header-column,
.flex-table__column.activity__column {
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

.flex-table__column.activity__header-column {
  border-right: 1px solid var(--border__color);
  padding-top: 3.3rem;
}

.flex-table__column.activity__header-column:first-child,
.flex-table__column.activity__column:first-child {
  flex-grow: 2.3;
}

.flex-table__column.activity__header-column:last-child,
.flex-table__column.activity__column:last-child {
  flex-grow: 0.7;
  padding-right: 2rem;
  border-right: none;
}

.flex-table__column.activity__column:nth-child(2),
.flex-table__column.activity__header-column:nth-child(2) {
  justify-content: flex-start;
  padding-left: 2.6rem;
}

.activity__body {
  border-radius: 0;
}

.activity__header-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: var(--retail-dashboard__card-header-title-padding);
  padding-right: var(--retail-dashboard__card-header-title-padding);
  height: var(--retail-dashboard__card-header-height);
  justify-content: space-between;
  background-color: #404060!important;
  border-bottom: 2px solid var(--component__border-color--glow);
}

.activity__header-text {
  font-size: var(--retail-dashboard__card-header-title-font-size);
  color: var(--retail-dashboard__card-header-title-font-color);
}

.activity__select-buttons {
  display: flex;
  padding: 1rem 1rem;
  background-color: #404060;
}
.form-control.ap-datepicker__input.activity__from__dpk-input, .form-control.ap-datepicker__input.activity__to__dpk-input {
  width: 10rem;
  font-size: 1rem;
}
.activity__to__dpk-wrapper,
.activity__from__dpk-wrapper,
.activity__type__select-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.activity__to__dpk-input,
.activity__from__dpk-input,
.activity__type__select {
  width: auto;
  font-size: 1.2rem;
  background: var(--activity__select-bg-color);
  border: 1px solid var(--activity__select-border-color);
  height: 3.5rem;
  text-align: center;
  letter-spacing: 0.1rem;
}

.activity__to__dpk-input:not(.active):hover,
.activity__from__dpk-input:not(.active):hover {
  background: var(--input__bg-color--focus);
}

.activity__select-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.activity__select-label {
  margin: 0;
  margin-right: 0.5rem;
}

.activity__type__select-label {
  margin: 0 0.5rem 0 1rem;
  white-space: nowrap;
}

.activity__from__dpk-label {
  margin: 0 0.75rem 0 3rem;
  white-space: nowrap;
}

.activity__to__dpk-label {
  margin: 0 0.75rem;
  white-space: nowrap;
}

.activity__select {
  width: 12rem;
  background: var(--activity__select-bg-color);
  border: 1px solid var(--activity__select-border-color);
  height: 3.5rem;
  letter-spacing: 0.1rem;
  -webkit-appearance: none;
  cursor: pointer;
}

.activity__select-wrapper {
  display: inline-flex;
  position: relative;
}

.activity__select-label {
  margin-bottom: 0;
  margin-right: 0.75rem;
  line-height: 2.4rem;
  position: relative;
}

.activity__triangles-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 0.8rem;
  left: 18rem;
  top: 1rem;
  pointer-events: none;
}

.activity__action {
  display: flex;
  align-items: center;
}

.activity__type-icon,
.activity__product-icon {
  width: 3.6rem;
  height: 3.6rem;
  margin-right: 2.4rem;
  display: flex;
  align-items: center;
}

.activity__type-icon--open-buy,
.activity__type-icon--open-sell {
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
}

.activity__date,
.activity__status,
.activity__amount,
.activity__action-column {
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  justify-content: center;
  padding-right: 2.8rem;
  text-align: right;
}

.activity__status {
  text-align: left;
  overflow: hidden;
}

.activity__status,
.activity__date {
  padding-left: 2.6rem;
}

.activity__date-date,
.activity__status-id,
.activity__amount-quantity,
.activity__price,
.activity__order-type,
.activity__quantity,
.activity__action-text--primary {
  font-size: 1.6rem;
  color: var(--font__color-primary);
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.activity__quantity {
  display: flex;
}

.activity__date-time,
.activity__status-text,
.activity__amount-value,
.activity__action-text--secondary {
  text-align: left;
  font-size: 13px;
  color: var(--font__color-secondary);
}

.flex-table__row.activity__row {
  height: 7rem;
  line-height: 7rem;
  display: flex;
  padding: 0 2.8rem;
}

.activity__table-header {
  padding: 0 2.8rem;
}

.activity__header-column:first-child .flex-table__header-cell--absolute {
  border-left: none;
}

.activity__table-header--background {
  height: 3.3rem;
  border-top: 1px solid var(--table__row-separator-color);
  border-bottom: 2px solid var(--table__header-separator-color);
}

.activity__quantity-text {
  margin-top: 0.1rem;
  margin-left: 0.5rem;
  font-size: 1.2rem;
}

.activity__btn {
  height: 3.6rem;
  width: 12.8rem;
}

.activity__btn--clear-filters {
  height: 3.5rem;
  margin-left: 2rem;
  align-self: center;
}

.activity-pagination__container {
  background: var(--component__header-bg-color);
  height: 6.4rem;
  padding: 0 1.4rem;
}

.activity-pagination__text {
  height: 3.8rem;
  width: 12.5rem;
  border: 1px solid transparent;
  text-align: center;
  background: var(--table__row-bg-color);
  font-size: 1.4rem;
}

.activity-pagination__text--disabled {
  background: transparent;
}

.activity-pagination__pages {
  line-height: 3.6rem;
  text-align: center;
}

.activity-pagination__page {
  width: 3.6rem;
  padding: 0;
  background: var(--table__row-bg-color);
  font-size: 1.4rem;
}

.activity-pagination__page--active {
  background: var(--pagination__bg-color--active);
}

.activity-pagination__page--active:last-child {
  border-right: 1px solid var(--border_color);
}

/* BROWSER QUERIES */

/* IE11+ specific styles */
_:-ms-fullscreen, :root .activity__select{
  padding-right: 0;
}

_:-ms-fullscreen, :root .activity__select-label::before,
.activity__select-label::after {
  display: none;
}

_:-ms-fullscreen, :root .activity .flex-table__header-cell--absolute{
  position: relative !important;
}

_:-ms-fullscreen, :root .activity__header-bar {
  flex-wrap: nowrap;
}

/* MEDIA QUERIES */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .fix-left-fluid-layout__main .activity:not(.activity__body) {
    width: auto;
    margin: 0;
    border-radius: 0;
    border-top: 2px solid var(--border__color);
    box-shadow: none;
  }

  .activity__header-bar {
    flex-direction: column;
    height: unset;
    line-height: 7rem;
    align-items: flex-start;
  }
  .activity__select-buttons {
    flex-wrap: wrap;
    margin-bottom: 3rem;
  }

  .activity__select-wrapper,
  .activity__to__dpk-wrapper,
  .activity__from__dpk-wrapper,
  .activity__btn--clear-filters {
    margin-bottom: 1rem;
  }

  .activity__select-wrapper {
    width: 100%;
  }

  .activity__from__dpk-label {
    margin-left: 0;
  }

  .flex-table__wide-row-container {
    overflow-x: unset;
  }
.order-history-table-pagination__content  .flex-table__wide-row-container {
    overflow-x: hidden;
  }

  .activity-pagination__text {
    width: auto;
    padding: 0 1rem;
  }

  .activity__date,
  .activity__status,
  .activity__amount,
  .activity__action-column {
    width: 18rem;
  }

  /* BROWSER QUERIES */
  
  /* IE11+ specific styles */
  _:-ms-fullscreen, :root .activity__header-bar{
    height: 27rem !important;
  }

  _:-ms-fullscreen, :root .activity__header-text {
    width: 100%;
    align-items:flex-end;
  }

  _:-ms-fullscreen, :root .activity__select-buttons {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  _:-ms-fullscreen, :root .activity__to__dpk-wrapper,
  .activity__from__dpk-wrapper{
    width: 100%;
  }

  _:-ms-fullscreen, :root .activity__btn--clear-filters{
    margin-left: 0;
  }
}

@media only screen and (max-width : 576px) {
  .activity__select {
    padding: 0 0.5rem;
  }

  .activity__triangles-container {
    width: 0.8rem;
    left: 21rem;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .activity__select {
    width: 14rem;
  }

  .activity__triangles-container {
    left: 22.5rem;
  }

  .activity__header-bar {
    font-size: 1.6rem;
    padding: 0 1.5rem;
    padding-left: var(--retail-dashboard__card-header-title-padding);
  }

  .activity__btn--clear-filters {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    line-height: initial;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }

  .activity__to__dpk-label {
    margin-left: 0;
  }

  .activity__row {
    padding: 0 1.6rem;
  }

  .activity__body {
    border-radius: 1rem;
  }

  .flex-table__column.activity__column:last-child {
    padding: 0;
    flex-grow: 1.3;
  }

  .activity__type-icon, .activity__product-icon {
    margin-right: 1.6rem;
    width: 2.6rem;
  }

  .activity__product-icon--no-product-icon {
    display: none;
  }

  .activity__action-text--primary,
  .activity__amount-quantity {
    font-size: 1.4rem;
  }

  .activity__action-text--secondary,
  .activity__amount-value {
    font-size: 1.2rem;
  }

  .activity__select-buttons {
    margin-bottom: 0;
    padding: 1rem 1.5rem;
  }

  .flex-table__column.activity__header-column:first-child, .flex-table__column.activity__column:first-child {
    min-width: 34%;
  }

  .flex-table__column.activity__header-column, .flex-table__column.activity__column{
    min-width: 24%;
  }

  .flex-table__column.activity__header-column:last-child, .flex-table__column.activity__column:last-child {
    min-width: 18%;
  }

  /* BROWSER QUERIES */
  
  /* IE11+ specific styles */
  _:-ms-fullscreen, :root .activity__header-bar{
    line-height: normal;
  }

  _:-ms-fullscreen, :root .activity__header-text {
    padding-top: 2.5rem;
    padding-bottom: 1rem;
  }

  _:-ms-fullscreen, :root .activity__to__dpk-label{
    margin-left: 0;
  }
}