.swiping-funds{
  width: 80%;
  margin-bottom: 2rem;
  padding: 2rem;
  min-height: 200px;
}
.swiping-funds h1{
  font-size: 2rem; 
  font-weight: 600;
}
.swiping-funds p{
  color: var(--text-gray-v2);
  font-size: 1.2rem;
  width: 100%;
}
.swiping-funds label{
  display:flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1.4rem;
  cursor: pointer;
}
.swiping-funds label span{
  padding-left: 2.5rem;
  position: relative;
}
.swiping-funds .input-radio + span::before{
  content: '';
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #a8a8c7;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.swiping-funds .input-radio:checked + span::after{
  content: '';
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #FF9443;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
}
.swiping-funds p{
  color: #a8a8c7;
  line-height: 1.6;
}
.swiping-funds p.subtitle-bold{
  color: white;
  font-weight: 500;
  font-size: 1.6rem;
}

.selector-swiping{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}
.swiping-funds a{
  color: #FF9443;
  font-weight: 500;
  text-decoration: underline;
}
.swiping-spinner-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 200px;
}
@media (max-width: 1170px) {
  .swiping-funds{
    width: 100%;
  }
  .user-settings-page__container2{
    width: 100%!important;
  }
}
.asterisk-text{
  margin-left: 10px;
  font-style: italic;
}
.asterisk-text.first{
  margin-top: 4rem;
}
.description-swiping{
 margin-left: 2.1rem;
}

.withdrawal-label-first{
  margin-top: 2rem;
}
