.btn-user {
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px 1.5rem!important;
    text-align: left;
    width: 100%;
}
.btn-user:hover, .btn-user:focus  {
    background-color: rgb(17, 17, 17);
    border: none;
    box-shadow: none;
}
.v2-bnx.lighting {
    background-color: var(--background-primary_v2);
}
.v2-bnx.bg-bnx-light {
    background-color: var(--background-primary_v2);
}
.v2-bnx.bg-bnx-light::before, .v2-bnx.lighting::before {
    content: '';
    background-color: var(--background-primary_v2);
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -2;
}
.v2-bnx.blocked-window > .container > .v2-step{
    filter: blur(4px);
    pointer-events: none;
}
.v2-bnx .header-v2 {
    background-color: var(--background-primary_v2);
    padding: 1.5rem 0 15rem;
    margin-bottom: -13rem;
    transition: padding 0.5s, margin 0.8s;
}
.v2-bnx .header-v2.normal-header {
    padding: 1.5rem 0 3rem;
    margin-bottom: 3rem;
}
.v2-bnx .header-v2 > .container {
    display: flex;
}
.v2-bnx .upload_btn_clean .input-group {
    padding: 2px!important;
    margin: 0!important;
    border: 0px solid var(--btn-checked-v2)!important;
    background-color: var(--btn-checked-v2)!important;
}
.v2-bnx .was-validated .upload_btn_clean .error-field-group .input-group {
    border: 1px solid var(--btn-checked-v2)!important;
}
.v2-bnx .was-validated .upload_btn_clean .input-group {
    border: 1px solid var(--btn-checked-v2)!important;
}
.v2-bnx .step-container .upload_btn_clean .form-group:hover > .rounded-circle-box.input-group:hover {
    background-color: #ebeef0!important;
}
.v2-bnx .upload_btn_clean .input-group > .form-label {
    display: none;
}
.v2-bnx .upload_btn_clean .input-group > .form-control.uploader-btn {
    background-color: var(--pc-inputs-background)!important;
}
.v2-bnx .upload_btn_clean .input-group > .form-control.uploader-btn:hover {
    background-color: var(--pc-inputs-background)!important;
}
.v2-bnx .upload_btn_clean .form-text {
    text-align: center;
}
.v2-bnx .upload_btn_clean .form-text > a {
    font-size: 1.2rem;
    margin-top: 1rem;
    cursor: default;
}
.v2-bnx .upload_btn_clean .form-text.text-danger {
    width: 100%;
    font-size: 1.3rem;
    padding-bottom: 3rem;
    margin-top: -3rem;
}
.v2-bnx .container {
    width: 1100px;
    margin: 0 auto;
}
.v2-bnx .container .v2-step {
    width: auto;
}
.v2-bnx .container .cloudbox {
    width: 100%;
    border-radius: 1rem;
    padding: 2rem 2rem;
}
/* .v2-bnx .container .cloudbox.shadow {
    box-shadow: 0 0 20px 5px rgb(121 121 121 / 18%)!important;
} */
.v2-bnx .container .cloudbox.bg-dark {
    background-color: var(--pc__background-secondary_v2)!important;
}
.v2-bnx .container .cloudbox.bg-light {
    background-color: var(--pc__background-secondary_v2)!important;
}
.v2-bnx .steps-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
}
.v2-bnx .steps-bottom > .step-bttm {
    width: 3rem;
    height: 3rem;
    display: inline-flex;
    color: #585858;
    font-size: 1.6rem;
    align-items: center;
    justify-content: center;
    border: 2px solid #585858;
    background-color: white;
    border-radius: 1.2rem;
    margin: 0 1rem;
    font-weight: 500;
    cursor: default;
}
.v2-bnx .steps-bottom > .step-bttm.active {
    background: var(--secondary-color-v2);
    color: #fff;
    border: none;
}
.v2-bnx .steps-bottom > .step-bttm.previous {
    background: var(--secondary-color-v2);
    color: #fff;
    border: none;
}
.v2-bnx .steps-bottom > .step-line {
    height: 1px;
    width: 6rem;
    background-color: #fff;
}
.v2-bnx .box-light {
    background-color: var(--pc__background-secondary_v2);
    padding: 3.7rem 1.5rem;
    border-radius: 0.5rem;
    text-align: center;
    color: var(--pc_font-primary);
    margin: 1rem 0;
    box-shadow: 0 0 14px 0 rgb(0 0 0 / 10%);
}
.v2-bnx .box-light > h1 {
    font-weight: 700;
    font-size: 2.5rem;
}
.v2-bnx .box-light > h1 > b {
    font-weight: inherit;
    font-size: inherit;
    color: #FF9015;
}
.v2-bnx .box-light > p {
    font-size: 1.3rem;
    font-weight: 500;
}
.v2-bnx .box-light.onerow {
    display: flex;
    align-items: center;
    padding: 0;
}
.v2-bnx .box-light.onerow > * {
    height: 14rem;
}
.v2-bnx .box-light.onerow > .text-box {
    font-size: 2.3rem;
    font-weight: 700;
    color: var(--pc_font-primary);
    padding: 1.5rem 1rem 1.5rem 3rem;
    width: 40%;
    text-align: left;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
}
.v2-bnx .box-light.onerow > .text-box > b {
    display: block;
    font-weight: inherit;
    color: inherit;
}
.v2-bnx .box-light.onerow > .image-box {
    width: 60%;
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center;
}
.v2-bnx .box-light.onerow > .image-box.cloudbg::before {
    content: '';
    position: absolute;
    width: 151%;
    height: 305%;
    background: var(--pc__background-secondary_v2);
    display: block;
    border-radius: 50%;
    top: -30%;
    left: 1rem;
    z-index: 0;
    box-shadow: 0 0 13px 5px var(--pc__background-secondary_v2);
}
.v2-bnx .box-light.onerow > .image-box.cloudbg > img {
    z-index: 9;
    display: block;
    margin: 0 auto;
    position: relative;
    height: 80%;
    opacity: 0.9;
}
.v2-bnx .header-v2 .logo-v2 {
    width: 25%;
}
.v2-bnx .header-v2 .close-v2 {
    margin-left: auto;
    vertical-align: middle;
    display: flex;
    align-items: center;
}
.v2-bnx .header-v2 .close-v2 > svg {
    width: 50px;
    height: 50px;
    color: white;
}

.v2-bnx .container .v2-step .v2-box {
    background-color: var(--pc__background-secondary_v2);
    margin: 0 auto;
    padding: 2rem 5rem;
    border-radius: 2.5rem;
}
.v2-bnx .container .v2-step .v2-box.none-colors {
    background-color: transparent;
    box-shadow: none;
}
.v2-bnx .container .v2-step .v2-box.w-1 {
    width: 8.33333333333333%;
}
.v2-bnx .container .v2-step .v2-box.w-2 {
    width: 16.6666666666667%;
}
.v2-bnx .container .v2-step .v2-box.w-3 {
    width: 25%;
}
.v2-bnx .container .v2-step .v2-box.w-4 {
    width: 33.3333333333333%;
}
.v2-bnx .container .v2-step .v2-box.w-5 {
    width: 41.6666666666667%;
}
.v2-bnx .container .v2-step .v2-box.w-55 {
    width: 45.466667%;
}
.v2-bnx .container .v2-step .v2-box.w-6 {
    width: 50%;
}
.v2-bnx .container .v2-step .v2-box.w-7 {
    width: 58.3333333333333%;
}
.v2-bnx .container .v2-step .v2-box.w-8 {
    width: 66.6666666666667%;
}
.v2-bnx .container .v2-step .v2-box.w-9 {
    width: 75%;
}
.v2-bnx .container .v2-step .v2-box.w-10 {
    width: 83.3333333333333%;
}
.v2-bnx .container .v2-step .v2-box.w-11 {
    width: 91.6666666666667%;
}
.v2-bnx .container .v2-step .v2-box.w-12 {
    width: 100%;
}
.v2-bnx .container .v2-step .v2-box .v2-image {
    display: block;
}
.v2-bnx .container .v2-step .step-bnx > img.error-img {
    width: 94%;
}
.v2-bnx .container .v2-step .v2-box h1 {
    font-family: "Montserrat", Sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
    color: var(--pc_font-primary);
}
.v2-bnx .container .v2-step .v2-box h1.color-white {
    color: white;
    font-weight: 600;
}
.v2-bnx .container .v2-step .v2-box h1.color-white > b {
    color: var(--pc_font-secundary);
    font-weight: inherit;
}
.v2-bnx .container .v2-step .v2-box h3 {
    font-family: "Montserrat", Sans-serif;
    font-weight: 600;
    text-align: center;
    color: #a8a8c7;
    font-size: 1.4rem;
}
.v2-bnx .container .v2-step .v2-box h4 {
    margin: 0 0 1.5rem 0;
    font-weight: 600;
    position: relative;
}
.v2-bnx .container .v2-step .v2-box #fromUrl {
    width: 60%;
    margin-bottom: 2rem;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.16);
}
.v2-bnx .container .v2-step .display-inline-flex {
    display: inline-flex;
    vertical-align: top;
}
.v2-bnx .container .v2-step .display-inline-flex > * {
    width: 100%;
}
.v2-bnx .container .v2-step .display-inline-block {
    display: inline-block;
    vertical-align: top;
}
.v2-bnx .container .v2-step .display-inline-block > * {
    width: 100%;
    margin-bottom: 5px;
}
.v2-bnx .container .v2-step .step-bnx > img {
    width: 55%;
    margin: 3rem auto 0;
}
.v2-bnx .btn {
    width: 100%;
    padding: 1.26rem 1.7rem;
    font-size: 1.4rem;
    border-radius: 1.3rem;
    font-weight: 600;
    font-family: "Montserrat", Sans-serif;
}
.v2-bnx .btn.btn-v2-dark {
    background: var(--primary-color-v2);
    color: white;
}
.v2-bnx .btn.btn-v2-dark:hover {
    opacity: 0.9;
}
.v2-bnx .btn.block {
    display: block;
    margin: 0.5rem auto 1rem;
}
.v2-bnx .light-link {
    color: var(--pc_font-primary);
    font-weight: 600;
    font-size: 1.3rem;
}
.v2-bnx .light-link:hover {
    color: var(--pc_font-primary);
}
.v2-bnx .btn.btn-light {
    width: auto;
}
.v2-bnx .v2-mini-container {
    display: flex;
    font-family: "Montserrat", Sans-serif;
}
.v2-bnx .v2-mini-container .v2-step-mini {
    width: 33%;
    text-align: center;
    position: relative;
}
.v2-bnx .v2-mini-container .v2-step-mini .count {
    padding: 2rem 0;
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--pc_font-primary);
}
.v2-bnx .v2-mini-container .v2-step-mini .count:after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 60%;
    background-color: #A6A6A6;
    top: 3rem;
    right: -31%;
}
.v2-bnx .v2-mini-container .v2-step-mini:last-child .count::after {
    display: none;
}
.v2-bnx .v2-mini-container .v2-step-mini .text {
    color: var(--pc_font-primary);
    font-weight: 600;
    font-size: 1.4rem;
}
.v2-bnx .v2-step-form {
    display: block;
}
.v2-bnx .wrapper-boucing, .body-step-bnx .wrapper-boucing {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-bottom: 2rem;
    margin-top: -2rem;
}
.v2-bnx .wrapper-boucing .ball, .body-step-bnx .ball {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    margin: 0 10px;
    animation: 1.5s bounce ease infinite;
}
.v2-bnx .wrapper-boucing .ball{
    width: 15px!important;
    height: 15px!important;
}
.v2-bnx .wrapper-boucing .ball.first, .body-step-bnx .ball.first {
    background-color: #FEBE7F;
    animation-delay: .25s;
}
.v2-bnx .wrapper-boucing .ball.second, .body-step-bnx .ball.second {
    background-color: var(--pc_font-secundary);
    animation-delay: .5s;
}
.v2-bnx .wrapper-boucing .ball.third, .body-step-bnx .ball.third {
    background-color: #FEBE7F;
    animation-delay: .75s;
}
@keyframes bounce {
    50% {
      transform: translateY(20px);
    }
}

.v2-bnx .container .v2-step .v2-box .v2-general-title {
    font-size: 3.3rem;
    font-weight: 700;
    color: var(--pc_font-primary);
    margin-bottom: 2rem;
}
.v2-bnx .container .v2-step .v2-box .v2-general-title > b {
    font-weight: inherit;
    font-size: inherit;
    color: rgb(255, 144, 19);
}
.v2-bnx .container .v2-step .v2-box .v2-group-fields > h3 {
    text-align: left;
    margin-bottom: -0.5rem;
    font-weight: 700;
    font-size: 1.5rem;
}
.v2-bnx .step-container {
    display: none;
    margin: 0 auto;
}
.v2-bnx .step-container.active {
    display: block;
}
.v2-bnx .step-container .v2-group-fields > .row > div.col-xl-12 {
    padding-right: 0px;
    padding-left: 15px;
}
.v2-bnx .step-container .v2-group-fields > .row > div {
    padding-right: 0px;
    padding-left: 15px;
}
.v2-bnx form.was-validated .form-group.error-field-group > .rounded-circle-box {
    border: 2px solid var(--btn-checked-v2);
}
.v2-bnx form.was-validated .form-group > .rounded-circle-box {
    border: 1px solid #2fb05a;
}
.v2-bnx form.was-validated .form-group .form-control {
    background-image: none;
}
.v2-bnx .step-container .form-group {
    margin-bottom: 1.7rem;
    border-radius: 2rem;
}
.v2-bnx .step-container .form-group > .rounded-circle-box {
    padding: 0.7rem 1.6rem;
    background-color: var(--pc-inputs-background);
    border-radius: 2rem;
    border: 1px solid var(--btn-checked-v2);
    display: block;
    height: 66px;
}
.v2-bnx .step-container .form-group .uploader-btn {
    color: #202020;
    background-color: var(--pc-inputs-background);
    overflow: hidden;
    padding: 0 1rem!important;
    font-size: 1.2rem!important;
}
.v2-bnx .step-container .form-group .form-text > a {
    color: #9999B7;
    margin-top: .5rem;
    line-height: 1.3;
    font-weight: 500;
}
.v2-bnx .step-container .form-group .form-label {
    font-size: 1.2rem;
    font-weight: 550;
    margin: 0.2rem 0 0rem 0;
    line-height: 1.5;
    color: #a8a8c7;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
}
.v2-bnx .step-container .form-group .form-label.hide-label {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    height: 0;
}
.v2-bnx .step-container .form-group .form-label.hide-label+.form-control,
.v2-bnx .step-container .form-group .form-label.hide-label+.form-control ::placeholder{
    color: #9999B7 !important;
    font-weight: 400;
}
.v2-bnx .step-container .form-group .DatePicker {
    display: block;
}
.v2-bnx .step-container .form-group .rdp-input-group .form-control {
    display: inline-flex;
    width: auto;
}
.v2-bnx .step-container .form-group .form-control {
    border: none;
    background: transparent !important;
    font-size: 1.5rem;
    font-weight: 600;
    height: 3rem;
    padding: 0rem 0rem;
    color: var(--pc_font-primary)!important;
    width: 100%;
}
.v2-bnx .step-container .form-group .form-control:focus {
    box-shadow: none!important;
    color: #495057;
}
.v2-bnx .step-container .form-group:focus-within > .rounded-circle-box, .v2-bnx .step-container .form-group:hover > .rounded-circle-box {
    background-color: var(--pc-inputs-background)!important;
    color: var(--pc_font-primary);
    border: 1px solid var(--btn-checked-v2)!important;
}
.v2-bnx .step-container .form-group .form-control::placeholder {
    color: #9999B7;
    font-weight: 500;
    text-transform: none;
}
.v2-bnx .step-container .form-group .form-control > option {
    text-transform: none;
}
.v2-bnx .step-container .form-group .form-control:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #F5F7F9 inset;
}

.v2-bnx .step-container .form-group .form-control:-webkit-autofill:hover,
.v2-bnx .step-container .form-group .form-control:-webkit-autofill:focus,
.v2-bnx .step-container .form-group .form-control:-webkit-autofill:active,
.v2-bnx .step-container .form-group:focus-within .form-control:-webkit-autofill {
    box-shadow: 0 0 0px 1000px var(--pc-inputs-background) inset!important;
}
.v2-bnx .step-container .form-group .form-control.responsive-datepicker[readonly] {
    cursor: pointer;
}
.v2-bnx .step-container .form-group .form-control.uploader-btn {
    height: 16rem;
}
.v2-bnx .step-container .form-group .form-control.disabled-input {
    pointer-events: none;
}
.v2-bnx .v2-box .was-validated .form-control.is-invalid:focus-within {
    border: none;
}
.v2-bnx .v2-box .info_alert-v2 {
    padding: 0rem;
    border: 2px solid #f93434;
    color: #f93434;
    font-weight: 650;
    border-radius: 2.5rem;
    width: 20px;
    height: 20px;
    margin-left: .5rem;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.v2-bnx .v2-box .info_alert-v2:hover {
    border: 2px solid #d82222;
    color: #d82222;
    background-color: #ebebeb;
}
.v2-bnx .v2-box .v2-list-title {
    color: var(--pc_font-primary);
    font-size: 1.4rem;
    margin: 2rem 0 1rem;
    display: block;
}
.v2-bnx .v2-box .v2-list {
    margin: 0;
    padding: 0 0 0 1.5rem;
    list-style: none;
}
.v2-bnx .v2-box .v2-list > li {
    color: #a8a8c7;
    font-size: 1.3rem;
    margin-bottom: 0.7rem;
    font-weight: 500;
    list-style: auto;
}
.v2-bnx .v2-box .v2-list > li > a {
    font-weight: inherit;
    font-size: inherit;
    color: rgb(255, 144, 19);
}
.v2-bnx .v2-box .big-input .input-group.rounded-circle-box {
    padding: 0;
    border-style: dashed!important;
}
.v2-bnx .v2-box .input-group.rounded-circle-box.markaseditable {
    box-shadow: 0 0 2px 3px rgb(255 171 74);
}
.v2-bnx .v2-box .big-input .input-group.rounded-circle-box > .form-label {
    display: none;
}
.v2-bnx .v2-box .big-input .input-group.rounded-circle-box > .form-control {
    border-radius: 2rem;
}
.v2-bnx .step-container .form-group .react-tel-input .form-control.form-pay-phone-custom {
    padding: 0rem 0rem 0rem 4.5rem;
}
.v2-bnx .step-container .form-group .flag-dropdown {
    border: none;
    background-color: transparent;
}
.v2-bnx .step-container .form-group .invalid-feedback {
    line-height: 1.5;
}
.v2-bnx .step-container .form-group.error-field-group .invalid-feedback {
    display: block;
    margin-top: 0.5rem;
}
.v2-bnx .step-container .form-group .rdp-addon > .input-group-text {
    font-size: 2.5rem;
    background-color: transparent;
    font-weight: 400;
    line-height: 0;
    border-radius: 2.5rem;
    border: 2px solid #b4b4b4;
    width: 30px;
    padding: .375rem .6rem;
}
.v2-bnx .step-container .form-group .rdp-addon > .input-group-text:hover {
    background-color: rgb(255 249 247);
    border-color: #ff6550;
}
.v2-bnx .v2-form-bottom > .col-xl-12 {
    display: flex;
    padding-right: 0;
}
.v2-bnx .v2-form-bottom > .col-xl-12 > .btn {
    width: 100%;
    background: var(--primary-color-v2);
    border: none;
    color: #fff;
}
.v2-bnx .v2-form-bottom > .col-xl-12 > .btn:first-child {
    background: rgba(168, 168, 199, 0.2);
}
.v2-bnx .v2-form-bottom > .col-xl-12 > .btn:nth-child(2) {
    margin-left: 1rem;
}
.v2-bnx .v2-form-bottom .btn.btn-light.validated-success {
    color: white;
    background: var(--primary-color-v2);
    opacity: 1;
}
.v2-bnx .v2-form-bottom .btn.btn-light.validated-success:hover {
    opacity: 0.9;
}
.v2-bnx .row.message-box {
    margin-top: 1em;
}
.v2-bnx .row.message-box .alert {
    font-size: 1.5rem;
    background-color: #ff9014;
    font-weight: 600;
    border-color: transparent;
    color: #fff;
    margin-bottom: 4rem;
    border-radius: 4px;
}
.v2-bnx .inst-list {
    padding-left: 0;
}
.v2-bnx .inst-list > li {
    font-size: 1.4rem;
    text-align: justify;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #fff;
}
.v2-bnx .inst-list > li::marker {
    font-weight: 600;
}
.v2-bnx .inst-list > li.title {
    list-style: none;
}
.v2-bnx .inst-list > .sublist {
    padding-left: 2rem;
    font-weight: 500;
}
.v2-bnx .inst-list > .sublist > li {
    color: #fff;
    text-align: left;
    list-style: disc;
}
.v2-bnx .step-container .form-control.markaseditable {
    box-shadow: none;
}
.v2-bnx .step-container .form-group.markaseditable > .rounded-circle-box {
    box-shadow: 0 0 2px 3px rgb(255 171 74);
    border-color: transparent!important;
}
.v2-bnx .bottom-minus-fix .form-group {
    margin: 0 0 -3rem 0;
}
.error-box {
    text-align: left;
    margin: 0;
    padding: 1rem;
    list-style: none;
    background: #ffcfcf;
    border-radius: 0.5rem;
}
.error-box > li {
    font-size: 1.3rem;
    list-style: auto;
    padding-left: 0.5rem;
    margin-left: 1.5rem;
}
.carousel-bnx.carousel {
    display: flex;
    flex-direction: column;
}
.carousel-bnx .carousel-inner {
    order: 1;
    -webkit-order: 1;
}
.carousel-bnx .carousel-item {
    text-align: center;
}
.carousel-bnx .carousel-item > img {
    margin: 6rem auto;
}
.carousel-bnx .carousel-caption {
    position: relative;
    display: block;
    left: 0;
    bottom: 0;
}
.carousel-bnx .carousel-caption > h3 {
    font-size: 1.6rem!important;
    font-weight: 700!important;
}
.carousel-bnx .carousel-caption > p {
    color: var(--pc_font-primary);
    font-size: 1.4rem!important;
    font-weight: 500!important;
}
.carousel-bnx .carousel-control-next, .carousel-control-prev {
    position: absolute!important;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 8%!important;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity .15s ease;
}
.carousel-bnx .carousel-control-prev {
    left: 0;
}
.carousel-bnx .carousel-control-next {
    right: 0;
}
.carousel-bnx .carousel-control-prev-icon, .carousel-bnx .carousel-control-next-icon {
    filter: brightness(200%) contrast(200%) saturate(0%) 
}
.carousel-bnx .carousel-indicators {
    margin-top: 1rem;
    position: relative;
    order: 3;
    -webkit-order: 3;
}
.carousel-bnx .carousel-indicators li {
    background-color: grey;
    width: 10px;
    height: 10px;
    border-radius: 1.5rem;
    border: none;
}
.carousel-bnx .carousel-indicators li.active {
    background-color: #FD9025;
}
@media only screen and (max-width: 1200px) {
    .v2-bnx .v2-form-bottom > .col-xl-6 > .btn {
        margin-bottom: 1.5rem;
    }
    .v2-bnx .step-container .v2-group-fields > .row > div {
        padding-right: 0px;
        padding-left: 0px;
    }
    .v2-bnx .container {
        width: 100%!important;
    }
}
@media only screen and (max-width: 750px) {
    .v2-bnx .header-v2 .logo-v2 {
        width: 40%;
    }
    .v2-bnx .header-v2 .close-v2 > svg {
        width: 40px;
        height: 40px;
    }
    .v2-bnx .btn.btn-light {
        width: 70%;
    }
    .v2-bnx .container .v2-step .v2-box h4 {
        margin: 0 0 -0.5rem 0;
    }
    .v2-bnx .v2-form-bottom > .col-xl-12 {
        display: block;
    }
    .v2-bnx .v2-form-bottom > .col-xl-12 > .btn:nth-child(2) {
        margin-left: 0;
        margin-top: 1rem;
    }
}
@media only screen and (max-width: 515px) {
    .v2-bnx .header-v2 {
        padding: 0.5rem 0 15rem;
        margin-bottom: -14rem;
    }
}
@media only screen and (max-width: 1045px) {
    .v2-bnx .container .v2-step .v2-box.w-5 {
        width: 80%;
    }
}
@media only screen and (max-width: 905px) {
    .v2-bnx .container .v2-step .v2-box.w-55 {
        width: 70%;
    }
}
@media only screen and (max-width: 630px) {
    .v2-bnx .container .v2-step .v2-box.w-1,
    .v2-bnx .container .v2-step .v2-box.w-2,
    .v2-bnx .container .v2-step .v2-box.w-3,
    .v2-bnx .container .v2-step .v2-box.w-4,
    .v2-bnx .container .v2-step .v2-box.w-5,
    .v2-bnx .container .v2-step .v2-box.w-55,
    .v2-bnx .container .v2-step .v2-box.w-6,
    .v2-bnx .container .v2-step .v2-box.w-7,
    .v2-bnx .container .v2-step .v2-box.w-8,
    .v2-bnx .container .v2-step .v2-box.w-9,
    .v2-bnx .container .v2-step .v2-box.w-10,
    .v2-bnx .container .v2-step .v2-box.w-11,
    .v2-bnx .container .v2-step .v2-box.w-12 {
        width: 80%;
    }
    .v2-bnx .v2-mini-container .v2-step-mini .text {
        font-size: 1.2rem;
    }
}
@media only screen and (max-width: 480px) {
    .v2-bnx .container .v2-step .v2-box.w-1,
    .v2-bnx .container .v2-step .v2-box.w-2,
    .v2-bnx .container .v2-step .v2-box.w-3,
    .v2-bnx .container .v2-step .v2-box.w-4,
    .v2-bnx .container .v2-step .v2-box.w-5,
    .v2-bnx .container .v2-step .v2-box.w-55,
    .v2-bnx .container .v2-step .v2-box.w-6,
    .v2-bnx .container .v2-step .v2-box.w-7,
    .v2-bnx .container .v2-step .v2-box.w-8,
    .v2-bnx .container .v2-step .v2-box.w-9,
    .v2-bnx .container .v2-step .v2-box.w-10,
    .v2-bnx .container .v2-step .v2-box.w-11,
    .v2-bnx .container .v2-step .v2-box.w-12 {
        width: 100%;
    }
    .v2-bnx .container .v2-step .step-bnx > img {
        width: 80%;
    }
}

.form-control.responsive-datepicker {
    text-align: left;
}
.alert.alert-special-bnx {
    padding: 2.95rem 2.25rem;
    font-size: 2.0rem;
    text-align: center;
    border-radius: 1rem;
    margin: 1.7rem 0;
    background: #ff9014;
    border-color: transparent;
    color: #fff;
}
.alert.alert-special-bnx > span, .alert.alert-special-bnx > span > a {
    font-size: inherit;
}
.alert.alert-special-bnx > span > a {
    color: inherit;
    font-weight: 600;
}
.alert.alert-special-bnx > span > a:hover {
    text-decoration: underline!important;
}

/* coink mobile */
.loading-logos {
    display: block;
    position: relative;
    width: 80%;
    margin: 0 auto;
    height: 160px;
}
.loading-logos .coink-isom, .loading-logos .banexcoin-isom { 
    top: 20%!important;
}
.loading-logos .coink-isom {
    position: absolute;
    animation-name: coink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes coink {
    0%   {left:0px;}
    50%  {left:80%;}
    100%  {left:0px;}
}
.loading-logos .banexcoin-isom {
    position: absolute;
    animation-name: banexcoin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes banexcoin {
    0%   {left:80%;}
    50%  {left: 0%; }
    100%  {left:80%;}
}

.loading-logos > h3 {
    position: absolute;
    top: 83%;
    left: 0;
    right: 0;
    color: #373A39;
    font-weight: 600;
    font-size: 2.5rem;
}
.loading-logos > h3 > b {
    font-weight: inherit;
    font-size: inherit;
    color: #FD8C25;
}

@media only screen and (max-width: 370px) {
    .loading-logos {
        width: 80%;
    }
}

.text-loading-center {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 2.2rem;
}
.v2-bnx .step-container .form-group .form-control.uploader-btn{
    border-radius: 20px;
}

.v2-step .text-right.col-xl-12{
    gap: 30px;
}