.v2-navbar-bnx {
    display: flex;
    margin: 0 0 0 auto;
    justify-content: flex-end;
    align-items: center;
    font-size: 2.5rem;
    height: 55px;
    column-gap: 1.5rem;
    width: calc(100% - 3rem);
    max-width: 1200px;
    padding-right: 2rem;
}
.v2-navbar-bnx .qr-section {
    position: relative;
    height: 55px;
    display: flex;
    align-items: center;
    z-index: 2;
}
.v2-navbar-bnx .qr-section:hover >p {
    color: var(--orange-color);
    cursor: pointer;
}
.v2-navbar-bnx .qr-section .qr-container{
    position: absolute;
    background: var(--pc__background-secondary_v2);
    z-index: 99999;
    padding: 1.5rem;
    border-radius: 5px;
    top: 55px;
    box-shadow: 0px 0px 3px -1px rgb(0 0 0 / 42%);
    left: -20px;
}
.v2-navbar-bnx .qr-section .qr-container img{
    width: 160px;
    height: 160px;
}
.v2-navbar-bnx .qr-section .qr-container p{
    font-size: 1.4rem;
    text-align: center;
    margin: 2rem 0 0;
    color: var(--text-light);
}
.v2-navbar-bnx .dropdown {
    display: flex;
}
.v2-navbar-bnx .nav-top-items {
    height: 55px;
    padding: .195rem 1.55rem;
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: none;
    font-weight: 550;
    font-size: 1.2rem;
    border-radius: 0px;
}
body #root .dark .v2-navbar-bnx .nav-top-items {
    color: white;
}
body #root .dark .v2-navbar-bnx .nav-top-items svg path, body #root .dark .v2-navbar-bnx .nav-top-items svg circle {
    stroke: white;
}
body #root .light .v2-navbar-bnx .nav-top-items {
    color: rgb(46, 46, 46);
}
body #root .light .v2-navbar-bnx .nav-top-items > .img-ico {
    width: 20px;
    display: inline-flex;
    margin-right: 0.5rem;
}
.v2-navbar-bnx .nav-top-items > svg {
    font-size: 1.9rem;
}
.v2-navbar-bnx .nav-top-items > svg.arrow-toggle {
    font-size: 1.1rem;
    margin-left: 0.7rem;
}
.v2-navbar-bnx .nav-top-items:hover, .v2-navbar-bnx .nav-top-items:focus, .v2-navbar-bnx .nav-top-items:not(:disabled):not(.disabled).active, .v2-navbar-bnx .nav-top-items:not(:disabled):not(.disabled):active, .v2-navbar-bnx .show .nav-top-items.dropdown-toggle {
    background-color: inherit !important;   
    box-shadow: none!important;
}
.v2-navbar-bnx .nav-top-items.dropdown-toggle::after {
    display: none;
}
.v2-navbar-bnx .nav-top-items > .lang-img, .v2-navbar-bnx .dropdown-menu .dropdown-item > .lang-img {
    display: inline-flex;
    align-items: center;
    align-content: center;
    position: relative;
    border-radius: 44px;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-right: 1rem;
}
.v2-navbar-bnx .nav-top-items > .lang-img > img, .v2-navbar-bnx .dropdown-menu .dropdown-item > .lang-img > img {
    display: block;
    height: 150%;
}
.v2-navbar-bnx .dropdown-menu {
    background: var(--pc__background-secondary_v2);
    box-shadow: 0px 0px 3px -1px rgb(0 0 0 / 42%);
}
.v2-navbar-bnx .dropdown-menu .dropdown-divider {
    border-top: 1px solid var(--pc__background-secondary_v2);
    border-bottom: 1px solid var(--pc__background-secondary_v2);
    margin: .1rem 0 0;
}
.v2-navbar-bnx .dropdown-menu .dropdown-header {
    font-size: 1.4rem;
    font-weight: 300;
}
.v2-navbar-bnx .dropdown-menu .dropdown-header.username {
    color: white;
    padding-top: 1.3rem;
}
.v2-navbar-bnx .dropdown-menu .dropdown-header.email {
    color: #c7c5c5;
    padding-bottom: 1rem;
    font-size: 1.3rem;
}
.v2-navbar-bnx .dropdown-menu .dropdown-header.title-header {
    padding-top: 1.5rem;
    color: var(--pc_font-primary);
}
.v2-navbar-bnx .dropdown-menu.normal-childrens .dropdown-item {
    font-size: 1.4rem;
}
.v2-navbar-bnx .dropdown-menu .dropdown-header .level-info {
    cursor: default;
}
.v2-navbar-bnx .dropdown-menu .dropdown-header .level-info .level-name {
    font-size: 1.4rem;
    color: #FD9025;
    font-weight: 500;
}

.v2-navbar-bnx .dropdown-menu .dropdown-header .level-info .is-verified {
    background: rgb(255 64 64 / 49%);
    padding: 0.5rem;
    border-radius: 5px;
    color: #fdd8d8;
    font-style: italic;
    margin-left: 1rem;
    font-size: 1rem;
}
.v2-navbar-bnx .dropdown-menu .dropdown-header .level-info .is-verified.verified {
    background: var(--background-primary_v2);
    color: #47C65D;
}
.v2-navbar-bnx .dropdown-menu .dropdown-item {
    text-transform: none;
    font-size: 1.2rem;
    padding: 10px 15px;
    display: flex;
    align-items: center;
}
.v2-navbar-bnx .dropdown-menu .dropdown-item > svg {
    margin-right: 0.5rem;
}
.v2-navbar-bnx .dropdown-menu .dropdown-item > svg path, .v2-navbar-bnx .dropdown-menu .dropdown-item > svg circle {
    stroke: white;
}
.v2-navbar-bnx .dropdown-menu .dropdown-item:hover, .v2-navbar-bnx .dropdown-menu .dropdown-item:focus, .v2-navbar-bnx .dropdown-menu .dropdown-item:active {
    color: var(--orange-color);
    background-color: var(--background-primary_v2);
    border: none;
}
.v2-navbar-bnx .dropdown-menu .dropdown-item:hover svg path,
.v2-navbar-bnx .dropdown-menu .dropdown-item:focus svg path,
.v2-navbar-bnx .dropdown-menu .dropdown-item:active svg path {
    stroke: #E97205;
}

@media only screen and (max-width: 535px) {
    .v2-navbar-bnx .nav-top-items {
        padding: .195rem 1rem;
    }
    .v2-navbar-bnx .nav-top-items.language {
        font-size: 0;
    }
    .v2-navbar-bnx .nav-top-items.language .lang-img {
        margin-right: 0.3rem;
    }
    .v2-navbar-bnx .nav-top-items.desktop {
        display: none;
    }
}
.tooltip-user [class$="arrow"] {
    display: none;
}

.tooltip-inner {
    background-color: var(--pc__background-secondary_v2) !important;
    border-radius: 10px!important;
    overflow: hidden;
    padding: 0!important;
    color: var(--pc_font-primary);
    }
  
    .tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: var(--pc__background-secondary_v2) !important;
    border-radius: 10px!important;  
    }
  
   .tooltip-container{
       min-width: 7rem;
       width: 100%;
       padding: 0 10px;
   }

.v2-navbar-bnx .text-app{
    margin: 0;
    font-size: 1.4rem;
    font-weight: 400;
}
.v2-navbar-bnx .item-profile,
.v2-navbar-bnx .item-setting{
    column-gap: 1rem;
    padding: 0;
}
.v2-navbar-bnx .item-profile p{
    margin: 0;
    font-size: 1.4rem;
    font-weight: 400;
}
.v2-navbar-bnx .item-profile div{
    background: var(--pc__background-secondary_v2);
    border-radius: 2rem;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pending-notification-bar{
    border-radius: 1.5rem!important;
    background: var(--pc__background-secondary_v2)!important;
}
.pending-notification-bar .table-responsive{
    max-height: 300px;
    overflow: auto;
    margin-bottom: .7rem;
}
@media screen and (max-width: 800px){
    .v2-navbar-bnx .visibility {
        display: none !important;
    }
}