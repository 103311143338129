.kyc-confirm-level__overlay{
  overflow-y: scroll;
}

.kyc-confirm-level {
  width: 66rem;
  border-radius: var(--standalone-form__border-radius);
  top: 0rem;
  margin: auto;
}

.kyc-confirm-level__header{
  font-size: var(--standalone-modal__header-font-size);
  border-bottom: 2px solid var(--standalone-form__header-border-color);
}

.kyc-confirm-level__description {
  font-size: var(--standalone-modal__description-font-size);
  line-height: 2rem;
  color: var(--standalone-modal__description-font-color);
}

.kyc-confirm-level__email {
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--font__color-secondary);
}

.kyc-confirm-level__sections-wrapper {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  border-top: 1px solid var(--border__color);
  display: flex;
}

.kyc-confirm-level__section {
  flex: 1;
  flex-shrink: 0;
  margin: 0 1.5rem;
}

.kyc-confirm-level__section:last-child {
  margin-right: 0;
}

.kyc-confirm-level__section:first-child {
  margin-left: 0;
}

.kyc-confirm-level__section-header {
  display: block;
  margin-bottom: 1.5rem;
  color: var(--font__color-primary);
}

.kyc-confirm-level__section-field {
  display: flex;
  justify-content: space-between;
  height: 3.2rem;
  line-height: 3.2rem;
  padding: 0 1.2rem;
  background: var(--primary__bg-color);
  border-bottom: 1px solid var(--border__color);
}

.kyc-confirm-level__section-field:last-child {
  border-bottom: none;
}

.kyc-confirm-level__section-field-label,
.kyc-confirm-level__section-field-value {
  color: var(--font__color-secondary);
}

.kyc-confirm-level__section-field-value-img {
  width: 3rem;
}

.kyc-confirm-level__section-field-value {
  color: var(--text-color);
}

.kyc-confirm-level__btn--success{
  background:var(--success__bg-color);
}

.kyc-confirm-level__btn--success-focus{
  border-color:var(--success__bg-color);
}

.kyc-confirm-level__btn--success:hover{
  background:var(--success__bg-color--hover);
}

/* MEDIA QUERIES */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .kyc-confirm-level {
    width: var(--standalone-form__container-width);
  }

  .kyc-confirm-level__sections-wrapper {
    flex-direction: column;
  }
  
  .kyc-confirm-level__section{
    margin: 0;
  }

  .kyc-confirm-level__section:last-child {
    margin-top: 1.5rem;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .kyc-confirm-level {
    width: calc(100% - 4rem);
  }
}