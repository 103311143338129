.eotc-buy-sell-layout__col {
  margin-top: 50px;
}

.eotc-buy-sell-layout__col--lg {
  width: 100%;
}

.eotc-buy-sell-layout__accent-bar {
  height: 21.5rem;
  margin-bottom: -21.5rem;
  background-image: var(--accent-bar__gradient);
  width: 100%;
  flex-shrink: 0;
}

.eotc-buy-sell-layout__container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1 0 auto;
}

.eotc-buy-sell-layout__footer {
  margin-top: 8rem;
  flex-shrink: 0;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .eotc-buy-sell-layout__footer {
    margin-top: 3.4rem;
  }
}


/* NEW UI */
.eotc-buy-sell-layout__right-panel-divider {display: none;}
.buy-sell-make-transaction__content {
  border: none;
}
.buy-sell__container {
  background: white;
  border: 2px solid #F1F1F1;
}
.buy-sell-make-transaction__title, .buy-sell-order-overview__title {
  padding: 0 0 2.8rem 0rem;
  font-size: 2rem;
  font-weight: 500;
  background-color: #404060;
}
.eotc-buy-sell-layout__make-transaction-col-1 {
  border-right: none;
  width: 47%;
}
.eotc-buy-sell-layout__make-transaction-col-2 {
  width: 53%;
}

.buy-sell__tab {
  border: none;
  background-color: #404060;
}

.buy-sell__tab--buy-selected, .buy-sell__tab {
  height: 4rem;
  line-height: 4rem;
}
.buy-sell__tab--buy-selected {
  background: var(--success-color-v2);
  border-bottom: 2px solid var(--success-color-v2);
  color: white;
}
.buy-sell__tab--buy-selected:hover {
  color: #fff !important;
}
.buy-sell__tab--sell-selected {
  background: var(--error-color-v2);
  border-bottom: 2px solid var(--error-color-v2);
  color: white;
}
.buy-sell__vertical-divider {
  display: none;
}

.buy-sell__amount-container {
  border: none;
}
.buy-sell__container .ap-select__select option {
  background: var(--pc__background-secondary_v2);
  line-height: 3rem;
}
.buy-sell__amount-header, .ap--label {
  color: rgb(36, 36, 36);
  font-weight: 600;
}
.buy-sell__amount-row {
  color: rgb(36, 36, 36);
  border-bottom: none;
}
.buy-sell__amount-row div {
  font-weight: 500;
}
.buy-sell__amount-container .buy-sell__last-in-row {
  font-family: Roboto,sans-serif;
  flex: 1 1 auto;
  letter-spacing: 0.4px;
  white-space: nowrap;
  font-weight: 400;
}

.ap-radio__label {
  position: relative;
  overflow: hidden;
  height: 22px;
  border-radius: 4rem;
  padding: 0px;
  margin: 0;
}
.ap-radio__checked {
  width: 1.3rem;
  height: 1.3rem;
  top: 0.5rem;
  left: 0.5rem;
}
.ap-radio input[type='radio']:checked ~ label > .ap-radio__check {
  background: #ED8300;
  border: none;
}
.ap-radio input[type='radio']:checked ~ label > .ap-radio__checked {
  background: #FFA638;
}
.ap-radio__check {
  top: 0.8rem;
  left: 0.8rem;
}
.buy-sell__container .ap-radio__check {
  background: #b6b6b6;
}
.ap-radio__checked {
  opacity: 1;
  background: #dfdfdf;
}

.buy-sell__confirm-button-container .ap-button__btn.buy-sell__btn {
  background: var(--success-color-v2) !important;
  border: 1.5px solid var(--success-color-v2);
  color: #ffffff;
}
.buy-sell__confirm-button-container .ap-button__btn.buy-sell__btn:disabled {
  cursor: no-drop;
  opacity: 0.5;
}
.buy-sell__confirm-button-container .ap-button__btn.buy-sell__btn:hover, .buy-sell__confirm-button-container .ap-button__btn.buy-sell__btn:focus {
  opacity: 0.9;
}
.buy-sell__confirm-button-container .ap-button__btn.buy-sell__btn:focus {
  box-shadow: none;
  outline: none;
}
.buy-sell__input-box .buy-sell__input {
  background: white;
  border-width: 0 0 2px 0;
  border-color: #b6b6b6;
  border-radius: 0;
}
.buy-sell__input:hover, .buy-sell__input:focus {
  background: white;
  border-bottom: 2px solid #FF9013;
  outline: none;
}
.buy-sell-order-overview {
  padding: 2rem 0 2.8rem 2.8rem;
}
.buy-sell-order-overview__content {
  display: block;
  position: relative;
}
.buy-sell-order-overview__lwt-container {
  width: 50%;
  display: inline-flex;
  border-top: none;
  margin-bottom: 1.5rem;
}
.buy-sell-order-overview__lwt-container .buy-sell-order-overview__lwt-text {
  text-align: left;
  font-weight: 600;
}
.buy-sell-order-overview__lwt-container .ap--label {
  font-weight: 500;
  color: rgb(95, 95, 95);
  order: 1;
}
.buy-sell-order-overview__lwt-container .ap--label, .buy-sell-order-overview__lwt-container .buy-sell-order-overview__lwt-text {
  display: block;
  width: 100%;
}

.buy-sell-balances__lwt-container {
  border-top: none;
  display: inline-grid;
  width: 50%;
}

.eotc-buy-sell-layout__make-transaction-container{
  background-color: #404060;
}

.buysell-calculator {
  margin: 10px auto 20px;
  background-color: var(--pc__background-secondary_v2);
  padding: 20px;
  border-radius: 2rem;
  color: white;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
}
.buysell-calculator .main-select {
  width: 100%;
  position: relative;
}
.buysell-calculator .main-select select {
  font-size: 1.3rem;
  background: #2e2e48;
  color: white;
  font-weight: 500;
  border-radius: 1.5rem;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  width: 100%;
  padding: 22px 12px 8px;
}
.buysell-calculator .main-select:after {
  content: url('/static/media/arrow-down.svg');
  right: 19px;
  top: 19px;
  padding:0 0 2px;
  position:absolute;
  pointer-events:none;
}
.buysell-calculator .main-select select > option {
  background-color: #383857!important;
  color: white;
}
.buysell-calculator .main-select .select-label {
  position: absolute;
  top: 15%;
  left: 1.3rem;
  color: #a8a8c6;
  font-weight: 500;
  pointer-events: none;
}
.buysell-calculator .buysell-details {
  border: none;
}
.buysell-confirm{
  width: 90%;
}
.buysell-calculator .buysell-details tr > td, .modal.buysell-confirm .buysell-details tr > td {
  border: none;
}
.buysell-calculator .buysell-details tr > td.text-left {
  font-size: 1.4rem;
  font-weight: normal;
  color: var(--text-gray-v2)!important;
}
.buysell-calculator .buysell-details tr > td.text-right {
  font-size: 1.6rem;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
  white-space: nowrap;
}
.buysell-calculator .buysell-details tr > td.text-right >b{
  font-size: 1.6rem;;
}
.buysell-calculator .buysell-details tr > td.substract {
  color: #f55331!important;
}
.buysell-calculator .buysell-body {
  position: relative;
}
.buysell-calculator .buysell-body .buysell-available-balance {
  text-align: right;
  font-size: 1.6rem;
  line-height: 1.6rem;
}
.buysell-calculator .buysell-body .input-element {
  margin-top: 1.5rem;
}
.buysell-calculator .buysell-body .input-element > div {
  height: 60px;
}
.buysell-calculator .buysell-body .input-element .label-text > input {
  font-size: 2.3rem;
}
.buysell-calculator .buysell-body .input-element .label-text > p.empty {
  top: -21px;
}
.buysell-calculator .buysell-body .input-element .inp-icon-element > img {
  width: 34px;
}
.buysell-calculator .buysell-body > .is-switch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 0px;
  padding-right: 20px;
  margin-bottom: -9px;
  margin-top: 15px;
}
.buysell-calculator .buysell-body .switch-side {
  width: 55px;
  height: 55px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: all .45s ease;
  z-index: 1;
}
.buysell-calculator .buysell-body .switch-side.rotate {
  transform: rotate(180deg);
}
.buysell-calculator .buysell-body .switch-side > svg {
  z-index: 11;
  position: relative;
  width: 24px;
}
.buysell-calculator .buysell-body .switch-side .fondo {
  width: 60px;
  height: 60px;
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal.buysell-confirm .modal-dialog {
  background-color: #383857;
  color: white;
  padding: 16px 5px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  height: fit-content;
  border-radius: 2rem;
  margin: auto;
}

.modal.buysell-confirm .modal-header {
  text-align: center;
}
.modal.buysell-confirm .modal-header .modal-title {
  width: 100%;
}

.modal.buysell-confirm .buysell-details tr > td {
  color: white;
  font-size: 1.2rem;
}
.modal.buysell-confirm .buysell-details tr > td b {
  font-size: 1.6rem;
}
.modal.buysell-confirm .buysell-details tr > td.text-right {
  font-size: 1.4rem;
  max-width: 166px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.modal.buysell-confirm .buysell-details tr > td.text-right.substract {
  color: #f55331;
  font-weight: 500;
}


.modal.buysell-confirm .modal-footer {
  border-top: none;
}
.buysell-loader-bg {
  position: absolute;
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(31 31 51 / 62%);
  z-index: 99;
  border-radius: 2rem;
}

.buysell-loader-bg > .spinner-border {
  zoom: 2;
}

.button-enable-buysell {
  margin: auto;
  display: block;
  background: linear-gradient(90deg, #FF9443 0%, hsl(16, 100%, 61%) 90%);
  background: var(--primary-color-v2);
  color: white !important;
  border: none;
  padding: 1.4rem 1.75rem;
  border-radius: 1.55rem;
  font-size: 1.6rem;
  font-weight: 500;
}
.buysell-container {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.buysell-container .buysell-header{
  display: grid;
  grid-template-columns: auto auto;
  text-align: center;
  background: var(--hover-color-v2);
  border-radius: 2rem;
  padding: .5rem;
}
.buysell-container .buysell-header >div{
  font-size: 1.8rem;
  padding: 1.3rem;
  cursor: pointer;
  border-radius: 1.7rem;
}
.buysell-container .buysell-header >div.buysell-active{
  background: var(--pc__background-secondary_v2);
}
.buysell-container .buysell-header >div:first-child{
  color: var(--success-color-v2);
}
.buysell-container .buysell-header >div:last-child{
  color: var(--error-color-v2);
}
.buysell-container .disable-btn-buysell{
  opacity: .5;
  pointer-events: none;
}