.exchange.range_container {
  position: relative;
  margin-top: .5rem;
}
.exchange .range_bar {
  width: 100%;
}
.exchange .progress_bar {
  -webkit-appearance: none;
  position: absolute;
  top: 2px;
  width: 100%;
  margin: auto;
}
.exchange .progress_bar::-webkit-progress-bar {
  background-color: var(--bg-dark-v2);
  border-radius: 999px;
  height: 15px;
  -webkit-height: 15px;
}
.exchange .progress_bar::-webkit-progress-value {
  background-color: #ff8701;
  -webkit-background-color: #ff8701;
  border-radius: 999px;
  -webkit-border-radius: 999px;
}
.exchange input[type="range"]::-webkit-slider-runnable-track {
  background-color: transparent;
  height: 15px;
  border-radius: 999px;
  z-index: 1;
}
.exchange .range_bar {
  opacity: 0;
}
.exchange .buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.exchange .buttonOption {
  color: var(--text-gray-v2);
  font-size: 14px;
}
.exchange .range_bar,
.exchange .progress_bar::-webkit-progress-bar {
  height: 10px;
}
.exchange .progress_bar::-webkit-progress-value {
  background-color: #ff9443;
  -webkit-background-color: #ff9443;
}
.exchange .range_container .buttonOption {
  position: relative;
}