.send-receive-two-factor-auth-modal {
  padding-left: 0.6rem;
}

.send-receive-two-factor-auth-modal__text-item {
  color: var(--font__color-secondary);
  font-size: 1.3rem;
  margin-bottom: 2rem;
  width: 30rem;
}

.send-receive-two-factor-auth-modal__text-item__failed {
  color: var(--exchange-colors__sell);
  padding-bottom: 2rem;
  text-align: center;
}

.send-receive-two-factor-auth-modal__btn {
  width: 20rem;
}

.send-receive-two-factor-auth-modal__footer {
  border-top: 1px solid var(--border__color);
  padding-bottom: 0.6rem;
}
