.card-page {
    display: flex;
    justify-content: center;
    margin-top: 7rem;
    padding: 0 2rem 4rem;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 3rem;
    row-gap: 3rem;
}
.card-page .card-container {
    position: relative;
    overflow: hidden;
    border-radius: 2rem;
}
.card-page .card-space {
    width: 100%;
    max-width: 480px;
    background: var(--pc__background-secondary_v2);
    padding: 3.2rem 4.4rem;
    border-radius: 2.2rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}
.card-page .card-space.right-transition {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -100%;
    transition: right 0.3s;
}
.card-page .card-space.right-transition.show-component {
    right: 0;
}
.card-page .card-space p {
    margin: 0;
}
.card-page .card-space h2 {
    text-align: center;
}
.card-page .card-space > img {
    width: 100%;
    max-width: 270px;
    margin: auto;
    pointer-events: none;
}
.card-page .card-space .card-details {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.card-page .card-space .card-details .card-section-info {
    background: linear-gradient(270deg, rgba(168, 168, 199, 0.15) -20%, rgba(255, 255, 255, 0) 100%);
    padding: 1.5rem 2rem;
    border-radius: 2.4rem;
}
.card-page .card-space .card-details .card-section-info.card-with-icon {
    display: grid;
    grid-template-columns: max-content auto max-content max-content;
    column-gap: 1.6rem;
    align-items: center;
    color: var(--text-gray-v2);
    font-size: 1.4rem;
    cursor: pointer;
    width: 340px;
}
.card-page .card-space .card-details .card-section-info.main-details {
    display: flex;
    justify-content: space-between;
}
.card-page .card-space .card-details .card-section-info.main-details .detail-card {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    font-size: 1.6rem;
}
.card-page .card-space .card-details .card-section-info.main-details .detail-card .user-name {
    font-weight: 600;
}
.card-page .card-space .card-details .card-section-info.main-details .detail-card .user-card-number {
    font-size: 1.8rem;
    font-weight: 300;
}
.card-page .card-space .card-details .card-section-info.main-details .detail-card > div:last-child {
    display: flex;
    justify-content: space-between;
    width: 180px;
    font-size: 1.4rem;
    color: var(--text-gray-v2);
}
.card-page .card-space .card-details .card-section-info.main-details .detail-card > div:last-child span {
    color: var(--text-light);
    font-weight: 500;
}
.card-page .card-space .card-details .card-section-info.main-details .counter-card {
    padding: 1rem 2rem;
    background: var(--pc__background-secondary_v2);
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    border-radius: 2rem;
    letter-spacing: 0px;
}
.card-page .card-space .card-details .card-section-info.main-details .actions-card {
    display: grid;
    grid-template-rows: auto max-content;
    width: 80px;
}
.card-page .card-space .card-details .card-section-info.main-details .actions-card .list-btn-card {
    display: flex;
    column-gap: 2rem;
    justify-content: flex-end;
    margin-bottom: 0.4rem;
}
.card-page .card-space .card-details .card-section-info.main-details .actions-card .list-btn-card button img {
    width: 20px;
}
.card-page .card-space .card-details .card-section-info.card-with-icon.last-info {
    grid-template-columns: max-content auto max-content;
    cursor: default;
}
.card-page .card-space .card-details .card-section-info.card-with-icon > div {
    background: var(--card-info-color);
    padding: 1.3rem;
    border-radius: 1.5rem;
}
.card-page .card-space .card-details .card-section-info.card-with-icon > div img {
    width: 15px;
}

.card-page .card-space .ammount-section {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}
.card-page .card-space .ammount-section > div:first-child {
    display: flex;
    justify-content: space-between;
}
.card-page .card-space .ammount-section > div:first-child p {
    color: var(--text-gray-v2);
    font-size: 1.2rem;
}
.card-page .card-space .ammount-section > div:first-child p.amount {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--text-light);
}
.card-page .card-space .ammount-section .line {
    width: 100%;
    height: 7px;
    background: var(--bg-dark-v2);
    border-radius: 2rem;
}
.card-page .card-space .ammount-section .line div {
    width: 0;
    height: 7px;
    background: var(--text-light);
    border-radius: 2rem;
}
.card-page .card-space .transaction-list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    overflow: auto;
    padding: 0;
}
.card-page .card-space .transaction-list.more-item {
    padding-right: 1rem;
}
.card-page .card-space .transaction-list .loader-transaction{
    display: flex;
    flex-direction: column;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
}
.card-page .card-space .approved-line {
    color: var(--text-gray-v2);
    font-size: 1.2rem;
}
.card-page .card-space .transaction-list .transaction-item {
    background: var(--card-text-color);
    padding: 1.5rem 2rem;
    border-radius: 2rem;
}
.card-page .card-space .transaction-list .transaction-item > div:first-child {
    display: grid;
    grid-template-columns: max-content auto max-content;
    align-items: center;
    column-gap: 1rem;
    font-size: 1.2rem;
}
.card-page .card-space .transaction-list .transaction-item > div:first-child span {
    display: block;
    height: 5px;
    width: 5px;
    background: var(--success-color-v2);
    border-radius: 2rem;
}
.card-page .card-space .transaction-list .transaction-item hr {
    margin-top: 3rem;
    border-top: 1px solid var(--pc__background-secondary_v2);
}
.card-page .card-space .transaction-list .transaction-item > div:last-child {
    display: flex;
    justify-content: space-between;
    color: var(--text-gray-v2);
    font-size: 1.2rem;
}
.back-arrow-card {
    width: 22px;
    position: absolute;
    top: 35px;
    cursor: pointer;
}
.card-reload {
    width: 22px;
    position: absolute;
    top: 33px;
    right: 40px;
    cursor: pointer;
}
.card-reload img{
    width: 95%;
}
.back-arrow-card img {
    width: 100%;
}
.tooltip-copy .tooltip-inner {
    background: var(--success-color-v2) !important;
    color: var(--text-dark);
    font-weight: 500;
}
.tooltip-copy .tooltip-inner .tooltip-container {
    transition: none !important;
}
@media screen and (max-width: 425px) {
    .card-page .card-space {
        padding: 3.2rem 1.4rem;
    }
}
