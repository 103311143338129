.recovery-2fa-modal {
  width: 45rem;
}

.recovery-2fa-modal__text-item {
  color: var(--font__color-secondary);
  font-size: 1.6rem;
  text-align: center;
  margin: auto;
  margin-bottom: 2rem;
  width: 30rem;
}
  
.recovery-2fa-modal .form-group {
  text-align: center;
  margin: auto;
}

.recovery-2fa-modal__recovery-code-container {
  margin: auto;
  cursor: pointer;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.2rem;
}
  
.recovery-2fa-modal__copy-recovery-code {
  color: var(--font__color-primary);
  display: inline-block;
  margin-right: 1rem;
  margin-top:1rem;
  font-size: 1.6rem;
  width: 100%;
}

.recovery-2fa-modal .ap-label-with-text {
    justify-content: center;
}

.recovery-2fa-modal__copy-icon {
  margin-left: 1rem;
}

