.fiat-withdraw-form__title,
.cryptowallet-withdraw__title {
  color: var(--text-primary);
  padding-bottom: 2rem;
}

.fiat-withdraw-form__body {
  display: flex;
  flex-direction: column;
}

.cryptowallet-withdraw__address-container {
  display: flex;
  padding: 2rem;
  background: var(--primary__bg-color);
  justify-content: space-between;
  /* font-size: 0.8rem; */
}

.cryptowallet-withdraw__qrcode {
  margin-right: 2rem;
}

.fiat-withdraw-form__input-wrapper,
.fiat-withdraw-form__select-wrapper {
  padding-bottom: 2.6rem;
}

.fiat-withdraw-form__input-wrapper:last-child {
  padding-bottom: 0;
}

.inline__info {
  width: 100%;
  text-align: right;
}

.fiat-withdraw-form__close__btn {
  margin-top: 1rem;
}

.fiat-withdraw-form__form-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
  min-height: 30rem;
  height: 100%;
}

.fiat-withdraw-form__select-wrapper,
.fiat-withdraw-form__input-wrapper {
  margin: 1rem;
  text-align: left;
}

.fiat-withdraw-form__select-label,
.fiat-withdraw-form__label {
  margin: 0 0 1.2rem;
  font-size: 1.4rem;
}

.fiat-withdraw-form__select,
.fiat-withdraw-form__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  min-width: 20rem;
  background: var(--secondary__bg-color);
  border: 1px solid var(--border__color);
}

.fiat-withdraw-form__input-box {
  display: block;
  width: 100%;
}

.fiat-withdraw-form__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  min-width: 19.6rem;
  background: var(--secondary__bg-color);
  border: 1px solid var(--border__color);
}

.fiat-withdraw-form__error {
  padding: 0 3.2rem 1.5rem;
}

.fiat-withdraw-form__btn {
  width: calc(100% - 2rem);
  margin: 1rem;
  height: 5rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
}

.fiat-withdraw-form__template-body,
.fiat-withdraw-form__template-footer {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
}

.fiat-withdraw-form__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
  position: initial;
  margin-left: 0;
}

.fiat-withdraw-form__spinner-circle {
  margin: 0;
}

.fiat-withdraw-form__frame {
  padding: 2.2rem;
  display: flex;
  flex-direction: column;
  height: 68vh;
}
.fiat-withdraw-form__frame-container {
  overflow-y: auto;
}

.fiat-withdraw-form__section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fiat-withdraw-form__label-in-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.2rem;
  width: 5rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
  margin-top: 0.1rem;
}

.fiat-withdraw-form__with-label {
  padding-left: 6rem;
}

.fiat-withdraw-form__info-items {
  margin: 1rem 1rem 2rem;
}

.fiat-withdraw-form__lwt-container {
  display: flex;
  flex-direction: row;
  min-height: 3rem;
  border-bottom: 1px solid var(--component__border-color);
  align-items: center;
  font-size: 1.2rem;
}

.fiat-withdraw-form__lwt-label {
  margin: 0;
}

.fiat-withdraw-form__input-wrapper--amount {
  width: 45%;
}

.fiat-withdraw-form__footer {
  padding: 0 2.2rem;
  border-top: 1px solid var(--border__color);
  display: flex;
  align-items: center;
  min-height: 10.6rem;
  height: 10.6rem;
}
.sidepane-details__header {
  padding: 0;
}
.react-confirm-alert-overlay .react-confirm-alert-body {
  border-radius: 2rem !important;
  font-size: 1.4rem !important;
  color: var(--pc_font-primary) !important;
  text-align: justify !important;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.react-confirm-alert-overlay .react-confirm-alert-body .react-confirm-alert-button-group{
  margin: 0;
}
.react-confirm-alert-overlay .react-confirm-alert-body > h1 {
  text-align: center !important;
  margin: 0px !important;
  font-size: 2rem !important;
}
.react-confirm-alert-overlay
  .react-confirm-alert-body
  > .react-confirm-alert-button-group {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
}
.react-confirm-alert-overlay
  .react-confirm-alert-body
  > .react-confirm-alert-button-group
  > button {
  width: 25rem !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.check-style-1 {
  display: flex;
}

.check-style {
  color: var(--pc_font-primary);
  line-height: 1.2;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: justify;
}

.check-style > b {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--pc_font-secundary);
  cursor: pointer;
}
.custom-ui {
  text-align: justify;
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  background: var(--pc__background-secondary_v2);
  box-shadow: 0 20px 75px rgb(0 0 0 / 23%);
  color: var(--pc_font-primary);
  font-size: 1.3rem;
  border-radius: 2rem;
  padding: 16px 24px;
}
.remember h1{
  font-size: 20px;
  font-weight: 700;
  letter-spacing: .5px;
}
.remember div:nth-child(2){
  text-align: center;
}
.remember >p{
  color: var(--text-gray-v2);
  font-size: 15px;
  line-height: 18px;
  margin-top: 2rem;
}
.remember div:last-child{
  text-align: right;
}
.remember div:last-child button{
  margin: 0;
  width: 15rem;
  font-size: 16px;
}
.custom-notice{
  max-width: 400px!important;
  margin: auto;
}
.btn-alert {
  outline: none;
  background: var(--primary-color-v2);
  display: inline-block;
  color: #eee;
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
  width: 25rem;
  border-radius: 10px;
  padding: 10px;
}
.btn-alert.gray {
  background: var(--text-gray-v2);
  width: 6rem !important;
  margin-right: 12px !important;
}
.btn-alert.coink-swiping{
  background: var(--select-text-v2)!important;
}
.btn-alert-container {
  text-align: center;
}
.tiyle-alert {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 600;
}
.tiyle-alert > b {
  color: #ff9015;
}
@media (max-width: 991px) {
  .react-confirm-alert {
    height: inherit !important;
    width: inherit !important;
  }
}

@media (max-width: 390px) {
  .custom-ui {
    width: inherit !important;
  }
}

.v2-field.form-check > .form-check-label {
  text-align: justify;
}

.subtitle>img {
  width: 18px;
  margin-right: 6px;
}

.v2-field.form-group > .btn-group .dropdown-menu > .dropdown-item > .item-selector-item > .item-selector-title > .currency {
  padding: 5px;
  width: 39%;
  border-radius: 18px;
  color: var(--text-light);
  margin: 0;
}

.dropdown-V3 {
  border-bottom: 1px dashed #ffffff7a;
  min-height: 94px;
}

.important-alert .warning-alert>img {
  width: 25px;
  filter: invert(1);
}

.coink-amount .input-element{
  margin-bottom: 1rem;
}
.coink-withdraw-details{
  margin-top: 4rem;
}
.coink-withdraw-details > div{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coink-withdraw-details > div p{
  color:#a8a8c7;
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}
.coink-withdraw-details > div p:last-child{
  display: flex;
  gap: 3px;
}
.coink-withdraw-details > div p:last-child span{
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  max-width: max-content;
}
.total-to-receive-coink{
  font-size: 1.4rem!important;
}
.coink-amount{
  position: relative;
}
.coink-maximum-button{
  color: white;
  background-color: #54566A;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  right: 30px;
  top: 15px;
}
.coink-amount .input-element  > div {
  padding-right: 91px;
}