.v2-container-top {
  width: 95%;
  max-width: 1200px;
  margin: 0.8rem auto 1rem;
  flex: 1 0 auto;
}
.v2-container-top .pending-notification-bar {
  border-radius: 0.5rem;
  background-color: white;
  margin-bottom: 0.7rem;
  box-shadow: 0 0.5rem 2rem rgb(0 0 0 / 15%);
  padding: 0.5rem 0.5rem;
}
.v2-container-top .pending-notification-bar > h2 {
  text-align: center;
  margin-top: 1rem;
}
.v2-container-top .bnx-v2-table {
  margin-bottom: 0rem;
  border: none;
}
.v2-container-top .bnx-v2-table thead > tr > th {
  border: none;
}
.v2-container-top .bnx-v2-table tbody > tr > td:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}
.v2-container-top .bnx-v2-table tbody > tr > td:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}
.v2-container-top .bnx-v2-table tbody > tr > td.disabled {
  cursor: not-allowed;
}
.v2-container-top .bnx-v2-table tbody > tr > td.disabled > button {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.v2-container-top .bnx-v2-table tbody > tr > td {
  vertical-align: middle;
  border-top: none;
  padding: .75rem 1rem;
}
.v2-container-top .bnx-v2-table tbody > tr > td.quantity {
  font-weight: 550;
}
.v2-container-top .bnx-v2-table tbody > tr > td > span {
  display: block;
}
.v2-container-top .bnx-v2-table tbody > tr > td > span.title {
  font-size: 1.4rem;
  font-weight: 600;
}
.v2-container-top .bnx-v2-table .btn.btn-bnx {
  background: #FF7A00;
  color: white;
  font-weight: 550;
  font-size: 1.2rem;
}
.modal.modal-bnx-v2.lighting .modal-content {
  background: #fff!important;
}
.modal.modal-bnx-v2.lighting .modal-content .modal-header {
  padding: 1.4rem 1.8rem 0.8rem!important;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}
.modal.modal-bnx-v2.lighting .modal-content .modal-header > button.close {
  float: right;
  right: 15px;
  top: 10px;
}
.modal.modal-bnx-v2 .modal-content {
  border-radius: .8rem;
  overflow: hidden;
}
.modal.modal-bnx-v2 .modal-content > form {
  padding: 0 1rem;
}
.modal.modal-bnx-v2 .modal-content > form .modal-body .v2-field .form-control:hover {
  border-color: transparent;
}
.modal.modal-bnx-v2 .modal-content > form .modal-body .v2-field .form-control:disabled {
  box-shadow: none;
}
.modal.modal-bnx-v2 .modal-content .modal-header {
  padding: 1rem 1.8rem;
}
.modal.modal-bnx-v2 .modal-content .modal-header > button.close {
  padding: 0rem 1rem;
  font-size: 4.3rem;
  font-weight: 500;
  margin: -0.7rem -1rem -1rem auto;
}
.modal.modal-bnx-v2 .modal-content .modal-body {
  padding: 1rem 1rem;
}
.modal.modal-bnx-v2 .modal-content .modal-footer .btn {
  padding: .375rem 1.55rem;
  font-weight: 550;
  border-radius: 0.7rem;
}
.modal.modal-bnx-v2 .bnx-v2-loading {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 48%);
  z-index: 9;
  align-items: center;
}
.modal.modal-bnx-v2 .bnx-v2-loading.show {
  display: inline-flex;
}
.modal.modal-bnx-v2 .bnx-v2-loading > .spinner-border {
  margin: auto;
  width: 6rem;
  height: 6rem;
}

body #root .dark .v2-container-top .pending-notification-bar {
  background-color: rgb(255 255 255 / 13%);
}
body #root .dark .v2-container-top .bnx-v2-table tbody > tr > td > span.title {
  color: white;
}
body #root .dark .v2-container-top .bnx-v2-table thead > tr > th {
  border-bottom: 1px solid #9e9e9e;
}
.modal-disclaimer.modal .modal-content { 
  border-radius: 2.8rem;
}
.modal-disclaimer.modal .modal-content .modal-header {
  border-bottom: none!important;
  text-align: center;
  align-items: center!important;
}
.modal-disclaimer .modal-body p {
  font-size: 1.6rem;
  padding: 1rem 1rem;
}
.modal-disclaimer .modal-body .disclaimer-list {
  padding: 0 4rem;
}
.modal-disclaimer .modal-body .disclaimer-list li {
  font-size: 1.6rem;
  padding-bottom: 1.5rem;
  text-align: justify;
}
.modal-disclaimer .modal-body .disclaimer-list li > a {
  font-size: inherit;
  color: #FD8023;
}
.modal-disclaimer .modal-body .disclaimer-list li > a:hover {
  opacity: 0.8;
}
.modal-disclaimer .modal-body .disclaimer-list li > b {
  font-weight: 550;
}
.modal-disclaimer.modal.modal-bnx-v2 .modal-content .modal-footer .btn {
  padding: 1.375rem 1.75rem;
}
.modal-disclaimer .modal-header .modal-title.h4 {
  font-size: 3rem!important;
  font-weight: 700!important;
}
.modal-disclaimer .modal-header .modal-title.h4 > b {
  font-size: inherit;
  font-weight: inherit;
  color: #FD8023;
}
.modal-disclaimer .modal-footer {
  align-items: center;
  justify-content: center;
  padding: .75rem .75rem 1.75rem .75rem;
}
.modal-disclaimer .modal-footer .btn-bnxv2.btn-primary {
  background: var(--primary-color-v2);
  color: white!important;
  font-size: 1.6rem!important;
  font-weight: 500!important;
  border-radius: 1.8rem!important;
  border: none !important;
  padding: 1.375rem 6.6rem!important;
}
.modal-disclaimer .modal-footer .btn-bnxv2.btn-primary:hover {
 opacity: 0.9;
}
.modal-disclaimer .modal-footer .btn.btn-secondary {
  background: var(--secondary-color-v2);
  color: #ffffff!important;
  font-size: 1.6rem!important;
  font-weight: 500!important;
  border-radius: 1.8rem!important;
  border: none!important;
}
.modal-disclaimer .modal-footer .btn.btn-secondary:hover {
  opacity: 0.9;
}