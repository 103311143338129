.movements-history {
    display: grid;
    justify-items: center;
    min-height: calc(100vh - 80px);
    overflow-x: auto;
    padding: 4rem;
}
.movements-history * {
    font-size: 1.2rem;
}
.movements-history p {
    margin: 0;
}
.movements-history .download-history {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3rem;
}
.movements-history .download-history > div {
    display: flex;
    column-gap: 5rem;
    padding: 1.8rem 2rem;
    background: var(--pc__background-secondary_v2);
    border-radius: 1.5rem;
    cursor: pointer;
}
.movements-history .banex-table {
    display: flex;
    flex-direction: column;
}
.movements-history .banex-table .banex-row {
    display: flex;
}
.movements-history .banex-table .banex-row div:nth-child(1) {
    width: 230px;
    min-width: 230px;
}
.movements-history .banex-table .banex-head .banex-row div:nth-child(2){
    padding-left: 79px;
    padding-right: 57px;
}
.movements-history .banex-table .banex-row div:nth-child(2),
.movements-history .banex-table .banex-row div:nth-child(3),
.movements-history .banex-table .banex-row div:nth-child(4) {
    min-width: 130px;
}
.movements-history .banex-table .banex-row div:nth-child(5) {
    width: 131px;
    min-width: 131px;
}
.movements-history .banex-table .banex-row div:nth-child(6) {
    width: 147px;
    min-width: 147px;
}
.movements-history .banex-table .banex-row div:nth-child(7) {
    width: 200px;
    min-width: 200px;
}
.movements-history .banex-table .banex-body div:nth-child(7) {
    padding-left: 45px;
}
.movements-history .banex-table .banex-cell {
    padding: 1rem 2rem;
    margin: 0;
}
.movements-history .banex-table .banex-head .banex-cell {
    text-align: center;
    color: var(--text-gray-v2);
    font-size: 1.4rem;
}
.movements-history .banex-table .banex-head .banex-cell::after {
    content: "▼";
    margin-left: 0.5rem;
}
.movements-history .banex-table .banex-body {
    border-radius: 13px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.movements-history .banex-table .banex-body * {
    font-size: 1.4rem;
}
.movements-history .banex-table .banex-body > svg {
    margin: 10rem auto 0;
}
.movements-history .banex-table .banex-body .banex-row {
    background: var(--pc__background-secondary_v2);
    border-radius: 1.5rem;
    position: relative;
}
.movements-history .banex-table .banex-body .banex-row .banex-cell {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
.movements-history .banex-table .banex-body .banex-row.PROCESSED-row,
.movements-history .banex-table .banex-body .banex-row.CONFIRMED-row {
    border-left: 2px solid var(--success-color-v2);
}
.movements-history .banex-table .banex-body .banex-row.CANCELED-row {
    border-left: 2px solid var(--error-color-v2);
}
.movements-history .banex-table .banex-body .banex-row.PENDING-row {
    border-left: 2px solid rgb(255 141 0);
}
.movements-history .banex-table .banex-body .banex-state {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.movements-history .banex-table .banex-body .banex-state::before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 2rem;
    margin-bottom: 2px;
}
.movements-history .banex-table .banex-body .banex-state.success-row::before {
    background: var(--success-color-v2);
    box-shadow: 0px 0px 5px 2px var(--success-color-v2);
}
.movements-history .banex-table .banex-body .banex-state.error-row::before {
    background: var(--error-color-v2);
    box-shadow: 0px 0px 5px 2px var(--error-color-v2);
}
.movements-history .banex-table .banex-body .product-full-name{
    font-size: 1.2rem;
    color: var(--text-gray-v2);
}