.modal-open .modal.modal-confirm-phone {
    height: 100%;
}
.modal-confirm-phone .loading-box {
    display: none;
    background-color: rgb(25 24 24 / 23%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 9;
}
.modal-confirm-phone .loading-box.cargando {
    display: flex;
    align-items: center;
}
.modal-confirm-phone .loading-box.cargando > .spinner-border {
    margin: auto;
    width: 4rem;
    height: 4rem;
}
.modal-confirm-phone .modal-dialog {
    display: flex;
    align-items: center;
    height: inherit;
    margin: auto;
}
.modal-confirm-phone .modal-content {
    border-radius: 1.5rem;
}
.modal-confirm-phone.dark .modal-content {
    background-color: #353A36;
}
.modal-confirm-phone .modal-content .validation-phone {
    padding: 40px 4rem 32px;
}
.modal-confirm-phone .modal-content .validation-phone.banner h1 {
    font-size: 1.7rem;
}
.modal-confirm-phone .modal-content .validation-phone.banner p.subtitle-information {
    font-size: 1.3rem;
    margin: 2.4rem 0;
    display: inline-block;
}
.modal-confirm-phone .modal-content .validation-phone.banner p.subtitle-information.last {
    font-size: 1.7rem;
    font-weight: 550;
    margin: 0rem 0 2.4rem;
}
.modal-confirm-phone.dark .modal-content .validation-phone.banner h1 {
    color: white;
}
.modal-confirm-phone.dark .modal-content .validation-phone.banner p.subtitle-information {
    color: white;
}
.modal-confirm-phone .modal-content .form-group input.form-control {
    border-radius: 17px;
    border-top: 2px solid;
    font-size: 1.2rem;
    padding-top: 3px;
    padding-bottom: 2px;
    height: 45px;
}
.modal-confirm-phone.light .modal-content .form-group input.form-control {
    border-top: 2px solid #dedede;
}
.modal-confirm-phone.light .validation-phone.banner .success-animation {
    margin: 0 auto 1.7rem auto;
}
.modal-confirm-phone.light .validation-phone.banner .success-animation .checkmark__circle {
    fill: #ffffff;
}
.modal-confirm-phone.dark .validation-phone.banner .success-animation .checkmark__circle {
    fill: #353A36;
}
.modal-confirm-phone .modal-content .form-group input.form-control[name="twofacode"], .modal-confirm-phone .modal-content .form-group input.form-control[name="phonecode"] {
    letter-spacing: 2.1rem;
    font-size: 1.5rem;
    text-align: center;
}
.modal-confirm-phone.dark .modal-content .form-group input.form-control {
    background: transparent;
    border-width: 1.5px;
    border-color: #969595;
    box-shadow: 0 0 0px 1px rgb(151 154 151 / 28%);
    color: white;
}
.modal-confirm-phone.dark .modal-content .form-group input.form-control:hover, .modal-confirm-phone.dark .modal-content .form-group input.form-control:focus {
    border-color: #c2c2c2;
}
.modal-confirm-phone .modal-content .form-group .solid-confirm {
    border: 1.5px solid;
    border-color: #969595;
    box-shadow: 0 0 0px 1px rgb(151 154 151 / 28%);
    border-radius: 17px;
}
.modal-confirm-phone .modal-content .form-group .solid-confirm .characters {
    height: 33px;
}
.modal-confirm-phone .modal-content .form-group .solid-confirm .characters .verification-input__character {
    line-height: 33px;
    border: none;
    background-color: transparent;
    color: white;
    margin-left: 4px;
    border-radius: 12px;
}
.modal-confirm-phone.light .modal-content .form-group .solid-confirm .characters .verification-input__character {
    color: rgb(94, 94, 94);
}
.modal-confirm-phone .modal-content .form-group .solid-confirm .characters .verification-input__character:last-child {
    margin-right: 4px;
}
.modal-confirm-phone .modal-content .form-group .solid-confirm .characters .verification-input__character.character--selected {
    background-color: rgba(255, 255, 255, 0.178);
}
.modal-confirm-phone.light .modal-content .form-group .solid-confirm .characters .verification-input__character.character--selected {
    background-color: rgba(187, 187, 187, 0.479);
}
.modal-confirm-phone .modal-content .form-group .react-tel-input .selected-flag .flag {
    top: 50%!important;
}
.modal-confirm-phone .modal-content .validation-phone.banner .form-group .form-label {
    font-size: 1.0rem;
}
.modal-confirm-phone.dark .modal-content .validation-phone.banner .form-group .form-label {
    color: white;
}
.modal-confirm-phone .modal-content .validation-phone.banner .btn-link {
    text-align: center;
}
.modal-confirm-phone .modal-content .validation-phone.banner .btn-link > b {
    font-weight: 400;
    color: #FF9015;
}
.modal-confirm-phone.dark .modal-content .validation-phone.banner .btn-link {
    color: white;
}
.modal-confirm-phone .modal-content .validation-phone.banner .btn-phone {
    width: 100%;
    padding: 0.7rem 2rem;
    border-radius: 1.2rem;
    font-size: 1.4rem;
    background: var(--primary-color-v2);
}
.modal-confirm-phone .modal-content .error-msg {
    color: var(--error-color-v2);
    font-weight: 550;
}
.modal-confirm-phone .modal-content .success-msg {
    color: #47C65D;
    font-weight: 400;
    margin: 0;
    padding: 1.2rem 0 0 0;
}
.modal-confirm-phone .modal-content .success-msg.active {
    visibility: visible;
    opacity: 1;
}
.modal-confirm-phone .modal-content .success-last {
    width: 50%;
}
@media (min-width: 576px) {
    .modal-confirm-phone .modal-dialog {
        max-width: 550px;
    }
}