.chart-container{
  margin-top: 1rem;
}
.tv-chart{
  height: 540px!important;
  position: relative;
}
@media (width < 785px) {   
  .tv-chart{
    height: 350px!important;
    position: relative;
}
}
.selector-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
}
.selector-content button{
  color: white;
  padding: 5px 10px;
  background-color: transparent;
  transition: 100ms all;
  font-size: 1.4rem;
}
.selector-content .chart-types button.selected{
  background-color: #494c63be;
  border-radius: 8px;
}
.spinner-container-trading{
  position: absolute;
  top: 50%;
  left: 50%;
}
.selector-mobile{
  display: none;
}
.selector-content .section-selector button.selected{
  color: #FF9242;
}
.selector-chart-type-title{
  color: white;
  font-size: 1.4rem;
  cursor: pointer;
}
ul.selector-chart-type{
  list-style: none;
  padding: 0;
  z-index: 999;
  border-radius: 1rem;
}
.selector-chart-type{
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--pc__background-secondary_v2)!important;
}
.selector-chart-type li{
  padding: 1rem .6rem 1rem 1rem;
  cursor: pointer;
  transition: 100ms all;
}
.selector-chart-type *{
  font-size: 1.4rem;
  margin: 0;
}
.chart-types .select:hover > ul, .selector-chart-type:hover, .selector-chart-type-title:hover + .selector-chart-type {
  display: block;
}
.chart-types .select:hover > ul li:hover{
  background-color: #494969!important;
} 
.chart-types .select:hover > ul li:hover:nth-child(2){
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.chart-types .select:hover > ul li:hover:nth-child(1){
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.chart-types .selector-chart-type-title{
  display: none;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
}
.chart-types .select{
  position: relative;
}
.chart-types .selector-chart-type-title p {
  margin: 0;
}
@media ( width < 785px ) {
  .selector-chart-type-title{
    display: flex!important;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  .selector-content{
    margin-bottom: 2rem;
  }
  .selector-mobile{
    display: initial;
  }
  .selector-desktop{
    display: none;
  }
  .exchange-content .first-column{
    width: 100%;
    display: block;
  }
  .exchange-content .second-column,
  .exchange-content .third-column{
    display: none;
  }
  .chart-types .select{
    display: initial;
  }
}