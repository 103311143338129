.settings-page__container {
  display: flex;
  flex: 1 0 auto;
}

.settings-page__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.settings-page__content .pd2 .row {
  padding: 0 1rem;
}

.settings-page__menu {
  display: flex;
  flex-direction: column;
  border-right: var(--component__border);
  background: white;
}

.settings-page__footer {
  flex-shrink: 0;
}


/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .settings-page__container {
    flex-direction: column;
  }

  .settings-page__content {
    flex-direction: column;
    flex: 1 0 auto;
  }
}