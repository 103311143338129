.recent-trade__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  border-left: var(--component__border);
}

.recent-trades__no-trading {
  color: var(--font__color-secondary);
  display: flex;
  align-items: center;
}

.recent-trades__no-trading--icon {
  margin-right: 0.5rem;
}

.recent-trades__table-header-price,
.recent-trades__table-price,
.recent-trades__table-header-qty,
.recent-trades__table-qty {
  padding: 0 1rem;
  text-align: center;
  flex: 2;
  flex-shrink: 0;
}

.recent-trades__table-price,
.recent-trades__table-qty {
  margin-left: auto;
}

.recent-trades__table-header-time,
.recent-trades__table-time {
  flex: 1;
  padding: 0 1.5rem 0 1rem;
  text-overflow: initial;
}

.flex-table__header-cell--absolute.recent-trades__fixed {
  border: 0;
  width: 33%;
}

.recent-trades__header-column {
  display: flex;
}

@media (max-width: 1280px) {
  .recent-trade__container {
    border-left: none;
  }
}

.flex-table__header.flex-table__header--background.recent-trades__table-header.recent-trades__table-header--background,
.flex-table.recent-trades.recent-trades__body,
.flex-table__row.recent-trades__row{
  background-color: var(--background-primary_v2)!important;
}
.flex-table__row.recent-trades__row *{
  font-size: 1.2rem;
}
.flex-table__header-cell.flex-table__header-cell--absolute.recent-trades__fixed{
  background-color: #a8a8c736;
  color: white!important;
  border-radius: 20px;
  padding: 2px 10px;
  width: -webkit-max-content;
  width: max-content;
  margin-left: 10px;
  margin-top: -27px;
}
.flex-table__body.recent-trades__body{
  max-height: 568px;
}