.send-receive-header {
  width: 100%;
  display: flex;
  margin-bottom: 4rem;
  margin-top: 1.6rem;
}

.send-receive-header__logo {
  display: inline-block;
  margin-right: 2.2rem;
}

.send-receive-header__title {
  font-size: 2.5rem;
  padding-top: 1rem;
  color: #ffffff;
}

.send-receive-header .product-icon--default {
  margin-right: 2.2rem;
}
