.kyc-level,
.kyc-level--selected {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 30rem;
  max-width: 30rem;
  height: var(--settings__kyc-card-height);
  color: var(--component__color-secondary);
  margin-right: 3rem;
  margin-bottom: 3rem;
  background: var(--settings__kyc-card-bg-color);
  box-shadow: var(--settings__card-box-shadow);
  border-radius: var(--settings__kyc-card-border-radius);
}

.kyc-level:not(.kyc-level--selected) {
  border-color: var(--settings__kyc-card-container-border-color);
  border-style: solid;
  border-width: var(--settings__kyc-card-container-border-size);
}

.ap-icon.ap-icon--star {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  border-radius: 100%;
  padding: 1rem;
  color: var(--settings__kyc-card-star-fill-color);
  background: var(--settings__kyc-card-star-bg-color);
  box-shadow: var(--settings__kyc-card-star-box-shadow);
  display: block;
  width: 4.2rem;
  height: 4.3rem;
  display: var(--settings__kyc-card-star-display);
}

.kyc-level__header-title {
  font-size: 2.5rem;
  letter-spacing: 1.1px;
  line-height: 6rem;
  padding: 0 1.5rem;
  height: 6rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: var(--component__color-secondary);
  background: var(--settings__kyc-card-header-bg-color);
  border-top-right-radius: var(--settings__kyc-card-border-radius);
  border-top-left-radius: var(--settings__kyc-card-border-radius);
}

.kyc-level__body {
  height: 100%;
  padding: 1.5rem;
  min-height: 17rem;
  background: var(--settings__kyc-card-bg-color);
  color: var(--component__color-secondary);
  border-top: solid var(--settings__kyc-card-body-top-border-size)
    var(--settings__kyc-card-body-top-border-color);
  border-bottom: solid var(--settings__kyc-card-body-bottom-border-size)
    var(--settings__kyc-card-body-bottom-border-color);
}

.kyc-level__benefits {
  margin-bottom: 2rem;
}

.kyc-level__sub-title {
  font-size: 1.2rem;
  line-height: 1.64rem;
}

.kyc-level__list {
  padding-left: 1.5rem;
}

.kyc-level__item-description,
.kyc-level__list-item {
  font-size: 1.1rem;
  line-height: 1.64rem;
}

.kyc-level__footer--verified {
  color: var(--settings__kyc-card-footer-color--verified);
}

.kyc-level__footer--under-review {
  color: var(--warning__color);
}

.kyc-level__under-review-icon,
.kyc-level__verified-icon {
  margin-right: 1.75rem;
}

.kyc-level__btn,
.kyc-level__change__btn {
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
}

.kyc-level__change__btn {
  background: var(--warning__bg-color);
  border: 1px solid var(--warning__bg-color);
}

.kyc-level__change__btn:hover {
  background: var(--warning__bg-color--hover);
  border: 1px solid var(--warning__bg-color--hover);
}

.kyc-level__change__btn:focus {
  background: var(--warning__bg-color--focus);
  border: 1px solid var(--warning__border-color--focus);
}

.kyc-level__footer {
  padding: 0 1.5rem;
  height: 5.5rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.kyc-level__header-title--selected,
.kyc-level__header-title--selected {
  color: var(--component__color-primary);
}

.kyc-level__sub-title--selected {
  color: var(--component__color-primary);
}

.kyc-level--selected {
  border-color: var(--kyc-level__border-color--selected);
  border-style: solid;
  border-width: var(--settings__kyc-card-container-border-size--selected);
}
