.v2-accounts span.badge {
    font-size: 1.1em;
    margin: 0;
    padding: 0;
    font-weight: 600;
}

.v2-accounts span.badge[bg='primary'] {
    background: #288f28;
    color: #fff;
    padding: 0.7em;
    border-radius: 1em;
}

.v2-accounts span.badge[bg='secondary'] {
    background: #f98e0a;
    color: #fff;
    padding: 0.7em;
    border-radius: 1em;
}