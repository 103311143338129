.layout-content {
    background-color: var(--background-primary_v2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.layout-auth-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.layout-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    height: 100vh;
    padding: 2rem 0;
}

.layout-container-center {
    justify-content: center;
}

.circle1,
.circle2,
.circle3 {
    background: var(--primary-color-v2);
    border-radius: 100%;
    position: absolute;
    box-shadow: 2px 2px 52px 0px var(--orange-color);
    -webkit-box-shadow: 2px 2px 52px 0px var(--orange-color);
    -moz-box-shadow: 2px 2px 52px 0px var(--orange-color);
}

.circle1 {
    width: 9px;
    height: 9px;
    left: 50%;
    top: 42%;
}

.circle2 {
    width: 25px;
    height: 25px;
    top: 200px;
    left: 100px;
}

.tagtransaparent {
    color: transparent;
}

.layout-auth-logo img {
    position: absolute;
    top: 60px;
    left: 60px;
    width: 200px;
}

@media (max-width:871px) {
    .layout-auth-logo img{
        display: none;
    }
}
.layout-content a {
    color: var(--pc_font-secundary) !important;
}

.layout-content .bottom-box-v2.normal.text-left.col-xl-6 a,
.layout-content .bottom-box-v2.normal.text-right.col-xl-6 a {
    color: var(--text-light) !important;
    font-size: 14px;
    transition: 300ms all;
}

.layout-content .bottom-box-v2.normal.text-left.col-xl-6 a:hover,
.layout-content .bottom-box-v2.normal.text-right.col-xl-6 a:hover {
    color: var(--orange-color) !important;
}

.layout-content .bottom-box-v2.problems-text.col-xl-10 a {
    font-size: 12px !important;
}

.layout-content form.was-validated .v2-field.form-group.valid .input-group-append {
    background-color: transparent;
}

.layout-content .justify-content-center.mt-1.mb-4.error-msg.row {
    color: var(--error-color-v2);
}

.layout-content .justify-content-center.mt-1.mb-4.error-msg.row p {
    padding: 0 4rem;
    text-align: center;
}

.layout-auth-logo img {
    width: 150px;
}

.bottomlinks {
    flex-wrap: nowrap;
}

.layout-container-center .diamond,
.layout-container-center .diamond2 {
    display: none;
}

.loginv2bg .layout-container .diamond,
.layout-container .diamond2 {
    position: absolute;
    z-index: 0;
}

.loginv2bg .layout-container .diamond2 {
    bottom: 80%;
    right: 15%;
}

.loginv2bg .layout-container .diamond {
    top: 50%;
    right: 85%;
}

.diamond2.login-diamond{
    bottom: 75%!important;
}
.diamond.login-diamond{
    top: 57%!important;
}
.diamond, .diamond2{
    width:320px;
}

.layout-container button {
    height: 44px;
}

.layout-container .v2-field.btn-bnxv2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pointerEvents-action {
    pointer-events: none;
}

.pointerEvents-action h1 {
    color: var(--text-light);
}

.layout-auth-logo {
    display: block;
}

.layout-container-children {
    width: 95%;
    max-width: 450px;
    margin: 0px 0px 0px auto;
    position: absolute;
    right: 200px;
    max-height: 95vh;
    height: auto;
}
.layout-container-children.two-factor-a{
    right: auto;
    padding: 2rem;
    border-radius: 20px;
    border: 1px solid #39395e;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}
.containerFooterOption{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    padding: 0 15px;
}
.chat-with-us{
    font-size: 14px;
}
@media (width > 600px) {
    .containerFooterOption{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        flex-direction: row;
        padding: 0 15px;
    }
    .chat-with-us{
        display: none;
    }
}
.containerFooterOption a,
.containerFooterOption p{
    color: var(--text-light)!important;
    font-size: 14px;
}
.containerFooterOption p{
    margin: 0;
}
.containerFooterOption a:hover{
    color: var(--pc_font-secundary) !important;
}
.login-page-overflow{
    overflow: hidden;
}

@media (min-width: 1200px) {
    .layout-content .inputscontainer>div {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .layout-content form .justify-content-center.mt-2.row .col-xl-10 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .form-container-v2 .col-xl-10{
        flex: none;
        max-width: initial;
    }
}

@media (max-width:1200px) {
    .layout-content .inputscontainer {
        width: 90%;
        margin: 2em auto auto 2em;
    }

    .layout-content .inputscontainer>div {
        padding-right: 0;
        padding-left: 0;
    }

    .layout-content form .justify-content-center.mt-2.row .col-xl-10 {
        text-align: center;
        margin: auto;
    }

    .layout-content .bottom-box-v2.col-xl-10 {
        width: 90%;
        text-align: center;
    }

    .circle1,
    .circle2,
    .circle3 {
        display: none;
    }
}

@media (max-width:890px) {
    .layout-container {
        justify-content: center;
    }

    .layout-container .diamond,
    .layout-container .diamond2 {
        display: none;
    }

    .pointerEvents-action {
        pointer-events: all;
    }

    .layout-container-children {
        right: auto;
    }
}