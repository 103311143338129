.video-modal-container{
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  top: -2px;
  left: -2px;
  width: 100vw;
  height: 100vh;
  z-index: 9999999991;
}
.video-modal{
  background-color: var(--background-primary_v2);
  width: 90vw;
  max-width: 800px;
  height: 500px;
  margin: 10rem auto 0;
  border-radius: 25px;
  padding: 2rem;
  position: relative;
}
.video-modal iframe{
  width: 100%;
  height: 100%;
  border-radius: 25px;
  margin: 0 auto;
  max-height: 500px;
}
.video-modal .close-icon{
  position: absolute;
  top: -1.4rem;
  right: -1.4rem;
  font-size: 2rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: var(--background-primary_v2);
  padding: .8rem;
  border-radius: 15px;
}