.menu-component {
  flex: 1;
  overflow: auto;
}

.menu-component__list {
  padding: 0;
  margin: 0;
}

.menu-component__item {
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--side-menu__item-separator-color);
  border-left: solid 4px transparent;
  width: var(--side-menu__item-width);
  height: var(--side-menu__item-height);
  padding: 0 2rem 0 0.75rem;
  cursor: pointer;
}

.menu-component__item:hover,
.menu-component__item:active {
  background: var(--side-menu__item-bg-color--hover);
}

.menu-component__item:active,
.menu-component__item--selected:hover,
.menu-component__item--selected {
  background: var(--side-menu__item-bg-color--selected);
}
