
.progress {
  display: block;
}
.progress-bar{
  overflow: visible;
}
.progress-bar-container .progress{
  margin-bottom: 11px;
  overflow: unset;
  background: var(--success-color-v2);
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  
}
.progress-bar-container .progress-bar {
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #FF4167;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  border-radius: 6px;
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}
.limit-details-title{
  text-align: justify;
  color: var(--text-gray-v2)!important;
}
.limit-details-info {
  color:var(--pc_font-primary);
  margin-right: 5px;
}

.progress-bar-container .valor{
  display: block;
  font-size: .8rem;
  float: right;
  color: var(--btn-checked-v2);
  margin-top: 3.5rem;
  text-align: initial;
}
.progress-bar-container .valor-remaining {
  white-space: nowrap;
  margin-top: 1.1rem;
  display: block;
  font-size: .8rem;
  color: var(--btn-checked-v2);
  text-align:end;
}

.progress-bar-container .limit-full {
  display: block;
  margin: 0 5px ;
  float: right;
  font-size: 9px;
}
.progress-bar-container .limit-full b {
  font-size: 1.9rem;
  font-weight: inherit;
  vertical-align: middle;
}
.progress-bar-container .valor-remaining span b {
  font-size: 1.9rem;
  font-weight: inherit;
  vertical-align: bottom;
}
.progress-bar-container .title-limit{ 
  margin: 28px 0 4px 0;
  font-weight: 600;
}

.progress-bar-container .title-limit>span{
  color: var(--pc_font-primary);
}

.container-fluid.progress-bar-container h3 {
  margin-bottom: 2rem;
  margin-top: 2rem;
  color: var(--pc_font-primary);
}

.descirption-limit{
  display: flex;
}
.description-valor {
     width: 10px; 
     height: 10px; 
     background: var(--error-color-v2);
     margin: 4rem 0 0px 3rem;
}

span.description-valor-text {
    padding: 2rem;
    position: absolute;
    margin: -2.3rem 0 0 0;
    color: var(--pc_font-primary);
}

.description-remaining {
     width: 10px;
    height: 10px;
    background: var(--success-color-v2);
    margin: 4rem 0 0px 14rem;
}


span.description-remaining-text {
    padding: 2rem;
    position: absolute;
    margin: -2.3rem 0 0 0;
    color: var(--pc_font-primary);
}
.notional-amount{
  color: var(--text-gray-v2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}
.notional-amount span:nth-child(2){
  font-size: 1.4rem;
}

@media screen and (max-width: 900px){
  .container-fluid.progress-bar-container .col-md-12.row-limit {
    padding-right: 3rem;
  }

}