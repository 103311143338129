.coink-app .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 65px;
    height: 20px;
}
.coink-app .lds-ellipsis div {
position: absolute;
top: 9px;
width: 8px;
height: 8px;
border-radius: 50%;
background: #fff;
animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.coink-app .lds-ellipsis div:nth-child(1) {
left: 8px;
animation: lds-ellipsis1 0.6s infinite;
}
.coink-app .lds-ellipsis div:nth-child(2) {
left: 8px;
animation: lds-ellipsis2 0.6s infinite;
}
.coink-app .lds-ellipsis div:nth-child(3) {
left: 30px;
animation: lds-ellipsis2 0.6s infinite;
}
.coink-app .lds-ellipsis div:nth-child(4) {
left: 60px;
animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(20px, 0);
    }
}

.coink-app .spinnerorange div {
    background-color: #FC9015!important;
}
