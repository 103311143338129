.deposit-form__title,
.cryptowallet-withdraw__title {
  color: var(--font__color-primary);
  padding-bottom: 2rem;
}

.cryptowallet-withdraw__address-container {
  display: flex;
  padding: 2rem;
  background: var(--primary__bg-color);
  justify-content: space-between;
  /* font-size: 0.8rem; */
}

.cryptowallet-withdraw__qrcode {
  margin-right: 2rem;
}

.deposit-form__input-wrapper {
  padding-bottom: 2rem;
}

.inline__info {
  width: 100%;
  text-align: right;
}

.deposit-form__close__btn {
  margin-top: 1rem;
}

.deposit-form__form-body {
  width: 100%;
  min-height: 30rem;
}

.deposit-form__select-wrapper,
.deposit-form__input-wrapper {
  display: inline-flex;
  align-items: center;
  margin: 0;
}

.deposit-form__select-label,
.deposit-form__label {
  width: 15rem;
  margin: 0;
  justify-content: flex-start;
}

.deposit-form__input,
.deposit-form__select {
  width: 22rem;
}

.deposit-form__btn {
  width: 20rem;
  margin: 1rem 0;
}

.deposit-form__template-body,
.deposit-form__template-footer,
.deposit-form__error {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color);
}

.deposit-form__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color);
  position: initial;
  margin-left: 0;
}

.deposit-form__spinner-circle {
  margin: 0;
}

.deposit-form__trustpay-form {
  color: rgb(var(--th-settings__trustpay-form-font-color));
}
