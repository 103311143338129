.activation{
  text-align: center;
  width: 96%;
  margin: 0 auto 4rem;
  max-width: 353px;
  min-height: calc(100vh - 200px);
  display: grid;
  place-content: center;
}
.activation h2{
  font-size: 2.2rem;
  text-align: center;
  font-weight: 400;
  margin: 2rem 0;
}
.activation .banexcard-container .activation-message{
  padding: 1rem;
  background-color: #363646;
  width: max-content;
  margin: 0 auto;
  border-radius: 5px;
}
.activation .banexcard-container .activation-message p{
  color: var(--text-gray-v2);
  font-size: 1.4rem;
}
.activation button{
  margin-top: 2rem;
  background-color: var(--select-text-v2)!important;
  transition: all .3s!important;
}
.activation button:disabled{
  opacity: .5!important;
  cursor: not-allowed;
}
.activation button:hover{
  opacity: .5!important;
}
.activation h4{
  font-size: 2rem;
  font-weight: 400;
  margin: 2rem 0;
}
.activation .description{
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  margin-bottom: 2rem;
}
.activation .characters{
  height: 35px;
}
.activation .character{
  line-height: 34px;
  border-radius: 5px;
  font-weight: 400;
  border-width: 1px;
}
.activation-result{
  max-width: 500px;
  margin-top: 4rem;
}