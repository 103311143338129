.reports-sidepane__body {
  overflow: scroll;
  padding: 3.2rem;
  height: 100%;
  border-top: 4px solid var(--tab__border-color--selected);
}

.reports-sidepane__header {
  padding: 2.6rem 3.2rem 5.8rem;
  font-size: 2.4rem;
  color: var(--font__color-primary);
}

.reports-sidepane__close-button {
  padding: 1.9rem !important;
  color: var(--font__color-secondary);
}

.reports-sidepane__menu {
  padding: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.reports-sidepane__menu-item {
  height: 5rem;
  font-size: 1.4rem;
}

.reports-sidepane__tab {
  border-radius: 6px;
}
