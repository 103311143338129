.App,
#root {
  /* height: 100vh; */
  height: auto;
  display: flex;
  flex-direction: column;
}

#root .App .trading-layout__container {
  height: 100vh;
}

html {
  background: var(--secondary__bg-color);
}

.loading-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.40);
  z-index: 9999;
}

.loading-overlay.noshow{
  display: none;
}

.loading-wheel {
  position: absolute;
  top: 37%;
  left: 0;
  right: 0;
  text-align: center;
}
.loading-wheel.bnx img {
  width: 30%;
  animation: grow-animation 1.2s infinite linear;
  opacity: 0.8;
  pointer-events: none;
}

@media screen and (max-width: 830px) and (min-width: 445px)  {
  .loading-wheel.bnx img {
    width: 20%;
  }
}

@media only screen and (min-width: 830px) {
  .loading-wheel.bnx img {
    width: 10%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes grow-animation {
  0% { transform: scale(0.5); }
  50% {transform: scale(1); }
  100% {transform: scale(0.5); }
}