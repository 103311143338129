.kyc-settings {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--settings__body-bg-color);
  max-width: var(--settings__body-max-width);
  margin: var(--settings__body-margin);
  padding: var(--settings__body-padding);
}

.kyc-settings__header {
  background: var(--settings__body-header-bg-color);
  margin: var(--settings__body-header-margin);
  position: relative;
}
.kyc-settings__header:before {
  display: var(--settings__accent-bar-display);
  content: '';
  background-image: var(--accent-bar__gradient);
  min-width: 100vw;
  height: calc(
    100% + (var(--settings__body-header-margin) * 2) +
      (var(--settings__body-padding) * 4)
  );
  position: absolute;
  z-index: -1;
  top: calc(((var(--settings__body-padding) * 2) + 2px) * -1);
  bottom: 0;
  left: calc(
    (
        var(--side-menu__item-width) + var(--settings__body-header-margin) +
          var(--settings__body-padding) + 2px
      ) * -1
  );
  right: 0;
}

.kyc-settings__header-text {
  font-size: var(--settings__body-header-title-font-size);
  color: var(--settings__body-header-title-font-color);
  letter-spacing: var(--settings__body-header-title-letter-spacing);
  padding-bottom: var(--settings__body-header-title-padding-bottom);
}

.kyc-settings__levels {
  overflow: visible;
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--settings__kyc-cards-container-margin-l);
}

.kyc-settings__description {
  font-size: var(--settings__body-header-sub-title-font-size);
  color: var(--settings__body-header-sub-title-font-color);
  letter-spacing: var(--settings__body-header-sub-title-letter-spacing);
  line-height: var(--settings__body-header-sub-title-line-height);
  letter-spacing: 0.5px;
}

.kyc-settings__description:last-of-type {
  padding-bottom: 0;
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .kyc-settings {
    width: 100%;
  }

  .kyc-settings__levels {
    display: unset;
  }

  .kyc-settings__header:before {
    left: calc(
      (
          var(--settings__body-header-margin) + var(--settings__body-padding) +
            2px
        ) * -1
    );
  }
}
