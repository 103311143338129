.advanced-order-form__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 0 2rem 2rem;
  margin-top: 2rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.advanced-order-form__input-box {
  flex-direction: column;
  width: 100%;
}

.advanced-order-form__main-section {
  display: flex;
  flex-shrink: 0;
  padding-left: 3em;
}

.advanced-order-form__main-section-limit-price {
  padding-left: 3rem;
}

.advanced-order-form__row {
  width: 100%;
  padding-bottom: 2rem;
}

.advanced-order-form--with-padding {
  padding-bottom: 2rem;
}

.advanced-order-form--with-small-padding {
  padding-bottom: 1rem;
}

.advanced-order-form__user-balance-container {
  width: 38rem;
  padding-top: 3.8rem;
  padding-bottom: 0.5rem;
  padding-left: 3em;
}

.advanced-order-form__select-wrapper,
.advanced-order-form__input-wrapper {
  display: inline-flex;
  align-items: baseline;
  margin: 0;
}

.advanced-order-form__input,
.advanced-order-form__select {
  width: 22rem;
}

.advanced-order-form__select-label,
.advanced-order-form__label {
  width: 12rem;
  height: 1rem;
  margin: 0;
}

.advanced-order-form__select-container--short
  .advanced-order-form-select__value-container {
  width: 5rem;
}

.advanced-order-form__limit-price-block {
  width: 18.6rem;
  border-radius: 3px;
  padding: 1.25rem 0 1rem 1.5rem;
  background: var(--advanced-order__limited-price-bg-color);
}

.advanced-order-form__limit-price-block-label {
  padding-bottom: 0.5rem;
  color: var(--font__color-secondary);
}

.advanced-order-form__limit-price-block-value {
  font-size: 2.2rem;
  color: 	var(--pc_font-primary);
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.advanced-order-form__lwt-container {
  width: 34rem;
  line-height: 2.2rem;
  flex-shrink: 0;
}

.advanced-order-form__lwt-label {
  margin: 0;
}

.advanced-order-form__lwt-text {
  letter-spacing: 0.6px;
  color: var(--pc_font-primary);
  font-size: 1.4rem;
  font-weight: 300;
}

.advanced-order-form__input--with-right-label {
  width: 17rem;
}

.advanced-order-form__btn {
  width: 20rem;
  margin-bottom: 0;
  margin-top: 2rem;
  flex-shrink: 0;
}

.advanced-order-form__verification-required {
  width: 100%;
}

.advanced-order-form__verification-required .verification-required__container {
  max-width: 20rem;
  min-width: 20rem;
}


/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 768px) {
  .advanced-order-form__main-section {
    display: block;
    width: 100%;
  }

  .advanced-order-form__main-section-limit-price {
    padding-top: 3rem;
    padding-left: unset;
  }

  .advanced-order-form__limit-price-block{
    width: 100%;
  }

  .advanced-order-form__body{
    padding: 3.3rem;
    margin-top: 0;
  }

  .advanced-order-form__select-wrapper,
  .advanced-order-form__input-wrapper{
    display: block;
  }
  
  .advanced-order-form__select-label, 
  .advanced-order-form__label{
    margin-bottom:1rem;
  }

  .advanced-order-form--with-padding{
    width: 100%;
  }

  .advanced-order-form__input, 
  .advanced-order-form__select{
    width: 100%;
  }

  .advanced-order-form__lwt-container{
    width: 100%;
  }

  .advanced-order-form__user-balance-container{
    width: 100%;
  }
}

.advanced-order-form__main-section div .advanced-order-form.advanced-order-form--with-padding{
  padding-left:0;
}
.advanced-order-form.advanced-order-form--with-padding{
  padding-left: 3em;
}
.advanced-order-form__row {
  padding-left: 3em;
}

.ap-button__btn.ap-button__btn--additive.advanced-order-form__btn.advanced-order-form__btn--additive,
.ap-button__btn.ap-button__btn--subtractive.advanced-order-form__btn.advanced-order-form__btn--subtractive{
  margin: 2em 0;
  border-radius: 8px;
  font-size: 1.4rem;
  width: 100%;
  text-transform: capitalize;
  padding: 0 2rem;
  height: 40px;
}

.ap-button__btn.ap-button__btn--subtractive.advanced-order-form__btn.advanced-order-form__btn--subtractive:disabled{
  background-color: #ff4167;
  color: white!important;
}
.advanced-order-form__user-balance-container .ap-label-with-text__text.user-balance__lwt-text{
  font-weight: 300;
  font-size: 1.4rem;
}
.ap-label-with-text.advanced-order-form__lwt-container{
  padding: 0;
  width: 100%;
  position: relative;
}
.ap-label-with-text__text.advanced-order-form__lwt-text{
  margin-top: 1.5rem;
  width: 100%;
  text-align: right;
  max-width: 200px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.advanced-order-form.advanced-order-form--with-padding{
  width: 100%;
}
.ap-label-with-text.advanced-order-form__lwt-container.details label{
  position: initial;
  margin-left: 0;
}