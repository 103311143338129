.ap-popover__popover {
  position: relative;
}

.ap-popover__content > div {
  box-shadow: var(--component__box-shadow);
  border-radius: 0 0 4px 4px;
}

.ap-popover__visible {
  display: block;
}

.ap-popover__hidden {
  display: none;
}