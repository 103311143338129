.banexcard-settings-page p{
  margin: 0;
}
.banexcard-settings-page{
  width: 96%;
  margin: 0 auto 4rem;
  max-width: 1400px;
}
.banexcard-image{
  width: 200px;
  margin-top: -6rem;
}
.banexcard-settings-page .body{
  display: grid;
  grid-template-columns: 30% 70%;
  place-content: center;
  gap: 4rem;
  margin: 4rem auto 4rem;
  width: 70%;
}
.banexcard-settings-page .body .left{
  padding: 2rem;
  text-align: center;
  width: 280px;
  margin: auto;
}
.banexcard-settings-page .body .left img.card-image{
  width: 150px;
  margin: auto;
}
.banexcard-settings-page h2{
  font-size: 2.2rem;
  text-align: center;
  font-weight: 400;
  margin: 2rem 0 0;
}