.balances-menu,
.trade-component.menu-component.balances-menu {
  height: 80vh;
  overflow-y: scroll;
  background: var(--balances-menu__bg-color);
}

.balances-menu__item-icon {
  padding: 1.2rem 1.5rem 1.2rem 1rem;
}

.balances-menu__item-symbol-name {
  font-size: var(--balances-menu__item-label-font-size);
  color: var(--component__font-color-seondary);
  padding: 2px 0px;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--balances-menu__symbol-name-color);
}

.balances-menu__item-balance-value {
  font-size: var(--balances-menu__item-value-font-size);
  color: var(--component__color-primary);
}

.balances-menu__item-value-symbol {
  font-size: var(--balances-menu__item-label-font-size);
  color: var(--component__font-color-seondary);
}

.balances-menu__item {
  min-width: 28rem;
  height: 7.5rem;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 768px) {
  .trade-component.menu-component.balances-menu {
    display: none;
  }
}
