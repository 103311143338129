.options-form .options-form-option.selected{
  background-color: var(--bg-dark-v2);
}
.options-form{
  list-style: none;
  padding: 0;
  width: 100%;
}
.options-form .options-form-option{
  padding: 1.5rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
  background: var(--background-primary_v2);
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border: 1px solid #a8a8c72d;
}
.options-form .option-info{
  max-width: 90%;
}
.options-form .option-info span {
  font-size: 1.4rem;
  color: var(--text-light);
  margin-bottom: 1rem;
}
.options-form .option-info p {
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  margin: 0;
}