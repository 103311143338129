.manual-deposit {
}

.manual-deposit__title {
  color: var(--font__color-primary);
  padding-bottom: 1rem;
  margin: 0 3rem;
  font-size: 1.2rem;
}

.manual-deposit__step-item {
  padding-bottom: 0.5rem;
  color: var(--font__color-secondary);
  font-size: 1.2rem;
  margin: 1rem 3rem;
}
div.manual-deposit__container > div:nth-child(3){
  padding-bottom: 2rem;
}

.manual-deposit__step {
  color: var(--font__color-secondary);
  padding-right: 1rem;
}

.manual-deposit__frame {
  padding: 2rem 1rem 0;
  border-top: 1px solid var(--border__color);
}

.manual-deposit__btn {
  width: 20rem;
  margin: 1rem 0;
}

.manual-deposit__loading {
  transition: none;
}

.manual-deposit__container {
  padding-top: 2.2rem;
  overflow-y: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 30rem;
}

.manual-deposit__container-with-footer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.manual-deposit__footer {
  padding: 0 2.2rem;
  display: flex;
  align-items: center;
  min-height: 10.6rem;
  height: 10.6rem;
  border-top: 1px solid var(--border__color);
}

.manual-deposit__input-box {
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: 3rem;
}

.manual-deposit__input,
.manual-deposit__select {
  width: 100%;
  background: var(--secondary__bg-color);
}

.manual-deposit__select-label,
.manual-deposit__label {
  width: 15rem;
  margin: 0;
  justify-content: flex-start;
}

.manual-deposit__input-wrapper {
  padding-bottom: 2rem;
}

.manual-deposit__select-wrapper,
.manual-deposit__input-wrapper {
  padding-bottom: 2.6rem;
}

.manual-deposit__select,
.manual-deposit__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  min-width: 20rem;
}

.manual-deposit__input-box {
  height: 4.4rem;
}

.manual-deposit__close__btn {
  margin-top: 1rem;
}

.manual-deposit__form-body {
  width: 100%;
  min-height: 30rem;
  padding: 2.2rem;
}

.manual-deposit__select-wrapper,
.manual-deposit__input-wrapper {
  margin: 1rem 2rem;
  text-align: left;
}

.manual-deposit__select-label,
.manual-deposit__label {
  margin: 0 0 1.2rem;
  font-size: 1.4rem;
}

.manual-deposit__input-box {
  display: block;
  width: 100%;
}

.manual-deposit__success {
  display: flex;
  height: 10rem;
  align-items: center;
  justify-content: center;
}
.manual-deposit__error {
}

.manual-deposit__btn {
  width: calc(100% - 2rem);
  margin: 1rem;
  height: 5rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
}

.manual-deposit__template-body,
.manual-deposit__template-footer {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
}

.manual-deposit__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
  position: initial;
  margin-left: 0;
}

.manual-deposit__spinner-circle {
  margin: 0;
}

.manual-deposit__label-in-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.2rem;
  width: 5rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}

.manual-deposit__with-label {
  padding-left: 6rem;
}

.manual-deposit__bank-info-label {
  padding: 2rem 3rem .5rem;
  color: var(--font__color-secondary);
  font-size: 1.4rem;
}

.manual-deposit__bank-info {
  border: 1px solid var(--border__color);
  padding: 1.5rem 2.3rem;
  margin: 3.2rem 3.2rem 3rem;
}

.manual-deposit__lwt-container {
  justify-content: unset;
}

.manual-deposit__lwt-label {
  min-width: 15rem;
}

.manual-deposit__info {
  padding-top: 0.5rem;
}
.fiat-tab {
  display: none;
  width: 100%;
  position: relative;
  height: 40rem;
  padding-top: 2rem;
}
.fiat-tab.active {
  display: table;
}
.fiat-tab .fiat-tab-content {
  vertical-align:middle;
  display: table-cell;
}
.fiat-tab .fiat-tab-content .fiat-tab-title {
  font-size: 1.9rem;
  padding: 0 2rem;
  text-align: center;
}
.fiat-tab .fiat-tab-content select.form-control {
  text-align: center;
  font-size: 1.4rem;
}
.fiat-tab .fiat-tab-content select.form-control option {
  text-align: center;
  font-size: 1rem;
}
.fiat-tab .fiat-tab-content .custom__btn {
  width: auto;
  text-align: center;
  display: block;
  margin: 1rem auto 0;
  padding: 0.5rem 1.5rem;
  font-size: 1.7rem;
}
.fiat-tab .fiat-tab-content .custom__btn:disabled {
  cursor: default;
  background: #d3d3d3;
}
.fiat-tab .col-xl-6 {
  padding: 0 2px;
}
.fiat-tab .cloud-info {
  margin: 1.2rem 0;
}
.fiat-tab .form-label {
  font-weight: 500;
}
.fiat-tab .form-group #transaction_number, .fiat-tab .form-group #amount {
  height: 4.6rem;
}
.fiat-tab #bbk_bank_logo, .fiat-tab #bbk_bank_logo2 {
  width: 50%;
  display: inline-block;
}
.fiat-tab #bbk_bank_logo2 {
  text-align: right;
}
.fiat-tab #bbk_bank_logo img, .fiat-tab #bbk_bank_logo2 img {
  width: 80%;
}
.fiat-tab .col-xl-6 .btn.btn-primary {
  width: 100%;
  padding: 1rem 1.5rem!important;
  font-size: 1.2rem;
}
.fiat-tab .link-a {
  color: #2424a1;
  cursor: pointer;
  font-weight: 500;
}
.fiat-tab .link-a:hover {
  color: #4646b9;
}

.label-confirm-bnx {
  font-weight: 500;
  font-size: 1.3rem;
  color:#a8a8c7;
}
.value-confirm-bnx {
  font-weight: 650;
  font-size: 1.3rem;
  color:var(--pc_font-primary);
}
.value-confirm-bnx > b {
  font-weight: inherit;
}
.value-confirm-bnx > span {
  font-weight: 400;
}

.bnx-title {
  font-weight: 500;
  font-size: 1.4rem;
  text-align: center;
  margin: 1rem 0;
  color:var(--pc_font-primary);
}
.bnx-title > b {
  font-weight: inherit;
  color: #FD8C25;
}
.one-row-data {
  display: flex;
}
.one-row-data > * {
  width: 50%;
}
.one-row-data > * > img {
  width: 50%;
}
.one-row-data > *:last-child {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bnx-v2 .just-hide {
  display: none;
}
.back-button-confirm {
  position: absolute;
  background: var(--primary-color-v2);
  border: none;
  color: var(--pc_font-primary);
  font-size: 1.4rem;
}
.back-button-confirm:hover, .back-button-confirm:focus, .back-button-confirm:active {
  background: #FD8C25!important;
}
.bnx-v2 .malf-data > .row {
  margin-top: 0.8em;
}
.bnx-v2 .ralf-data > img {
  width: 100%;
}
.required-fields-helptext.row {
  margin-top: -5px!important;
  padding-bottom: 10px;
}
.required-fields-helptext .important-message {
  font-size: 1rem!important;
  font-weight: 400!important;
}
small.text-muted.form-text.help-simple {
  font-size: 1rem;
  text-align: left;
}
/* MEDIA QUERIES */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .manual-deposit__lwt-label {
    min-width: 10rem;
  }
}

.bnx-v2 .deposit-text>b {
  color: black;
}

.bnx-v2 .deposit-text {
  font-size: 1.3rem;
}

.bnx-v2 .important-alert.deposit-alert {
  padding-bottom: 0.2rem!important;
}

.card_banking.v2-databank  #bbk_bank_logo2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.custom-ui>h1 {
  text-align: center;
  padding-bottom: 19px;
}

.custom-ui>p {
  padding-bottom: 14px;
}
.new-link-v2 {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
  color: var(--pc_font-secundary);
}
.new-link-v2:hover,
.new-link-v2:active,
.new-link-v2:focus {
  color: var(--pc_font-secundary);
  opacity: 0.7;
}
.coink-alert-swiping-now{
  margin-top: 1rem;
  background-color: #FD8C25;
  padding: 1rem;
  border-radius: 15px;
  color: white;
  font-size: 1.4rem;
  text-align: center;
}
.limits-details-p{
  display: flex;
  justify-content: space-between;
}
.limits-details-p p{
  color: var(--text-gray-v2);
  font-size: 1.2rem;
}
.limits-details-p p:first-child{
  margin-bottom: .6rem;
}
.limits-details-p p:nth-child(2){
  color: white;
  font-size: 1.4rem;
}