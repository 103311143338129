.delivery-process p,.delivery-process span{
  color: var(--text-gray-v2);
  margin: 0;
  font-size: 1.4rem;
}
.delivery-process{
  text-align: center;
  width: 95%;
  margin: 0 auto;
}
.delivery-process h3{
  margin: 2rem 0;
  padding-bottom: 2rem;
}
.delivery-process h3 strong{
  color: var(--pc_font-secundary);
}
.delivery-process .location{
  color: white;
  margin: 2rem 0;
}
.delivery-process .step{
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 4rem;
}
.delivery-process .step:nth-child(2){
  margin-top: 2rem;
}
.delivery-process .step .square{
  width: 28px;
  height: 28px;
  background-color: var(--background-primary_v2);
  border-radius: 6px;
  display: grid;
  place-content: center;
  position: relative;
  border: 2px solid var(--background-primary_v2);
}
.delivery-process .step .square::after{
  position: absolute;
  content: " ";
  height: 41px;
  width: 1px;
  background-color: var(--background-primary_v2);
  opacity: .8;
  display: block;
  bottom: -43px;
  left: 12px;
}
.delivery-process .step.last .square::after{
  display: none;
}
.delivery-process .step.active .square{
  border-color: white;
}
.delivery-process .delivery-steps-images{
  display: flex;
  justify-content: space-between;
  margin-top: 3.5rem;
  margin-bottom: 1rem;
}
.delivery-process .delivery-steps-images img{
  opacity: .2;
}
.delivery-process .delivery-steps-images img.delivery-step-active{
  opacity: 1;
}
