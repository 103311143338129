.verification-start {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 80px);
}
.verification-start .card-verification {
    width: 440px;
}
.verification-start .container-verification {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}
.verification-start .container-verification h2 {
    text-align: center;
    margin: 0;
}
.verification-start .container-verification > p {
    font-size: 1.4rem;
    color: var(--text-gray-v2);
    text-align: center;
    margin: 0;
}
.verification-start .container-verification .list-verification {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin: 2.5rem 0;
}
.verification-start .container-verification .list-verification .item-verification {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 1.5rem;
    align-items: center;
}
.verification-start .container-verification .list-verification .item-verification .verification-icon {
    border: 1px solid #a5a6b442;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
}
.verification-start .container-verification .list-verification .item-verification .verification-info p {
    font-size: 1.4rem;
    margin: 0;
    color: var(--text-gray-v2);
}
.verification-start .container-verification .list-verification .item-verification .verification-info p b {
    font-size: 1.4rem;
    color: var(--text-light);
}
