.labelContCoink{
    position: relative;
    display: flex;
    align-items: center;
}
.labelContCoink >img{
    margin-left: 10px;
    cursor: pointer;
}
.bocadillo-cuadrado {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 20px 15px;
    border-radius: 15px;
    position: absolute;
    z-index: 1;
    bottom: 32px;
    width: calc(100vw - 3rem);
    background: var(--background-primary_v2);
    box-shadow: 0px 6px 21px -5px rgba(154,170,207,0.35)
}
.bocadillo-cuadrado h3{
    font-weight: 700;
    font-size: 1.3rem;
    text-align: left !important;
    margin-bottom: 0;
}
.bocadillo-cuadrado p{
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 0;
}
.bocadillo-cuadrado span{
    border: 10px solid white;
    content: '';
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 25px);
}


.coink-app .font-18{
    font-size: 1.8rem!important;
}