.containerElementInputAPC{
    position: relative;
}
.containerElementInputAPC >div{
    margin-bottom: 1rem !important;
}
.noVisible__input-wrapper{
    display: none;
}
.ap-input__input-box.ExternalAddress-input__input-box input {
    position: absolute;
    top: 52px;
    left: 1px;
    padding-right: 40px;
}
.ap-input__input-box.ExternalAddress-input__input-box input:focus {
    color: #fff;
}
.fontIconAPC{
    position: absolute;
    right: 49px;
    bottom: -65px;
    z-index: 9;
    font-size: 20px;
    color: #fc5c5c;
}
.fontIconAPC.check{
    color: #47c65d;
}
.textValidate{
    color: #FD365F;
}
.textValidate.check{
    color: #29CC78;
}