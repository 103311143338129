.divTableFilter {
    display: flex;
}
.divTableFilter > .v2-field {
    margin-right: 0.5rem;
}
.divTableFilter > * {
    height: 45px;
}
.divTableFilter > .v2-field.btn-bnxv2 {
    padding: 1.1rem 1.75rem;
}
.divTableloading-bg {
    background-color: rgb(100 102 147 / 49%);
    position: absolute;
    width: 100%;
    height: 90%;
    z-index: 999;
    border-radius: 2rem;
    display: none;
}
.divTableloading-bg.show {
    display: block;
}
.pagination.divTablePagination {
    margin-bottom: 2rem;
}
.pagination.divTablePagination .page-link {
    padding: 1.1rem 1.35rem;
    font-size: 1.8rem;
    background-color: #4a4a70;
    border: 2px solid #383857;
    color: #ffffff;
}
.pagination.divTablePagination .page-item.active .page-link {
    color: #fd8f25;
    background-color: #74739F;
}