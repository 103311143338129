.currency_space {
    width: 100%;
    max-width: 480px;
    background: var(--pc__background-secondary_v2);
    padding: 3.2rem 4.4rem;
    border-radius: 2.2rem;
}
.currency_space h2{
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 2.5rem;
}
.currency_space .select_title {
    font-size: 1.4rem;
}
.currency_space label {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
}
.currency_space label img {
    position: absolute;
    left: 10px;
    top: 10px;
    height: 20px;
}
.currency_space label input {
    width: 100%;
    background: var(--secondary-color-v2);
    font-size: 1.2rem;
    border-radius: 1.5rem;
    padding: 1rem;
    padding-left: 4rem;
    color: var(--text-light);
}
.currency_space label input::placeholder {
    color: var(--text-light);
    opacity: 0.5;
}
.currency_space .list_currency {
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 278px;
}
.currency_space .list_currency .currency_item {
    display: grid;
    grid-template-columns: max-content auto max-content;
    align-items: center;
    column-gap: 2.4rem;
    cursor: pointer;
    padding: 1.5rem 1rem;
}
.currency_space .list_currency .currency_item:hover {
    background: var(--hover-color-v2);
}
.currency_space .list_currency .currency_item.disable_item_currency {
    opacity: 0.1;
    cursor: no-drop;
}
.currency_space .list_currency .currency_item:not(:last-child) {
    border-bottom: 1px solid var(--secondary-color-v2);
}
.currency_space .list_currency .currency_item .name_currency p {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
}
.currency_space .list_currency .currency_item .name_currency p:last-child {
    color: var(--text-gray-v2);
    font-size: 1.2rem;
}
.currency_space .list_currency .currency_item .right_icon img {
    height: 20px;
}
@media screen and (max-width: 425px){
    .currency_space{
        padding: 3.2rem 2.4rem;
    }
}
