.card-gradient {
  padding: 20px 32px 0;
  width: 94%;
  max-width: 700px;
  min-width: 400px;
  position: relative;
  border-radius: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(10px);
  animation: fadeIn .8s;
  background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), #292B41);
  border-top: 2px solid #464863;
  margin: auto;
}
.card-gradient.type-SUCCESS {
  background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0)),
      linear-gradient(180deg, rgba(93, 210, 103, 0.05), rgba(46, 46, 72, 0.05));
}
.card-gradient.type-PENDING {
  background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0)),
      linear-gradient(180deg, rgba(255, 220, 0, 0.1), rgba(128, 128, 128, 0) 50%);
}
.card-gradient.type-INFO {
  background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0)),
      linear-gradient(180deg, rgba(255, 220, 0, 0.1), rgba(128, 128, 128, 0) 50%);
}
.card-gradient.type-ERROR {
  background: linear-gradient(180deg, rgba(242, 32, 32, 0.05), rgba(46, 46, 72, 0.05)),
      linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0));
}
.card-gradient.child {
  padding-bottom: 30px;
}
.card-gradient .content-icon-status {
  width: 114px;
  height: 114px;
  border-radius: 50%;
  border: 9px solid #2e2e48;
  position: absolute;
  top: -57px;
  padding: 2px;
  background: rgba(63, 63, 89, 1);
}
.card-gradient .content-icon-status .space-with-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8px solid #2e2e48;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-gradient .content-icon-status .space-with-icon.second-type-SUCCESS,
.card-gradient .content-icon-status .space-with-icon.second-type-FULLYPROCESSED {
  background: rgba(93, 210, 103, 0.05);
}
.card-gradient .content-icon-status .space-with-icon.second-type-PENDING,
  .card-gradient .content-icon-status .space-with-icon.second-type-NEW{
  background: rgba(255, 220, 0, 0.07);
}
.card-gradient .content-icon-status .space-with-icon.second-type-ERROR,
.card-gradient .content-icon-status .space-with-icon.second-type-REJECTED
.card-gradient .content-icon-status .space-with-icon.second-type-USERCANCELLED {
  background: rgba(242, 32, 32, 0.05);
}
.card-gradient .content-icon-status .space-with-icon.second-type-PENDING img{
  width: 40px;
}
.card-gradient .title-info-detail {
  font-weight: 400;
  font-size: 2.2rem !important;
  margin-bottom: 2.5rem;
  /* margin-top: 1.5rem; */
  color: var(--text-light);
  text-align: center;
}
.card-gradient > p {
  color: var(--text-gray-v2);
  text-align: center;
  font-size: 1.6rem;
}
.card-gradient button {
  font-size: 1.6rem;
  font-weight: 400;
}
.card-gradient > p.message {
  margin-bottom: 2rem;
}
.card-gradient > p.white-text {
  color: var(--text-light);
}
.card-gradient > p .text-link {
  color: var(--text-gray-v2);
}
.card-gradient > p .text-link.web {
  text-decoration: auto !important;
}
.card-gradient > p .text-link:active,
.card-gradient > p .text-link:focus {
  color: var(--text-gray-v2);
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.card-gradient-actions{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
}
.card-gradient-actions button{
  color: white;
  font-size: 1.6rem;
  padding: 1.5rem;
  width: 100%;
  background-color: var(--select-text-v2) !important;
  border-radius: 1.6rem;
  transition: all .3s;
}
.card-gradient-actions button.secondary{
  background-color: #393A50!important;
}
.card-gradient-actions button.orange-gradient{
  background: var(--primary-color-v2)!important;
}
.card-gradient button:hover{
  opacity: .5;
}
.card-gradient button:disabled{
  opacity: .5;
  cursor: not-allowed;
}
.with-type{
  padding-top: 72px;
}
.with-bottom{
  padding-bottom: 20px;
}
.card-gradient .back-action{
  position: absolute;
  left: 25px;
  top: 23px;
  cursor: pointer;
}
.card-gradient p.scrollable{
  max-height: calc(100vh - 300px);
  overflow: auto;
  padding-right: 3rem;
  text-align: left;
}