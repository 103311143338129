.verification-required__container {
  min-width: 100%;
  max-width: 100%;
}
.verification-required,
.verification-required__no-link {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: var(--verification-required__bg-color);
  transition: var(--transition__settings);
  margin: 1rem 0;
  padding: 1.5rem 1rem;
  max-width: 100%;
}
.verification-required {
  cursor: pointer;
  display: flex;
}

.verification-required:hover {
  background: var(--verification-required__bg-color--hover);
}

.verification-required__no-link *,
.verification-required * {
  color: var(--verification-required__font-color);
}

.verification-required__warning--alert {
  display: flex;
  margin: 0 1rem;
  flex-grow: 1;
}

.verification-required__not-verified {
  margin-bottom: 0;
  flex-grow: 9;
  flex-shrink: 1;
}
