.modal-2fa{
  max-width: 90%;
  width: 400px;
  color: white;
}
.modal-2fa-header img{
  position: absolute;
  right: 20px;
  top: 20px;
}
.modal-2fa-header{
  margin-top: 3rem;
}
.modal-2fa-header p{
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}
.modal-2fa input[type="number"]::-webkit-inner-spin-button,
.modal-2fa input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-2fa-body {
  padding: 1rem 2rem 0!important;
}
.modal-2fa-body p.desc{
  margin-top: 2rem;
  font-size: 1.6rem;
}
.modal-footer{
  border: none;
}
.modal-2fa-body .form-group{
  width: 94px;
  margin: auto;
}
.modal-2fa-body .form-group input{
  font-size: 1.8rem!important;
}
.modal-2fa .modal-footer button{
  width: 100%;
  margin: 1rem 2rem;
}
.modal-2fa .text-danger{
  padding: 0!important;
  height: 24px;
  font-weight: bold;
  color: var(--error-color-v2);
}

.ticket-detail-v2 > .row {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ticket-detail-v2 > .row .text-left {
  font-size: 1.5rem;
  font-weight: 500;
}

.ticket-detail-v2 > .row .text-right {
  font-size: 1.4rem;
  font-weight: 400;
  color: #9e9ebc;
  word-wrap: break-word;
}
.side-currency {
  background-color: #212235;
  color: white;
  font-weight: 400;
  font-size: 1.6rem;
  display: block;
  border-radius: 1.7rem;
  padding: 1rem 1.3rem;
  width: max-content;
  margin-left: auto;
  margin-bottom: 1rem;
}

.side-currency-title {
  display: flex;
  align-items: center;
  color: #9e9ebc;
}