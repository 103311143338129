.order-entry {
  background: var(--component__bg-color);
  flex-grow: 1;
}

.order-entry__container {
  height: 100%;
  border-left: var(--component__border);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--component__bg-color);
}

.order-entry__container .trade-component__body {
  height: 100%;
}
.trade-component__body {
  overflow: scroll;
}

.order-entry__body {
  height: calc(100% - 6rem);
  overflow: hidden;
  padding: 0 0 2rem;
}

.order-entry__body .trade-component__section {
  padding: 2rem 1.6rem 0;
}

.order-entry__sb-container,
.order-entry__input-wrapper,
.order-entry__select-wrapper {
  padding: 0 1.6rem;
}

.order-entry__select{
  -webkit-appearance: none;
}

.order-entry__triangles-container {
  right: 2.5rem;
}

.order-entry__form {
  height: calc(100% - 3.2rem);
}

.order-entry__buy-sell-wrapper {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--border__color);
  height: 35px;
  background: #a8a8c724;
  border-radius: 5px;
  overflow: hidden;
}

.order-entry__buy-sell-radio-tab-wrapper {
  display: flex;
  padding: 0 1.6rem;
  justify-content: left;
}

.ap-radio-tab__label--subtractive:hover {
  border-color: var(--exchange-colors__sell);
  color: var(--radio__tab-color--hover);
}

.ap-radio-tab__label--additive:hover {
  border-color: var(--exchange-colors__buy);
  color: var(--radio__tab-color--hover);
}

.ap-radio-tab__label--additive:active {
  color: var(--exchange-colors__buy);
}

.ap-radio-tab__label--subtractive:active {
  color: var(--exchange-colors__sell);
}

.ap-radio-tab__label--checked-subtractive,
.ap-radio-tab__label--checked-subtractive:hover {
  color: var(--radio__tab-color--hover);
  border-color: var(--exchange-colors__sell);
}

.ap-radio-tab__label--checked-additive,
.ap-radio-tab__label--checked-additive:hover {
  color: var(--radio__tab-color--hover);
  border-color: var(--exchange-colors__buy);
}

.order-entry__sb-container {
  width: 100%;
}

.order-entry__rt-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.order-entry__rt-label {
  text-align: center;
  width: 50%;
}

.order-entry__btn {
  width: 100%;
}

.order-entry__item-button {
  letter-spacing: 0.5px;
  border-radius: 4px;
  padding: 1.5rem;
  margin: -1.25rem -1rem;
  color: var(--link__color);
  cursor: pointer;
}

.order-entry__item-button:hover {
  color: var(--order-entry__item-btn-color--hover);
  background: var(--order-entry__item-btn-bg-color--hover);
}

.order-entry__item-button:active {
  color: var(--order-entry__item-btn-color--hover);
  background: var(--order-entry__item-btn-bg-color--hover);
}

.order-entry__hr {
  margin: 1.3rem -1.6rem;
}

.order-entry__buy-sell-hr {
  margin: -1px 0 0 0;
}

.order-entry__lwt-text {
  color: var(--font__color-primary);
}

.order-entry__input-box {
  flex-direction: column;
  justify-content: center;

}
body #root .dark .form-control.ap-input__with-label.order-entry__with-label.ap-input__input.order-entry__input{
  border-color: var(--border-gray-v2)!important;
  text-align: right;
  padding-right: 43px;
  padding-left: 0;
  font-size: 1.2rem;
}
body #root .dark .form-control.ap-input__with-label.order-entry__with-label.ap-input__input.order-entry__input:focus{
  border-color: #FF9443 !important;
}
body #root .dark .order-entry .ap--label{
  color: var(--text-gray-v2)!important;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 10px;
}
.body #root .dark .order-entry .quantity label,
.body #root .dark .limit .order-entry .ap--label{
  color: #a8a8c7!important;
}
.not-logged-buttons a{
  display: block;
  width: 100%;
  color: white;
  border-radius: 8px;
  height: 40px;
  font-size: 1.4rem;
  background-color:  #1f1f30c2;
  transition: all 0.3s ease;
  padding-top: 9px;
  text-align: center;
}
.not-logged-buttons a.register{
  background: var(--primary-color-v2);
  margin-bottom: 1.5rem;
}
.not-logged-buttons a:hover{
  transform: translateY(-5px);
}
.available-balance{
  color: #a8a8c7;
  font-size: 1.2rem;
  margin: 1rem 0;
}
.available-balance span{
  color: white;
}
.form-group.ap-input__input-wrapper.order-entry__input-wrapper{
  position: relative;
}
.form-control.ap-select__select.order-entry__select{
  color: #a8a8c7!important;
}
.form-control.ap-select__select.advanced-order-form__select,.form-control.ap-input__with-label.advanced-order-form__with-label.ap-input__input.advanced-order-form__input, .ap-input__input-box .advanced-order-form__input.form-control, .report-block-trade-form__input-box .report-block-trade-form__input.form-control{
  border: 1px solid var(--border-gray-v2)!important;
  font-size: 1.4rem;
  width: 100%;
  text-align: right;
  padding-right: 23px;
  direction: rtl;
}
.advanced-order-form__select-wrapper, .advanced-order-form__input-wrapper{
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}
.advanced-order-form__select-label, .advanced-order-form__label{
  width: max-content!important;
}
.ap-modal__footer.confirm-block-trade-modal__bought__footer{
  text-align: center;
}