.page-header-nav__container {
  display: flex;
}

.page-header-nav__item {
  display: flex;
  align-items: center;
  width: 16rem;
  padding-top: 0.5rem;
  cursor: pointer;
  justify-content: center;
  color: var(--nav-item__color);
  background: var(--nav-header__bg-gradient);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: var(--z-index__navigation);
  border-left: 2px solid transparent;
}

.page-header-nav__item:focus{
  border-right: solid 1px var(--nav-item__separator-color);
}

.page-header-nav__menu-toggle--active::after {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: -1px;
  right: 1.3rem;
  border-left: 0.8rem solid transparent;
  border-right: 0.8rem solid transparent;
  border-bottom: 0.8rem solid var(--pc__background-secondary_v2);
}

.page-header-nav__item--hoverable:hover {
  background: var(--nav-item__bg-color--hover);
  color: var(--nav-item__color--hover);
}

.page-header-nav__item:focus:not(:hover){
  color: var(--nav-item__color);
}

.page-header-nav__item--selected:hover,
.page-header-nav__item--selected {
  background: var(--nav-item__bg-color--selected);
  color: var(--nav-item__color--action);
  position: relative;
}

/*.page-header-nav__item--selected:after{
  position: absolute;
  bottom:0;
  content: "";
  height: 2px;
  width: 100%;
  calc(100% - 2rem);
  border-bottom: 2px solid var(--component__border-color--glow);
}*/

.page-header-nav__label {
  font-size: var(--nav-item__font-size);
  padding-left: .8rem;
  padding-right: .8rem;
}

.page-header-nav__icon.ap-icon{
  display: flex;
  width: var(--nav-item__icon-size);
}

.page-header-nav__icon--exchange {
  margin-top: 0.3rem;
}

.page-header-nav__menu-toggle {
  cursor: pointer;
  border-right: 1px solid var(--tab__separator-color);
  width: var(--nav-header__height);
  min-height: var(--nav-header__height);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--nav-item__color);
  z-index: 1;
  display: none;
}

.page-header-nav__menu-toggle:hover,
.page-header-nav__menu-toggle:focus {
  color: var(--nav-item__color--action);
}

.page-header-nav__menu-toggle--active ~ .page-header-nav__item--selected,
.page-header-nav__menu-toggle:hover ~ .page-header-nav__item--selected {
  background: var(--nav-item__bg-color--selected);
  color: var(--nav-item__color--action);
}

.page-header-nav__icon--menu-toggle {
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 80%;
}

.page-header-nav__icon--menu-toggle:before {
  content: '';
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid;
  display: inline-block;
  margin-top: 0rem;
}

.page-header-nav__icon--menu-toggle svg{
  display: none;
}

.page-header-nav__container--closed {
  overflow: hidden;
}
.menu_hover .menuly .__react_component_tooltip {
  display: none;
}

/* BROWSER QUERIES */

/* IE11+ specific styles */
_:-ms-fullscreen, :root .page-header-nav__icon--menu-toggle:before {
  margin-top: var(--nav-header__height);
}

/* MEDIA QUERIES */
.menuly {
  display: grid;
  grid-template-rows: max-content auto max-content;
  position: fixed!important;
  left: 0;
  top: 0!important;
  bottom: 0;
  height: 100%;
  background: var(--pc__background-secondary_v2);
  z-index: 2;
  width: 8rem;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
  box-shadow: 0 20px 21px 7px #2e2e48;
  max-width: 300px;
}
/* Medium Devices, Tablets */
@media only screen and (min-width: 1025px) {
  /*.page-header-nav__container{
    overflow: auto;
    display: block;
    position: fixed!important;
    left: 0;
    top: 5.2rem;
    bottom: 0;
    height: calc(100% - 5.2rem);
    background: #353A36;
    z-index: 99;
    width: 8rem;
  }*/
  .menuly {
    display: grid;
    grid-template-rows: max-content auto max-content;
    position: fixed!important;
    left: 0;
    top: 5.2rem;
    bottom: 0;
    height: 100%;
    background: var(--pc__background-secondary_v2);
    z-index: 2;
    width: 8rem;
    -webkit-transition: width 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
    box-shadow: 0 20px 21px 7px #2e2e48;
  }
  .menuly a {text-decoration: none;}

  .menuly.toggled {
    width: 20rem;
  }
  .menuly.toggled a {
    justify-content: left;
    padding-left: 1.5rem!important;
  }
  .menuly.toggled a .page-header-nav__icon.ap-icon {
    width: 3.1rem;
  }
  .menuly.toggled a.page-header-nav__item.desplegable .page-header-nav__label {
    display: none!important;
  }
  .menuly.toggled .page-header-nav__label {
    display: block;
  }
  .menu_hover .menuly {
    width: 21rem;
  }
  .menu_hover .menuly .page-header-nav__label {
    display: flex;
  }
  .menu_hover .page-header__header-item--logo {
    width: 21rem!important;
    gap: 2rem;
  }

  .menu-toggle svg {
    -webkit-transition: -webkit-transform .8s ease-in-out;
    -ms-transition: -ms-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
  }
  .toggled .menu-toggle svg {
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .menu-toggle {
    position: absolute;
    top: 87px;
    right: -10px;
    z-index: 999;
    background: #FFFFFF;
    width: 20px!important;
    height: 20px!important;
    border-radius: 3rem;
    padding: 1px 5px!important;
    -webkit-box-shadow: 0 0 2px rgba(9, 30, 66, 0.35);
    box-shadow: 0 0 2px rgba(9, 30, 66, 0.35);
    cursor: pointer;
  }
  .menu-toggle:hover {
    background: #FF9013;
  }
  .menu-toggle svg {
    pointer-events: none;
  }
  .menu-toggle .st0 {
    fill: #FF9013;
  }
  .menu-toggle:hover .st0 {
    fill: #FFFFFF;
  }
  .page-header__content-wrapper.toggled .page-header__header-item--logo {
    width: 20rem!important;
  }
}
@media only screen and (max-width: 1024px) {
  .page-header-nav__container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  .page-header-nav__item {
    border-right: 0px solid transparent;
    flex-direction: column;
    width: 7.5rem;
  }

  .page-header-nav__item:focus{
    border-right: solid 0px transparent;
  }

  .page-header-nav__item--selected:hover,
  .page-header-nav__item--selected {
    background: var(--nav-header__bg-gradient);
  }

  .page-header-nav__item--selected:after{
    display: none;
  }
 
  .page-header-nav__label {
    font-size: 1.1rem;
    padding-top: 0.5rem;
  }
  
  .page-header-nav__icon.ap-icon{
    height: var(--nav-item__icon-size);
  }
}

/* Small Devices, Phones */
@media only screen and (max-width: 720px) {
  .page-header-nav__container {
    order: 1;
    flex-direction: column;
    z-index: var(--z-index__navigation);
    position: absolute;
    height: var(--nav-header__height);
    flex-grow: unset;
    justify-content: unset;
  }

  .page-header-nav__item {
    padding-left: 3rem;
    padding-right: 1.5rem;
    min-height: var(--nav-header__height);
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    width: 20rem;
    position: relative;
    border-top: 1px solid var(--tab__separator-color);
    order: 2;
  }

  .page-header-nav__item:not(.page-header-nav__item--selected) {
    box-shadow: var(--nav-header__box-shadow);
    background: var(--nav-item__bg-color);
  }

  .page-header-nav__item:hover:not(.page-header-nav__item--selected) {
    background: var(--background-primary_v2);
  }

  .page-header-nav__item:last-of-type {
    -webkit-border-bottom-right-radius: var(--component__border-radius);
    -moz-border-radius-bottomright: var(--component__border-radius);
    border-bottom-right-radius: var(--component__border-radius);
  }

  .page-header-nav__item--selected:hover,
  .page-header-nav__item--selected {
    order: 1;
    z-index: -1;
    cursor: default;
  }

  .page-header-nav__menu-toggle {
    position: absolute;
    right: 0;
    width: 100%;
    display: flex;
  }

  .page-header-nav__icon--menu-toggle {
    position: absolute;
    right: 2rem;
    width: 0;
    height: 0;
  }

  .page-header-nav__label {
    font-size: var(--nav-item__font-size);
    padding-top: 0;
  }
  
  .page-header-nav__icon.ap-icon{
    height: auto;
  }


  /* BROWSER QUERIES */
  
  /* IE11+ specific styles */
  _:-ms-fullscreen, :root .page-header-nav__icon--menu-toggle {
    right: 2.5rem;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 645px) {
  .page-header-nav__menu-toggle{
    position: relative;
    width: var(--nav-header__height);
    justify-content: center;
  }
  
  .page-header-nav__icon--menu-toggle{
    right: unset;
  }

  .page-header-nav__icon--menu-toggle svg{
    margin-top: 0.6rem;
    display: block;
  }

  .page-header-nav__icon--menu-toggle:before{
    display: none;
  }

  .page-header-nav__item--selected:hover, 
  .page-header-nav__item--selected{
    background: var(--nav-item__bg-color--selected);
  }
}
.toggle-button{
  position: absolute;
  top: 60px;
  right: -8px;
  transform: scale(1.5);
  transition: 300ms all;
  width: 19px;
  height: 19px;
  background-color: #404060;
  border-radius: 100%;
  padding-top: 4px;
  text-align: center;
}
.toggle-button.open{
  transform: scale(1.5) rotate(180deg)translateY(1px);
}
.pagination__page.pagination__page--active.order-history-table-pagination__page.order-history-table-pagination__page--active{
  color: #FF9013!important;
  text-decoration: underline!important;
}
.reference-tooltip{
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip-menu{
  position: absolute;
  width: max-content;
  background: #2e2e48;
  border-radius: 33px;
  padding: 4px 8px;
  left: 57px;
  top: -12px;
  display: none;
}
.reference-tooltip:hover > div > p.tooltip-menu,
.reference-tooltip > .tooltip-menu:hover{
  display: block!important;
}

@media (width < 1024px) {
  .reference-tooltip:hover > div > p.tooltip-menu,
.reference-tooltip > .tooltip-menu:hover{
  display: none!important;
}
}