.send-receive-tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.send-receive-tabs__tab {
  font-size: 1.4rem;
  height: 5rem;
  line-height: 5rem;
  flex: 1 1 auto;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid var(--border__color--light);
  color: var(--tab__font-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-receive-tabs__tab:first-child {
  border-right: 1px solid var(--border__color--light);
}

.send-receive-tabs__tab-icon {
  margin-right: 1.2rem;
  margin-top: 0.5rem;
  width: 1.7rem;
}

.send-receive-tabs__tab--selected {
  color: var(--tab__font-color--selected);
  border-bottom: 2px solid var(--tab__border-color--selected);
}

.send-receive-tabs__tab-text {
  position: relative;
}
