

.elementor-widget-heading .elementor-heading-title>a.made-by svg {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 .07em !important;
    vertical-align: -0.2em !important;
    background: none !important;
    padding: 0 !important;
    fill: white;
    -o-transition: var(--transition__settings);
    transition: var(--transition__settings);
}
.elementor-widget-heading .elementor-heading-title>a.made-by:hover svg {
    fill: red;
}
.test-class .test a{
    background: rgb(96, 96, 97) none repeat scroll 0% 0%!important;
    position: initial!important;
    float: right!important;
    margin: 1rem!important;   
}
.flogo-landing .twoimgs > a > img {
    filter:none;
}
.flogo-landing .twoimgs .libroV2 {
    margin-left: 20px;
}
.flogo-landing {
        display: flex;
        justify-content: center;
        align-items: center;
            
}

.flogo-container
{
    margin-top: 0!important;
}
.elementor-column-wrap .elementor-widget-container .terms {
    line-height: 16px;
}