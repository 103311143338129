.wallet-card {
  width: 37.2rem;
  height: 26.9rem;
  background: var(--wallet-card__bg-color);
  border-radius: 1rem;
  margin: 0 2rem 4rem 2rem;
  display: flex;
  flex-direction: column;
}

.wallet-card__icon {
  margin-left: 2.25rem;
  height: 100%;
  align-items: flex-end;
  display: flex;
}

.wallet-card__icon-label-row {
  display: flex;
  align-items: flex-end;
  flex: 1.3;
  padding-bottom: 3.3rem;
}

.wallet-card__symbol-label {
  font-size: 2rem;
  margin-left: 1.5rem;
  color: var(--font__color-primary);
  line-height: 4rem;
}

.wallet-card__balance-row {
  display: flex;
  flex: 1;
  width: 100%;
}

.wallet-card__balance {
  width: 53%;
  padding: 2rem 2.25rem 3rem;
}

.wallet-card__label {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--font__color-secondary);
}

.wallet-card__value {
  width: 50%;
  border-left: 1px solid var(--border__color);
  padding: 2rem 2rem 3rem;
}

.wallet-card__amount {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  letter-spacing: -0.6px;
}

.wallet-card__amount-text {
  font-size: 1.8rem;
  color: var(--font__color-primary);
  padding-right: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wallet-card__activity-link-row {
  background: var(--wallet-card__activity-link-row-bg-color);
  padding: 1.5rem 2.25rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.wallet-card__activity-link {
  font-size: 1.4rem;
}

.wallet-card__next-icon {
  margin-left: 0.75rem;
}

.wallet-card__icon-nav-buttons {
  display: flex;
}

.wallet-card__balance-symbol-label {
  font-size: 1.4rem;
  color: var(--font__color-secondary);
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .wallet-card {
    max-width: 29.6rem;
    height: 21.4rem;
    margin: 0 1.4rem 2.8rem;
  }

  .wallet-card__balance-row {
    flex: 1.3;
  }

  .wallet-card__balance {
    padding: 1.5rem 2.5rem 2rem 2.25rem;
  }

  .wallet-card__amount-text {
    font-size: 1.6rem;
  }

  .wallet-card__balance-symbol-label {
    font-size: 1.2rem;
  }

  .wallet-card__icon-label-row {
    padding-bottom: 1rem;
  }

  .wallet-card__symbol-label {
    line-height: 2.8rem;
  }
}

@media (min-width: 768px) and (max-width: 982px) {
  .wallet-card {
    min-width: 29.6rem;
    max-width: 33rem;
  }
}

/* Mobile */
@media (min-width: 320px) and (max-width: 767px) {
  .wallet-card {
    width: 35.1rem;
    height: 20.1rem;
    margin: 0;
    margin-bottom: 1.2rem;
  }

  .wallet-card__balance-row {
    flex: 1.3;
  }

  .wallet-card__balance {
    padding: 1.2rem 2.5rem 2rem 2.7rem;
  }

  .wallet-card__amount-text {
    font-size: 1.8rem;
  }

  .wallet-card__balance-symbol-label {
    font-size: 1.4rem;
  }

  .wallet-card__icon-label-row {
    padding-bottom: 1rem;
  }

  .wallet-card__icon {
    margin-left: 2.7rem;
  }

  .wallet-card__symbol-label {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .wallet-card__activity-link-row {
    padding: 1.5rem 1.2rem 1.5rem 2.7rem;
  }

}
