.intermediateLevel, .kyc-level.intermediateLevel{
    border-left: 5px solid #825e9f !important;
}
.intermediateLevel .levelHeader .levelTitle, .kyc-level.intermediateLevel .kyc-level__header-title{
    color: #825e9f !important;
}

a.more-btn{
    width: stretch;
    margin: 0 5rem;
    border: 2px solid #e9e9e9;
    padding: 1rem .5rem;
    border-radius: .5rem;
    color: #999999;
    font-weight: 600;
    margin-top: -4rem;
    text-align: center;
}

a.more-btn:hover{
    border: 2px solid #FF8F15;
    color: #FF8F15;
    opacity: 0.5;
}

/* Corrección de estilos en responsive de los Reportes lmedina*/
.pika-single.retail .pika-table {
    background-color: initial;
    color: white;
    font-size: 1.2rem;
  }
  .pika-single.retail .pika-title {
    padding: 8px 8px 0;
    background-color: #f28e2a;
    width: 25.6rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.pika-single.retail thead {
    background-color: #f28e2a;
}

.pika-single.retail .pika-button, .pika-single.retail td {
    background: transparent;
    color: var(--font__color-secondary);
    text-align: center;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 2.4rem;
    color: black;
    width: 2.8rem;
}

.pika-single.retail .pika-button:hover {
    background: #f28e2a;
    color: white;
}
.kyc-pagev2 .LevelBlock .LevelForSingle .levelHeader .levelTitle {
    font-size: 1.7rem;
}
.kyc-pagev2 .LevelBlock .LevelForSingle {
    padding: 20px 10px 20px 10px;
    height: 26rem;
    vertical-align: top;
}
.kyc-pagev2 .LevelBlock .LevelForSingle .levelContent {
    height: 17rem;
    width: 100%;
    display: block!important;
}
.kyc-pagev2 .LevelBlock.minified .LevelForSingle {
    height: inherit!important;
    border-radius: 1rem;
    padding: 18px 15px 15px 15px;
}

.LevelForSingle.hideButton .levelFooter .VerifiedStatus {
    display: none;
}