.kyc-form__title {
  color: var(--font__color-secondary);
  font-size: 1.6rem;
}

.kyc-form__wrapper {
  width: 37rem;
  margin-top: 2rem;
}

.inline__info {
  width: 100%;
  text-align: right;
}

.kyc-form__close__btn {
  margin-top: 1rem;
}

.kyc-form__form-body {
  width: 100%;
}

.kyc-form__select-wrapper,
.kyc-form__input-wrapper,
.kyc-form__dpk-wrapper {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 2rem 1rem 0 0;
  align-items: baseline;
}

.kyc-form__select-label,
.kyc-form__label,
.kyc-form__dpk-label {
  width: 15rem;
  margin: 0;
  justify-content: flex-start;
  margin-top: 0.7rem;
}

.kyc-form__info {
  margin-top: 0.7rem;
  padding-left: 2rem;
  font-style: italic;
}

.form-group .kyc-form__input,
.form-group .kyc-form__dpk-input,
.form-group .kyc-form__select {
  width: 22rem;
}

.kyc-form__btn:not(.ap-upload__btn) {
  width: 18em;
  margin: 1rem 0;
}

.kyc-form__template-body,
.kyc-form__template-footer,
.kyc-form__loading {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
}

.kyc-form__error:not(.ap-upload__error) {
  position: absolute;
  top: 4.9rem;
  margin-left: 1rem;
  margin-top: unset;
  padding-top: unset;
}

.kyc-form__section {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border__color);
}

.kyc-form__section--bottom {
  display: flex;
  justify-content: space-between;
}

.kyc-manual-directions__email {
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--font__color-secondary);
}

.ap-phone-input__input-box.kyc-form__input-box {
  width: 22rem;
}

.ap-phone-input__input-box.kyc-form__input-box .react-phone-number-input__country-select option{
  color: var(--font__color-primary);
}


.kyc-form__input-box .react-phone-number-input__row {
  height: var(--input__height);
  padding: 0 0 0 1rem;
  border-radius: 2px;
  background: var(--input__bg-color);
  border: 1px solid var(--input__border-color);
}

.kyc-form__back__btn {
  margin-right: 2rem;
}
