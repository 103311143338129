.content-form-delete{
    width: 100%;
}
.delete-account-container {
    width: 100%;
    max-width: 470px;
    margin: auto;
}
.delete-account,
.delete-account .success-msg {
    padding: 0 !important;
}
.delete-account .success-msg .delete-section {
    padding-top: 6rem;
}
.delete-account .success-msg .delete-section h2 {
    font-size: 2rem;
}
.delete-account .success-msg .delete-section p {
    font-size: 1.6rem;
}
.delete-account .success-msg .delete-section .statusTransaction.firstType_SUCCESS {
    background: none;
}
.delete-account .success-msg .delete-section .spaceConIcon.secondType_SUCCESS {
    background: inherit;
}
.container-delete {
    padding: 0 5rem;
    width: 100%;
    max-width: 470px;
    margin: auto;
}
.container-delete .delete-title{
    padding: 0;
}
.container-delete .delete-title b{
    font-size: 2rem;
    font-weight: 600;
}
.container-delete p{
    font-size: 1.2rem;
    color: var(--text-gray-v2);
    margin-bottom: 0;
}
.container-delete textarea{
    resize: none;
    height: 90px !important;
    padding: 1.25rem !important;
    font-size: 1.4rem !important;
}
.container-delete .list-items-delete {
    margin-top: 3rem;
    margin-bottom: 0;
}
.container-delete h3 {
    margin: 3rem 0 0;
}
.container-delete .text-suggestion {
    margin: 2rem 0;
}
.container-delete button {
    margin-top: 2rem;
    background: var(--error-color-v2) !important;
}
.container-delete button:disabled:hover {
    cursor: no-drop;
}
.back-arrow-delete {
    margin-left: 4rem;
    width: max-content;
    cursor: pointer;
}
.button-succes-delete{
    margin-top: 2rem;
    background: var(--select-text-v2) !important;
}
.content-form-delete .col-xl-4{
    max-width: 100%;
    flex: 100%;
}
.content-form-delete label{
    text-align: inherit !important;
}