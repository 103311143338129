:root {
  --retail-dashboard__card-header-title-font-size: 1.8rem !important;
}

.dashboard-page {
  width: 95%;
  max-width: 1200px;
  margin: 4rem auto 0;
  flex: 1 0 auto;
}

.dashboard-page__notification-row {
  box-shadow: var(--component__box-shadow);
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 3.7rem;
}

.dashboard-page__market-overview-container {
  display: flex;
}

.dashboard-page__market-overview-container-left {
  width: 110rem;
  overflow: hidden;
  margin-right: 40px;
  height: 490px;
}

.dashboard-page__market-overview-container-right {
  width: 48rem;
  overflow: hidden;
  height: 410px;
  border: 0px solid #F1F1F1;
  padding: 10px;
  margin-top: 7rem;
  background: #404060!important;
  border-radius: 0.3rem;
}

.dashboard-page__footer {
  margin-top: 8rem;
  flex-shrink: 0;
}

.dashboard-page__market-overview-container-right .dashboard-page__balances-header {
  height: auto;
}

.dashboard-page__balances-header {
  display: flex;
  align-items: center;
  padding-left: var(--retail-dashboard__card-header-title-padding);
  height: var(--retail-dashboard__card-header-height);
  font-size: var(--retail-dashboard__card-header-title-font-size);
  color: var(--retail-dashboard__card-header-title-font-color);
  /*background: var(--component__header-bg-color);*/
  position: relative;
  /*border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;*/
}

/*.dashboard-page__balances-header::after {
  content: '';
  width: 100%;
  height: 0.2rem;
  background-color: var(--component__border-color--glow);
  position: absolute;
  bottom: 0;
  left: 0;
}*/

.dashboard-page__market-overview-container-right .product-balances__container {
  height: calc(100% - var(--retail-dashboard__card-header-height));
}

.dashboard-page__market-header {
  display: flex;
  height: var(--retail-dashboard__card-header-height);
  /*background-color: var(--component__header-bg-color);*/
  align-items: center;
  padding-left: var(--retail-dashboard__card-header-title-padding);
  font-size: var(--retail-dashboard__card-header-title-font-size);
  color: var(--retail-dashboard__card-header-title-font-color);
  /*border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;*/
  position: relative;
}

/*.dashboard-page__market-header::after {
  content: '';
  width: 100%;
  height: 0.2rem;
  background-color: var(--component__border-color--glow);
  position: absolute;
  bottom: 0;
  left: 0;
}*/

/* MEDIA QUERIES */

/* Medium Devices, Tablets */
@media only screen and (max-width: 1228px) {
  .dashboard-page__market-overview-container {
    flex-direction: row;
    justify-content: center;
  }

  .dashboard-page__market-overview-container-left {
    width: 100%;
    max-width: 52.2rem;
    margin-bottom: 0;
  }

  .dashboard-page__market-overview-container-right {
    width: 42.2rem;
  }

  .dashboard-page__recent-activity-container {
    max-width: 96.8rem;
    margin: 0 auto;
  }

  .dashboard-page__notification-row {
    max-width: 96.8rem;
    margin: 0 auto 3.7rem;
  }
}


.banexcoin_marketview {
  display: block;
  border: 0px solid #F1F1F1;
  padding: 10px 10px;
  margin-right: 40px;
  background: white;
  border-radius: 0.3rem;
  width: 70rem;
}

.banexcoin_marketview .market-data-cards__card-banex {
  width: 30%;
  height: 11rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
  margin: 15px 1.5%;
  border-radius: 0.7rem;
  background: white;
}

.banexcoin_marketview .market-data-cards__card-banex:hover, .banexcoin_marketview .market-data-cards__card-banex.selected {
  -webkit-box-shadow: 0 0 4px 2px #FF9443   ;
  box-shadow: 0 0 4px 2px #FF9443;
  cursor: pointer;
}

.banexcoin_marketview .market-data-cards__card-banex .invidual__card {
  display: block;
  width: 100%;
  text-align: center;
}

.banexcoin_marketview .market-data-cards__card-banex .invidual__card>.card_pair_title {
  font-size: 1.4rem;
  padding: 15px 0;
}

.banexcoin_marketview .market-data-cards__card-banex .card_pair_icon_crypto {
  display: block;
  width: 100%;
  padding: 10px 0;
}

.banexcoin_marketview .market-data-cards__card-banex .card_pair_icon_crypto .vertical-line {
  width: 2.5px;
  height: 3rem;
  background: #e3e3e3;
  display: inline-block;
  vertical-align: middle;
  margin: 0 1rem;
  border-radius: 5px;
}

.banexcoin_marketview .market-data-cards__card-banex .card_pair_icon_crypto img {
  display: inline-block;
  vertical-align: middle;
}

.banner-step {
  height: 100%;
  overflow: hidden;
}

.banner-step img {
  object-fit: cover;
}
.banner-step .banner-container {
  display: flex;
  padding: 0px !important;
}

.banner-step  .banner-img-left {
  padding: 0px !important;
  width: auto;
}

.banner-step .banner-img-right {
  padding: 0px !important;
  width: auto;
}

.banner-step .banner-img-right>img{
  float: right;
}

.banner-step .banner-title {
  text-align: center;
  top: 16px;
}

.banner-step .banner-title>h1>p {
  font-size: 2.8rem;
  font-weight: 400;  
  color:#ffffff;
}

.banner-step .banner-title>h1>p>b {
  color: #FF8E0A;
  font-weight: 500;
}

.banner-step .banner-title>h3{
  font-size: 1.8rem;
  font-weight: 400;
  color:#ffffff;
}

.banner-step .banner-links{
  margin-top: 3px;
}

.banner-step .banner-links>a>img{
  padding: 5px;
}
.tooltip-inner {
  background-color: #ffffff;
  border-radius: 10px!important;
  overflow: hidden;
  padding: 0!important;
  }
  .tooltip.bs-tooltip-bottom .arrow:before {
      border-bottom-color: #ffffff !important;
      border-radius: 10px!important;
  }

.banner-step .banner-tool {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 12450px) {
  .banner-step .banner-title>h1>p {
    font-size: 2.6rem;
  }
}

@media only screen and (max-width: 950px) {
  .no-tooltip{
    display: none;
  }
  .banner-container{
    height: 150px;
  }
  .banner-step .banner-img-right>img{
    display:none;
  }
  .banner-step .banner-img-left>img{
    display:none;
  }
  
}

@media only screen and (max-width: 582px) {
  .no-tooltip{
    display: none;
  }
  .banner-step .banner-title>h1>p {
    font-size: 2.5rem;
  }
  .banner-step .banner-title>h3{
    font-size: 1.7rem;
  }
  .banner-step .banner-links>a>img {
    padding-top: 12px;
    width: 90%;
}  
}

@media only screen and (max-width: 523px) {
  .no-tooltip{
    display: none;
  }
  .banner-container{
    height: 140px;
  }
  .banner-step .banner-title>h1>p {
    font-size: 2.2rem;
  }
  .banner-step .banner-title>h3{
    font-size: 1.7rem;
  }
  .banner-step .banner-links>a>img {
    padding-top: 7px;
    width: 90%;
}  
}

@media only screen and (max-width: 464px) {
  .no-tooltip{
    display: none;
  }
  .banner-container{
    height: 120px;
  }
  .banner-step .banner-title>h1>p {
    font-size: 1.5rem;
  }
  .banner-step .banner-title>h3{
    font-size: 1.1rem;
  }
  .banner-step .banner-links>a>img {
    padding-top: 7px;
    width: 90%;
}  
}

@media only screen and (max-width: 350px) {
  .no-tooltip{
    display: none;
  }
  .banner-container{
    height: 100px;
  }
  .banner-step .banner-title>h1>p {
    font-size: 1.3rem;
  }
  .banner-step .banner-title>h3{
    font-size: 0.9rem;
  }
  .banner-step .banner-links>a>img {
    padding-top: 3px;
    width: 75%;
}  
}
.marketview_container_banex {
  display: flex;
  padding: 0 10px 0 2px;
  height: 410px;
}

.marketview_container_banex_child>div {
  width: 100%;
}

.marketview_container_banex .row-market {
  display: block;
  width: 100%;
  padding: 10px 0;
  text-align: center;
}

.marketview_container_banex .row-market>b {
  display: block;
}

.marketview_container_banex .row-market>b.header {
  font-size: 1.7rem;
}

.right_info_marketview {
  width: 25rem;
  background: white;
  border-radius: .3rem;
}

/* TABLE OVERVIEW*/
.market-overview-container-table-container{
  background-color: var(--pc__background-secondary_v2);
  width: 100%;
  border-radius: 1.6rem;
}
.market-overview-container-table-container .table-container{
  width: 100%;
  position: relative;
}
.market-overview-container-table-container .table-container .overview-data{
  display: none;
}
.market-overview-container-table-container .table-container .overview-data.data-active{
  display: block;
}
.market-overview-container-header{
  padding: 1.5rem 3.5rem;
  background-color: var(--bg-dark-v2);
  display: flex;
  column-gap: 6rem;
  align-items: center;
  border-radius: 1.6rem;
}
.market-overview-container-header h2{
  margin: 0;
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--text-gray-v2);
  cursor: pointer;
  position: relative;
}
.market-overview-container-header .tab-active{
  color: var(--text-light);
}
.market-overview-container-header .tab-active::after{
  content: '';
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: var(--orange-color);
  animation: tabSelect .3s;
  animation-fill-mode: forwards;

}
.market-overview-container-header a{
  background: var(--primary-color-v2);
  padding: 0.7rem 1rem;
  border-radius: 1rem;
  color: var(--pc_font-primary);
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
}
.divTable{
  display: table;
  width: 100%;
  margin: 2rem auto;
  min-width: 920px;
  overflow: auto;
  padding: 0 15px;
}
.divTable.divTable-v2 {
  min-width: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}
.divTable.divTable-v2 .divTableRow.header .divTableCell.desk:last-child{
  text-align: left;
}
.divTable .no-data-text {
  font-size: 1.2rem;
  padding: 1rem 3rem;
}
.divTableRow {
  display: table-row;
}
.divTableRow:not(.header):hover {
  background-color: var(--bg-dark-v2);
}
.divTableRow.selector-detail {
  cursor: pointer;
}
.divTableRow .instrument-cell{
  display: grid;
  gap: 1rem;
  grid-template-columns: 100px 30px 110px;
}
.instrument-cell-element{
  display: flex;
  align-items: center;
}
.instrument-cell-element img{
  margin-right: 1.5rem;
}
.divTableRow p,
.divTableRow span{
  font-size: 1.4rem;
}
.divTableHeading {
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  display: table-cell;
  padding: 10px 6px;
}
.divTableCell .mob {
  display: none;
}
.divTableCell .btn-bull-dash {
  width: 100%;
  text-align: center;
  max-width: 126px;
  min-width: 126px;
}
.divTableCell.text,
.divTableHead {
  width: 138px;
  text-align: center;
}

.divTableCell.text.red span,
.divTableCell.text.green span{
  font-size: 1.4rem!important;
}
.divTableCell.text.red span {
  color: var(--error-color-v2)!important;
}
.divTableCell.text.green span {
  color: var(--success-color-v2)!important;
}
.divTableCell.text span:first-child{
  color: var(--text-gray-v2);
  margin-right: 5px;
}
.divTableCell.text.green,
.divTableCell.text.red{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.divTableCell.text.green > div{
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--success-color-v2);
  width: 10px;
}
.divTableCell.text.red > div{
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--error-color-v2);
  width: 10px;
}
.divTableCell .icon-current {
  margin-bottom: 0.7rem;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.divTableCell .symbol-current {
  padding: 0rem 2rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.divTableCell .name-current {
  color: var(--text-gray-v2);
  font-size: 1.2rem;
}
.divTableCell .subtitle-current {
  color: var(--text-gray-v2);
  font-size: 1.4rem;
  margin: 0;
  margin-top: 0.5rem;
}
.divTableCell .text-v2 {
  font-size: 1.4rem;
  font-weight: 500;
}
.divTableCell .text-v2 span {
  color: var(--text-gray-v2);
  font-size: 1.2rem;
  display: block;
}
.instrument-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.divTableHeading {
  display: table-header-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}
.divTableRow.header .divTableCell.text,
.divTableRow.header .divTableCell.left-text {
  opacity: .5;
}
.divTableRow.header .divTableCell.left-text p,
.divTableRow.header .divTableCell.text p {
  font-size: 1.4rem;
  color: var(--text-gray-v2);
  font-weight: 600;
}
@media only screen and (max-width: 1228px) {
  .market-overview-container-table-container {
    margin: 0 auto;
}
}
.dashboard_page {
  width: 95%;
  max-width: 1200px;
  margin: 2rem auto 0;
}
.dashboard_page > h1 {
  margin-bottom: 2rem;
}
.dashboard_page .content_dashboard_page{
  display: grid;
  row-gap: 1.6rem;
}
.dashboard_page .content_dashboard_page .balance_global_section{
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.6rem;
}
.dashboard_page .content_dashboard_page .balance_global_section .alert.alert-v2{
  margin: 0;
}
.refers-container {
  display: grid;
  grid-template-columns: auto max-content;
  gap: 1.6rem;
  padding: 2rem;
  background: var(--primary-color-v2);
  border-radius: 1.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  align-items: center;
}
.refers-container p{
  margin: 0;
}
.refers-container a{
  background: var(--background-primary_v2)
}
@keyframes tabSelect {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}
@media screen and (max-width: 800px) {
  .dashboard_page .content_dashboard_page .balance_global_section {
    grid-template-columns: auto;
    row-gap: 1.6rem;
  }
}
@media screen and (max-width: 770px) {
  .market-overview-container-header {
    column-gap: 1.5rem;
    padding: 1.5rem;
  }
  .market-overview-container-header h2 {
    font-size: 1.4rem;
    text-align: center;
  }
  .divTableCell.desk {
    display: none;
  }
  .divTableCell .mob {
    display: block;
  }
  .divTableCell .mob button{
    display: block;
  }
  .divTableCell .name-current {
    display: block;
    padding-left: 4.4rem;
    color: var(--text-light);
    margin-bottom: 0rem;
    position: relative;
    bottom: 3px;
    margin-top: 4px;
  }
  .divTableCell .icon-current {
    margin-bottom: -1.1rem;
  }
  .refers-container {
    padding: 1rem;
  }
}
.banexcard-banner{
  background: var(--primary-color-v2);
  border-radius: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  box-shadow: 1px 2px 34px 1px rgba(255,112,58,0.77);
  -webkit-box-shadow: 1px 2px 34px 1px rgba(255,112,58,0.77);
  -moz-box-shadow: 1px 2px 34px 1px rgba(255,112,58,0.77);
  margin: 4rem 0;
}
.banexcard-banner a{
  background: var(--hover-color-v2);
  transition: all .3s;
}
.banexcard-banner a:hover{
  transform: translateY(-3px);
}
.banexcard-banner span{
  font-size: 1.6rem;
}
.banexcard-banner .congratulations{
  font-size: 2rem;
  font-weight: 500;
  color: var(--text-light);
  margin-right: 10px;
}
@media (width < 600px) {
  .banexcard-banner{
    flex-direction: column;
    justify-content: center;
    padding: 1.6rem 0;
  }
  .banexcard-banner div{
    text-align: center;
  }
  .banexcard-banner a{
    margin-top: 1rem;
  }
  
}