
.wallets-page__accent-bar {
  height: 21.5rem;
  margin-bottom: -21.5rem;
  background-image: var(--accent-bar__gradient);
  width: 100%;
  flex-shrink: 0;
}

.wallets-page__container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1 0 auto;
}

.wallets-page__footer {
  margin-top: 8rem;
  flex-shrink: 0;
}

.wallets-page__wallet-list-container {
  box-shadow: var(--component__box-shadow);
  overflow: hidden;
  border-radius: 1rem;
}

.wallets-page__wallet-list-container-header {
  padding: 3rem 3rem 3.6rem;
  font-size: 2.4rem;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .wallets-page__footer {
    margin-top: 3.4rem;
  }
}