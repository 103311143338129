.request-status-page{
  display: grid;
  place-content: center;
  min-height: calc(100vh - 80px);
}
.request-status-page .content{
  text-align: center;
}
.request-status-page .content h4{
  font-size: 2rem;
  font-weight: 400;
  margin-top: 2rem;
}
.request-status-page .content p{
  font-size: 1.4rem;
  margin-top: 1.5rem;
  color: var(--text-gray-v2);
  font-weight: 300;
}
.request-status-max-w{
  max-width: 420px;
}