
.banexcard-footer{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: var(--bg-dark-v2);
  padding: 1.2rem 1rem;
  height: 60px;
  border-radius: 1.6rem;
}
.banexcard-footer .button{
  max-width: 120px;
  padding: .6rem 1rem;
}
.banexcard-footer{
  justify-content: center;
  gap: 2rem;
}
.banexcard-footer p,
.banexcard-footer span{
  font-size: 1.4rem;
  color: var(--text-gray-v2);
}
.banexcard-footer p strong{
  color: white;
  font-weight: 600;
}
.banexcard-footer span{
  color: white;
}
.banexcard-footer button{
  background: var(--select-text-v2)!important;
  padding: .4rem!important;
}
.banexcard-footer button p{
  margin: 0;
  font-weight: 400;
}