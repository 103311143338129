.slick-list{
  position: relative;
  width: calc(100% + 9px);
}
.slick-dots{
   display: none!important;
 } 
.slick-slide{
  padding-right: 10px;
 }
.slick-slide .carousel-img{
  border-radius: 1.6rem;
   width: 100%;
 }
.slick-arrow{
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.6em;
  background-color: rgba(255, 255, 255, 0.5);
  color: rgb(255, 255, 255);
  opacity: 1;
  box-shadow: 0 0 2px 0px #333;
  border-radius: 50%;
  border: none;
  width: 50px;
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  outline: none;
  text-align: center;
}
.ArrowContent.left {
  position: absolute;
  height: 100%;
  z-index: 2;
  left: 15px;
}
.ArrowContent.right {
  position: absolute;
  height: 100%;
  z-index: 2;
  right: 15px;
  top: 0;
}
.ArrowContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-arrow:hover{
  color:#000;
}
.img-with{
  text-align: -webkit-center;
}
.img-with a{
  width: 100%;
}

.link-none{
  pointer-events: none;
}
@media screen and (max-width: 425px) {
  .slick-slide .carousel-img{
    height: 200px;
  }
}