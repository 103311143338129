.page-header__container{
  background: var(--nav-header__bg-gradient);
  box-shadow: 0 4px 0 0 var(--nav-item__bg-color--selected),
  0 2px 2rem 0 var(--component__box-shadow-color);
  margin-bottom: 4px;
}

/*.page-header__content-wrapper {
  max-width: var(--nav-header__max-width);
  position: relative;
  display: flex;
  flex-shrink: 0;
  z-index: var(--z-index__navigation);
  margin: 0 auto;
  height: var(--nav-header__height);
  background: var(--nav-header__bg-gradient);
}*/

.page-header__content-wrapper {
  max-width: var(--nav-header__max-width);
  position: relative;
  display: flex;
  flex-shrink: 0;
  z-index: var(--z-index__navigation);
  margin: 0 auto;
  height: var(--nav-header__height);
  background: var(--nav-header__bg-gradient);
}

.page-header__header-item {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 16rem;
  padding-top: 0rem;
  /*border-right: 1px solid var(--nav-item__separator-color);*/
  border-color: var(--nav-item__separator-color);
  color: var(--nav-item__color);
}

.page-header__header-item--hoverable {
  cursor: pointer;
}

.page-header__header-item--hoverable:hover,
.page-header__header-item--active {
  background: var(--component__bg-color);
  color: var(--font__color-primary);
}

.page-header__header-item--logo {
  width: 8rem;
  height: 0;
  color: var(--font__color-primary);
  flex-shrink: 0;
  background: var(--pc__background-secondary_v2);
  position: fixed;
  z-index: 320;
  left: 0;
  -webkit-transition: width .5s ease-in-out;
  -moz-transition: width .5s ease-in-out;
  -o-transition: width .5s ease-in-out;
  transition: width .5s ease-in-out;
}

.page-header__header-item--logo .ap-logo .ap-logo__img {
  width: 50%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-header__right-panel {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  padding-top: 1rem;
}

.page-header__icon {
  width: 2rem;
  display: flex;
  align-items: center;
  margin: 0 0.8rem 0 0;
  cursor: pointer;
}

.page-header__icon--logo > svg {
  width: 10rem;
  height: 2.4rem;
}

.page-header__header-item--last {
  position: relative;
  width: 6.5rem;
  cursor: pointer;
  border-right: 0;
}

.page-header__icon--user-settings {
  width: auto;
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  margin: 0;
  margin-bottom: 0.3rem;
}

.page-header__header-item--last {
  display: none;
}
.menu_hover {
  position: fixed;
  top: 0;
  z-index: 999;
}

/* MEDIA QUERIES */

/* Medium Devices, Tablets */
@media only screen and (max-width: 1024px) {
  .menu_hover {
    position: initial;
  }
  .page-header__header-item{
    border-right: 0px solid transparent;
  }

  .page-header__header-item--logo{
    width: 16.2rem;
  }

  .page-header__header-item--logo .ap-logo .ap-logo__img{
    max-width: 12.8rem;
  }

  .page-header__right-panel {
    flex-grow: unset;
  }
}

/* Small Devices, Phones */
@media only screen and (max-width: 720px) {
  .page-header__header-item{
    flex: 1 1;
  }

  .page-header__right-panel {
    order: 3;
    flex-grow: unset;
    position: absolute;
    right: 0;
    height: var(--nav-header__height);
  }
}
@media only screen and (max-width: 1024px) {
  .page-header__header-item--logo {
    height: 6.2rem;
    width: auto;
    position: absolute;
    margin-left: 7rem;
  }
  .page-header-nav__icon--menu-toggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .page-header-nav__menu-toggle {
    min-height: 6.2rem;
  }
  .desplegable-togg, .toggle-bar, .sub-patrimonio {
    display: none!important;
  }
  .page-header-nav__container--closed .menuly{
    display: none !important;
  }
  .menuly {
    position: absolute;
    bottom: 0;
    top: 84px!important;
    height: calc(100vh - 71px);
    background: var(--pc__background-secondary_v2);
    width: 100vw;
    overflow: hidden;
    z-index: 1;
  }
  .ap-icon.ap-icon--exchange, .ap-icon.ap-icon--compra-venta, .ap-icon.ap-icon--easybuy {
    width: auto;
  }
  .page-header-nav__label {
    display: inline-block!important;
    color: var(--pc_font-primary);
  }
  .page-header-nav__item--selected .page-header-nav__label, .page-header-nav__item.page-header-nav__item--selected svg, .page-header-nav__item.page-header-nav__item--selected svg .st0 {
    color: white;
    fill: white;
  }
  @media only screen and (max-width: 1024px) {
    .page-header-nav__icon.ap-icon {
      height: auto;
    }
  }
  /* .page-header-nav__item {
    display: inline-block;
    padding: 1.5rem 0.5rem!important;
    margin: 0;
    vertical-align: top;
    height: 6.2rem!important;
  } 
  .page-header-nav__item .page-header-nav__icon.ap-icon {
    margin-right: 1rem;
  }
  .page-header-nav__item svg .st0, .page-header-nav__item svg {
    fill: black;
    color: rgb(16, 16, 16);
  }
  .page-header-nav__item .__react_component_tooltip {
    display: none!important;
    visibility: hidden;
    opacity: 0;
  }
  .page-header-nav__item[href='/easy-buy'] svg {
    height: 23px;
    width: 23px;
  }*/
  .page-header__container {
    height: 6.2rem;
  }
  /* .page-header-nav__container {
    height: 6.2rem;
  } */
  .page-header-nav__icon--menu-toggle:before {
    display: none;
  }
  .alert-top-bar {
    padding: 1rem 1rem;
    margin: 1rem auto 0rem;
    width: 100%;
    border-radius: 0;
  }
}
.menu-toggle-icon{
  color: white;
  transform: scale(.8);
}
.page-header__header-item.page-header__header-item--logo.open{
  width: 21rem!important;
  transition-duration: 300ms;
  height: 5.2rem;
}
.App.fluid.container.clearfix.logged.dark.with-menu{
  width: calc(100% - 21rem)!important;
  margin-left: 21rem!important;
} 
.App.fluid.container.clearfix.logged.dark{
  transition: 100ms all;
}
.logo-opened-menu{
  display: none;
}
.logo-closed-menu{
  display: block;
}
@media (width < 1025px) {
  .logo-closed-menu{
    display: none;
  }
}