
/* reusable classes */
.display-flex {
    display: flex;
}
/* V2 Bnx UI by David Arrarte */
.bnx-v2-general-container {
    background: var(--background-primary_v2);
    padding: 0;
}
.bnx-v2-general-container::before {
    content: '';
    background-color: var(--background-primary_v2);
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
}
.bnx-v2-general-container .container {
    max-width: 1366px;
    padding-bottom: 2rem;
}
.bnx-v2-general-container .container.fix-clear-top {
    margin-top: 0rem;
}
.bnx-v2-general-container .container.fix-clear-top.mt-header {
    margin-top: -14rem;
}
.bnx-v2-general-container .container.fix-clear-top.oneblock-center.mt-header {
    margin-top: -4rem;
}
.bnx-v2-general-container .container.fix-clear-top.oneblock-center.mt-header .bnxv2-box-rounded.fix-height {
    height: auto;
    display: block;
    border-radius: 2.5rem;
}
.bnx-v2-general-container .container.fix-clear-top.display-flex.oneblock-center > div > div {
    margin: auto;
}
.bnx-v2-general-container .container.display-flex {
    display: flex;
    padding-bottom: 0;
}
.bnx-v2-general-container .container.display-flex > div {
    flex-grow: 1;
}
.bnx-v2-general-container .container.display-flex > div .bnx-left-general {
    margin-top: 14rem;
}
.bnx-v2-general-container .container.display-flex > div .bnx-left-general, .bnx-v2-general-container .container.display-flex > div .bnx-right-general {
    display: inline-flex;
    align-items: center;
    padding: 0;
}
.bnx-v2-general-container .container.display-flex > div .bnx-left-general img.portal-img {
    display: block;
    width: 50%;
    margin: auto;
    height: min-content;
}
.bnx-v2-general-container .header-container {
    padding: 3rem 0 3rem;
    height: 14rem;
    background: var(--background-primary_v2);
}
.bnx-v2-general-container .header-container a {
    width: 100%;
}
.bnx-v2-general-container .header-container .logo-bnx {
    width: 22%;
}
.bnx-v2-general-container .container .horizontal-complete {
    width: 100%;
}
.link-signup-select {
    background-color: var(--pc__background-secondary_v2);
    width: 100%;
    height: 100%;
    border-radius: 2.5rem;
    margin: -2rem 0 1.5rem;
    padding: 4rem 2rem;
    box-shadow: 0 0 18px 1px rgb(66 65 65 / 18%);
    transition: box-shadow 0.2s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: stretch;
}
.link-signup-select:hover {
    box-shadow: 0 0 18px 1px rgb(25 25 25 / 30%);
}
.link-signup-select .titlex {
    font-size: 2.4rem;
    color: var(--pc_font-primary);
    font-weight: 550;
    width: 100%;
    line-height: 1.3;
}
.link-signup-select .titlex > b {
    font-weight: 500;
    color:  var(--btn-checked-v2);
}
.link-signup-select .titlex-sub {
    font-weight: 550;
    color: var(--pc_font-primary);;
    font-size: 1.6rem;
    display: block;
}
.link-signup-select .rounded-bg {
    padding: 0;
    width: 100%;
    border-radius: 0 0.5rem 0.5rem 0;
    height: 200px;
}
.link-signup-select .rounded-bg > img {
    z-index: 9;
    width: 50%;
    max-width: 180px;
    margin: auto;
}
.bnx-v2-general-container .bnxv2-box-rounded {
    background: var(--background-primary_v2);
    padding: 2.5rem 3rem;
    /* border-radius: 2.5rem 0 0 2.5rem;
    box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%); */
    width: 100%;
}
.bnx-v2-general-container .bnxv2-box-rounded.fix-height {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.bnx-v2-general-container .bnxv2-box-rounded .title-form {
    margin-bottom: 0.5rem;
}
.bnx-v2-general-container .bnxv2-box-rounded .title-form, .bnx-v2-general-container .bnxv2-box-rounded .title-form > b {
    font-weight: 550;
    font-size: 2.3rem;
}
.bnx-v2-general-container .bnxv2-box-rounded .title-form {
    color: var( --pc_font-primary);
}
.bnx-v2-general-container .bnxv2-box-rounded .title-form > b {
    color: var( --pc_font-secundary);
}
.bnx-v2-general-container .bnxv2-box-rounded .title-form-normal {
    margin-bottom: 1.5rem;
    font-weight: 600;
    color: var(--pc_font-primary);
}
.bnx-v2-general-container .bnxv2-box-rounded p.title-form-normal {
    font-size: 1.3rem;
}
.bnx-v2-general-container .bnxv2-box-rounded p.title-form-normal > b {
    font-weight: inherit;
    color: var(--pc_font-secundary);
}
.bnx-v2-general-container .bnxv2-box-rounded .subtitle-form {
    color: var(--pc_font-primary);
}
.bnx-v2-general-container .bnxv2-box-rounded h4.subtitle-form > b {
    color: #FD9025;
    font-weight: inherit;
}
.bnx-v2-general-container .bnxv2-box-rounded .form-group select.form-control {
    color: #fff !important;
}
.bnx-v2-general-container .bnxv2-box-rounded .label-bnxv2 {
    font-weight: 600;
    font-size: 1.3rem;
}
.bnx-v2-general-container .bnxv2-box-rounded .bottom-box-v2, .bnx-v2-general-container .bnxv2-box-rounded .bottom-box-v2 > a {
    font-weight: 550;
    font-size: 1.4rem;
    color: var(--pc_font-secundary)
}
.bnx-v2-general-container .bnxv2-box-rounded .bottom-box-v2 > a {
    color: var(--pc_font-secundary);
    font-weight: 500;
}

.justify-content-center.problems-1{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 44px;
    margin: 2.5rem;
    box-sizing: border-box;
    padding-top: 2.5rem;
}
.justify-content-center.problems-1 div:first-child{
    margin: 0;
}
.justify-content-center.problems-1 div:last-child{
    margin: 0;
    text-align: right;
}

.justify-content-center.problems-2 {
    margin-top: 0!important;
}

.justify-content-center .problems-text{
 margin: 16px;
}


.bnx-v2-general-container .bnxv2-box-rounded .bottom-box-v2 .bottom-link {
    font-size: 1.4rem;
    color: #fff !important;
}
.bnx-v2-general-container .bnxv2-box-rounded .bottom-box-v2 .right-border {
    border-right: 2px solid #9c9c9c;
}
.bnx-v2-general-container .bnxv2-box-rounded .bottom-box-v2.normal > a {
    color: var(--pc_font-primary);
    font-weight: 500;
}
.bnx-v2-general-container .bnxv2-box-rounded .ilustration-img {
    margin: 2rem 0 3rem;
    width: 60%;
}
.bnx-v2-general-container .bnxv2-box-rounded .error-msg {
    text-align: center;
    color: var(--error-color-v2);
    font-weight: 500;
}
.bnx-v2-general-container .bnxv2-box-rounded .error-msg > div {
    font-size: 1.3rem;
}
.bnx-v2-step {
    width: 100%;
    padding: 2rem 0 1.5rem;
    text-align: center;
}
.bnx-v2-step .circle-step {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 0.3rem;
    border-radius: 3rem;
    background-color: #C6C6C6;
}
.bnx-v2-step .circle-step.active {
    background-color: #FD9025;
}
.bnx-v2-general-container .display-flex-oneline {
    display: flex;
}
.bnx-v2-general-container .display-flex-oneline > * {
    width: 50%;
}
.bnx-v2-general-container .container-new-card-v2{
    padding: 0 20rem;
}
.bnx-v2-general-container .container-new-card-v2 >div:first-child{
    display: grid;
    grid-template-columns: auto 556px;
}
.bnx-v2-general-container .container-new-card-v2 >div:first-child >div{
    flex: initial;
    max-width: initial;
}
.bnx-v2-general-container .container-new-card-v2 >div:first-child >div:first-child img{
    margin: initial !important;
    margin-left: -10rem !important;
    width: 90% !important;
}

.options-signup{
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.options-signup > div{
    width: 100%;
}
.options-signup .link-register-bottom span,
.options-signup .link-register-bottom a{
    font-size: 2rem;
}
.options-signup .link-register-bottom a{
    color: var(--orange-color);
}
.options-signup h2{
    font-size: 3rem;
}
.options-signup h2 b{
    color: var(--orange-color);
}

.container-options-signup{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4rem;
    margin: 2rem auto;
}

.option-register-card{
    height: 400px;
    margin: 2rem 0;
    max-width: 400px;
    width: 90%;
}
@media (max-width: 1201px) {
    .no-tablet {
        display: none!important;
    }
}
@media (max-width: 1200px){
    #root, .App.container {
        width: 100%;
        max-width: 100%;
    }
    .bnx-v2-general-container {
        width: 100%;
    }
    .bnx-v2-general-container .container.display-flex > div .bnx-left-general, .bnx-v2-general-container .container.display-flex > div .bnx-right-general {
        display: block;
    }
    .bnx-v2-general-container .container.display-flex > div .bnx-left-general img.portal-img {
        width: 26%;
        display: block;
        margin: 0 auto 3rem;
    }
    .bnx-v2-general-container .header-container .logo-bnx {
        display: block;
        margin: 0 auto 3rem;
    }
    .bnx-v2-general-container .container.fix-clear-top.mt-header {
        margin-top: 0rem;
    }
    .bnx-v2-general-container .bnxv2-box-rounded .bottom-box-v2 {
        text-align: center!important;
    }
    .bnx-v2-general-container .bnxv2-box-rounded.fix-height {
        height: 100%;
    }
    .bnx-v2-general-container .container .horizontal-complete {
        margin: 0;
    }
    .bnx-v2-general-container .container .horizontal-complete.responsive-oneline {
        display: flex;
    }
    .bnx-v2-general-container .container .horizontal-complete.responsive-oneline > * {
        width: 50%;
        padding-right: 5px;
        padding-left: 5px;
    }
    .bnx-v2-general-container .container-new-card-v2 >div:first-child{
        grid-template-columns: auto;
    }
}
@media (max-width: 995px){
    .bnx-v2-general-container .header-container {
        height: 12rem;
    }
    .bnx-v2-general-container .header-container .logo-bnx {
        width: 32%;
    }
    .options-signup{
        height: auto;
        padding-top: 150px;
    }
}
@media only screen and (max-width: 768px) {
    .bnx-v2-general-container .container-new-card-v2{
        padding: 0 1rem;
    }
}
@media (max-width: 640px){
    .bnx-v2-general-container .header-container .logo-bnx {
        width: 50%;
    }
    .bnx-v2-general-container .container.fix-clear-top {
        margin-top: 0rem;
    }
    .bnx-v2-general-container .container.display-flex > div .bnx-left-general img.portal-img {
        width: 50%;
    }
    .bnx-v2-general-container .bnxv2-box-rounded .title-form, .bnx-v2-general-container .bnxv2-box-rounded .title-form > b {
        font-weight: 600;
        font-size: 2.2rem!important;
    }
    .link-signup-select {
        padding: 3rem 1rem;
    }
    .link-signup-select .rounded-bg > img {
        width: 70%;
    }
    .link-signup-select .titlex {
        font-size: 1.6rem;
    }
    .link-signup-select .titlex-sub {
        font-size: 1.3rem;
    }
    .bnx-v2-general-container .container.fix-clear-top.oneblock-center.mt-header {
        margin-top: 0rem;
    }
}
@media (max-width: 540px){
    .options-signup h2{
        padding: 0;
        font-size: 2rem;
    }
    .bnx-v2-general-container .container.fix-clear-top {
        margin-top: 0rem;
    }
    .bnx-v2-general-container .container .horizontal-complete.responsive-oneline {
        display: block;
    }
    .bnx-v2-general-container .container .horizontal-complete.responsive-oneline > * {
        width: 100%;
    }
    .link-signup-select {
        padding: 2rem 1rem;
        margin-bottom: 4rem;
    }
    .link-signup-select > .no-mobile {
        display: none;
    }
    .link-signup-select > .rounded-bg > img {
        width: 90%;
    }
    .link-signup-select > .righted {
        width: 60%;
        margin: 1rem auto!important;
    }
    .bnx-v2-general-container .bnxv2-box-rounded p.title-form-normal {
        text-align: justify;
    }
    .v2-field.radius-group .form-check .form-check-label {
        line-height: 1.9;
        vertical-align: top;
    }
}
@media (max-width: 400px){
    .bnx-v2-general-container .header-container .logo-bnx {
        width: 70%;
    }
    .bnx-v2-general-container .container.fix-clear-top {
        margin-top: 0rem;
    }
    .bnx-v2-general-container .bnxv2-box-rounded {
        padding: 1.5rem 2rem;
    }
}
