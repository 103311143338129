.advanced-order-sidepane__tab-container {
  padding: 0;
  background: var(--advanced-order__sidepane-tab-container-bg-color);
}

.advanced-order-sidepane__tab {
  display: inline-block;
  line-height: 3rem;
  padding: 0 2.5rem;
  font-size: 1.6rem;
  cursor: pointer;
  color: var(--component__color-secondary);
  width: 50%;
  text-align: center;
}

.advanced-order-sidepane__tab:hover {
  color: var(--component__color-primary);
}

.advanced-order-sidepane__tab--buy-selected {
  color: #5dd267!important;
  border-bottom: 2px solid #5dd267;
}

.advanced-order-sidepane__tab--sell-selected {
  color: #ff4167!important;
  border-bottom: 2px solid #ff4167;
}

@media only screen and (max-width: 480px) {
  .advanced-order-sidepane__container--with-tabs .slide-pane__content {
    overflow: visible;
  }

  .advanced-order-form__body {
    overflow: visible;
    min-height: fit-content;
  }

  .slide-pane.advanced-order-sidepane__container--with-tabs {
    overflow-y: scroll;
    background: var(--side-pane__content-bg-color);
    -webkit-overflow-scrolling: touch;
  }

  .advanced-order-form__btn {
    margin-bottom: 2rem;
  }
}
