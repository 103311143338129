.snackbar {
  min-height: 52px;
  color: var(--font__color-primary);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
  padding: 2rem;
  position: relative;
}

.snackbar--info {
  background: var(--information__color);
}

.snackbar--success {
  background: var(--success__color);
}

.snackbar--warning {
  background: var(--danger__color);
}

.snackbar__text,
.snackbar__close {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  vertical-align: middle;
}
