.language_v2 {
    background: var(--pc__background-secondary_v2) !important;
    border: none !important;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    margin: 0;
    align-items: center;
    padding: 1rem 2.2rem;
    width: 100%;
}
.language_v2:after {
    content: none !important;
}
.language_v2:hover,
.language_v2:active,
.language_v2:focus {
    background:  var(--background-primary_v2) !important;
    border: none !important;
    border-color: none !important;
    box-shadow: none !important;
}
.language_v2 .cont_icon {
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
}
.language_v2 p {
    display: none;
    margin: 0;
    font-weight: 600;
    font-size: 1.2rem;
    padding: 0;
    padding-left: 0.7rem;
}
.language_v2 svg {
    font-size: 1.1rem;
    margin-left: 0.7rem;
}
.language_v2_items {
    display: none;
    transform: translate(0px, -40px) !important;
    width: 100%;
    background: var(--bg-dark-v2) !important;
}
.language_v2_items button {
    padding: 1rem 2.5rem !important;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: capitalize !important;
    align-items: center;
    column-gap: 1rem;
    display: none;
}
.language_v2_items button:hover {
    color: var(--text-light);
}
.language_v2_items .lang-img {
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    min-width: 20px;
}
.page-header-nav__label .language_v2_items .dropdown-menu .show {
    display: none;
}
@media screen and (max-width: 1024px) {
    .language_v2{
        padding: 1.8rem 2rem;
    }
    .language_v2_items {
        transform: translate(0px, 0px) !important;
    }
    .language_v2_items button {
        display: flex !important;
        padding: 1.8rem 2rem !important;
    }
}
