.transfers__wrapper {
  margin-bottom: var(--wallet-details__body-margin);
  width: 100%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background: var(--component__bg-color);
  overflow: hidden;
  box-shadow: 0 0.5rem 2.5rem 0 var(--shadow__color);
}

.transfers__body {
  max-height: 52rem;
}

.transfers {
  border-radius: 1rem;
  background: var(--component__bg-color);
  overflow: hidden;
  box-shadow: 0 0.5rem 2.5rem 0 var(--shadow__color);
}
.transfers__header-column,
.transfers__column {
  text-align: left;
  flex-grow: 1;
}

.transfers__header-column--amount,
.transfers__column--amount {
  text-align: right;
  padding-right: 2.8rem;
}

.transfers__header-column--sent-by,
.transfers__column--sent-by,
.transfers__column--recipient,
.transfers__header-column--recipient {
  flex-grow: 1.7;
}

.transfers__header-column--status,
.transfers__column--status {
  flex-grow: 0.9;
  padding-left: 0;
}

.transfers__header-column--note,
.transfers__column--note {
  flex-grow: 0.6;
  white-space: nowrap;
}
.transfers__column--note .transfers__primary-text {
  display: inline-block;
}

.transfers__header-column--transfers-action,
.transfers__column--transfers-action {
  flex-grow: 1.5;
}

.transfers__header-column--time,
.transfers__column--time {
  flex-grow: 0.8;
}

.transfers__multiline-col {
  line-height: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.transfers__primary-text,
.transfers__secondary-text {
  padding-left: 2.4rem;
}

.transfers__column:first-child
  .transfers__multiline-col
  .transfers__primary-text,
.transfers__column:first-child
  .transfers__multiline-col
  .transfers__secondary-text {
  padding-left: 1rem;
}

.transfers__primary-text {
  font-size: 1.6rem;
  color: var(--font__color-primary);
}

.transfers__secondary-text {
  font-size: 1.3rem;
}

.transfers__action {
  display: flex;
  align-items: center;
}

.transfers__row {
  min-height: 7rem;
  line-height: 2rem;
  display: flex;
  padding: 0 2rem;
}

.transfers__table-header {
  padding: 0 2rem;
}

.transfers__table-header:nth-child(2) {
  height: 3.3rem;
  margin-top: -3.3rem;
}

.transfers__header-column:first-child .flex-table__header-cell--absolute {
  border-left: none;
}

.transfers__header-column {
  line-height: 3.3rem;
  border-right: 1px solid var(--border__color);
}

.transfers__fixed {
  position: static;
  margin-top: 0;
  padding-left: 2.4rem;
}

.transfers__header-column:first-child .transfers__fixed {
  padding-left: 1rem;
}

.transfers__header-column:last-child {
  border-right: none;
}

.transfers__table-header--background {
  height: 3.3rem;
  border-top: 1px solid var(--table__row-separator-color);
  border-bottom: 2px solid var(--table__header-separator-color);
}

.transfers__quantity-text {
  margin-top: 0.1rem;
  margin-left: 0.5rem;
  font-size: 1.2rem;
}

.transfers__btn {
  height: 3.6rem;
  width: 12.8rem;
  border-radius: 0.4rem;
}

.transfers__btn--clear-filters {
  height: 3.5rem;
  margin-left: 2rem;
  align-self: center;
}

.transfers-pagination__container {
  background: var(--component__header-bg-color);
  height: 6.4rem;
  padding: 0 1.4rem;
}

.transfers-pagination__text {
  height: 3.6rem;
  width: 12.5rem;
  border: 1px solid var(--border__color);
  text-align: center;
  background: var(--background-primary_v2);
}

.transfers-pagination__text--disabled {
  background: transparent;
}

.transfers-pagination__pages {
  line-height: 3.6rem;
  text-align: center;
}

.transfers-pagination__page {
  width: 3.6rem;
  padding: 0;
  background: var(--table__row-bg-color);
  border-right: 1px solid var(--border__color);
  font-size: 1.4rem;
}

.transfers-pagination__page:last-child {
  border: none;
}

.transfers-pagination__page--active {
  background: var(--pagination__bg-color--active);
}

/* Safari specific styles */

/* MEDIA QUERIES */
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .transfers__wrapper {
    width: auto;
    margin: 0;
    border-radius: 0;
  }
  .transfers{
    border-radius: 0;
  }
  .transfers__header-bar {
    display: block;
  }  
  .transfers__body {
    min-width: 80rem;
  }

  .transfers-pagination__text {
    width: unset;
    padding: 0 1rem;
  } 
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .transfers__header-bar {
    padding: 0 1.5rem;
  }

  .transfers__sub-header-bar {
    flex-direction: column;
    justify-content: space-around;
  }

  .transfers__refresh-transfers__btn {
    margin-top: 1rem;
  }
}
