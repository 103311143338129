.coink-app .statusTransaction {
    padding: 72px 12px 82px;
    width: 100%;
    position: relative;
    border-radius: 38pt;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coink-app .statusTransaction.firstType_SUCCESS {
    background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0)),
        linear-gradient(180deg, rgba(93, 210, 103, 0.05), rgba(46, 46, 72, 0.05));
}

.coink-app .statusTransaction.firstType_PENDING {
    background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0)), linear-gradient(180deg, rgba(255, 220, 0, 0.1), rgba(128, 128, 128, 0) 50%);
}

.coink-app .statusTransaction.firstType_ERROR {
    background: linear-gradient(180deg, rgba(242, 32, 32, 0.05), rgba(46, 46, 72, 0.05)),
        linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0));
}

.coink-app .statusTransaction .contentIconStatus {
    width: 114px;
    height: 114px;
    border-radius: 50%;
    border: 9px solid var(--background-primary_v2);
    position: absolute;
    top: -57px;
    padding: 2px;
    background: rgba(63, 63, 89, 1);
}

.coink-app .statusTransaction .contentIconStatus .spaceConIcon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid var(--background-primary_v2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.coink-app .statusTransaction .contentIconStatus .spaceConIcon.secondType_SUCCESS {
    background: rgba(93, 210, 103, 0.05);
}

.coink-app .statusTransaction .contentIconStatus .spaceConIcon.secondType_PENDING {
    background: rgba(255, 220, 0, 0.07);
}

.coink-app .statusTransaction .contentIconStatus .spaceConIcon.secondType_ERROR {
    background: rgba(242, 32, 32, 0.05);
}

.coink-app .statusTransaction .titInfoDetail {
    font-weight: 700;
    font-size: 1.7rem;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    color: var(--text-light);
}
.coink-app .statusTransaction >p {
    color: var(--text-gray-v2);
}
.statusTransaction .titInfoDetail {
    font-weight: 400;
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    color: var(--text-light);
}
.coink-deposit-finish-container .statusTransaction .titInfoDetail {
    font-size: 1.6rem!important;

}