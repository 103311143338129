.statusTransaction {
    padding: 72px 12px 82px;
    width: 90%;
    max-width: 543px;
    position: relative;
    border-radius: 38pt;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(10px);
    animation: fadeIn .8s;
    background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0)),
    linear-gradient(180deg, rgba(93, 210, 103, 0.05), rgba(46, 46, 72, 0.05));
}
.statusTransaction.firstType_SUCCESS {
    background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0)),
        linear-gradient(180deg, rgba(93, 210, 103, 0.05), rgba(46, 46, 72, 0.05));
}
.statusTransaction.firstType_PENDING {
    background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0)),
        linear-gradient(180deg, rgba(255, 220, 0, 0.1), rgba(128, 128, 128, 0) 50%);
}
.statusTransaction.firstType_INFO {
    background: linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0)),
        linear-gradient(180deg, rgba(255, 220, 0, 0.1), rgba(128, 128, 128, 0) 50%);
}
.statusTransaction.firstType_ERROR {
    background: linear-gradient(180deg, rgba(242, 32, 32, 0.05), rgba(46, 46, 72, 0.05)),
        linear-gradient(180deg, rgba(168, 168, 199, 0.14), rgba(128, 128, 128, 0));
}
.statusTransaction.child {
    padding-bottom: 30px;
}
.statusTransaction .contentIconStatus {
    width: 114px;
    height: 114px;
    border-radius: 50%;
    border: 9px solid #2e2e48;
    position: absolute;
    top: -57px;
    padding: 2px;
    background: rgba(63, 63, 89, 1);
}
.statusTransaction .contentIconStatus .spaceConIcon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid #2e2e48;
    display: flex;
    align-items: center;
    justify-content: center;
}
.statusTransaction .contentIconStatus .spaceConIcon.secondType_SUCCESS,
.statusTransaction .contentIconStatus .spaceConIcon.secondType_FULLYPROCESSED {
    background: rgba(93, 210, 103, 0.05);
}
.statusTransaction .contentIconStatus .spaceConIcon.secondType_PENDING,
    .statusTransaction .contentIconStatus .spaceConIcon.secondType_NEW{
    background: rgba(255, 220, 0, 0.07);
}
.statusTransaction .contentIconStatus .spaceConIcon.secondType_ERROR,
.statusTransaction .contentIconStatus .spaceConIcon.secondType_REJECTED
.statusTransaction .contentIconStatus .spaceConIcon.secondType_USERCANCELLED {
    background: rgba(242, 32, 32, 0.05);
}
.statusTransaction .contentIconStatus .spaceConIcon.secondType_PENDING img{
    width: 40px;
}
.statusTransaction .titInfoDetail {
    font-weight: 700;
    font-size: 2.2rem !important;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    color: var(--text-light);
    text-align: center;
}
.statusTransaction > p {
    color: var(--text-gray-v2);
    text-align: center;
    font-size: 1.6rem;
}
.statusTransaction button {
    font-size: 1.6rem;
    font-weight: 400;
}
.statusTransaction > p.white-text {
    color: var(--text-light);
}
.statusTransaction > p .text-link {
    color: var(--text-gray-v2);
}
.statusTransaction > p .text-link.web {
    text-decoration: auto !important;
}
.statusTransaction > p .text-link:active,
.statusTransaction > p .text-link:focus {
    color: var(--text-gray-v2);
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}