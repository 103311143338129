.increment-level {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 80px);
}
.increment-level .container-increment {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 5rem;
    padding: 4rem;
}
.increment-level .container-increment .banexcard-benefits {
    text-align: right;
    padding-top: 5rem;
}
.increment-level .container-increment .banexcard-benefits .benefits-title {
    font-size: 1.8rem;
    margin: 2rem 0;
}
.increment-level .container-increment .banexcard-benefits .benefits-info {
    margin-bottom: 1rem;
}
.increment-level .container-increment .banexcard-benefits .benefits-info p {
    margin: 0;
    margin-right: 1rem;
    display: inline-block;
    font-size: 1.2rem;
}
.increment-level .container-increment .card-increment {
    width: 100%;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.increment-level .container-increment .card-increment .steps-bottom-v2 .step-bttm {
    background: inherit !important;
    margin: 0 0.3rem;
    padding-top: 1px;
    font-weight: 500;
    color: #2b2d3d;
    border: 2px solid;
}
.increment-level .container-increment .card-increment .steps-bottom-v2 .step-bttm.previous {
    background: #2b2d3d !important;
}
.increment-level .container-increment .card-increment .steps-bottom-v2 .step-bttm.active {
    border: 1px solid;
    background: #2b2d3d !important;
    color: var(--text-light);
    font-weight: 300;
}
.increment-level .container-increment .card-increment .steps-bottom-v2 .step-line {
    background: #2b2d3d !important;
    height: 2px;
    width: 3rem;
}
.increment-level .container-increment .card-increment h2 {
    margin: 0;
    font-size: 2.4rem;
}
.increment-level .container-increment .card-increment h2 b {
    color: var(--orange-color);
}
.increment-level .container-increment .card-increment > p {
    margin: 0;
    font-size: 1.4rem;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 a {
    color: var(--orange-color);
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 ol li {
    color: var(--text-gray-v2);
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .row {
    margin-top: 1.5rem;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .message-box.row {
    margin-top: -2rem;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 > div:nth-child(4) {
    padding-top: 1.7rem;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 > div:nth-child(5) .row {
    margin-top: 0;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 > div:nth-child(5) .row .form-group {
    margin-bottom: 0.5rem;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 > div:nth-child(6) .row {
    margin-top: 0.2rem;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 h1,
.increment-level .container-increment .card-increment .inputs-bxn-v2 > :nth-last-child(2),
.increment-level .container-increment .card-increment .inputs-bxn-v2 > :nth-last-child(3) {
    display: none;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-form-bottom button {
    box-shadow: none;
    opacity: 1 !important;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-form-bottom button:last-child {
    background: var(--select-text-v2) !important;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-form-bottom button:disabled{
    opacity: 0.5 !important;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-group-fields h3 {
    font-size: 1.4rem;
    margin: 0;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-group-fields .uploader-btn {
    height: 18px;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-group-fields .input-group,
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-group-fields .input-group:focus {
    border: none !important;
    background: #2b2d3d !important;
    height: 50px;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-group-fields .input-group label {
    margin: 0;
    color: var(--text-gray-v2);
    display: block;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-group-fields .input-group input,
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-group-fields .input-group select {
    height: 1.8rem;
    font-weight: 400;
    -webkit-text-fill-color: initial;
}
.increment-level .container-increment .card-increment .inputs-bxn-v2 .v2-group-fields .input-group input::placeholder {
    font-weight: 400 !important;
}
.increment-level .v2-bnx .v2-box .v2-list-title {
    font-size: inherit;
    margin: inherit;
}
.increment-level .v2-bnx .v2-box .v2-list {
    margin: inherit;
    padding: inherit; 
    list-style: inherit;
    padding-inline-start: 40px;
    margin-bottom: 1rem;
}
.increment-level .v2-bnx .v2-box .v2-list li {
    font-size: inherit;
    margin-bottom: 0;
    font-weight: inherit;
}
.increment-level .v2-bnx .v2-box .big-input .input-group.rounded-circle-box {
    padding: 0.7rem 1.6rem;
}
